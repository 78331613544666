import React from 'react';
import { View, Pressable } from 'react-native';
import MyText from '@atoms/MyText';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import MyModal from '@atoms/MyModal';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { normalize } from '../../../sharedStyle/helper';
import AntDesign from 'react-native-vector-icons/AntDesign';
import InfoTextComponent from '@atoms/InfoTextComponent';
import styles from './style';

const ToolTipModal = ({ isVisible, handleVisible, withdrawalLimit }) => (
  <MyModal
    isVisible={isVisible}
    onPress={handleVisible}
    containerStyle={styles.containerStyle}
  >
    <View style={styles.innerContainer}>
      <View style={styles.rowStyle}>
        <View style={styles.subRow}>
          <View style={styles.iconContainer}>
            <MCIcon
              name="wallet"
              size={normalize(17)}
              color={colors.secondary_orchid}
              style={styles.modalIconStyle}
            />
          </View>

          <MyText isRegular={false} customStyle={styles.limitStyle}>
            Limit GajiGesa
          </MyText>
        </View>

        <Pressable style={styles.crossPressable} onPress={handleVisible}>
          <AntDesign name="close" size={normalize(20)} />
        </Pressable>
      </View>

      <MyText customStyle={styles.witdrawLimitStyle} isRegular={false}>
        {withdrawalLimit}
      </MyText>

      <InfoTextComponent
        customTextStyle={styles.tooltipBannerTextStyle}
        iconContainer={styles.tooltipBannerIconContainerStyle}
        containerStyle={styles.tooltipBannerContainerStyle}
        iconName="info-outline"
        infoText="Jumlah ini merupakan total saldo maksimal yang bisa diakses per periode transaksi/siklus gaji, sesuai dengan ketentuan tempat kerjamu."
        size={normalize(22)}
        iconColor={colors.neutral_charcoal}
      />
    </View>
  </MyModal>
);

export default ToolTipModal;
