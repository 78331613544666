export const COINS_CASES = {
  setCoins: 'setCoins',
  setCoinList: 'setCoinList',
  setUseCoin: 'setUseCoin',
};

const initialState = {
  coinBalance: 0,
  coinList: [],
  useCoin: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case COINS_CASES.setCoins:
      return {
        ...state,
        coinBalance: action.payload,
      };

    case COINS_CASES.setCoinList:
      return {
        ...state,
        coinList: action.payload,
      };

    case COINS_CASES.setUseCoin:
      return {
        ...state,
        useCoin: action.payload,
      };

    default:
      return state;
  }
};
