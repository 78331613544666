import React from 'react';
import { View } from 'react-native';
import MyText from '@atoms/MyText';
import { SVG_ICONS } from '../../../uiUtils/SvgIcons';
import { numberWithDots } from '@gaji-gesa/gg-react-shared/src/utils/sharedUtils';
import { styles } from './style';

const SalaryNetworth = ({ amount }) => (
  <View style={styles.containerStyle}>
    <View style={styles.salaryAccount}>{SVG_ICONS.zakat.salaryAccount}</View>

    <View style={styles.textContainer}>
      <MyText isRegular={false} customStyle={styles.labelText}>
        Akun Gaji
      </MyText>
      <MyText
        isRegular={false}
        customStyle={styles.amountStyle}
      >{`Rp${numberWithDots(amount)}`}</MyText>
    </View>
  </View>
);
export default SalaryNetworth;
