import React from 'react';
import Modal from 'react-native-modal';
const MyModal = ({
  isVisible,
  onPress,
  children,
  containerStyle,
  hasBackdrop = true,
  customBackdrop,
  backdropOpacity = 0.7,
  onModalHide,
  copilot,
  ...props
}) => {
  return (
    <Modal
      {...props}
      {...copilot}
      onSwipeComplete={onPress}
      onModalHide={onModalHide}
      hasBackdrop={hasBackdrop}
      useNativeDriver={true}
      onBackButtonPress={onPress}
      customBackdrop={customBackdrop}
      backdropOpacity={backdropOpacity}
      backdropTransitionOutTiming={0}
      onBackdropPress={onPress}
      isVisible={isVisible}
      swipeDirection="down"
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        margin: 10,
        ...containerStyle,
      }}
    >
      {children}
    </Modal>
  );
};

export default MyModal;
