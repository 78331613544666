import React from 'react';
import { Pressable, View } from 'react-native';
import MyText from '@atoms/MyText';
import { styles } from './style';
import PropTypes from 'prop-types';

const ListHeader = ({ onLinkPress, showLink }) => {
  function shouldShowLlnk(show) {
    if (show)
      return (
        <Pressable onPress={onLinkPress}>
          <MyText isRegular={false} customStyle={styles.TambahNomorHp}>
            Tambah Nomor HP
          </MyText>
        </Pressable>
      );

    return null;
  }

  return (
    <View style={styles.ListHeader}>
      <View style={styles.Group153}>
        <View style={styles.Group761}>
          <MyText isRegular={false} customStyle={styles.NomorHpLainnya}>
            Nomor HP Lainnya
          </MyText>
          <MyText style={styles.Maks10NomorLainnya}>
            (Maks. 10 nomor lainnya)
          </MyText>
        </View>
        {shouldShowLlnk(showLink)}
      </View>
    </View>
  );
};

ListHeader.propTypes = {
  onLinkPress: PropTypes.func.isRequired,
  showLink: PropTypes.bool,
};

export default ListHeader;
