import React from 'react';
import { View, Image } from 'react-native';
import PropTypes from 'prop-types';
import MyText from '@atoms/MyText';
import { styles } from './style';

const ReferralHeader = ({ headerText }) => (
  <View style={styles.infoOuterContainer}>
    <View style={styles.infoLeftSubContainer}>
      <Image
        source={require('@gaji-gesa/gg-react-shared/src/assets/Svg/referral/art_referral.png')}
        resizeMode={'contain'}
        style={styles.rewardLogoImageStyle}
      />
    </View>
    <View style={styles.infoRightSubContainer}>
      <MyText isRegular={false} customStyle={styles.infoTextStyle}>
        {headerText}
      </MyText>
    </View>
  </View>
);
ReferralHeader.propTypes = {
  headerText: PropTypes.string,
};
ReferralHeader.defaultProps = {
  headerText: '',
};
export default ReferralHeader;
