import React from 'react';
import MyText from '@atoms/MyText';
import PropTypes from 'prop-types';
import { styles } from './style';

const ProfileSection = ({ title, showSection, children }) => {
  if (!showSection) return null;
  return (
    <>
      <MyText isRegular={false} customStyle={styles.textStyle}>
        {title}
      </MyText>
      {children}
    </>
  );
};

ProfileSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
  showSection: PropTypes.bool,
};

ProfileSection.defaultProps = {
  showSection: true,
};
export default ProfileSection;
