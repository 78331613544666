import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, View } from 'react-native';

import { colors } from '@style/colors';
import MyText from '@atoms/MyText';
import styles from './style';
import { getDropDownIcon } from './DropDownSelectComponentUtil';

const DropDownSelectComponent = ({
  selectedItem,
  placeholder,
  title,
  isMandatory,
  onPress,
  icon,
}) => {
  return (
    <View style={styles.mainContainer}>
      <MyText h2 customStyle={styles.titleText}>
        {title}
        <MyText customStyle={styles.star}>{isMandatory ? '*' : ''}</MyText>
      </MyText>
      <TouchableOpacity
        onPress={onPress}
        activeOpacity={0.5}
        style={styles.inputContainer}
      >
        <MyText
          customStyle={{
            color: selectedItem === '' ? colors.philippineGray : colors.black,
            flex: 1,
          }}
        >
          {selectedItem === '' ? placeholder : selectedItem}
        </MyText>
        {getDropDownIcon(icon)}
      </TouchableOpacity>
    </View>
  );
};

DropDownSelectComponent.defaultProps = {
  selectedItem: '',
  isMandatory: false,
};

DropDownSelectComponent.propTypes = {
  isMandatory: PropTypes.bool,
  title: PropTypes.string.isRequired,
  selectedItem: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
};

export default DropDownSelectComponent;
