import React from 'react';
import { View } from 'react-native';
import MyText from '@atoms/MyText';
import { SVG_ICONS } from '../../../uiUtils/SvgIcons';
import { styles } from './style';

const Flow = () => (
  <View style={styles.mainContainer}>
    <View style={styles.rowOne}>
      <View style={styles.kamuContainer}>
        <MyText customStyle={styles.kamuStyle}>Kamu</MyText>
      </View>

      <View style={styles.temanuContainer}>
        <MyText customStyle={styles.temanuStyle}> Temanmu </MyText>
      </View>
    </View>

    <View style={styles.rowTwo}>
      <View style={styles.iconContainer}>{SVG_ICONS.referral.refProgOne}</View>

      <View style={styles.arrowContainer}>
        {SVG_ICONS.referral.pointingArrow}
      </View>

      <View style={[styles.iconContainer, styles.redishBack]}>
        {SVG_ICONS.referral.refProgTwo}
      </View>

      <View style={styles.arrowContainer}>
        {SVG_ICONS.referral.pointingArrowRed}
      </View>

      <View style={[styles.iconContainer, styles.redishBack]}>
        {SVG_ICONS.referral.refProgThree}
      </View>
    </View>

    <View style={styles.rowThree}>
      <MyText isRegular={false} customStyle={styles.textOne}>
        Bagikan Kode
      </MyText>
      <MyText isRegular={false} customStyle={styles.textTwo}>
        Gunakan Kode
      </MyText>
      <MyText isRegular={false} customStyle={styles.textThree}>
        Transaksi
      </MyText>
    </View>
  </View>
);

export default Flow;
