import { COMMON_REDUCER_CASES } from '../reducer/Common';

export const respSuccess = (message, otherToastConfig) => ({
  type: COMMON_REDUCER_CASES.respSuccess,
  payload: { message, ...otherToastConfig },
});

export const respError = (message, otherToastConfig) => ({
  type: COMMON_REDUCER_CASES.respError,
  payload: { message, ...otherToastConfig },
});

export const respWarn = (message, otherToastConfig) => ({
  type: COMMON_REDUCER_CASES.warn,
  payload: { message, ...otherToastConfig },
});

export const respReset = (data) => ({
  type: COMMON_REDUCER_CASES.respReset,
  payload: data,
});

export const setShowTerms = (data) => ({
  type: COMMON_REDUCER_CASES.setShowTerms,
  payload: data,
});

export const setAcceptedTerms = (data) => ({
  type: COMMON_REDUCER_CASES.setAcceptedTerms,
  payload: data,
});

export const setLoanTerms = (data) => ({
  type: COMMON_REDUCER_CASES.setLoanTerms,
  payload: data,
});

export const setConfirmed = (data) => ({
  type: COMMON_REDUCER_CASES.setConfirmed,
  payload: data,
});

export const updateShowModal = (data) => ({
  type: COMMON_REDUCER_CASES.updateShowModal,
  payload: data,
});

export const setPageFailed = (data) => ({
  type: COMMON_REDUCER_CASES.setPageFailed,
  payload: data,
});

export const resetModalType = (data) => ({
  type: COMMON_REDUCER_CASES.resetModalType,
  payload: data,
});

export const setShowModal = (data) => ({
  type: COMMON_REDUCER_CASES.setShowModal,
  payload: data,
});

export const setModalType = (data) => ({
  type: COMMON_REDUCER_CASES.setModalType,
  payload: data,
});

export const setCanContinue = (data) => ({
  type: COMMON_REDUCER_CASES.setCanContinue,
  payload: data,
});

export const setDisabledSubmit = (data) => ({
  type: COMMON_REDUCER_CASES.setDisabledSubmit,
  payload: data,
});

export const setDisabledDate = (data) => ({
  type: COMMON_REDUCER_CASES.setDisabledDate,
  payload: data,
});

export const setDisabledToday = (data) => ({
  type: COMMON_REDUCER_CASES.setDisabledToday,
  payload: data,
});

export const setGlobalLoading = (data) => ({
  type: COMMON_REDUCER_CASES.setGlobalLoading,
  payload: data,
});

export const hideToast = () => ({
  type: COMMON_REDUCER_CASES.setToast,
  payload: {
    visible: false,
    message: '',
    duration: 0,
    gravity: '',
  },
});

export const setParentPage = (page) => ({
  type: COMMON_REDUCER_CASES.setParentPage,
  payload: page,
});

export const setTour = (data) => ({
  type: COMMON_REDUCER_CASES.setTour,
  payload: data,
});

export const setStoriesCommon = (data, value) => ({
  type: COMMON_REDUCER_CASES.setStories,
  payload: {
    stories: data,
    showStories: value,
  },
});

export const setSource = (source) => ({
  type: COMMON_REDUCER_CASES.setSource,
  payload: source,
});

export const setActiveSavingDetailsTab = (source) => ({
  type: COMMON_REDUCER_CASES.setActiveSavingDetailsTab,
  payload: source,
});

export const setAppTourParams = (payload) => ({
  type: COMMON_REDUCER_CASES.setAppTourParams,
  payload,
});

export const resetAppTourParams = () => ({
  type: COMMON_REDUCER_CASES.resetAppTourParams,
});

export const setSeenProfile = (data) => ({
  type: COMMON_REDUCER_CASES.setSeenProfile,
  payload: data,
});

export const setSeenLoan = (data) => ({
  type: COMMON_REDUCER_CASES.setSeenLoan,
  payload: data,
});

export const setSeenZakat = (data) => ({
  type: COMMON_REDUCER_CASES.setSeenZakat,
  payload: data,
});

export const setPWAUpdated = (data) => ({
  type: COMMON_REDUCER_CASES.setLatestPWA,
  payload: data,
});

export const setShowPinRegister = (data) => ({
  type: COMMON_REDUCER_CASES.setShowPinRegister,
  payload: data,
});

export const setVerifiedPin = (data) => ({
  type: COMMON_REDUCER_CASES.setVerifiedPin,
  payload: data,
});
