import React from 'react';
import { Pressable, View, StyleSheet } from 'react-native';
import { colors } from '@style/colors';
import MyText from '@atoms/MyText';

function textCustomStyles(isDark) {
  return {
    color: isDark ? colors.white : colors.orange,
    paddingVertical: 10,
  };
}

const RoundedButton = ({ title, onPress, isDark = true, container }) => {
  return (
    <View style={[styles.container, container]}>
      <Pressable
        style={isDark ? styles.darkButton : styles.lightButton}
        onPress={onPress}
        android_ripple={{ color: colors.lightGrey }}
      >
        <MyText customStyle={textCustomStyles(isDark)}>{title}</MyText>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  darkButton: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.orange,
  },
  lightButton: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFDBCF',
  },
  container: {
    borderRadius: 20,
    overflow: 'hidden',
    flex: 1,
  },
});

export default RoundedButton;
