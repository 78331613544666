import React, { useRef } from 'react';
import {
  TouchableOpacity,
  Dimensions,
  StyleSheet,
  Platform,
} from 'react-native';
import ExpoAnt from '@expo/vector-icons/AntDesign';
import RNAnt from 'react-native-vector-icons/AntDesign';
import ModalDropdown from 'react-native-modal-dropdown/components/ModalDropdown';
import { normalize } from '@style/helper';
import { Font } from '@style/Fonts';
import { colors } from '@style/colors';
import Divider from '@atoms/Divider';
const { width: screenWidth } = Dimensions.get('window');

const AIcon = Platform.OS === 'web' ? ExpoAnt : RNAnt;

const DropdownInput = ({
  styleFocus,
  style,
  options,
  defaultValue,
  onSelect,
  textStyle,
  dropTextStyle,
  onDropdownWillShow,
  onDropdownWillHide,
  dropStyle,
}) => {
  let dropdownRef = useRef();
  return (
    <TouchableOpacity
      accessibilityLabel="savedAccountInput"
      style={[style, dropDown.container, styleFocus]}
      onPress={() => {
        dropdownRef.current.show();
      }}
    >
      <ModalDropdown
        ref={dropdownRef}
        options={options}
        defaultValue={defaultValue}
        textStyle={[dropDown.text, textStyle]}
        dropdownStyle={[dropDown.style, dropStyle]}
        dropdownTextHighlightStyle={dropDown.textHighlight}
        dropdownTextStyle={[dropDown.dropText, dropTextStyle]}
        onSelect={onSelect}
        onDropdownWillShow={onDropdownWillShow}
        renderSeparator={() => (
          <Divider horizontal customStyle={{ marginVertical: 5 }} />
        )}
        onDropdownWillHide={onDropdownWillHide}
      />
      <AIcon
        name="caretdown"
        style={dropDown.icon}
        onPress={() => dropdownRef.current.show()}
      />
    </TouchableOpacity>
  );
};

const dropDown = StyleSheet.create({
  container: {
    borderWidth: 1.5,
    borderRadius: 12,
    maxHeight: '85%',
    padding: 8,
    borderColor: '#d4d4d4',
    width: screenWidth * 0.81,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'space-between',
    paddingRight: 15,
    paddingVertical: 12,
  },
  text: {
    fontSize: normalize(15),
    color: colors.red,
    fontFamily: Font.Nunito_Regular,
  },
  style: {
    width: '80%',
    maxHeight: '50%',
    marginTop: '4%',
    borderRadius: 14,
    overflow: 'hidden',
    borderWidth: 1,
    marginLeft: -10,
  },
  textHighlight: {
    backgroundColor: '#f56b57',
    color: 'white',
    borderRadius: 14,
  },
  dropText: {
    fontSize: 17,
    fontFamily: Font.Nunito_Regular,
  },
  icon: { fontSize: 13, color: '#d4d4d4', marginLeft: 5 },
});

export default DropdownInput;
