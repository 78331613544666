// let API_URL = '';
// let API_GAJITIM = '';
// let API_LOAN_URL = '';
// let ENV_SENTRY=''
// let GRAPHQL_URL=''
// let MEDIA_STORAGE=''
// let WHATSAPP_URL=''
// let AMPLITUDE_API_KEY=''
// let DYNAMICLINK_API_KEY=''

let envObject = {};
let storageHandler = null;
let sentryHandler = null;
let xApiKey = '';
let axiosSuccessCallBack = () => {};
let axiosFailureCallBack = () => {};

export function setEnvironmentInShared(env) {
  envObject = JSON.parse(JSON.stringify(env));
  // debugger;
  // API_URL = env.API_URL;
  // API_GAJITIM = env.API_GAJITIM;
  // API_LOAN_URL = env.API_LOAN_URL
  // ENV_SENTRY = env.ENV_SENTRY;
  // GRAPHQL_URL = env.GRAPHQL_URL;
  // MEDIA_STORAGE = env.MEDIA_STORAGE;
  // WHATSAPP_URL = env.WHATSAPP_URL;
  // AMPLITUDE_API_KEY = env.AMPLITUDE_API_KEY;
  // DYNAMICLINK_API_KEY = env.DYNAMICLINK_API_KEY
}

export function setStorageHandler(storageObject) {
  storageHandler = storageObject;
}

export function setSentryHandler(sentryObj) {
  sentryHandler = sentryObj;
}

export function setXAPIKey(xApiKeyValue) {
  xApiKey = xApiKeyValue;
}

export function setInterceptorCallBacks(successCallBack, failureCallBack) {
  axiosSuccessCallBack = successCallBack;
  axiosFailureCallBack = failureCallBack;
}

export {
  // API_URL, API_GAJITIM,API_LOAN_URL,ENV_SENTRY,GRAPHQL_URL,MEDIA_STORAGE,WHATSAPP_URL,AMPLITUDE_API_KEY,DYNAMICLINK_API_KEY,
  envObject,
  storageHandler,
  sentryHandler,
  xApiKey,
  axiosSuccessCallBack,
  axiosFailureCallBack,
};
