import { gql } from '@apollo/client';

const CHECK_INVENTORY = gql`
  query checkInventory {
    voucher_list(
      where: {
        status: { _eq: AVAILABLE }
        transactionId: { _is_null: true }
        employeeId: { _is_null: true }
        purchasedDate: { _is_null: true }
        redeemedDate: { _is_null: true }
        expiryDate: { _is_null: true }
      }
    ) {
      status
    }
  }
`;

const FETCH_EMPLOYEE_VOUCHER = gql`
  query getFeatures($productCode: String) {
    voucher_list(order_by: { purchasedDate: desc }, where: { productCode: { _eq: $productCode } }) {
      status
      voucherCode
      productCode
      expiryDate
    }
  }
`;

export { CHECK_INVENTORY, FETCH_EMPLOYEE_VOUCHER };
