export const twSharedSharedDateContainerStyles = `justify-center mt-0 w-full h-50px bg-secondary_orchid`;
export const twSharedBalanceComponent = `flex-row self-center justify-center items-center content-center mt-12px`;
export const twSharedBalanceStyles = `elevation-3 mx-16px mt-12px rounded-12px rounded-12px bg-neutral_cotton`;
export const twSharedUangKamuTxtStyle = `mx-5.5px text-xs leading-1.5`;
export const twSharedBalanceTextStyle = `text-black text-2xl`;
export const twSharedProratedDetailParentStyles = `p-15px flex-row bg-neutral_cotton items-center bg-neutral_mist mx-16px rounded-b-12px`;
export const twSharedRefreshStyle = `flex-1 self-center content-center justify-center items-center`;
export const twSharedFilterBtnParentStyle = `flex-row items-center flex-1`;
export const twSharedFilterBtnStyle = `px-5px rounded-20px flex-row items-center self-center justify-center flex-1`;
export const twSharedFilterRefreshContainerStyle = `flex-row items-center justify-center self-center mt-10px mx-16px h-34px border-disabled_smoke border-2 rounded-12px overflow-hidden`;
export const twSharedFilterCountStyles = `absolute bg-primary_carnation h-15px w-15px text-center rounded-7.5px justify-center items-center -top-5px -right-5px`;
export const twSharedIncExpContainerStyle = `flex-row item-center mt-4px mx-15px`;
export const twSharedIncExpDividerStyle = `mt-10px h-24px mx-13px w-4px rounded-12px`;
export const twSharedActivityIndicatorStyle = `mt-30px`;
export const twSharedFlexRow = `flex-row`;
export const twSharedMarginLeft7 = `ml-7px leading-18px`;
export const twSharedWidth1 = `w-1px`;
export const twSharedPaddingTop0 = `pt-0`;
export const twSharedModalContainerStyle = `m-0 items-stretch pt-20 justify-end`;
export const twSharedMarginTop15 = `mt-15px`;
export const twSharedPaddingBottom = `pb-100px`;
export const twSharedSelfCenter = `self-center`;
export const twSharedRPContainerStyles = `self-center mt-4px`;
export const twSharedRPParentStyles = `flex-row self-center`;
export const twSharedTextColorWhite = `text-neutral_cotton`;
export const twSharedFilterModalParent = `h-full mt-100px bg-neutral_cotton`;
export const twSharedMarginLeft10 = `ml-10px`;
export const twSharedProratedTextStyle = `leading-18px text-neutral_charcoal`;
export const twSharedInfoIconStyle = `bg-secondary_orchid rounded-12 w-15px h-15px justify-center`;
export const twSharedFilterPressable = `flex-row items-center self-center flex-1 justify-center`;
export const twSharedselectedIndicator = `h-10px w-10px rounded-5px mx-5px`;
