import { gql } from '@apollo/client';

export const GET_ALL_BANKS = gql`
  query GGBanks {
    gg_bank(where: { bankBranch: { _neq: "0" } }) {
      id
      bankName
    }
  }
`;

export const GET_USER_ALL_BANKS = gql`
  query EmployeeBank {
    gg_employee {
      bank_name {
        bankBranch
        bankCode
        bankName
        id
      }
      beneficiaryName
      bankAccountNo
    }
    user_enrolled_bank(where: { status: { _neq: "DELETE" } }, limit: 10) {
      accountNumber
      beneficiaryName
      id
      isUserRead
      nickname
      status
      gg_bank {
        id
        bankName
        bankCode
      }
    }
  }
`;

export const DELETE_USER_BANK = gql`
  mutation deleteUserBank($accountId: Int) {
    delete_user_enrolled_bank(where: { id: { _eq: $accountId } }) {
      affected_rows
    }
  }
`;

export const CREATE_BANK_ACCOUNT = gql`
  mutation createBankAccount(
    $accountNumber: String
    $bankId: Int
    $beneficiaryName: String
    $nickName: String
    $employeeId: Int
  ) {
    insert_user_enrolled_bank(
      objects: {
        accountNumber: $accountNumber
        bankId: $bankId
        beneficiaryName: $beneficiaryName
        nickname: $nickName
        employeeId: $employeeId
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const EDIT_BANK_ACCOUNTS = gql`
  mutation editBank($accountId: [Int]!) {
    update_user_enrolled_bank(where: { id: { _in: $accountId } }, _set: { isUserRead: true }) {
      affected_rows
    }
  }
`;
