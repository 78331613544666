import { StyleSheet } from 'react-native';
import { colors } from '@style/colors';

const styles = StyleSheet.create({
  mainContainer: {
    marginVertical: 12,
  },
  star: {
    color: colors.textRed,
  },
  titleText: {
    color: colors.philippineGray,
  },
  inputContainer: {
    borderWidth: 1,
    borderRadius: 10,
    borderColor: colors.grey,
    paddingVertical: 12,
    paddingHorizontal: 15,
    marginTop: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default styles;
