import React from 'react';
import { Image, View, Pressable } from 'react-native';
import MyText from '@atoms/MyText';
import Icon from 'react-native-vector-icons/MaterialIcons';
import PropTypes from 'prop-types';
import { styles } from './style';

const EmptyPhoneBook = ({ onPress }) => {
  return (
    <View style={styles.Group13603}>
      <View style={styles.Group341}>
        <Image
          style={styles.IllustrationLandscapeArt_phonebook}
          source={require('@assets/Svg/phoneEnroll/art_phonebook.png')}
        />

        <MyText isRegular={false} customStyle={styles.BelumAdaNomorHpTerda}>
          Belum Ada Nomor HP Terdaftar
        </MyText>
        <MyText customStyle={styles.NomorHpLainnyaMasihK}>
          {
            'Nomor HP Lainnya Masih Kosong\n Manfaatkan fitur ini untuk top-up pulsa, paket data, atau saldo e-wallet untuk orang terdekatmu.'
          }
        </MyText>
        <Pressable onPress={onPress} style={styles.Frame13537}>
          <MyText isRegular={false} customStyle={styles.TambahNomorHp}>
            Tambah Nomor HP{' '}
          </MyText>
          <Icon name="add" style={styles.iconStyle} />
        </Pressable>
      </View>
    </View>
  );
};

EmptyPhoneBook.propTypes = {
  onPress: PropTypes.func.isRequired,
};

export default EmptyPhoneBook;
