import { StyleSheet } from 'react-native';
import { colors } from '@gaji-gesa/gg-react-shared/src/appColors';
import { normalize, normalizeHeight } from '../../sharedStyle/helper';

const styles = StyleSheet.create({
  collapsibleContainer: {
    paddingHorizontal: normalize(10),
    marginTop: normalizeHeight(5),
    marginBottom: normalizeHeight(10),
    backgroundColor: colors.mist,
    borderRadius: normalize(12),
    marginHorizontal: normalize(14),
  },
  termsHeaderRow: {
    paddingHorizontal: normalize(16),
    paddingVertical: normalizeHeight(13),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  termsHeaderText: { fontSize: normalize(14) },
});

export default styles;
