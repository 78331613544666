import { BILLPAYMENT_REDUCER_CASES } from '../reducer/BillPayments';

export const updateProduct = (data) => ({
  type: BILLPAYMENT_REDUCER_CASES.updateProduct,
  payload: data,
});

export const updateBiller = (data) => ({
  type: BILLPAYMENT_REDUCER_CASES.updateBiller,
  payload: data,
});

export const resetProduct = (data) => ({
  type: BILLPAYMENT_REDUCER_CASES.resetProduct,
  payload: data,
});

export const setRegisteredAccounts = (data) => ({
  type: BILLPAYMENT_REDUCER_CASES.setRegisteredAccounts,
  payload: data,
});

export const setRegisteredAccountsWater = (data) => ({
  type: BILLPAYMENT_REDUCER_CASES.setRegisteredAccountsWater,
  payload: data,
});

export const setRegisteredAccountsPrepaid = (data) => ({
  type: BILLPAYMENT_REDUCER_CASES.setRegisteredAccountsPrepaid,
  payload: data,
});
