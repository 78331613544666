import React from 'react';
import { Pressable, View } from 'react-native';
import MyText from '@atoms/MyText';
import PropTypes from 'prop-types';
import { styles } from './style';

const ReferralBanner = ({
  confettiScrapesBg,
  confettiGiftIcon,
  onPress,
  giftStyle,
}) => (
  <Pressable style={styles.container} onPress={onPress}>
    <MyText isRegular={false} customStyle={styles.textStyle}>
      {
        'Ajak teman kerjamu pakai GajiGesa dan \nkumpulkan poin sebanyak-banyaknya!'
      }
    </MyText>
    <View style={styles.assetContainer}>
      <View style={styles.scrapesStyle}>{confettiScrapesBg}</View>
      <View style={[styles.confettiStyle, giftStyle]}>{confettiGiftIcon}</View>
    </View>
  </Pressable>
);

ReferralBanner.propTypes = {
  confettiScrapesBg: PropTypes.object,
  confettiGiftIcon: PropTypes.object,
  onPress: PropTypes.func.isRequired,
  giftStyle: PropTypes.object,
};
ReferralBanner.defaultProps = {
  confettiScrapesBg: '',
  confettiGiftIcon: '',
  giftStyle: {},
};

export default ReferralBanner;
