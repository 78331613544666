export const MODAL_REDUCER_CASES = {
  SET_SHOW_EWA_MODAL: 'SET_SHOW_EWA_MODAL',
  SET_SHOW_REASONS_MODAL: 'SET_SHOW_REASONS_MODAL',
};

const initialState = {
  source: null,
  showEWAModal: false,
  showReasonsModal: false,
};

export default (state = initialState, action) => {
  switch (action?.type) {
    case MODAL_REDUCER_CASES.SET_SHOW_EWA_MODAL:
      return {
        ...state,
        showEWAModal: action?.payload.show ?? false,
        source: action?.payload.source ?? null,
      };
    case MODAL_REDUCER_CASES.SET_SHOW_REASONS_MODAL:
      return {
        ...state,
        showReasonsModal: action?.payload ?? false,
      };
    default:
      return state;
  }
};
