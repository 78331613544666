import { StyleSheet } from 'react-native';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { normalize } from '../../../sharedStyle/helper';

const styles = StyleSheet.create({
  innerRowStyle: {
    backgroundColor: colors.neutral_mist,
    flexDirection: 'row',
    paddingVertical: normalize(15),
    paddingHorizontal: normalize(23),
    justifyContent: 'space-between',
  },
  innterRowValue: { marginRight: normalize(11), fontSize: normalize(12) },
  titleTextStyle: { fontSize: normalize(12) },
});
export default styles;
