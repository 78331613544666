import { StyleSheet } from 'react-native';
import { normalize } from '../../../sharedStyle/helper';
import colors from '@gaji-gesa/gg-react-shared/src/colors';

export const styles = StyleSheet.create({
  containerStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: normalize(15),
    marginTop: normalize(17),
  },
  innerContainerStyle: {
    flexDirection: 'row',
    alignItems: 'center',

    marginRight: normalize(10),
  },
  textStyle: {
    color: colors.success_cactus,
    marginRight: normalize(5),
    fontSize: normalize(20),
  },
  toolTipStyle: {
    marginTop: -1,
  },
});
