import React from 'react';
import MyText from '@atoms/MyText';
import { colors } from '@gaji-gesa/gg-react-shared/src/appColors';
import { View } from 'react-native';
import styles from './style';

export const getBankDetailsUi = (
  placeholder,
  bankName,
  accountNumber,
  beneficiaryName
) => {
  if (placeholder) {
    return (
      <MyText customStyle={{ color: colors.philippineGray }}>
        {placeholder}
      </MyText>
    );
  }

  return (
    <View style={styles.bankContainer}>
      <View
        style={[
          styles.bankNameContainer,
          bankName ? { paddingVertical: 10 } : {},
        ]}
      >
        <MyText
          isRegular={false}
          numberOfLines={1}
          customStyle={styles.bankName}
        >
          {bankName}
        </MyText>
        <MyText numberOfLines={1} customStyle={styles.accountNumber}>
          {' '}
          | {accountNumber}
        </MyText>
      </View>
      <MyText h2 customStyle={{ color: colors.philippineGray }}>
        {beneficiaryName}
      </MyText>
    </View>
  );
};
