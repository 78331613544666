import React from 'react';
import { View } from 'react-native';
import MyText from '@atoms/MyText';
import { numberWithDots } from '@gaji-gesa/gg-react-shared/src/utils/sharedUtils';
import LabelTitle from '../LabelTitle';
import ToolTip from '@atoms/CustomTooltip';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { normalize } from '../../../sharedStyle/helper';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { styles } from './style';

const TotalZakat = ({ amount, onInfoClose, onInfoPress, toolTipVisible }) => (
  <View style={styles.containerStyle}>
    <LabelTitle title={'Total Zakat : '} />
    <View style={styles.innerContainerStyle}>
      <MyText
        isRegular={false}
        customStyle={styles.textStyle}
      >{`Rp${numberWithDots(amount)}`}</MyText>
      <ToolTip
        description={'Jumlah yang harus dibayar per-orang adalah Rp50.000'}
        isVisible={toolTipVisible}
        onClose={onInfoClose}
      >
        <MCIcon
          name="information"
          size={normalize(20)}
          onPress={onInfoPress}
          style={styles.toolTipStyle}
          color={colors.neutral_charcoal}
        />
      </ToolTip>
    </View>
  </View>
);

export default TotalZakat;
