import { gql } from '@apollo/client';

/**
 *
 *   Referral Terms and conditions fetch
 *
 */
const REFERRAL_TNC = gql`
  query getReferralRulesTNC($employerId: Int) {
    referral_config(where: { employerId: { _eq: $employerId }, _and: { enabled: { _eq: true } } }) {
      referralTnc
      refereeRewardAmount
      referrerRewardAmount
    }
  }
`;

export { REFERRAL_TNC };
