import { Action } from "./Interfaces/AppTour/Action"
import { AppTour } from "./Interfaces/AppTour/CurrentAppTour"
import { Journey } from "./Interfaces/AppTour/Journey"

export const APP_TOUR_CASES = {
    setJourneyListAndCurrentAppTour: 'setJourneyListAndCurrentAppTour',
    setJourneyList: 'setJourneyList',
    setCurrentAppTour: 'setCurrentAppTour',
    changeStep: 'changeStep'
}

type initialState = {
    journeyList: Array<Journey>,
    currentAppTour: AppTour
}

export default (state: initialState = {
    journeyList: [],
    currentAppTour: {
        step: 1,
        isPrevious: false,
        id: null,
        employeeId: null,
        type: '',
        interactionStatus: null,
        featureInteraction: null,
        interactionDate: null,
        interval: null,
        order: null,
        flowConst: null
    }
}, action: Action) => {

    switch (action.type) {
        case APP_TOUR_CASES.setJourneyListAndCurrentAppTour:

            return {
                ...state,
                journeyList: action.payload.list,
                currentAppTour: action.payload.tour
            }


        case APP_TOUR_CASES.setJourneyList:

            return {
                ...state,
                journeyList: action.payload,
            }

        case APP_TOUR_CASES.setCurrentAppTour:

            return {
                ...state,
                currentAppTour: action.payload,
            }

        case APP_TOUR_CASES.changeStep:

            return {
                ...state,
                currentAppTour: {
                    ...state.currentAppTour,
                    step: action.payload.step,
                    isPrevious: action.payload.isPrevious,
                    type: action.payload.type
                },
            }

        default:
            return state
    }
}
