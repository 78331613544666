import { StyleSheet } from 'react-native';
import { normalize, normalizeHeight } from '../../../sharedStyle/helper';
import { colors } from '@gaji-gesa/gg-react-shared/src/appColors';
export const styles = StyleSheet.create({
  termsRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: normalizeHeight(8),
    paddingRight: normalize(10),
  },
  termsIconContainer: {
    width: '10%',
    alignItems: 'center',
    height: '100%',
    paddingTop: normalizeHeight(2),
  },
  termsTextContainer: { width: '90%' },
  termsTextStyle: {
    fontSize: normalize(14),
    paddingLeft: normalize(8),
  },
  circlePlaceholder: {
    borderRadius: normalize(20),
    height: normalize(20),
    width: normalize(20),
    backgroundColor: '#454545',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: normalize(2),
  },
  numericText: { color: colors.white, fontSize: normalize(10) },
});
