import React from 'react';
import Arrow from 'react-native-vector-icons/MaterialIcons';
import Check from 'react-native-vector-icons/AntDesign';
import { colors } from '@gaji-gesa/gg-react-shared/src/appColors';
import Status from '@atoms/BankCards/Status';
export const bankSelectionComponent = (status, isSelected) => {
  if (status === 'PENDING') {
    return <Status status={status} />;
  }

  if (isSelected) {
    return <Check name="check" color={colors.orange} size={25} />;
  }

  return <Arrow name="keyboard-arrow-right" color={colors.black} size={20} />;
};
