import { ATTENDANCE_REDUCER_CASES } from '../reducer/Attendance';

export const setPaymentCycles = (data) => ({
  type: ATTENDANCE_REDUCER_CASES.setPaymentCycles,
  payload: data,
});

export const setSummaryData = (data) => ({
  type: ATTENDANCE_REDUCER_CASES.setSummaryData,
  payload: data,
});

export const setAttendanceData = (data) => ({
  type: ATTENDANCE_REDUCER_CASES.setAttendanceData,
  payload: data,
});

export const setCurrentButton = (data) => ({
  type: ATTENDANCE_REDUCER_CASES.setCurrentButton,
  payload: data,
});

export const setModalDate = (data) => ({
  type: ATTENDANCE_REDUCER_CASES.setModalDate,
  payload: data,
});

export const setAttendanceSubmission = (data) => ({
  type: ATTENDANCE_REDUCER_CASES.setAttendanceSubmission,
  payload: data,
});

export const setTodayAttendance = (data) => ({
  type: ATTENDANCE_REDUCER_CASES.setTodayAttendance,
  payload: data,
});

export const setSalaryType = (data) => ({
  type: ATTENDANCE_REDUCER_CASES.setSalaryType,
  payload: data,
});

export const setAttendanceSubmissionToday = (data) => ({
  type: ATTENDANCE_REDUCER_CASES.setAttendanceSubmissionToday,
  payload: data,
});

export const setSkipBreak = (data) => ({
  type: ATTENDANCE_REDUCER_CASES.setSkipBreak,
  payload: data,
});
