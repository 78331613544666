import {
  OTHER_ACCOUNT_NAMES,
  DYNAMIC_ACCOUNT_NAMES,
  BOTTONSHEET_KEYS,
} from '@gaji-gesa/gg-react-shared/src/utils/sharedConstants';

import { COMMON_CONSTANTS } from '@gaji-gesa/gg-react-shared/src/utils/sharedConstants';

import { SVG_ICONS } from './SvgIcons';
import { SHARED_AMPLITUDE_EVENT_SOURCES as AMPLITUDE_EVENT_SOURCES } from '@gaji-gesa/gg-react-shared/src/utils/amplitudeConstants';
import { colors } from '@style/colors';

export const ACCOUNT_LIST = [
  {
    constantAccountName: DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT,
    accountName: 'Akun Gaji',
    amount: null,
    icon: SVG_ICONS.dynamicAccounts.salaryAccount,
    disabledIcon: SVG_ICONS.dynamicAccounts.disabledSalaryAccount,
    source: 'home_page_top_card',
    color: colors.softOrchid,
    amplitudeAccountName:
      AMPLITUDE_EVENT_SOURCES.dynamicAccounts.salary_account,
    isRealDynamicAccount: false,
    priority: 1,
    hasOnPress: true,
  },
  {
    constantAccountName: DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE,
    accountName: 'Uang Kantor',
    amount: null,
    icon: SVG_ICONS.dynamicAccounts.workAllowanceIcon,
    disabledIcon: SVG_ICONS.dynamicAccounts.workAllowanceIcon,
    source: 'home_page_top_card',
    color: colors.softCarnation,
    amplitudeAccountName:
      AMPLITUDE_EVENT_SOURCES.dynamicAccounts.work_allowance,
    isRealDynamicAccount: true,
    priority: 1,
    hasOnPress: false,
  },
  {
    constantAccountName: DYNAMIC_ACCOUNT_NAMES.SDACCOUNT,
    accountName: 'Pencairan Gaji',
    amount: null,
    icon: SVG_ICONS.dynamicAccounts.sdAccount,
    source: 'home_page_top_card',
    color: colors.softClover,
    amplitudeAccountName:
      AMPLITUDE_EVENT_SOURCES.dynamicAccounts.salary_disbursement,
    isRealDynamicAccount: true,
    priority: 1,
    hasOnPress: false,
  },
  {
    constantAccountName: DYNAMIC_ACCOUNT_NAMES.POINTS,
    accountName: 'Poin',
    amount: null,
    icon: SVG_ICONS.coin3,
    color: colors.softSunflower,
    source: 'home_page',
    isRealDynamicAccount: true,
    amplitudeAccountName: AMPLITUDE_EVENT_SOURCES.dynamicAccounts.poins,
    priority: 1,
    hasOnPress: true,
  },
  {
    constantAccountName: DYNAMIC_ACCOUNT_NAMES.UANG_KAS,
    accountName: 'Uang Kas',
    amount: null,
    icon: SVG_ICONS.dynamicAccounts.uangKas,
    color: colors.softSunflower,
    source: 'home_page',
    isRealDynamicAccount: true,
    amplitudeAccountName: AMPLITUDE_EVENT_SOURCES.dynamicAccounts.uang_kas,
    priority: 1,
    hasOnPress: false,
  },
  // [CORE-1282]: Removing as part of feedback from product
  {
    constantAccountName: OTHER_ACCOUNT_NAMES.SAVINGS,
    accountName: 'Kamu Hemat',
    amount: null,
    icon: SVG_ICONS.dashboard.savings,
    source: 'home_page_top_card',
    isRealDynamicAccount: false,
    priority: 1,
    hasOnPress: true,
  },
];

export const BOTTOMSHEET_CONTENT = {
  [BOTTONSHEET_KEYS.BANK_DELETE]: {
    title: 'Yakin Mau Hapus?',
    description:
      'Untuk menambah rekening ulang, kamu perlu mengulang proses verifikasi dari awal.',
    buttonText: 'Hapus Nomor',
    buttonText2: 'Batal',
    hasTwoButtons: true,
    icon: SVG_ICONS.deleteModalIcon,
  },
  [BOTTONSHEET_KEYS.MAX_LIMIT]: {
    title: 'Wah, sudah mencapai batas, nih...',
    description:
      'Maaf, kamu sudah menambahkan 10 nomor rekening di GajiGesa. Tambah lagi jika kamu sudah menghapus nomor rekening yang tidak terpakai.',
    buttonText: 'Saya Paham',
    hasTwoButtons: false,
    icon: SVG_ICONS.bottomSheet.logout,
  },
  [BOTTONSHEET_KEYS.DUPLICATE_ENTRY]: {
    title: 'Ups, ada yang salah, nih...',
    description:
      'Nomor rekening ini sudah ada di daftarmu. Silakan ubah nomor ini atau hapus lalu tambahkan kembali',
    buttonText: 'Kembali',
    hasTwoButtons: false,
    icon: SVG_ICONS.duplicateModalIcon,
  },
};

export const BANK_TRANSFER_ICON_INFO = {
  active: true,
  description: '',
  icon: null,
  imageUrl: COMMON_CONSTANTS.bankTransferIconUrl,
  menu: 'Keuangan',
  id: 101,
  name: 'Transfer',
};

export const LOAN_ICON_INFO = {
  active: true,
  description: '',
  icon: null,
  imageUrl: COMMON_CONSTANTS.loanIconUrl,
  menu: 'Keuangan',
  id: 100,
  name: 'e-Kasbon',
};

export const ZAKAT_ICON_INFO = {
  active: true,
  description: '',
  icon: null,
  imageUrl: COMMON_CONSTANTS.zakatIconUrl,
  menu: 'Zakat & Donasi',
  id: 102,
  name: 'Zakat Fitrah',
};

export const LOAN_CATEGORY_INFO = {
  title: 'Keuangan',
  data: [
    {
      title: 'Keuangan',
      list: [LOAN_ICON_INFO, BANK_TRANSFER_ICON_INFO],
    },
  ],
};

export const BANK_CATEGORY_INFO = {
  title: 'Keuangan',
  data: [
    {
      title: 'Keuangan',
      list: [BANK_TRANSFER_ICON_INFO],
    },
  ],
};

export const ZAKAT_CATEGORY_INFO = {
  title: 'Zakat & Donasi',
  data: [
    {
      title: 'Zakat & Donasi',
      list: [ZAKAT_ICON_INFO],
    },
  ],
};

export const LOAN_APPLICATION_STATUS = {
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  DISBURSED: 'DISBURSED',
  FULLY_PAID: 'FULLY_PAID',
};

export const LOAN_STATUS_TYPES = {
  [LOAN_APPLICATION_STATUS.PENDING_APPROVAL]: {
    title: 'Pengajuan Sedang Dalam Proses',
    description:
      'Harap tunggu proses verifikasi dan transfer maks. 1 x 24 jam kerja oleh tim GajiGesa dan tempat kerjamu.',
    buttonText: 'Oke',
    icon: SVG_ICONS.loan.verification,
  },
  [LOAN_APPLICATION_STATUS.APPROVED]: {
    title: 'Pengajuan e-Kasbon Disetujui!',
    description:
      'Cek uang masuk di rekening rekening terdaftar (utama) di GajiGesa',
    buttonText: 'Lihat Detail',
    icon: SVG_ICONS.loan.approved,
  },

  [LOAN_APPLICATION_STATUS.DISBURSED]: {
    title: 'Pengajuan e-Kasbon Disetujui!',
    description:
      'Cek uang masuk di rekening rekening terdaftar (utama) di GajiGesa',
    buttonText: 'Lihat Detail',
    icon: SVG_ICONS.loan.approved,
  },

  [LOAN_APPLICATION_STATUS.REJECTED]: {
    title: 'Pengajuan e-Kasbon Ditolak!',
    description:
      'Maaf, pengajuanmu belum disetujui perusahaan karena belum memenuhi syarat & ketentuan, namun anda masih dapat menggunakan EWA dan Silakan coba ajukan di lain waktu',
    buttonText: 'Oke',
    icon: SVG_ICONS.loan.rejected,
  },
  [LOAN_APPLICATION_STATUS.FULLY_PAID]: {
    title: 'Cicilan e-Kasbon Selesai!',
    description:
      'Kamu memenuhi syarat untuk mengajukan e-Kasbon lagi. Tertarik untuk ajukan kembali?',
    buttonText: 'Nanti saja',
    buttonText2: 'Saya Mau',
    hasTwoButtons: true,
    icon: SVG_ICONS.loan.fullyPaid,
  },
};

export const BOTTONSHEET_PHONE_ENROLLMENT = {
  PHONE_DELETE: 'PHONE_DELETE',
  MAX_LIMIT: 'MAX_LIMIT',
  DUPLICATE_ENTRY: 'DUPLICATE_ENTRY',
};

export const BOTTONSHEET_PHONE_CONTENT = {
  [BOTTONSHEET_PHONE_ENROLLMENT.PHONE_DELETE]: {
    title: 'Yakin Mau Hapus?',
    description:
      'Untuk menambah nomor HP, kamu perlu mengulang proses verifikasi dari awal.',
    buttonText: 'Hapus Nomor',
    buttonText2: 'Batal',
    hasTwoButtons: true,
    icon: SVG_ICONS.deleteModalIcon,
  },
  [BOTTONSHEET_PHONE_ENROLLMENT.MAX_LIMIT]: {
    title: 'Wah, sudah mencapai batas, nih...',
    description:
      'Maaf, sudah ada 10 nomor HP yang kamu daftarkan di GajiGesa. Tambah lagi jika kamu sudah hapus nomor HP yang tidak terpakai.',
    buttonText: 'Saya Paham',
    hasTwoButtons: false,
    icon: SVG_ICONS.bottomSheet.logout,
  },
  [BOTTONSHEET_PHONE_ENROLLMENT.DUPLICATE_ENTRY]: {
    title: 'Ups, ada yang salah, nih...',
    description:
      'Nomor ini ada dalam daftarmu. Silakan tambahkan nomor yang belum ada di daftar, ya!',
    buttonText: 'Saya Paham',
    hasTwoButtons: false,
    icon: SVG_ICONS.duplicateModalIcon,
  },
};

export const SECURITY_PIN_INFO_MODAL = {
  GG_01_FALLBACK: {
    title: 'PIN Berhasil Dibuat',
    description:
      'PIN sudah aktif, kamu sudah bisa transaksi lebih aman dan nyaman',
    buttonText: 'Oke',
    hasTwoButtons: false,
    icon: SVG_ICONS.bottomSheet.success,
  },
  GG_01: {
    title: 'PIN Berhasil Dibuat',
    description:
      'PIN sudah aktif, sekarang kamu bisa tambah nomor HP dan akun bank lain dengan aman!',
    buttonText: 'Tambah No. HP',
    buttonText2: 'Tambah Bank',
    hasTwoButtons: true,
    icon: SVG_ICONS.bottomSheet.success,
  },
  GG_01_PHONE_ENROLL: {
    title: 'PIN Berhasil Dibuat',
    description:
      'PIN sudah aktif, sekarang kamu bisa tambah nomor HP lain dengan aman!',
    buttonText: 'Tambah Nomor HP',
    hasTwoButtons: false,
    icon: SVG_ICONS.bottomSheet.success,
  },
  GG_01_BANK_ENROLL: {
    title: 'PIN Berhasil Dibuat',
    description:
      'PIN sudah aktif, sekarang kamu bisa tambah akun bank lain dengan aman!',
    buttonText: 'Tambah Akun Bank',
    hasTwoButtons: false,
    icon: SVG_ICONS.bottomSheet.success,
  },

  ACTIVE_01: {
    title: 'Manfaat Punya PIN',
    description: null,
    buttonText: 'Tambah No. HP',
    buttonText2: 'Tambah Bank',
    hasTwoButtons: true,
    icon: SVG_ICONS.bottomSheet.pinLock,
  },
  ACTIVE_01_PHONE_ENROLL: {
    title: 'Manfaat Punya PIN',
    description: null,
    buttonText: 'Tambah Nomor HP',
    hasTwoButtons: false,
    icon: SVG_ICONS.bottomSheet.pinLock,
  },
  ACTIVE_01_BANK_ENROLL: {
    title: 'Manfaat Punya PIN',
    description: null,
    buttonText: 'Tambah Akun Bank',
    hasTwoButtons: false,
    icon: SVG_ICONS.bottomSheet.pinLock,
  },

  THREE_RETRY: {
    title: 'PIN Sudah Salah 3 Kali',
    description:
      'Karena kamu sudah memasukkan PIN yang tidak sama sebanyak 3 kali, yuk ulangi buat PIN baru lagi',
    buttonText: 'Ulangi Buat PIN',
    hasTwoButtons: false,
    icon: SVG_ICONS.bottomSheet.logout,
  },
  THREE_RETRY_VERIFICATION: {
    title: 'PIN Sudah Salah 3 Kali',
    description:
      'Kamu sudah salah 3 kali memasukkan PIN yang salah. Permintaan kamu akan tetap diproses, silahkan hubungi layanan pelanggan untuk proses reset PIN',
    buttonText: 'Nanti Saja',
    buttonText2: 'Reset PIN',
    hasTwoButtons: true,
    icon: SVG_ICONS.bottomSheet.logout,
  },
  BEFORE_CREATE_PIN: {
    title: 'Eits, Aktivasi PIN Dulu, Yuk!',
    description:
      'Saatnya bikin transaksimu lebih aman dan nyaman di GajiGesa dengan aktivasi PIN',
    buttonText: 'Nanti Saja',
    buttonText2: 'Buat Sekarang',
    hasTwoButtons: true,
    icon: SVG_ICONS.bottomSheet.pinLock,
  },
  USER_BACK_PREVENT: {
    title: 'Yakin Mau Batalkan?',
    description:
      'Dengan membatalkan proses ini, kamu perlu mengulangi semua proses verifikasi, mulai dari unggah foto.',
    buttonText: 'Batalkan',
    buttonText2: 'Jangan Batalkan',
    hasTwoButtons: true,
    icon: '',
  },
};

export const KTP_INFO_MODAL = {
  'GG-404': {
    title: 'Yuk, Lengkapi Data Dirimu…',
    description:
      'Sebelum mulai menabung, lengkapi dulu yuk data pribadimu untuk keamanan saat transaksi',
    buttonText: 'Lengkapi Data Diri Sekarang',
    hasTwoButtons: false,
    icon: SVG_ICONS.bottomSheet.dataFound,
  },
  'KTP_ALREADY_REGISTERED': {
    title: 'Fitur Segera Hadir!',
    description:
      'Sabar ya sebentar lagi fitur ini akan hadir dan kami akan memberitahu-mu segera!',
    buttonText: 'Kembali',
    hasTwoButtons: false,
    icon: SVG_ICONS.bottomSheet.waiting,
  },
};
