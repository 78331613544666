export const USER_PHONE_CASES = {
  setSelectedPhone: 'setSelectedPhone',
  clearSelectedPhone: 'clearSelectedPhone',
};

const initialState = {
  selectedPhoneNumber: '',
  selectedUserName: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_PHONE_CASES.setSelectedPhone:
      return {
        ...state,
        selectedPhoneNumber: action.data.phoneNumber,
        selectedUserName: action.data.name,
      };

    case USER_PHONE_CASES.clearSelectedPhone:
      return {
        ...state,
        selectedPhoneNumber: '',
        selectedUserName: '',
      };
    default:
      return state;
  }
};
