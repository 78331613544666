import { StyleSheet } from 'react-native';
import { colors } from '@style/colors';
import { Font } from '@style/Fonts';

export default StyleSheet.create({
  pressable: {
    paddingVertical: 10.5,
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
  },
  border: {
    overflow: 'hidden',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
    marginVertical: 10,
    backgroundColor: colors.white,
    borderWidth: 0.8,
    borderColor: colors.blue,
    borderRadius: 10,
  },
  container: {
    overflow: 'hidden',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
    marginVertical: 10,
    backgroundColor: colors.blue,
    borderRadius: 10,
  },
  text: {
    fontFamily: Font.Nunito_Bold,
    marginStart: 8,
    color: colors.white,
  },
});
