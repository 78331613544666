import React from 'react';
import { View } from 'react-native';
import { styles } from './style';
import MyText from '@atoms/MyText';

const LoanTitle = () => (
  <View style={styles.container}>
    <MyText isRegular={false}> Pilih jumlah dana yang kamu butuhkan </MyText>
  </View>
);

export default LoanTitle;
