import { TRANSACTION_DETAILS_CASES } from '../reducer/TransactionDetails';

export const setFilter = (data) => ({
  type: TRANSACTION_DETAILS_CASES.setFilter,
  payload: data,
});

export const setEmpPaymentCycle = (data) => ({
  type: TRANSACTION_DETAILS_CASES.setEmployeePaymentCycle,
  payload: data,
});

export const setBalances = (balance, totalIncome, totalExpense) => ({
  type: TRANSACTION_DETAILS_CASES.setBalances,
  payload: {
    balance,
    totalIncome,
    totalExpense,
  },
});

export const setAccrual = (data) => ({
  type: TRANSACTION_DETAILS_CASES.setAccuredSalary,
  payload: data,
});

export const setTransactionList = (data) => ({
  type: TRANSACTION_DETAILS_CASES.setTransactionList,
  payload: data,
});

export const setReasonsTransaction = (childKey, data) => ({
  type: TRANSACTION_DETAILS_CASES.setReasonsTransaction,
  payload: {
    childKey,
    data,
  },
});

export const changeExpense = (data) => ({
  type: TRANSACTION_DETAILS_CASES.changeExpense,
  payload: data,
});

export const changeIncome = (data) => ({
  type: TRANSACTION_DETAILS_CASES.changeIncome,
  payload: data,
});

export const changeTransaction = (transactionReasonId, description, transHistoryId, transReason) => ({
  type: TRANSACTION_DETAILS_CASES.changeTransactionList,
  payload: {
    transactionReasonId,
    description,
    transHistoryId,
    transReason,
  },
});

export const setAllExpense = (data) => ({
  type: TRANSACTION_DETAILS_CASES.setAllExpense,
  payload: data,
});

export const setAllIncome = (data) => ({
  type: TRANSACTION_DETAILS_CASES.setAllIncome,
  payload: data,
});
