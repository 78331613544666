import React from 'react';
import { Pressable, View } from 'react-native';
import MyText from '@atoms/MyText';
import PropTypes from 'prop-types';
import { styles } from './style';

import MCI from 'react-native-vector-icons/MaterialCommunityIcons';

const ListPhonenumber = ({
  ownerName,
  phoneNumber,
  showTick,
  disabled,
  onPress,
}) => {
  function shouldShowTick(show) {
    if (show) return <MCI name="check" style={styles.showTickStyle} />;
    return null;
  }

  return (
    <Pressable
      disabled={disabled}
      onPress={onPress}
      style={styles.ListPhonenumber}
    >
      <View style={styles.Frame13537}>
        <View style={styles.RekeningNumber}>
          <MyText customStyle={styles.phoneNumberStyle}>{phoneNumber}</MyText>
        </View>
        <View style={styles.Name_provider}>
          <MyText isRegular={false} customStyle={styles.ownerStyle}>
            {ownerName}
          </MyText>
        </View>
      </View>
      {shouldShowTick(showTick)}
    </Pressable>
  );
};

ListPhonenumber.propTypes = {
  ownerName: PropTypes.string,
  phoneNumber: PropTypes.string,
  showTick: PropTypes.bool,
  disabled: PropTypes.bool,
  onPress: PropTypes.func,
};

export default ListPhonenumber;
