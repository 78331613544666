import React from 'react';

import WalletColor from '@assets/Svg/walletColor.svg';
import WalletColorSmall from '@assets/Svg/walletColorSmall.svg';
import Congrats from '@assets/Svg/congrats.svg';
import Account from '@assets/Svg/account.svg';
import { SvgXml } from 'react-native-svg';
import Basket from '@assets/Svg/grocery.svg';
import AccuredSalary from '@assets/Svg/accuredSalary.svg';
import EmptyWallet from '@assets/Svg/empty-wallet-time.svg';
// POINTS
import Coin from '@assets/Svg/point_coins.svg';

//Reasons Icons Expense
import Absent from '@assets/Svg/Reasons/Expense/Absenteeism.svg';
import Apparel from '@assets/Svg/Reasons/Expense/Apparel.svg';
import AutoRepairs from '@assets/Svg/Reasons/Expense/AutoRepairs.svg';
import Beauty from '@assets/Svg/Reasons/Expense/Beauty.svg';
import BillUtility from '@assets/Svg/Reasons/Expense/BillUtility.svg';
import BPJS from '@assets/Svg/Reasons/Expense/BPJS.svg';
import Childneeds from '@assets/Svg/Reasons/Expense/Childneeds.svg';
import Donation from '@assets/Svg/Reasons/Expense/Donation.svg';
import Education from '@assets/Svg/Reasons/Expense/Education.svg';
import Electronic from '@assets/Svg/Reasons/Expense/Electronic.svg';
import Entertainment from '@assets/Svg/Reasons/Expense/Entertainment.svg';
import FamilyFriends from '@assets/Svg/Reasons/Expense/FamilyFriends.svg';
import FoodsDrinks from '@assets/Svg/Reasons/Expense/FoodsDrinks.svg';
import Gift from '@assets/Svg/Reasons/Expense/gift.svg';
import Groceries from '@assets/Svg/Reasons/Expense/Groceries.svg';
import Health from '@assets/Svg/Reasons/Expense/Health.svg';
import HouseRenovation from '@assets/Svg/Reasons/Expense/HouseRenovation.svg';
import HouseRent from '@assets/Svg/Reasons/Expense/HouseRent.svg';
import Installment from '@assets/Svg/Reasons/Expense/Installment.svg';
import Investment from '@assets/Svg/Reasons/Expense/Investment.svg';
import LateFine from '@assets/Svg/Reasons/Expense/LateFine.svg';
import Otherexpense from '@assets/Svg/Reasons/Expense/Otherexpense.svg';
import Salarywithdrawal from '@assets/Svg/Reasons/Expense/Salarywithdrawal.svg';
import Savings from '@assets/Svg/Reasons/Expense/Savings.svg';
import StartupCapital from '@assets/Svg/Reasons/Expense/StartupCapital.svg';
import Tax from '@assets/Svg/Reasons/Expense/Tax.svg';
import Transportation from '@assets/Svg/Reasons/Expense/Transportation.svg';
import Vacation from '@assets/Svg/Reasons/Expense/Vacation.svg';
import SalaryAdjustment from '@assets/Svg/Reasons/Expense/salaryAdjustment.svg';
import LoanExp from '@assets/Svg/Reasons/Expense/Loan.svg';

//Reasons Icons Income
import Bonus from '@assets/Svg/Reasons/Income/BonusAllowance.svg';
import Borrow from '@assets/Svg/Reasons/Income/Borrowmoney.svg';
import BusinessProfit from '@assets/Svg/Reasons/Income/BusinessProfit.svg';
import Comission from '@assets/Svg/Reasons/Income/Commission.svg';
import InvestmentIncome from '@assets/Svg/Reasons/Income/Investment.svg';
import OtherIncome from '@assets/Svg/Reasons/Income/Otherincome.svg';
import Overtime from '@assets/Svg/Reasons/Income/Overtime.svg';
import Salary from '@assets/Svg/Reasons/Income/Salary.svg';
import SalaryReciept from '@assets/Svg/Reasons/Income/salaryReciept.svg';

import Pulsa from '@assets/Svg/Savings/offers/pulsa.svg';
import TokenPLN from '@assets/Svg/Savings/offers/token_pln.svg';
import Voucher from '@assets/Svg/Savings/offers/voucher.svg';

import OPulsa from '@assets/Svg/Savings/transaction/pulsa.svg';
import OTokenPLN from '@assets/Svg/Savings/transaction/token_pln.svg';
import OVoucher from '@assets/Svg/Savings/transaction/voucher.svg';
import OCampaign from '@assets/Svg/Savings/transaction/campaign.svg';
import ODefault from '@assets/Svg/Savings/transaction/default.svg';

import Sparklestar from '@assets/Svg/Savings/star.svg';
import MoneyWings from '@assets/Svg/Savings/money_wings.svg';
import Coin2 from '@assets/Svg/coin.svg';

// New Dashboard
import SalaryDashboard from '@assets/Svg/NewDashboard/salary.svg';
import SavingsDashboard from '@assets/Svg/NewDashboard/savings.svg';

//Bottom Nav
import Home from '@assets/Svg/BottomNav/Home.svg';
import Transaction from '@assets/Svg/BottomNav/Transaction.svg';
import EWA from '@assets/Svg/BottomNav/EWA.svg';
import BillPayment from '@assets/Svg/BottomNav/BillPayment.svg';
import Attendance from '@assets/Svg/BottomNav/Attendance.svg';
import Others from '@assets/Svg/BottomNav/Others.svg';
import GoBack from '@assets/Svg/goBack.svg';
import GoForward from '@assets/Svg/goForward.svg';
import Pause from '@assets/Svg/pause.svg';
import Swipe from '@assets/Svg/swipe.svg';
import Update from '@assets/Svg/update.svg';

//Rewards
import RewardIcon from '@assets/Svg/rewardIcon.svg';
import CopyIcon from '@assets/Svg/clipboardCopy.svg';
import BulletPoint from '@assets/Svg/bullet.svg';
import Gift2 from '@assets/Svg/gift.svg';
import Question from '@assets/Svg/question.svg';

import GiftNew from '@assets/Svg/giftNew.svg';

//Onboarding
import GGWhiteLogo from '@assets/Svg/welcome/gg_logo_white.svg';
import ArtWorkOne from '@assets/Svg/welcome/art_one.svg';
import ArtWorkTwo from '@assets/Svg/welcome/art_two.svg';
import ArtWorkThree from '@assets/Svg/welcome/art_three.svg';

//New Login
import Whatsapp from '@assets/Svg/Login/whatsapp.svg';
import MessageNoti from '@assets/Svg/otp/messageNoti.svg';
import WhatsappOTP from '@assets/Svg/otp/wa.svg';
import SmsOTP from '@assets/Svg/otp/sms.svg';

//Bottomsheet
import Suspend from '@assets/Svg/Login/BottomSheet/art_accountSuspended.svg';
import ComeBack from '@assets/Svg/Login/BottomSheet/art_comebackLater.svg';
import ErrorData from '@assets/Svg/Login/BottomSheet/art_errorData.svg';
import Otp from '@assets/Svg/Login/BottomSheet/art_OTP.svg';
import Unregistered from '@assets/Svg/Login/BottomSheet/art_phoneNumer_Unregistered.svg';
import Registered from '@assets/Svg/Login/BottomSheet/art_Registered.svg';
import Waiting from '@assets/Svg/Login/BottomSheet/art_waiting.svg';
import LogoutHand from '@assets/Svg/Login/BottomSheet/art_logout.svg';
import Success from '@assets/Svg/Login/BottomSheet/art_successGeneral.svg';
import PinLock from '@assets/Svg/Login/BottomSheet/art_PIN.svg';
import DataFound from '@assets/Svg/Login/BottomSheet/art_dataFound.svg';
import { normalize } from '@style/helper';

//GAMIFICATION
import Medal from '@assets/Svg/medal.svg';
import GroundLayer from '@assets/Svg/rewardInfo/groundLayer.svg';
import RewardAsset from '@assets/Svg/rewardInfo/rewardAsset.svg';
import TierOne from '@assets/Svg/gamification/TierOne.svg';
import TierTwo from '@assets/Svg/gamification/TierTwo.svg';
import TierThree from '@assets/Svg/gamification/TierThree.svg';
import TierFour from '@assets/Svg/gamification/TierFour.svg';
import ComingSoonTier from '@assets/Svg/gamification/comingSoonTier.svg';
import ComingSoonLock from '@assets/Svg/gamification/comingSoonLock.svg';
import MissionDark from '@assets/Svg/gamification/missionDark.svg';
import MissionLight from '@assets/Svg/gamification/missionLight.svg';
import StarSprinkler from '@assets/Svg/gamification/starSprinkler.svg';
import EmptyMountain from '@assets/Svg/gamification/emptyMountain.svg';
import CupIcon from '@assets/Svg/rewardInfo/cup.svg';
import GiftIcon from '@assets/Svg/rewardInfo/gift.svg';
import NoteIcon from '@assets/Svg/rewardInfo/note.svg';

//Voucher Inventory Screen
import MilvikLogo from '@assets/Svg/voucherInventory/milvik/mlivikLogo.svg';
import Clipboard from '@assets/Svg/voucherInventory/milvik/clipboard.svg';
import IcVoucher from '@assets/Svg/voucherInventory/milvik/icVoucher.svg';
import CheckSign from '@assets/Svg/voucherInventory/milvik/checkSign.svg';
import IcVoucherEmpty from '@assets/Svg/voucherInventory/milvik/icVoucherEmpty.svg';
import BackgroundLay from '@assets/Svg/voucherInventory/milvik/backgroundLay.svg';

// Dynamic Account
import ArtInformation from '@assets/Svg/DynamicAccounts/art_information.svg';
import SDIcon from '@assets/Svg/DynamicAccounts/sdAccount.svg';
import WorkAllowanceIcon from '@assets/Svg/DynamicAccounts/workAllowance.svg';
import UangKas from '@assets/Svg/DynamicAccounts/uangkas.svg';
import SalaryAccount from '@assets/Svg/DynamicAccounts/salaryAccount.svg';
import DisabledSalaryAccount from '@assets/Svg/DynamicAccounts/disabledSalaryAccount.svg';

//PWA

import NewBantuanIcon from '@assets/Svg/newBantuan.svg';
import SuccessIcon from '@assets/Svg/success.svg';
import ClockIcon from '@assets/Svg/clock.svg';
import OthersWA from '@assets/Svg/others/other-wa.svg';
import Logout from '@assets/Svg/others/other-exit.svg';

// Menu icons

import HomeIcon from '@assets/Svg/MenuIcons/home.svg';
import BillPayments from '@assets/Svg/MenuIcons/billpayments.svg';
import Other from '@assets/Svg/MenuIcons/other.svg';
import TransactionMenu from '@assets/Svg/MenuIcons/transactionsMenu.svg';
import AbsensiMenu from '@assets/Svg/MenuIcons/absensi.svg';
import LoanMenu from '@assets/Svg/MenuIcons/loan.svg';
import BantuanMenu from '@assets/Svg/MenuIcons/bantuan.svg';
import ProfileMenu from '@assets/Svg/MenuIcons/profile.svg';
import FinanceMenu from '@assets/Svg/MenuIcons/finance.svg';

//Profile

import ProfileAvatar from '@assets/Svg/Profile/happyFace.svg';
import WaBantuan from '@assets/Svg/Profile/whatsapp.svg';
import Confetti from '@assets/Svg/Profile/confetti.svg';
import ConfettiScrapes from '@assets/Svg/Profile/confettiScrapes.svg';
import ReferralGift from '@assets/Svg/Profile/gift.svg';
import SalaryInformation from '@assets/Svg/salaryInfo/salaryInformation.svg';

//Referral
import ReferralPrgOne from '@assets/Svg/referral/ref_prog_one.svg';
import ReferralPrgTwo from '@assets/Svg/referral/ref_prog_two.svg';
import ReferralPrgThree from '@assets/Svg/referral/ref_prog_three.svg';
import PointingArrow from '@assets/Svg/referral/pointingArrow.svg';

//Loan
import LoanApproved from '@assets/Svg/loan/art_approved.svg';
import LoanRejected from '@assets/Svg/loan/art_rejected.svg';
import LoanVerification from '@assets/Svg/loan/art_verification.svg';
import LoanFullyPaid from '@assets/Svg/loan/art_approved.svg';
import LoanProducts from '@assets/Svg/loan/art_e-Kasbon.png';
import Cash from '@assets/Svg/loan/cash.svg';
import VerifiedUser from '@assets/Svg/loan/verified_user.svg';
// Bank Module
import DeleteModalIcon from '@assets/Svg/Login/BottomSheet/art_deleteGeneral.svg';
import DuplicateModalIcon from '@assets/Svg/Login/BottomSheet/processReject.svg';

//Phone Enrollment
import PhoneBook from '@assets/Svg/phoneEnroll/art_phonebook.png';
import PhoneBookError from '@assets/Svg/phoneEnroll/art_phonebookError.png';
import PhoneIcon from '@assets/Svg/phoneEnroll/ic_phone.svg';

import ZakatMain from '@assets/Svg/zakat/zakat_main.svg';
import ZakatPowered from '@assets/Svg/zakat/zakat_powered.png';

// Salary info Screen
import Calculator from '@assets/Svg/salaryInfo/calculator.svg';
import CheckSalaryInfo from '@assets/Svg/salaryInfo/check.svg';
import RefreshSalaryInfo from '@assets/Svg/salaryInfo/refresh.svg';

import PhoneCheck from '@assets/Svg/salaryInfo/phone-check.svg';
import PhoneLock from '@assets/Svg/salaryInfo/phone-lock.svg';
import PhoneVocal from '@assets/Svg/salaryInfo/phone-vocal.svg';

// Security Pin
import PadLock from '@assets/Svg/securityPin/padLock.svg';
import ChatSmile from '@assets/Svg/chat_smile.svg';

// Treasury
import Bappebti from '@assets/Svg/treasury/bappebti.svg';
import ButtonGold from '@assets/Svg/treasury/ic_gold.svg';
import TreasuryLogo from '@assets/Svg/treasury/treasurylogo.svg';

export const Wallet = ({ color = '#979797' }) => {
  const xml = `
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.25 5.25C2.25 4.42157 2.92157 3.75 3.75 3.75H14.25C15.0785 3.75 15.75 4.42157 15.75 5.25V6.75H12.75C11.5073 6.75 10.5 7.75733 10.5 9C10.5 10.2427 11.5073 11.25 12.75 11.25H15.75V12.75C15.75 13.5785 15.0785 14.25 14.25 14.25H3.75C2.92157 14.25 2.25 13.5785 2.25 12.75V5.25Z" fill="${color}"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.25 9C11.25 8.17155 11.9216 7.5 12.75 7.5H15.75C16.1642 7.5 16.5 7.83578 16.5 8.25V9.75C16.5 10.1642 16.1642 10.5 15.75 10.5H12.75C11.9216 10.5 11.25 9.82845 11.25 9ZM13.5 9C13.5 9.41422 13.1642 9.75 12.75 9.75C12.3358 9.75 12 9.41422 12 9C12 8.58578 12.3358 8.25 12.75 8.25C13.1642 8.25 13.5 8.58578 13.5 9Z" fill="${color}"/>
    </svg>    
  `;
  return <SvgXml xml={xml} width={18} height={18} />;
};

export const SVG_ICONS = {
  ChatLogo: { chatLogo: <ChatSmile /> },
  bottomNav: {
    home: <Home />,
    transaction: <Transaction />,
    ewa: <EWA />,
    billpayment: <BillPayment />,
    attendance: <Attendance />,
    others: <Others />,
  },
  rewards: {
    gift: <RewardIcon height={11} width={11} />,
    copyIcon: <CopyIcon />,
    bulletPoint: <BulletPoint />,
  },
  dashboard: {
    salaryWhite: <SalaryDashboard fill="#ffffff" />,
    salary: <SalaryDashboard />,
    savings: <SavingsDashboard fill="#A555AF" />,
  },
  walletColor: <WalletColor />,
  walletColorSmall: <WalletColorSmall />,
  congrats: <Congrats />,
  account: <Account />,
  basket: <Basket />,
  accuredSalary: <AccuredSalary />,
  emptyWallet: <EmptyWallet fill="#000" />,
  pulsa: <Pulsa />,
  tokenPLN: <TokenPLN />,
  voucher: <Voucher />,
  sparkleStar: <Sparklestar />,
  moneyWings: <MoneyWings />,
  coin: <Coin2 height={22} width={22} viewBox="0 10 250 250" />,
  coin2: <Coin2 height={15} width={15} viewBox="0 10 250 250" />,
  coin3: <Coin2 width={15} height={15} viewBox="0 10 250 250" />,
  coin4: <Coin2 height={18} width={18} viewBox="0 10 250 250" />,
  goBack: <GoBack />,
  goForward: <GoForward />,
  swipe: <Swipe />,
  pause: <Pause />,
  gift: <Gift2 />,
  giftSmall: <Gift2 height={30} width={30} />,
  question: <Question />,
  update: <Update />,
  giftNew: <GiftNew height={25} width={25} />,
  welcome: {
    whiteGGLogo: <GGWhiteLogo height={normalize(40)} width={normalize(50)} />,
    artwork: [
      //+(Dimensions.get('screen').width/3).toString()+" "+(Dimensions.get('screen').height/2).toString()
      <ArtWorkOne width="250" height="250" viewBox="0 0 1000 1000" />,
      <ArtWorkTwo width="250" height="250" viewBox="0 0 1000 1000" />,
      <ArtWorkThree width="250" height="250" viewBox="0 0 1000 1000" />,
    ],
  },
  otp: {
    messageNoti: <MessageNoti />,
    whatsappOTP: <WhatsappOTP />,
    smsOTP: <SmsOTP />,
  },
  whatsapp: <Whatsapp />,
  bottomSheet: {
    suspend: <Suspend />,
    comeback: <ComeBack />,
    error: <ErrorData />,
    otp: <Otp />,
    unregistered: <Unregistered />,
    registered: <Registered />,
    waiting: <Waiting />,
    logout: <LogoutHand />,
    success: <Success />,
    pinLock: <PinLock />,
    dataFound: <DataFound />,
  },
  gamification: {
    medal: <Medal height={normalize(40)} width={normalize(50)} />,
    groundLayer: <GroundLayer height={normalize(300)} width={normalize(300)} />,
    rewardAsset: <RewardAsset />,
    missionDark: <MissionDark />,
    missionLight: <MissionLight />,
    tierIcon: {
      tierOne: <TierOne height={normalize(40)} width={normalize(50)} />,
      tierTwo: <TierTwo height={normalize(40)} width={normalize(50)} />,
      tierThree: <TierThree height={normalize(40)} width={normalize(50)} />,
      tierFour: <TierFour height={normalize(40)} width={normalize(50)} />,
      comingSoonLock: (
        <ComingSoonLock height={normalize(22)} width={normalize(50)} />
      ),
      comingSoonTier: (
        <ComingSoonTier height={normalize(100)} width={normalize(110)} />
      ),
      profilePage: {
        tierOne: TierOne,
        tierTwo: TierTwo,
        tierThree: TierThree,
        tierFour: TierFour,
      },
    },
    coin: (
      <Coin2
        height={normalize(25)}
        width={normalize(25)}
        viewBox="0 10 275 275"
      />
    ),
    starSprinkler: (
      <StarSprinkler
        fill="#ffffff"
        height={normalize(12)}
        width={normalize(12)}
      />
    ),
    emptyMountain: <EmptyMountain />,
    rewardInfo: [<CupIcon />, <GiftIcon />, <NoteIcon />],
  },
  voucherInventory: {
    milvikLogo: <MilvikLogo />,
    clipboard: <Clipboard />,
    icVoucher: <IcVoucher />,
    checkSign: <CheckSign />,
    icVoucherEmpty: <IcVoucherEmpty />,
    backgroundLay: <BackgroundLay />,
  },
  dynamicAccounts: {
    artInfo: <ArtInformation />,
    disabledSalaryAccount: <DisabledSalaryAccount />,
    salaryAccount: <SalaryAccount />,
    sdAccount: <SDIcon />,
    workAllowanceIcon: <WorkAllowanceIcon />,
    uangKas: <UangKas />,
  },
  profilePage: {
    profileAvatar: <ProfileAvatar />,
    waBantuan: WaBantuan,
    coin: (
      <Coin2
        height={normalize(17)}
        width={normalize(17)}
        viewBox="0 10 250 250"
      />
    ),
    confetti: <Confetti />,
    confettiScrapes: <ConfettiScrapes />,
    referralGift: <ReferralGift />,
    salaryInformation: SalaryInformation,
  },

  referral: {
    refProgOne: <ReferralPrgOne />,
    refProgTwo: <ReferralPrgTwo />,
    refProgThree: <ReferralPrgThree />,
    pointingArrow: <PointingArrow fill="#3863F0" />,
    pointingArrowRed: <PointingArrow fill="#F56B57" />,
  },
  supportIcon: <NewBantuanIcon />,
  successIcon: <SuccessIcon />,
  clockIcon: <ClockIcon />,
  others: [<OthersWA />, <Logout />],
  loan: {
    approved: <LoanApproved />,
    rejected: <LoanRejected />,
    verification: <LoanVerification />,
    fullyPaid: <LoanFullyPaid />,
    loanProducts: <LoanProducts />,
    cash: <Cash />,
    verifiedUser: <VerifiedUser />,
  },
  deleteModalIcon: <DeleteModalIcon />,
  duplicateModalIcon: <DuplicateModalIcon />,
  phoneEnroll: {
    phoneBook: <PhoneBook />,
    phoneBookError: <PhoneBookError />,
    phoneIcon: PhoneIcon,
  },
  zakat: {
    zakatMain: <ZakatMain />,
    zakatPowered: <ZakatPowered />,
    salaryAccount: (
      <SalaryAccount
        width={normalize(20)}
        height={normalize(20)}
        viewBox="0 0 15 14"
      />
    ),
  },
  salaryInfo: {
    calculator: <Calculator />,
    checkSalaryInfo: <CheckSalaryInfo />,
    refreshSalaryInfo: <RefreshSalaryInfo />,
    phoneCheck: <PhoneCheck />,
    phoneLock: <PhoneLock />,
    phoneVocal: <PhoneVocal />,
  },
  securityPin: {
    padLock: PadLock,
  },
  treasury: {
    bappebti: <Bappebti />,
    buttonGold: <ButtonGold />,
    treasuryLogo: <TreasuryLogo />,
  },
};

export const ReasonsIncreased = {
  6: <Groceries height={25} width={25} fill="#3863F0" />,
  8: <FoodsDrinks height={25} width={25} fill="#3863F0" />,
  4: <Health height={25} width={25} fill="#3863F0" />,
  2: <BillUtility height={25} width={25} fill="#3863F0" />,
  1: <HouseRent height={25} width={25} fill="#3863F0" />,
  3: <Education height={25} width={25} fill="#3863F0" />,
  7: <Apparel height={25} width={25} fill="#3863F0" />,
  12: <AutoRepairs height={25} width={25} fill="#3863F0" />,
  11: <Transportation height={25} width={25} fill="#3863F0" />,
  5: <Tax height={25} width={25} fill="#3863F0" />,
  9: <Gift height={25} width={25} fill="#3863F0" />,
  13: <HouseRenovation height={25} width={25} fill="#3863F0" />,
  16: <Investment height={25} width={25} fill="#3863F0" />,
  15: <StartupCapital height={25} width={25} fill="#3863F0" />,
  14: <Vacation height={25} width={25} fill="#3863F0" />,
  17: <Entertainment height={25} width={25} fill="#3863F0" />,
  18: <Otherexpense height={25} width={25} fill="#3863F0" />,
  19: <Donation height={25} width={25} fill="#3863F0" />,
  20: <Salarywithdrawal height={25} width={25} fill="#3863F0" />,
  21: <FamilyFriends height={25} width={25} fill="#3863F0" />,
  22: <Childneeds height={25} width={25} fill="#3863F0" />,
  23: <Installment height={25} width={25} fill="#3863F0" />,
  24: <Savings height={25} width={25} fill="#3863F0" />,
  25: <Electronic height={25} width={25} fill="#3863F0" />,
  26: <Beauty height={25} width={25} fill="#3863F0" />,
  27: <LateFine height={25} width={25} fill="#3863F0" />,
  28: <Absent height={25} width={25} fill="#3863F0" />,
  29: <BPJS height={25} width={25} fill="#3863F0" />,
  30: <Salary height={25} width={25} fill="#3863F0" />,
  31: <Bonus height={25} width={25} fill="#3863F0" />,
  32: <InvestmentIncome height={25} width={25} fill="#3863F0" />,
  34: <Comission height={25} width={25} fill="#3863F0" />,
  35: <Overtime height={25} width={25} fill="#3863F0" />,
  36: <Borrow height={25} width={25} fill="#3863F0" />,
  37: <OtherIncome height={25} width={25} fill="#3863F0" />,
  33: <BusinessProfit height={25} width={25} fill="#3863F0" />,
  38: <SalaryAdjustment height={25} width={25} />,
  39: <SalaryReciept height={25} width={25} />,
  40: <SalaryReciept height={25} width={25} />,
  41: <SalaryAdjustment height={25} width={25} />,
  42: <SalaryReciept height={30} width={30} />,
  43: <SalaryAdjustment height={30} width={30} />,
  47: <LoanExp fill="#3863F0" />,
};

//FILL IS ADDED HERE AS DISCUSSED WITH ALVER ON 27.10.2022

export const Reasons = {
  1: <HouseRent fill="#F23015" />,
  2: <BillUtility fill="#F23015" />,
  3: <Education fill="#F23015" />,
  4: <Health fill="#F23015" />,
  5: <Tax fill="#F23015" />,
  6: <Groceries fill="#F23015" />,
  7: <Apparel fill="#F23015" />,
  8: <FoodsDrinks fill="#F23015" />,
  9: <Gift fill="#F23015" />,
  11: <Transportation fill="#F56B57" />,
  12: <AutoRepairs fill="#F23015" />,
  13: <HouseRenovation fill="#F23015" />,
  16: <Investment fill="#F23015" />,
  15: <StartupCapital fill="#F23015" />,
  14: <Vacation fill="#F23015" />,
  17: <Entertainment fill="#F23015" />,
  18: <Otherexpense fill="#F23015" />,
  19: <Donation fill="#F23015" />,
  20: <Salarywithdrawal fill="#F23015" />,
  21: <FamilyFriends fill="#F23015" />,
  22: <Childneeds fill="#F23015" />,
  23: <Installment fill="#F23015" />,
  24: <Savings fill="#F23015" />,
  25: <Electronic fill="#F23015" />,
  26: <Beauty fill="#F23015" />,
  27: <LateFine fill="#F23015" />,
  28: <Absent fill="#F23015" />,
  29: <BPJS fill="#F23015" />,
  30: <Salary fill="#56893E" />,
  31: <Bonus fill="#56893E" />,
  32: <InvestmentIncome fill="#56893E" />,
  34: <Comission fill="#56893E" />,
  35: <Overtime fill="#56893E" />,
  36: <Borrow fill="#56893E" />,
  37: <OtherIncome fill="#56893E" />,
  33: <BusinessProfit fill="#56893E" />,
  38: <SalaryAdjustment />,
  39: <SalaryReciept />,
  40: <SalaryReciept height={30} width={30} />,
  41: <SalaryAdjustment height={30} width={30} />,
  42: <SalaryReciept height={30} width={30} />,
  43: <SalaryAdjustment height={30} width={30} />,
  47: <LoanExp fill="#F23015" />,
};

export const IconSavings = {
  'BP-10': <OPulsa />,
  'BP-05': <OTokenPLN />,
  'BP-13': <OVoucher />,
  'CM-01': <OCampaign />,
  'BP-14': <OVoucher />,
  'CP-02': <OCampaign />,
};

export const Points = {
  coin: <Coin height={30} width={30} />,
};

export const getIconSavings = (id) => {
  if (IconSavings[id]) return IconSavings[id];
  else return <ODefault />;
};

export const CustomReasons = {
  6: Groceries,
  8: FoodsDrinks,
  4: Health,
  2: BillUtility,
  1: HouseRent,
  3: Education,
  7: Apparel,
  12: AutoRepairs,
  11: Transportation,
  5: Tax,
  9: Gift,
  13: HouseRenovation,
  16: Investment,
  15: StartupCapital,
  14: Vacation,
  17: Entertainment,
  18: Otherexpense,
  19: Donation,
  20: Salarywithdrawal,
  21: FamilyFriends,
  22: Childneeds,
  23: Installment,
  24: Savings,
  25: Electronic,
  26: Beauty,
  27: LateFine,
  28: Absent,
  29: BPJS,
  30: Salary,
  31: Bonus,
  32: InvestmentIncome,
  34: Comission,
  35: Overtime,
  36: Borrow,
  37: OtherIncome,
  33: BusinessProfit,
  38: SalaryAdjustment,
  39: SalaryReciept,
  40: SalaryReciept,
  41: SalaryAdjustment,
  47: LoanExp,
};

export const MenuIcons = {
  home: HomeIcon,
  transaction: TransactionMenu,
  billpayment: BillPayments,
  other: Other,
  absensi: AbsensiMenu,
  loan: LoanMenu,
  bantuan: BantuanMenu,
  profile: ProfileMenu,
};

export const SavingsDashboardIcon = SavingsDashboard;
