import { StyleSheet } from 'react-native';
import { normalize } from '../../../sharedStyle/helper';
import colors from '@gaji-gesa/gg-react-shared/src/colors';

export const styles = StyleSheet.create({
  Group13603: {
    paddingHorizontal: normalize(15),
  },
  Group341: {
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  IllustrationLandscapeArt_phonebook: {
    width: '100%',
    height: 172,
  },
  BelumAdaNomorHpTerda: {
    color: colors.neutral_charcoal,
    fontSize: normalize(16),
  },
  NomorHpLainnyaMasihK: {
    textAlign: 'center',
    color: colors.neutral_darkMist,
    fontSize: normalize(14),
    marginVertical: normalize(14),
  },
  Frame13537: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: normalize(182),
    height: normalize(30),
    paddingLeft: 38,
    paddingRight: 38,
    paddingTop: 6,
    paddingBottom: 6,
    borderWidth: 1,
    borderColor: colors.disabled_smoke,
    borderRadius: normalize(100),
  },
  TambahNomorHp: {
    marginRight: 8,

    fontSize: normalize(10),
  },
  Add: {
    width: 16,
    height: '100%',
  },
  iconStyle: { fontSize: normalize(14), marginLeft: normalize(-2) },
});
