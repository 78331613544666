import React, { useMemo } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { styles } from './styles';
import MyText from '@atoms/MyText';
import { colors } from '@style/colors';

const Status = ({ status }) => {
  const getStatusConfig = useMemo(() => {
    if (status === 'PENDING') {
      return {
        color: colors.softSunflower,
        textColor: colors.darkSunFlower,
        text: 'Dalam Verifikasi',
      };
    }

    if (status === 'APPROVED') {
      return {
        color: null,
        textColor: null,
        text: '',
      };
    }
    return {
      color: colors.softRose,
      textColor: colors.toastError,
      text: 'Ditolak',
    };
  }, [status]);

  return (
    <View
      style={[styles.container, { backgroundColor: getStatusConfig.color }]}
    >
      <MyText
        h2
        customStyle={{ color: getStatusConfig.textColor }}
        isRegular={false}
      >
        {getStatusConfig.text}
      </MyText>
    </View>
  );
};

Status.propTypes = {
  status: PropTypes.string.isRequired,
};
Status.defaultProps = {};
export default Status;
