import { DYNAMIC_ACCOUNTS_CASES } from '../reducer/DynamicAccounts';

export const setDynamicAccounts = (data) => ({
  type: DYNAMIC_ACCOUNTS_CASES.setDynamicAccounts,
  payload: data,
});

export const setCurrentHomePageSelectedAccount = (data) => ({
  type: DYNAMIC_ACCOUNTS_CASES.setCurrentHomePageSelectedAccount,
  payload: data,
});

export const setCurrentTransactionPageSelectedAccount = (data) => ({
  type: DYNAMIC_ACCOUNTS_CASES.setCurrentTransactionPageSelectedAccount,
  payload: data,
});

export const setDataAccounts = (data) => ({
  type: DYNAMIC_ACCOUNTS_CASES.setDataAccounts,
  payload: data,
});

export const clearDynamicAccountReducer = () => ({
  type: DYNAMIC_ACCOUNTS_CASES.clearDynamicAccountReducer,
});

export const setWorkAllowanceAmount = (data) => ({
  type: DYNAMIC_ACCOUNTS_CASES.workAllowanceAccountConfig.setWorkAllowanceAmount,
  payload: data,
});

export const setWorkAllowanceEditable = (data) => ({
  type: DYNAMIC_ACCOUNTS_CASES.workAllowanceAccountConfig.setWorkAllowanceEditable,
  payload: data,
});

export const setWorkAllowanceMaxAmount = (data) => ({
  type: DYNAMIC_ACCOUNTS_CASES.workAllowanceAccountConfig.setWorkAllowanceMaxAmount,
  payload: data,
});

export const setSdAmount = (data) => ({
  type: DYNAMIC_ACCOUNTS_CASES.sdAccountConfig.sdAmount,
  payload: data,
});

export const setCurrentBpSelectedAccount = (data) => ({
  type: DYNAMIC_ACCOUNTS_CASES.setCurrentBpSelectedAccount,
  payload: data,
});

export const setUangKasAllowanceAmount = (data) => {
  return {
    type: DYNAMIC_ACCOUNTS_CASES.uangKasAccountConfig.setUangKasAmount,
    payload: data,
  };
};

export const setUangKasMaxAmount = (data) => {
  return {
    type: DYNAMIC_ACCOUNTS_CASES.uangKasAccountConfig.setUangKasAllowanceMaxAmount,
    payload: data,
  };
};

export const setUangKasEditable = (data) => {
  return {
    type: DYNAMIC_ACCOUNTS_CASES.uangKasAccountConfig.setUangKasEditable,
    payload: data,
  };
};
