import { StyleSheet } from "react-native";
import { colors } from "@style/colors";
import { Font } from "@style/Fonts";
import { normalize, normalizeHeight } from "@style/helper";

export const styles = StyleSheet.create({
   modal:{
       justifyContent:'flex-end', 
       margin:0
    },
    container:{
        backgroundColor:colors.white, 
        width:'100%', 
        borderTopEndRadius:15, 
        borderTopStartRadius:15,
        minHeight:'80%',
        maxHeight:'80%'
   

    },
    info:{
        paddingHorizontal:15
    },
    infoDetailRow:{
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'space-between',
        marginVertical:2
    }
})