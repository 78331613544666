import { StyleSheet } from 'react-native';
import { colors } from '@style/colors';

export const styles = StyleSheet.create({
  bankDetailsContainer: {
    flex: 1,
  },
  eyeButton: { marginStart: 10 },
  greyText: {
    color: colors.philippineGray,
  },
  mainContainer: {
    marginHorizontal: 10,
  },
  detailsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 10,
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
