import { Dimensions } from 'react-native';
import { Font } from './Fonts';
import { normalize, normalizeHeight } from './helper';

const { height: screenHeight, width: screenWidth } = Dimensions.get('window');

// CENTER VIEWS
const centerView = {
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
};

// TEXT COMPONENTS
const titleText = {
  fontSize: 30,
  marginTop: 20,
  marginBottom: 20,
  fontFamily: Font.Nunito_Regular,
};
const subTitleText = {
  fontSize: normalize(17),
  marginTop: 15,
  marginBottom: 10,
  marginLeft: 25,
  fontFamily: Font.Nunito_Regular,
};
const smallerText = {
  fontSize: normalize(12),
  alignSelf: 'center',
  fontFamily: Font.Nunito_Regular,
};
const xSmallText = {
  fontSize: normalize(12),
  fontWeight: 'bold',
  fontFamily: Font.Nunito_Bold,
};

/* LOANS */
const boxCard = {
  body: {
    height: screenHeight > 700 ? screenHeight / 3 : screenHeight / 2.4,
    width: screenWidth / 1.1,
    backgroundColor: '#FFF',
    borderRadius: 16,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    display: 'flex',
    alignItems: 'center',
    maxHeight: normalizeHeight(250),
    alignSelf: 'center',
    marginTop: 10,
    // marginBottom: 5
  },
  row: {
    flexDirection: 'row',
  },
  column: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    margin: 10,
  },
  wrapper: {
    marginLeft: -18,
    marginBottom: 5,
  },
  title: {
    alignSelf: 'flex-start',
    color: '#3863f0',
    fontSize: normalize(14),
    fontFamily: Font.Nunito_Bold,
    marginBottom: 10,
    marginTop: 15,
    marginLeft: 25,
  },
  text: {
    fontSize: normalize(13),
    textAlign: 'center',
    fontFamily: Font.Nunito_Bold,
  },
  borderStatus: {
    backgroundColor: '#137A08',
    marginLeft: 20,
    borderRadius: 20,
    width: '55%',
    paddingVertical: 8,
  },
  borderStatusText: {
    fontSize: normalize(10),
    color: '#fff',
    textAlign: 'center',
  },
};
const loanWrapper = {
  wrapperAmount: {
    flexDirection: 'row',
    marginHorizontal: 20,
    justifyContent: 'space-between',
    display: 'flex',
    // flexWrap: 'wrap'
  },
  contentLoan: {
    paddingVertical: 8,
    paddingHorizontal: 10,
    backgroundColor: '#fff',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#D4D4D4',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 0.4,
    shadowRadius: 6,
    elevation: 5,
    width: '30%',
    marginVertical: 5,
  },
  contentLoan2: {
    paddingVertical: 8,
    // paddingHorizontal: 10,
    backgroundColor: '#fff',
    // borderRadius: 5,
    borderWidth: 1,
    borderColor: '#D4D4D4',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 0.4,
    shadowRadius: 6,
    elevation: 5,
    // margin: 5,
    paddingHorizontal: 20,
    borderRadius: 20,
    marginBottom: 5,
  },
};
const cardFee = {
  container: {
    flexDirection: 'row',
    margin: 15,
  },
  wrapper: {
    padding: 10,
    backgroundColor: '#FFECE8',
    flex: 0.5,
    borderRadius: 5,
    justifyContent: 'flex-start',
    elevation: 1,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,
  },
  flex: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
  },
  text: {
    fontSize: normalize(10),
    fontFamily: Font.Nunito_Regular,
    color: '#E36A58',
    marginBottom: 10,
  },
  text2: {
    fontSize: normalize(12),
    fontFamily: Font.Nunito_Regular,
    color: '#E36A58',
  },
};
const table = {
  box: {
    marginHorizontal: 15,
    marginBottom: 10,
    elevation: 1,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,
    backgroundColor: '#F1EEFF',
    borderRadius: 5,
    flexDirection: 'column',
  },
  header: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10,
  },
  body: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10,
    backgroundColor: '#EBE7FF',
  },
  text: {
    fontSize: normalize(12),
    fontFamily: Font.Nunito_Regular,
    color: '#6C6590',
  },
  column: {
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#6C6590',
  },
  label: {
    color: '#fff',
    backgroundColor: '#F2A12D',
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 15,
  },
};
const loanActiveStyle = {
  reminderBox: {
    marginVertical: 10,
    flexDirection: 'row',
    borderRadius: 5,
    borderTopWidth: 1,
    borderTopColor: '#C4C4C4',
    borderLeftWidth: 10,
    borderLeftColor: '#F2A12D',
    borderRightWidth: 1,
    borderRightColor: '#C4C4C4',
    borderBottomWidth: 1,
    borderBottomColor: '#C4C4C4',
    backgroundColor: '#fff',
    marginHorizontal: 15,
    elevation: 1,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,
  },
  reminderImage: {
    paddingVertical: 20,
    paddingHorizontal: 10,
    marginLeft: 10,
  },
  reminderTextAlign: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    display: 'flex',
    marginLeft: 10,
  },
  reminderText: {
    fontSize: normalize(12),
    fontFamily: Font.Nunito_Regular,
    color: '#6C6590',
    flex: 0.3,
  },
  reminderTextBold: {
    color: '#F56B57',
    fontSize: normalize(14),
    fontFamily: Font.Nunito_Bold,
  },
  installments: { flexDirection: 'row', padding: 10 },
};
const checkBoxStyle = {
  wrapper: {
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    height: '5%',
    marginTop: 10,
  },
  text: {
    fontSize: normalize(13),
    fontFamily: Font.Nunito_Regular,
    color: '#8B8989',
  },
  button: {
    alignSelf: 'center',
    elevation: 1,
  },
};
const backgroundLoan = {
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  borderTopColor: '#3863F0',
  borderTopWidth: screenHeight / 4,
  width: screenWidth,
  position: 'absolute',
  right: 0,
  maxHeight: normalizeHeight(500),
};
/* END LOANS */

// USED IN CARDGRADIENT, SALARYDETAIL and SALARY DASHBOARD
const backgroundImage = {
  container: {
    borderRadius: 8,
    paddingVertical: 8,
    paddingHorizontal: 10,
    marginHorizontal: '6%',
    marginBottom: '10%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingLeft: 25,
  },
  subContainer: {
    flexDirection: 'row',
    marginHorizontal: '10%',
  },
  wrapperLeft: {
    flexDirection: 'column',
  },
  subWrapperLeft: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 2,
  },
  textBackground: {
    fontSize: normalize(12),
    fontFamily: Font.Nunito_Regular,
    color: '#FFFFFF',
  },
  wrapperRight: {
    marginLeft: '15%',
    backgroundColor: '#FFFFFF',
    paddingVertical: 10,
    paddingHorizontal: 15,
    flexDirection: 'column',
    borderRadius: 8,
    marginRight: 5,
  },
  subWrapperRight: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  textBackground2: {
    fontSize: normalize(14),
    fontFamily: Font.Nunito_Bold,
    color: '#454545',
  },
};

/* =================================================== USED IN ONE PLACE ONLY ======================================== */
// SALARY DASHBOARD ONLY
const selectDate = {
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: '5%',
    marginBottom: '2%',
  },
};

// SALARY DETAIL ONLY
const detailSalary = {
  container: {
    flexDirection: 'column',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: '#E5E5E5',
    marginTop: 10,
    paddingVertical: 10,
    paddingHorizontal: 15,
    marginBottom: 10,
  },
  firstSubContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  wrapperFirst: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  secondSubContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 12,
  },
  firstText: {
    fontSize: normalize(10),
    fontFamily: Font.Nunito_Regular,
    color: '#EC2105',
    textAlign: 'center',
    paddingVertical: 5,
    paddingHorizontal: 8,
    backgroundColor: '#FFF1EF',
    borderRadius: 15,
  },
  boxSecondText: {
    backgroundColor: '#3863F0',
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 5,
  },
  secondText: {
    fontSize: normalize(10),
    fontFamily: Font.Nunito_Regular,
    color: '#FFFFFF',
    textAlign: 'center',
  },
};

// BUTTONCOLOR ONLY
const buttonColor = {
  button: {
    paddingHorizontal: normalize(10),
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: normalize(7),
    backgroundColor: '#0053DC',
    borderRadius: 20,
    marginStart: normalize(10),
    minWidth: normalize(60),
  },
  title: {
    fontFamily: Font.Nunito_Regular,
    color: '#FFFFFF',
  },
};

// BUTTONBORDER ONLY
const buttonBorder = {
  button: {
    paddingHorizontal: normalize(10),
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: normalize(7),
    borderColor: '#0053DC',
    borderRadius: 20,
    borderWidth: 1.5,
    minWidth: normalize(60),
  },
  title: {
    fontFamily: Font.Nunito_Regular,
    color: '#0053DC',
  },
};

// INFORMASI ONLY
const rowView = {
  marginTop: 20,
  marginLeft: 25,
  flex: 1,
  flexDirection: 'row',
  maxHeight: 40,
};

// HEADERBAR ONLY
const header = {
  wrapper: {
    flexDirection: 'row',
    backgroundColor: '#3863F0',
    shadowRadius: 0,
    shadowOffset: {
      height: 0,
    },
    height: screenHeight / 12,
    elevation: 4,
  },
  title: {
    color: '#FFFF',
    fontSize: normalize(17),
    marginLeft: 28,
    justifyContent: 'flex-start',
    flex: 0.8,
    marginTop: 20,
    fontFamily: Font.Nunito_Bold,
  },
  button: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flex: 1,
    marginBottom: 5,
  },
};

/* ===================================================================================================================== */

/* ------------------------------------- MAKE A COMPONENT FOR THIS AND ADD THE OBJECT ----------------------------------- */
// LOGO
const mdLogo = {
  width: normalize(125),
  height: normalize(125),
  alignSelf: 'center',
};

// This one apparently only being used in the Login and OTP views
const backgroundStyle = {
  width: '100%',
  height: '100%',
  resizeMode: 'contain',
};

const coinLogo = (pts) => ({
  width: normalize(pts),
  height: normalize(pts),
  alignSelf: 'center',
  marginRight: '3%',
});
/* ----------------------------------------------------------------------------------------------------------------------- */

export {
  centerView,
  rowView,
  titleText,
  subTitleText,
  smallerText,
  xSmallText,
  backgroundStyle,
  mdLogo,
  coinLogo,
  boxCard,
  loanWrapper,
  cardFee,
  table,
  loanActiveStyle,
  checkBoxStyle,
  backgroundLoan,
  header,
  backgroundImage,
  selectDate,
  detailSalary,
  buttonColor,
  buttonBorder,
};
