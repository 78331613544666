import { StyleSheet } from 'react-native';
import { normalize } from '../../../sharedStyle/helper';
import colors from '@gaji-gesa/gg-react-shared/src/colors';

export const styles = StyleSheet.create({
  ListPhonenumber: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: normalize(48),
    paddingHorizontal: normalize(15),
    paddingTop: 4,
    paddingBottom: 4,
  },
  Frame13537: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: '100%',
  },
  RekeningNumber: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },

  Name_provider: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
  },

  showTickStyle: { fontSize: normalize(17), color: colors.primary_carnation },
  phoneNumberStyle: {
    color: colors.neutral_charcoal,
    fontSize: normalize(12),
  },
  ownerStyle: {
    color: colors.neutral_charcoal,
    opacity: 1,
    fontSize: normalize(12),
  },
});
