import React from 'react';
import Delete from 'react-native-vector-icons/MaterialIcons';
import { colors } from '@style/colors';

export const getEyeIconName = (masked) => {
  return masked ? 'eye-off' : 'eye';
};

export const deleteButton = (status, onPress) => {
  if (status !== 'PENDING') {
    return (
      <Delete onPress={onPress} name="delete" color={colors.black} size={20} />
    );
  }
  return null;
};
