import React, { useCallback } from 'react';
import { FlatList } from 'react-native';
import LoanMetaItem from '@atoms/Loan/LoanMetaItem';
import { styles } from './style';
import { LOAN_META_INFO } from '@gaji-gesa/gg-react-shared/src/utils/sharedConstants';

const LoanMetaList = () => {
  const renderItem = useCallback(
    ({ item, index }) => <LoanMetaItem key={index} item={item} index={index} />,
    []
  );

  return (
    <FlatList
      contentContainerStyle={styles.contentContainerStyle}
      data={LOAN_META_INFO}
      keyExtractor={(item, index) => index.toString()}
      renderItem={renderItem}
    />
  );
};

export default LoanMetaList;
