import React, { useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { styles } from './styles';
import MyText from '@atoms/MyText';
import Delete from 'react-native-vector-icons/MaterialIcons';
import Eye from 'react-native-vector-icons/Ionicons';
import { colors } from '@style/colors';
import { masker } from '@gaji-gesa/gg-react-shared/src/utils/sharedUtils';
import Status from '../Status';
import { deleteButton, getEyeIconName } from './BankItemUtils';

const BankItem = ({ item, onPressDelete, onMasked, onUnMasked }) => {
  const [masked, setMasked] = useState(true);
  const formattedBankNumber = useMemo(() => {
    if (masked) return masker(item.accountNumber);
    return item.accountNumber;
  }, [masked, item.accountNumber]);

  const onClickedEye = useCallback(() => {
    if (!masked) {
      onMasked();
    } else {
      onUnMasked();
    }
    setMasked(!masked);
  }, [masked, onMasked, onUnMasked]);

  return (
    <View style={styles.detailsContainer}>
      <View style={styles.bankDetailsContainer}>
        <MyText isRegular={false}>{item.bankName}</MyText>
        <View style={styles.rowContainer}>
          <MyText h2 isRegular={false} customStyle={styles.greyText}>
            {formattedBankNumber}
          </MyText>
          <Eye
            onPress={onClickedEye}
            name={getEyeIconName(masked)}
            size={20}
            style={styles.eyeButton}
            color={colors.balck}
          />
        </View>
        <View style={styles.rowContainer}>
          <MyText customStyle={styles.greyText} isRegular={false}>
            {item.nickName}
            {item.beneficiaryName !== '' && item.nickName !== '' ? ' • ' : ''}
          </MyText>
          <MyText customStyle={styles.greyText}>{item.beneficiaryName}</MyText>
        </View>
      </View>
      <View style={styles.rowContainer}>
        <Status status={item.status} />

        {deleteButton(item.status, () => onPressDelete(item))}
      </View>
    </View>
  );
};

BankItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    beneficiaryName: PropTypes.string.isRequired,
    bankName: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
    nickName: PropTypes.string,
  }).isRequired,
  onPressDelete: PropTypes.func.isRequired,
  onMasked: PropTypes.func.isRequired,
  onUnMasked: PropTypes.func.isRequired,
};
BankItem.defaultProps = {};
export default BankItem;
