import React from 'react';
import { View } from 'react-native';
import MyText from '@atoms/MyText';
import { styles } from './style';

const LabelTitle = ({ title, containerStyle }) => (
  <View style={[styles.containerStyle, containerStyle]}>
    <MyText isRegular={false} customStyle={styles.textStyle}>
      {title}
    </MyText>
  </View>
);

export default LabelTitle;
