import { AddedUserBank } from '../reducer/Interfaces/UserBank/AddedUserBank';
import { DefaultBankAccount } from '../reducer/Interfaces/UserBank/DefaultBankAccount';
import { USER_BANK_CASES } from '../reducer/UserBank';

const setUserBankData = (
  defaultBankAccount: DefaultBankAccount,
  userBanks: Array<AddedUserBank>,
  commonUserReadStatus: boolean,
) => ({
  type: USER_BANK_CASES.setUserBankData,
  payload: {
    defaultBankAccount,
    userBanks,
    commonUserReadStatus,
  },
});
const changeUserReadStatus = (userBanks: Array<AddedUserBank>) => ({
  type: USER_BANK_CASES.changeUserReadStatus,
  payload: userBanks,
});

const createNewBankAccount = (userBanks: Array<AddedUserBank>) => ({
  type: USER_BANK_CASES.createNewBankAccount,
  payload: userBanks,
});

const deleteBankAccount = (userBanks: Array<AddedUserBank>) => ({
  type: USER_BANK_CASES.deleteBankAccount,
  payload: userBanks,
});

const setCommonUserReadStatus = (commonUserReadStatus: boolean) => ({
  type: USER_BANK_CASES.setCommonUserReadStatus,
  payload: commonUserReadStatus,
});

export { setUserBankData, changeUserReadStatus, createNewBankAccount, deleteBankAccount, setCommonUserReadStatus };
