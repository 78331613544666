import { StyleSheet } from 'react-native';
import { normalize } from '../../../sharedStyle/helper';
import colors from '@gaji-gesa/gg-react-shared/src/colors';

export const styles = StyleSheet.create({
  mainContainer: {
    paddingVertical: normalize(11),
    borderWidth: 1,
    borderColor: '#DEDEDE',
    borderRadius: normalize(12),
    marginHorizontal: normalize(15),
    marginTop: normalize(16),
    justifyContent: 'center',
  },
  rowOne: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: normalize(8),
  },
  rowTwo: { flexDirection: 'row', justifyContent: 'center' },
  rowThree: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: normalize(5),
  },
  kamuContainer: {
    borderRadius: normalize(12),
    borderWidth: 1,
    borderStyle: 'dashed',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: normalize(10),
    marginRight: normalize(70),
    marginLeft: normalize(-7),
    borderColor: colors.secondary_orchid,
  },
  kamuStyle: {
    fontSize: normalize(10),
    textAlign: 'center',
    color: colors.secondary_orchid,
  },
  temanuContainer: {
    borderRadius: normalize(12),
    borderWidth: 1,
    borderStyle: 'dashed',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: normalize(37),
    borderColor: colors.primary_carnation,
  },
  temanuStyle: { color: colors.primary_carnation },
  iconContainer: {
    borderRadius: normalize(11),
    height: normalize(28),
    width: normalize(28),
    backgroundColor: colors.secondary_softOrchid,
    justifyContent: 'center',
    alignItems: 'center',
  },
  arrowContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: -1,
    marginLeft: normalize(-13),
  },
  redishBack: { backgroundColor: colors.primary_softCarnation },
  textOne: {
    fontSize: normalize(10),
    marginLeft: normalize(-4),
    textAlign: 'center',
  },
  textTwo: { fontSize: normalize(10), textAlign: 'center' },
  textThree: {
    fontSize: normalize(10),
    marginLeft: normalize(10),
    textAlign: 'center',
  },
});
