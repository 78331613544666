import { StyleSheet } from 'react-native';
import { normalize, normalizeHeight } from '../../../sharedStyle/helper';
import colors from '@gaji-gesa/gg-react-shared/src/colors';

export const styles = StyleSheet.create({
  outerContainer: {
    marginBottom: normalize(10),
    marginTop: normalize(15),
    paddingHorizontal: normalize(15),
  },
  textContainer: { width: '85%', marginLeft: normalize(10) },
  textStyles: { fontSize: normalize(14) },
  descStyles: {
    color: colors.neutral_charcoal,
    fontSize: normalize(12),
    marginBottom: normalize(7),
    marginTop: normalize(4),
  },
  listContainer: {
    marginTop: normalizeHeight(5),
    marginBottom: normalizeHeight(10),
    backgroundColor: colors.neutral_mist,
    borderRadius: normalize(12),
    paddingHorizontal: normalize(5),
    paddingTop: normalize(4),
  },
  row: { flexDirection: 'row' },
  iconStyle: { marginTop: normalize(7) },
});
