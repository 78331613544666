import React from 'react';
import { Platform, StyleSheet, Text, TouchableOpacity } from 'react-native';
import RNIcon from 'react-native-vector-icons/FontAwesome5';
import ExpoIcon from '@expo/vector-icons/FontAwesome5';
import { normalize } from '../../sharedStyle/helper';
import { Font } from '../../sharedStyle/Fonts';

const Icon = Platform.OS === 'web' ? ExpoIcon : RNIcon;

const styles = StyleSheet.create({
  iconButtonText: {
    fontSize: normalize(14),
    fontFamily: Font.Nunito_Regular,
    color: '#292929',
    alignSelf: 'center',
    marginRight: 15,
  },
});

const IconButton = ({ testId, icon, onPress, style, size, iconStyle }) => {
  return (
    <TouchableOpacity
      accessibilityLabel={testId}
      onPress={onPress}
      style={{ ...style }}
    >
      <Text style={styles.iconButtonText}>
        <Icon
          name={icon}
          style={{ ...iconStyle }}
          size={size || normalize(20)}
        />
      </Text>
    </TouchableOpacity>
  );
};

export default IconButton;
