import React from 'react';
import { View } from 'react-native';
import { styles } from './style';
import MyText from '@atoms/MyText';
import Icon from 'react-native-vector-icons/MaterialIcons';

const NoticeBar = () => (
  <View style={styles.container}>
    <Icon style={styles.iconContainer} name="info-outline" />
    <MyText isRegular={false} customStyle={styles.textStyle}>
      Untuk perubahan data silahkan hubungi HR Kamu
    </MyText>
  </View>
);

export default NoticeBar;
