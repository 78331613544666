import { useLazyQuery, useMutation } from '@apollo/client';
import { useMemo, useState } from 'react';
import {
  GET_ELIGIBILTY_APP_STATUS,
  UPDATE_LOAN_STATUS,
} from '@gaji-gesa/gg-react-shared/src/gqlQuery/loan';
import { checkForLoan } from '@gaji-gesa/gg-react-shared/src/utils/sharedUtils';
import { useSelector } from 'react-redux';

const useLoans = ({ isLoanFeature, onShowModal, onUpdate }) => {
  const [loanData, setLoanData] = useState(null);
  const [isRefetch, setRefetch] = useState(false);

  const { employeeId } = useSelector((state) => state.Authentication);

  const [getEligibilityAppStatus] = useLazyQuery(GET_ELIGIBILTY_APP_STATUS, {
    fetchPolicy: 'no-cache',
    variables: { employeeId: employeeId },
    onCompleted: (data) => {
      setLoanData(data);
      setRefetch(false);
      isRefetch && onShowModal(data);
    },
    onError: (error) => {
      setLoanData(null);
    },
  });

  const [updateLoanStatus] = useMutation(UPDATE_LOAN_STATUS, {
    fetchPolicy: 'no-cache',
    variables: { applicationId: loanData?.loan_applications[0]?.id },
    onCompleted: (data) => {
      onUpdate(data.update_loan_applications?.returning[0]);
    },
    onError: (error) => {},
  });

  const showLoan = useMemo(
    () => checkForLoan(isLoanFeature, loanData),
    [isLoanFeature, loanData]
  );

  return {
    getEligibilityAppStatus,
    updateLoanStatus,
    showLoan,
    loanData,
    isRefetch,
    setRefetch,
  };
};

export default useLoans;
