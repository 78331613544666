import { LOAN_REDUCER_CASES } from '../reducer/Loan';

export const setLoan = (data) => ({
  type: LOAN_REDUCER_CASES.setLoan,
  payload: data,
});

export const setStatusLoan = (data) => ({
  type: LOAN_REDUCER_CASES.setStatusLoan,
  payload: data,
});

export const setAmountReceived = (data) => ({
  type: LOAN_REDUCER_CASES.setAmountReceived,
  payload: data,
});

export const setLoanCalculate = (data) => ({
  type: LOAN_REDUCER_CASES.setLoanCalculate,
  payload: data,
});

export const setAdminFee = (data) => ({
  type: LOAN_REDUCER_CASES.setAdminFee,
  payload: data,
});

export const resetLoan = (data) => ({
  type: LOAN_REDUCER_CASES.resetLoan,
  payload: data,
});
