import React from 'react';
import { View, Pressable } from 'react-native';
import MIcon from 'react-native-vector-icons/MaterialIcons';
import MyText from '@atoms/MyText';
import Collapsible from 'react-native-collapsible';
import Item from '..//Item';
import styles from './style.js';

const FAQSection = ({
  title,
  data,
  collapse,
  handleCollapse,
  containerStyle,
}) => (
  <>
    <Pressable
      style={[styles.container, containerStyle]}
      onPress={handleCollapse}
    >
      <MyText isRegular={false} customStyle={styles.titleStyle}>
        {title}
      </MyText>

      <View style={styles.iconContainerStyle}>
        <MIcon
          name={collapse ? 'keyboard-arrow-down' : 'keyboard-arrow-up'}
          size={24}
        />
      </View>
    </Pressable>
    <Collapsible collapsed={collapse} style={styles.collapseContainerStyle}>
      {data.map((e) => (
        <Item icon={e.icon} title={e.desc} />
      ))}
    </Collapsible>
  </>
);

export default FAQSection;
