import React, { useMemo, useState } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { styles } from './styles';
import Bank from 'react-native-vector-icons/FontAwesome';
import MyText from '@atoms/MyText';
import Arrow from 'react-native-vector-icons/Ionicons';
import Eye from 'react-native-vector-icons/Ionicons';
import Add from 'react-native-vector-icons/AntDesign';
import { colors } from '@style/colors';
import { masker } from '@gaji-gesa/gg-react-shared/src/utils/sharedUtils';
import Divider from '@atoms/Divider';
import ImageButton from '@atoms/ImageButton';
import HasMoreBank from '../HasMoreBank';
import { getEyeIconName } from '../BankItem/BankItemUtils';
import { getArrowIcon } from './DefaultBankCardUtils';

const DefaultBankCard = ({
  hasMoreBanks,
  onPressMoreBanks,
  showRedDot,
  onPressCreateBankAccount,
  defaultAccount,
}) => {
  const [expanded, setExpanded] = useState(true);
  const [masked, setMasked] = useState(true);

  const formattedBankNumber = useMemo(() => {
    if (masked) return masker(defaultAccount?.bankAccountNo);
    return defaultAccount?.bankAccountNo;
  }, [masked, defaultAccount?.bankAccountNo]);

  if (hasMoreBanks) {
    return <HasMoreBank onPress={onPressMoreBanks} showDot={showRedDot} />;
  }

  return (
    <View style={styles.mainContainer}>
      <View style={styles.detailsContainer}>
        <Bank
          name="bank"
          size={15}
          style={styles.bankIcon}
          color={colors.black}
        />
        <View style={styles.bankDetailsContainer}>
          <MyText h2>Rekening Terdaftar (Utama)</MyText>
          <MyText
            isRegular={false}
            customStyle={styles.bankStyle}
            numberOfLines={1}
          >
            {defaultAccount?.bankName}
          </MyText>
          <View style={styles.rowContainer}>
            <MyText h2 customStyle={styles.greyText}>
              {defaultAccount?.beneficiaryName} •{' '}
            </MyText>
            <MyText h2 isRegular={false} customStyle={styles.greyText}>
              {formattedBankNumber}
            </MyText>
            <Eye
              onPress={() => setMasked(!masked)}
              name={getEyeIconName(masked)}
              size={20}
              style={styles.eyeIcon}
              color={colors.balck}
            />
          </View>
        </View>
        <Arrow
          onPress={() => setExpanded(!expanded)}
          name={getArrowIcon(expanded)}
          color={colors.black}
          size={20}
        />
      </View>
      {expanded && (
        <>
          <Divider horizontal customStyle={styles.divider} />
          <ImageButton
            onPress={onPressCreateBankAccount}
            styleContainer={styles.imageButton}
            textStyle={styles.buttonStyle}
            text={'Tambah rekening lainnya'}
            iconRight={<Add name="plus" color={colors.black} size={20} />}
          />
        </>
      )}
    </View>
  );
};

DefaultBankCard.propTypes = {
  hasMoreBanks: PropTypes.bool.isRequired,
  onPressMoreBanks: PropTypes.func.isRequired,
  showRedDot: PropTypes.bool.isRequired,
  onPressCreateBankAccount: PropTypes.func.isRequired,
  defaultAccount: PropTypes.shape({
    bankAccountNo: PropTypes.string.isRequired,
    beneficiaryName: PropTypes.string.isRequired,
    bankName: PropTypes.string.isRequired,
  }).isRequired,
};
DefaultBankCard.defaultProps = {};
export default DefaultBankCard;
