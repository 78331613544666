import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { StyleSheet } from 'react-native';
import { normalize, normalizeHeight } from '../../../sharedStyle/helper';

export const styles = StyleSheet.create({
  infoOuterContainer: {
    flexDirection: 'row',
  },
  infoLeftSubContainer: {
    width: '34%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoRightSubContainer: {
    width: '66%',
    justifyContent: 'center',
    paddingVertical: normalizeHeight(18),
  },
  logoImageStyle: { width: normalize(130), height: normalize(90) },
  infoTextStyle: { fontSize: normalize(14), paddingRight: normalize(12) },
  linkTextStyle: {
    textDecorationLine: 'underline',
    color: colors.secondary_orchid,
  },
  linkStyle: { marginTop: normalize(5) },
});
