import { TRANSACTION_REDUCER_CASES } from '../reducer/Transactions';

export const verifyWithdrawal = (data) => ({
  type: TRANSACTION_REDUCER_CASES.verifyWithdrawal,
  payload: data,
});

export const verifyBillPayment = (data) => ({
  type: TRANSACTION_REDUCER_CASES.verifyBillPayment,
  payload: data,
});

export const setInquiry = (data) => ({
  type: TRANSACTION_REDUCER_CASES.setInquiry,
  payload: data,
});

export const resetInquiry = (data) => ({
  type: TRANSACTION_REDUCER_CASES.resetInquiry,
  payload: data,
});

export const setUserAccountNumber = (data) => ({
  type: TRANSACTION_REDUCER_CASES.setUserAccountNumber,
  payload: data,
});

export const resetTransaction = (data) => ({
  type: TRANSACTION_REDUCER_CASES.resetTransaction,
  payload: data,
});

export const setReason = (data) => ({
  type: TRANSACTION_REDUCER_CASES.setReason,
  payload: data,
});

export const setTransactionCategoryId = (data) => ({
  type: TRANSACTION_REDUCER_CASES.setTransactionCategoryId,
  payload: data,
});

export const setTransactionSource = (data) => ({
  type: TRANSACTION_REDUCER_CASES.setTransactionSource,
  payload: data,
});
