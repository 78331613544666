import React from 'react';
import { View } from 'react-native';
import MyText from '@atoms/MyText';
import { numberWithDots } from '@gaji-gesa/gg-react-shared/src/utils/sharedUtils';
import { styles } from './style';
import Proptypes from 'prop-types';

export const Row = ({ title, value }) => {
  return (
    <View style={styles.rowStyle}>
      <MyText isRegular customStyle={styles.titleStyle}>
        {title}
      </MyText>

      <MyText isRegular={false} customStyle={styles.titleStyle}>
        {value}
      </MyText>
    </View>
  );
};

const TransaksiDetails = ({
  fee,
  bankName,
  benificiaryName,
  bankAccountNo,
  show,
}) => {
  if (!show) return null;

  return (
    <>
      <View style={styles.outerContainer}>
        <MyText isRegular={false} customStyle={styles.mainTitleStyle}>
          Detail Transaksi
        </MyText>

        <View style={styles.innerContainer}>
          <Row title={'Biaya Layanan'} value={`Rp${numberWithDots(fee)}`} />
          <Row title={'Bank Tujuan'} value={bankName} />
          <Row title={'No. Rekening'} value={bankAccountNo} />
          <Row title={'Pemilik Rekening'} value={benificiaryName} />
        </View>
      </View>
    </>
  );
};

TransaksiDetails.propTypes = {
  fee: Proptypes.number,
  bankName: Proptypes.string,
  benificiaryName: Proptypes.string,
  bankAccountNo: Proptypes.number,
  show: Proptypes.bool,
};

export default TransaksiDetails;
