import { StyleSheet } from 'react-native';
import { normalize } from '../../../sharedStyle/helper';
import colors from '@gaji-gesa/gg-react-shared/src/colors';

export const styles = StyleSheet.create({
  containerStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: normalize(15),
    marginVertical: normalize(12),
    borderRadius: normalize(8),
    backgroundColor: colors.secondary_softOrchid,
  },

  textOne: {
    marginTop: normalize(12),
    fontSize: normalize(14),
    color: colors.neutral_charcoal,
  },
  textTwo: {
    marginTop: normalize(12),
    fontSize: normalize(12),
    color: colors.blackColor,
  },
  textThree: {
    marginTop: normalize(10),
    fontSize: normalize(10),
    color: colors.neutral_charcoal,
  },
  textFour: {
    marginTop: normalize(7),
    marginBottom: normalize(12),
    fontSize: normalize(11),
    textAlign: 'center',
    color: colors.neutral_charcoal,
  },
});
