import React from 'react';
import { View, Text, Pressable, ActivityIndicator } from 'react-native';
import { colors } from '@style/colors';
import styles from './styles';

const ImageButton = ({
  onPress,
  styleContainer,
  text,
  iconLeft,
  iconRight,
  isBorder = false,
  textStyle,
  disabled,
  loading,
  pressableStyle,
  indicatorColor = colors.black,
  rippleColor = colors.grey,
}) => {
  return (
    <View
      style={[
        isBorder ? styles.border : styles.container,
        { ...styleContainer },
      ]}
    >
      <Pressable
        android_ripple={{ color: rippleColor }}
        onPress={onPress}
        style={[styles.pressable, pressableStyle]}
        disabled={disabled}
      >
        {loading ? (
          <ActivityIndicator
            style={{ marginHorizontal: 15 }}
            size={'small'}
            color={indicatorColor}
          />
        ) : (
          <>
            {iconLeft}
            <Text
              style={[
                styles.text,
                {
                  color: isBorder ? colors.blue : colors.white,
                  marginStart: iconLeft ? 8 : 0,
                  marginEnd: iconRight ? 8 : 0,
                },
                textStyle,
              ]}
            >
              {text}
            </Text>
            {iconRight}
          </>
        )}
      </Pressable>
    </View>
  );
};

export default ImageButton;
