import React from 'react';
import { Pressable, View } from 'react-native';
import PropTypes from 'prop-types';
import MyText from '@atoms/MyText';
import { numberWithDots } from '@gaji-gesa/gg-react-shared/src/utils/sharedUtils';
import { styles } from './style';

const PoinBadge = ({ poinAmount, icon, onPress, showBadge }) => {
  if (showBadge) {
    return (
      <Pressable onPress={onPress} style={styles.container}>
        <View style={styles.iconContainer}>{icon}</View>
        <View style={styles.textContainer}>
          <MyText isRegular={false} customStyle={styles.textStyle}>
            {numberWithDots(poinAmount)}
          </MyText>
        </View>
      </Pressable>
    );
  }
  return null;
};

PoinBadge.propTypes = {
  poinAmount: PropTypes.number,
  icon: PropTypes.any.isRequired,
  onPress: PropTypes.func.isRequired,
  showBadge: PropTypes.bool,
};
PoinBadge.defaultProps = {
  poinAmount: 0,
  showBadge: true,
};
export default PoinBadge;
