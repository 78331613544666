import React from 'react';
import { TextInput, View } from 'react-native';
import PropTypes from 'prop-types';
import MyText from '@atoms/MyText';
import styles from './style';
import { colors } from '@gaji-gesa/gg-react-shared/src/appColors';
import { getErrorComponent } from './AmountInputUtils';
const AmountInput = ({ title, setNominal, nominal, error, onEndEditing }) => {
  return (
    <View style={styles.mainContainer}>
      <MyText h2>{title}</MyText>
      <View
        activeOpacity={0.5}
        style={[
          styles.contentContainer,
          {
            borderColor: error.visible ? colors.roseRed : colors.philippineGray,
          },
        ]}
      >
        <MyText>Rp</MyText>
        <TextInput
          onEndEditing={onEndEditing}
          keyboardType="number-pad"
          value={nominal}
          onChangeText={setNominal}
          style={styles.textInput}
        />
      </View>
      {getErrorComponent(error)}
    </View>
  );
};

AmountInput.propTypes = {
  title: PropTypes.string.isRequired,
  setNominal: PropTypes.func.isRequired,
  nominal: PropTypes.string.isRequired,
  error: PropTypes.shape({
    visible: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
  }),
  onEndEditing: PropTypes.func.isRequired,
};
AmountInput.defaultProps = {};
export default AmountInput;
