import { WITHDRAWAL_CASES } from "../reducer/WIthdrawal";

export const actionSetInitialWithdrawal = (data) => ({
  type: WITHDRAWAL_CASES.setInitialWithdrawal,
  payload: data,
});

export const actionSetWorkedDays = (data) => ({
  type: WITHDRAWAL_CASES.setWorkedDays,
  payload: data,
});

export const actionSetWorkingDays = (data) => ({
  type: WITHDRAWAL_CASES.setWorkingDays,
  payload: data,
});

export const actionSetMaxSalary = (data) => ({
  type: WITHDRAWAL_CASES.setMaxSalary,
  payload: data,
});

export const actionSetWithdrawAmount = (data) => ({
  type: WITHDRAWAL_CASES.setWithdrawAmount,
  payload: data,
});

export const actionSetSalaryWorkDays = (data) => ({
  type: WITHDRAWAL_CASES.setSalaryWorkDays,
  payload: data,
});

export const actionSetReason = (key, data) => ({
  type: WITHDRAWAL_CASES.setReason,
  payload: {
    key,
    data,
  },
});

export const actionSetTodayBalance = (data) => ({
  type: WITHDRAWAL_CASES.setTodayBalance,
  payload: data,
});

export const actionSetUpdatedDate = (data) => ({
  type: WITHDRAWAL_CASES.setUpdatedDate,
  payload: data,
});

export const actionSetLockPeriod = (data) => ({
  type: WITHDRAWAL_CASES.setLockPeriod,
  payload: data,
});

export const actionSetMinBalance = (data) => ({
  type: WITHDRAWAL_CASES.setMinBalance,
  payload: data,
});

export const actionSetReasonList = (data) => ({
  type: WITHDRAWAL_CASES.setReasonList,
  payload: data,
});

export const setEditable = (data) => ({
  type: WITHDRAWAL_CASES.setEditable,
  payload: data,
});

export const actionSetUsedBalance = (data) => ({
  type: WITHDRAWAL_CASES.setUsedBalance,
  payload: data,
});

export const actionSetOptions = (options, withdrawAmount, maxSalary) => ({
  type: WITHDRAWAL_CASES.setOptions,
  payload: {
    options,
    withdrawAmount,
    maxSalary
  },
});

export const actionSetReferralPrecheck = (visible, message) => ({
  type: WITHDRAWAL_CASES.setReferralCheck,
  payload: {
    visible,
    message,
  },
});

export const actionSetReferralPrecheckFail = (data) => ({
  type: WITHDRAWAL_CASES.setReferralPrecheckFail,
  payload: data,
});
