import { StyleSheet } from 'react-native';
import { normalize } from '../../../sharedStyle/helper';
import colors from '@gaji-gesa/gg-react-shared/src/colors';

export const styles = StyleSheet.create({
  containerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: normalize(14),
  },
  iconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: normalize(10),
  },
  textContainer: { width: '85%', paddingLeft: normalize(6) },
  textStyle: { fontSize: normalize(10.5), color: colors.neutral_charcoal },
});
