import React from 'react';
import { View } from 'react-native';
import MyText from '@atoms/MyText';
import { SVG_ICONS } from '../../../uiUtils/SvgIcons';
import TNCitem from '@atoms/Referral/TNCitem';
import { styles } from './style';
import PropTypes from 'prop-types';

const AttentionBoard = ({ show, dataList }) => {
  if (!show) return null;

  return (
    <>
      <View style={styles.outerContainer}>
        <View style={styles.row}>
          <View style={styles.iconStyle}>{SVG_ICONS.loan.verifiedUser}</View>
          <View style={styles.textContainer}>
            <MyText isRegular={false} customStyle={styles.textStyles}>
              Harap Perhatian!
            </MyText>
            <MyText isRegular customStyle={styles.descStyles}>
              Baca ketentuan berikut ini sebelum melanjutkan proses pengajuan
              dana
            </MyText>
          </View>
        </View>

        <View style={styles.listContainer}>
          {dataList.map((o, i) => (
            <TNCitem key={i} item={o} index={i} />
          ))}
        </View>
      </View>
    </>
  );
};

AttentionBoard.propTypes = {
  show: PropTypes.bool,
  dataList: PropTypes.array,
};
export default AttentionBoard;
