export const MISSION_CASES = {
  setMissionTierAndMissions: 'setMissionTierAndMissions',
};

const initialState = {
  tiers: [],
  missions: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MISSION_CASES.setMissionTierAndMissions:
      return {
        tiers: action.payload.tiers,
        missions: action.payload.missions,
      };

    default:
      return state;
  }
};
