import { Action } from './Interfaces/AppTour/Action';
import { AddedUserBank } from './Interfaces/UserBank/AddedUserBank';
import { DefaultBankAccount } from './Interfaces/UserBank/DefaultBankAccount';

export const USER_BANK_CASES = {
  setUserBankData: 'setUserBankData',
  changeUserReadStatus: 'changeUserReadStatus',
  createNewBankAccount: 'createNewBankAccount',
  deleteBankAccount: 'deleteBankAccount',
  setCommonUserReadStatus: 'setCommonUserReadStatus',
};

type initialState = {
  defaultBankAccount: DefaultBankAccount | null;
  userBanks: Array<AddedUserBank>;
  commonUserReadStatus: boolean;
};

export default (
  state: initialState = {
    defaultBankAccount: null,
    userBanks: [],
    commonUserReadStatus: false,
  },
  action: Action,
) => {
  switch (action.type) {
    case USER_BANK_CASES.setUserBankData:
      return {
        ...state,
        defaultBankAccount: action.payload.defaultBankAccount,
        userBanks: action.payload.userBanks,
        commonUserReadStatus: action.payload.commonUserReadStatus,
      };

    case USER_BANK_CASES.changeUserReadStatus:
      return {
        ...state,
        userBanks: action.payload,
      };

    case USER_BANK_CASES.createNewBankAccount:
      return {
        ...state,
        userBanks: action.payload,
      };

    case USER_BANK_CASES.deleteBankAccount:
      return {
        ...state,
        userBanks: action.payload,
      };

    case USER_BANK_CASES.setCommonUserReadStatus:
      return {
        ...state,
        commonUserReadStatus: action.payload,
      };

    default:
      return state;
  }
};
