import React, { useState } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
} from 'react-native';
import ExpoMIcon from '@expo/vector-icons/MaterialIcons';
import RNMIcon from 'react-native-vector-icons/MaterialIcons';
import { colors } from '@style/colors';
import MyModal from '@atoms/MyModal';
import { Font } from '@style/Fonts';
import { normalize, normalizeHeight } from '@style/helper';

const MIcon = Platform.OS === 'web' ? ExpoMIcon : RNMIcon;

export const TOOlTIP_TYPE = {
  UP: 'UP',
  DOWN: 'DOWN',
};
export const TRIANGLE_SIZE = 11;

const Customtooltip = ({
  description,
  tooltipPosValue,
  onClose,
  toolTipType = 'UP',
  isVisible,
}) => {
  const [alertHeight, setAlertHeight] = useState(50);
  const [arrowPoint, setArrowPoint] = useState(null);

  /*
Function restrics arrrow pointer going post tooltip component boundary
*/
  const getBoundary = (leftBoundary, rightBoundary, arrowPoint) => {
    if (arrowPoint < leftBoundary) return leftBoundary;
    if (arrowPoint > rightBoundary) return rightBoundary;
    return arrowPoint;
  };

  return (
    <MyModal
      isVisible={isVisible}
      hasBackdrop={false}
      collapsible={false}
      animationIn={'fadeIn'}
      animationOut={'fadeOut'}
      containerStyle={styles.container}
    >
      <View
        style={[{ flex: 1, alignItems: 'center' }]}
        pointerEvents={'box-only'}
        onTouchStart={onClose}
      >
        <View
          style={{
            marginTop:
              toolTipType == TOOlTIP_TYPE.DOWN
                ? tooltipPosValue.y - alertHeight - normalize(25)
                : tooltipPosValue.y + normalize(15),
          }}
        >
          {toolTipType == TOOlTIP_TYPE.UP && (
            <View
              style={[
                { marginLeft: tooltipPosValue.x - normalize(TRIANGLE_SIZE) },
                styles.triangleConst,
                styles.arrowUp,
              ]}
            />
          )}
          <View
            onLayout={(e) => {
              setAlertHeight(Math.round(e.nativeEvent.layout.height));
              setArrowPoint(
                getBoundary(
                  e.nativeEvent.layout.x + normalize(15),
                  e.nativeEvent.layout.width +
                    e.nativeEvent.layout.x -
                    normalize(15),
                  tooltipPosValue.x
                )
              );
            }}
            style={styles.content}
          >
            <View style={styles.textView}>
              <Text style={styles.text}>{description}</Text>
            </View>
            <TouchableOpacity onPress={onClose} style={styles.iconView}>
              <MIcon name="close" size={normalize(18)} color={colors.white} />
            </TouchableOpacity>
          </View>
          {toolTipType == TOOlTIP_TYPE.DOWN && (
            <View
              style={[
                { marginLeft: arrowPoint - normalize(TRIANGLE_SIZE) },
                styles.triangleConst,
                styles.arrowDown,
              ]}
            />
          )}
        </View>
      </View>
    </MyModal>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'transparent',
    justifyContent: undefined,
    alignItems: undefined,
    margin: 0,
    zIndex: 100000,
  },
  content: {
    justifyContent: 'center',
    backgroundColor: colors.blackTooltip,
    borderRadius: 8,
    flexDirection: 'row',
    marginHorizontal: '4%',
    width: '95%',
    paddingVertical: normalizeHeight(8),
    paddingLeft: normalize(5),
    paddingRight: normalize(10),
  },
  text: {
    color: colors.whiteTooltip,
    fontSize: normalize(12),
    fontFamily: Font.Nunito_Regular,
  },
  textView: {
    width: '85%',
    paddingLeft: normalize(4),
    paddingRight: normalize(7),
    justifyContent: 'center',
  },
  iconView: {
    paddingLeft: normalize(10),
    paddingRight: normalize(4),
    justifyContent: 'center',
    alignItems: 'center',
  },
  triangleConst: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
  },
  arrowUp: {
    borderTopWidth: 0,
    borderRightWidth: normalize(TRIANGLE_SIZE),
    borderBottomWidth: normalize(TRIANGLE_SIZE),
    borderLeftWidth: normalize(TRIANGLE_SIZE),
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: colors.blackTooltip,
    borderLeftColor: 'transparent',
  },
  arrowDown: {
    borderTopWidth: normalize(TRIANGLE_SIZE),
    borderRightWidth: normalize(TRIANGLE_SIZE),
    borderLeftWidth: normalize(TRIANGLE_SIZE),
    marginTop: -0.2,
    borderTopColor: colors.blackTooltip,
    borderRightColor: 'transparent',
    borderBottomColor: 'transparent',
    borderLeftColor: 'transparent',
  },
});

export default React.memo(Customtooltip);
