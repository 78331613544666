import { FEATURE_FLAGS_CASES } from '../reducer/FeatureFlags';

export const setFeatureFlag = (data, loading) => {
  return {
    type: FEATURE_FLAGS_CASES.setFeatureFlags,
    payload: {
      data,
      loading,
    },
  };
};

export const setFeatureFlagError = (data, loading) => ({
  type: FEATURE_FLAGS_CASES.setFeatureFLagError,
  payload: data,
});

export const setFeatureFlagLoading = (data, loading) => ({
  type: FEATURE_FLAGS_CASES.setFeatureFlagLoading,
  payload: data,
});
