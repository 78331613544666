import { gql } from '@apollo/client';

const GET_SAVING_AMOUNT = gql`
  query SavingAmount($from: timestamp, $to: timestamp) {
    saving: gg_transaction_saving_view_aggregate(where: { transactiondate: { _gte: $from, _lte: $to } }) {
      aggregate {
        sum {
          amount
        }
        sum {
          compareamount
        }
      }
    }
    campaign: gg_transaction_campaign_view_aggregate(where: { transactiondate: { _gte: $from, _lte: $to } }) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`;

const GET_SAVING_SUMMARY = gql`
  query SavingSummary($from: timestamp, $to: timestamp, $offset: Int!, $limit: Int!) {
    gg_transaction(
      offset: $offset
      limit: $limit
      where: { createdAt: { _gte: $from, _lte: $to } }
      order_by: { createdAt: desc }
    ) {
      id
      campaign {
        id
        userid
        date
        offer {
          amount
          categoryid
          description
          url
          id
          category {
            displayname
          }
        }
      }
      offer {
        amount
        compareamount
        categoryid
        id
        category {
          displayname
        }
      }
      category {
        displayname
      }
      userId
      createdAt
      transTotalAmount
      employeeId
      transAmount
    }
  }
`;

const GET_SAVING_GRAPH_DATA = gql`
  query SavingAmount($from: String!, $to: String!) {
    gg_transaction_campaign_group_view(where: { month: { _gte: $from, _lt: $to } }, order_by: { month: desc }) {
      amount
      categoryid
      month
      offerid
      category {
        displayname
        transCategoryName
        transCategoryColor
      }
    }
    gg_transaction_saving_group_view(where: { month: { _gte: $from, _lt: $to } }, order_by: { month: desc }) {
      amount
      compareamount
      categoryid
      month
      category {
        displayname
        transCategoryName
        transCategoryColor
      }
    }
  }
`;

const GET_PAYMENT_CYCLES = gql`
  query PaymentCycle($id: Int) {
    gg_payment_cycle(where: { employeeId: { _eq: $id } }, order_by: { firstWorkingDate: desc }) {
      id
      firstWorkingDate
      endWorkingDate
    }
  }
`;

const GET_COINS = gql`
  query TransactionSummary($id: Int) {
    balance: employee_account(where: { id: { _eq: $id } }) {
      availableBalance
    }
  }
`;

const GET_COINS_LIST = gql`
  query pointsTxnList($id: Int, $limit: Int, $offset: Int, $accountId: Int) {
    points_txn: account_transaction(
      where: { accountNumber: { _eq: $accountId } }
      limit: $limit
      offset: $offset
      order_by: { createdAt: desc }
    ) {
      value: transAmount
      employeeId
      transactionId: id
      createdAt
      description
      account_transaction_history {
        transHistoryId: id
        positionType
        description
        notes
      }
      gg_transaction_reason {
        transactionType
        transReason: reason
        tag
      }
    }
  }
`;

const GET_USER_ID = gql`
  query getUserId($empId: Int) {
    gg_employee(where: { id: { _eq: $empId } }) {
      userId
    }
  }
`;

const GET_USER_PROPERTIES = gql`
  query MyQuery {
    gg_employee {
      gender
    }
    gg_salary_profile(limit: 1, order_by: { createdAt: desc }) {
      fee
      feeType
      salaryAmount
      salaryTypeInfo {
        salaryType
      }
    }
  }
`;

const GET_UPDATE_STATUS = gql`
  query appUpdate {
    gajigesa_app_version(order_by: { created_at: desc }, limit: 10) {
      created_at
      force_update
      id
      recommended_update
      release_notes
      version_number
    }
  }
`;

export {
  GET_SAVING_AMOUNT,
  GET_SAVING_SUMMARY,
  GET_SAVING_GRAPH_DATA,
  GET_PAYMENT_CYCLES,
  GET_COINS,
  GET_COINS_LIST,
  GET_USER_ID,
  GET_USER_PROPERTIES,
  GET_UPDATE_STATUS,
};
