import { StyleSheet } from 'react-native';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { normalize } from '../../../sharedStyle/helper';

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: normalize(14),
    paddingVertical: normalize(10),
    alignItems: 'center',
  },
  subgroupStyle: { flexDirection: 'row', alignItems: 'center' },
  iconContainer: {
    borderRadius: normalize(11),
    justifyContent: 'center',
    alignItems: 'center',
    height: normalize(28),
    width: normalize(28),
  },
  titleStyle: {
    color: colors.neutral_charcoal,
    fontSize: normalize(12),
    marginLeft: normalize(7),
  },
  badgeStyle: {
    borderRadius: 12,
    backgroundColor: colors.primary_softCarnation,
    paddingHorizontal: normalize(9),
    paddingVertical: normalize(7),
    marginRight: normalize(10),
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
  },
  badeSuccessStyle: {
    paddingRight: normalize(10),
    backgroundColor: colors.success_softCactus,
  },
  badgeTextStyle: {
    fontSize: normalize(10),
    color: colors.error_rose,
  },
  badgeSuccessText: {
    fontSize: normalize(10),
    color: colors.success_cactus,
  },
  badgeIconStyle: { position: 'absolute', right: 0, bottom: 0 },
});
