import React, { useCallback } from 'react';
import { FlatList, Image, Pressable, View } from 'react-native';
import { colors } from '@gaji-gesa/gg-react-shared/src/appColors';
import MyText from '@atoms/MyText';
import Baru from '@atoms/Loan/Baru';
import { styles } from './style';

const BillPaymentItem = ({ item, onPress, showNew }) => {
  const keyExtractor = (e) => e.id.toString();

  const renderItem = useCallback(
    ({ item }) => {
      if (!item.imageUrl && !item.isLocalImagePath) {
        return null;
      }

      let imgsrc = { uri: item.imageUrl };

      if (item.isLocalImagePath) imgsrc = item.isLocalImagePath;

      return (
        <View style={styles.itemContainer}>
          <Pressable
            onPress={() => onPress(item)}
            android_ripple={{ color: colors.lightGrey }}
            style={styles.product}
            testID={item.id + '_BP'}
          >
            <Image
              source={imgsrc}
              resizeMode={'contain'}
              style={styles.image}
            />
          </Pressable>
          <MyText h2 customStyle={styles.title}>
            {item.name}
          </MyText>
          <Baru
            showBaru={item.id === 100 && showNew}
            customStyles={styles.baruStyle}
          />
        </View>
      );
    },
    [showNew, onPress]
  );

  return (
    <FlatList
      data={item.list}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      numColumns={4}
      contentContainerStyle={styles.contentStyle}
    />
  );
};

export default React.memo(BillPaymentItem);
