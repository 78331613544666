import { StyleSheet } from 'react-native';
import { normalize, normalizeHeight } from '../../../sharedStyle/helper';
import { colors } from '@gaji-gesa/gg-react-shared/src/appColors';

export const styles = StyleSheet.create({
  refferalContainer: {
    borderRadius: normalize(10),
    marginHorizontal: normalize(16),
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.softOrchid,
    paddingVertical: normalizeHeight(10),
    marginTop: normalize(5),
  },
  referralDescText: { marginBottom: normalize(3), fontSize: normalize(12) },
  referralCodeStyle: { fontSize: normalize(20), color: colors.black },
});
