import { DYNAMIC_ACCOUNT_NAMES } from '../../utils/sharedConstants';

export const DYNAMIC_ACCOUNTS_CASES = {
  setDynamicAccounts: 'setDynamicAccounts',
  setCurrentHomePageSelectedAccount: 'setCurrentHomePageSelectedAccount',
  setCurrentTransactionPageSelectedAccount: 'setCurrentTransactionPageSelectedAccount',
  setDataAccounts: 'setDataAccounts',
  clearDynamicAccountReducer: 'clearDynamicAccountReducer',
  workAllowanceAccountConfig: {
    setWorkAllowanceAmount: 'setWorkAllowanceAmount',
    setWorkAllowanceEditable: 'setWorkAllowanceEditable',
    setWorkAllowanceMaxAmount: 'setWorkAllowanceMaxAmount',
  },
  uangKasAccountConfig: {
    setUangKasAllowanceMaxAmount: 'setUangKasAllowanceMaxAmount',
    setUangKasAmount: 'setUangKasAmount',
    setUangKasEditable: 'setUangKasEditable',
  },
  sdAccountConfig: {
    sdAmount: 'setSdAmount',
  },
  setCurrentBpSelectedAccount: 'setCurrentBpSelectedAccount',
};

const initialState = {
  dynamicAccounts: [],
  currentHomePageSelectedAccount: null,
  currentTransactionPageSelectedAccount: null, // Initially gets set during Login and App Startup
  currentBpSelectedAccount: null, // Initially gets set during Login and App Startup
  dataAccounts: [], // used on homepage, transaction list and BP
  [DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE]: {
    minAmount: 0,
    maxAmount: null,
    value: null,
    isEditable: false,
  },
  [DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT]: {
    minAmount: 50000,
    maxAmount: null,
    value: null,
  },
  [DYNAMIC_ACCOUNT_NAMES.UANG_KAS]: {
    minAmount: 0,
    maxAmount: null,
    value: null,
    isEditable: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DYNAMIC_ACCOUNTS_CASES.setDynamicAccounts:
      return {
        ...state,
        dynamicAccounts: action.payload,
      };

    case DYNAMIC_ACCOUNTS_CASES.setCurrentHomePageSelectedAccount:
      return {
        ...state,
        currentHomePageSelectedAccount: action.payload,
      };

    case DYNAMIC_ACCOUNTS_CASES.setCurrentTransactionPageSelectedAccount:
      return {
        ...state,
        currentTransactionPageSelectedAccount: action.payload,
      };

    case DYNAMIC_ACCOUNTS_CASES.setDataAccounts:
      return {
        ...state,
        dataAccounts: action.payload,
      };

    case DYNAMIC_ACCOUNTS_CASES.setCurrentBpSelectedAccount:
      return {
        ...state,
        currentBpSelectedAccount: action.payload,
      };

    case DYNAMIC_ACCOUNTS_CASES.workAllowanceAccountConfig.setWorkAllowanceAmount:
      return {
        ...state,
        [DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE]: {
          ...state[DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE],
          value: action.payload,
        },
      };

    case DYNAMIC_ACCOUNTS_CASES.workAllowanceAccountConfig.setWorkAllowanceMaxAmount:
      return {
        ...state,
        [DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE]: {
          ...state[DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE],
          maxAmount: action.payload,
        },
      };

    case DYNAMIC_ACCOUNTS_CASES.workAllowanceAccountConfig.setWorkAllowanceEditable:
      return {
        ...state,
        [DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE]: {
          ...state[DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE],
          isEditable: action.payload,
        },
      };

    case DYNAMIC_ACCOUNTS_CASES.sdAccountConfig.sdAmount:
      return {
        ...state,
        [DYNAMIC_ACCOUNT_NAMES.SDACCOUNT]: {
          ...state,
          value: action.payload,
        },
      };

    case DYNAMIC_ACCOUNTS_CASES.uangKasAccountConfig.setUangKasAllowanceMaxAmount:
      return {
        ...state,
        [DYNAMIC_ACCOUNT_NAMES.UANG_KAS]: {
          ...state[DYNAMIC_ACCOUNT_NAMES.UANG_KAS],
          maxAmount: action.payload,
        },
      };

    case DYNAMIC_ACCOUNTS_CASES.uangKasAccountConfig.setUangKasAmount:
      return {
        ...state,
        [DYNAMIC_ACCOUNT_NAMES.UANG_KAS]: {
          ...state[DYNAMIC_ACCOUNT_NAMES.UANG_KAS],
          value: action.payload,
        },
      };
    case DYNAMIC_ACCOUNTS_CASES.uangKasAccountConfig.setUangKasEditable:
      return {
        ...state,
        [DYNAMIC_ACCOUNT_NAMES.UANG_KAS]: {
          ...state[DYNAMIC_ACCOUNT_NAMES.UANG_KAS],
          isEditable: action.payload,
        },
      };
    case DYNAMIC_ACCOUNTS_CASES.clearDynamicAccountReducer:
      return initialState;
    default:
      return state;
  }
};
