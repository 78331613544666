import React from 'react';
import { View, Linking } from 'react-native';
import PropTypes from 'prop-types';
import MyText from '@atoms/MyText';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { PWA_REFFERAL_LINK } from '@gaji-gesa/gg-react-shared/src/utils/sharedConstants';
import { styles } from './style';
import { numberWithDots } from '@gaji-gesa/gg-react-shared/src/utils/sharedUtils';

const HYPERLINK_TEXT = 'Baca syarat dan ketentuan di sini';

const TNCitem = ({ item, index, refereeAmt, referrerAmt }) => {
  const displayText = () => {
    let text = item.replace(
      '$$referrerRewardAmount$$',
      numberWithDots(referrerAmt)
    );
    text = text.replace('$$refereeRewardAmount$$', numberWithDots(refereeAmt));
    return text;
  };

  return (
    <View style={styles.termsRow}>
      <View style={styles.termsIconContainer}>
        <View style={styles.circlePlaceholder}>
          <MyText customStyle={styles.numericText}>{index + 1}</MyText>
        </View>
      </View>
      <View style={styles.termsTextContainer}>
        <MyText isRegular customStyle={styles.termsTextStyle}>
          {displayText(item)}
          {item === HYPERLINK_TEXT && (
            <MyText
              customStyle={{ color: colors.secondary_orchid }}
              onPress={() => Linking.openURL(PWA_REFFERAL_LINK)}
            >
              {' '}
              {PWA_REFFERAL_LINK}
            </MyText>
          )}
        </MyText>
      </View>
    </View>
  );
};

TNCitem.propTypes = {
  item: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
  refereeAmt: PropTypes.number.isRequired,
  referrerAmt: PropTypes.number.isRequired,
};

export default TNCitem;
