import React from 'react';
import { Pressable, View } from 'react-native';
import { styles } from './style';
import MyText from '@atoms/MyText';
import PropTypes from 'prop-types';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { normalize } from '../../../sharedStyle/helper';

export function shouldRenderBadge(showBadge, count, badgeIcon) {
  let isSucess = count === 0;
  if (showBadge) {
    return (
      <View style={[styles.badgeStyle, isSucess && styles.badeSuccessStyle]}>
        <MyText
          isRegular={false}
          customStyle={[
            styles.badgeTextStyle,
            isSucess && styles.badgeSuccessText,
          ]}
        >
          {isSucess ? 'Selesai!' : `${count} Misi Tersisa`}
        </MyText>
        {isSucess && <View style={styles.badgeIconStyle}>{badgeIcon}</View>}
      </View>
    );
  }
  return null;
}

export function shouldRenderKYCStatus(showKyCStatus, isSucess) {
  if (showKyCStatus)
    return (
      <View style={[styles.badgeStyle, isSucess && styles.badeSuccessStyle]}>
        <MyText
          isRegular={false}
          customStyle={[
            styles.badgeTextStyle,
            isSucess && styles.badgeSuccessText,
          ]}
        >
          {isSucess ? 'Aktif' : `Buat Sekarang!`}
        </MyText>
      </View>
    );

  return null;
}

export function shouldRenderArrow() {
  return <Icon name="keyboard-arrow-right" size={normalize(21)} />;
}

const InfoRow = ({
  icon: Component,
  iconBgColor,
  badgeIcon,
  title,
  onPress,
  showBadge,
  noOfIncomplete,
  iconSvgStyle,
  success,
  showKyCStatus,
  show,
}) => {
  if (show) {
    return (
      <Pressable style={styles.container} onPress={onPress}>
        <View style={styles.subgroupStyle}>
          <View
            style={[styles.iconContainer, { backgroundColor: iconBgColor }]}
          >
            <Component {...iconSvgStyle} />
          </View>
          <MyText isRegular customStyle={styles.titleStyle}>
            {title}
          </MyText>
        </View>
        <View style={styles.subgroupStyle}>
          {shouldRenderBadge(showBadge, noOfIncomplete, badgeIcon)}
          {shouldRenderKYCStatus(showKyCStatus, success)}
          {shouldRenderArrow()}
        </View>
      </Pressable>
    );
  }

  return null;
};

InfoRow.propTypes = {
  icon: PropTypes.object,
  iconBgColor: PropTypes.string,
  badgeIcon: PropTypes.object,
  title: PropTypes.string.isRequired,
  onPress: PropTypes.string.isRequired,
  showBadge: PropTypes.bool,
  noOfIncomplete: PropTypes.number,
  iconSvgStyle: PropTypes.object,
  success: PropTypes.bool,
  showKyCStatus: PropTypes.bool,
};

InfoRow.defaultProps = {
  icon: '',
  iconBgColor: '',
  badgeIcon: '',
  title: '',
  showBadge: false,
  noOfIncomplete: 0,
  iconSvgStyle: [],
  show: true,
  success: false,
  showKyCStatus: false,
};

export default InfoRow;
