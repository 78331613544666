import React from 'react';
import { View, Pressable } from 'react-native';
import MyText from '@atoms/MyText';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { normalize } from '../../../sharedStyle/helper';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { styles } from './style';

const DonationAdder = ({
  disabledMinus = true,
  noOfPeople,
  onPressAdd,
  onPressMinus,
}) => (
  <View style={styles.containerStyle}>
    <Pressable
      onPress={onPressMinus}
      disabled={disabledMinus}
      style={[
        styles.leftPressable,
        disabledMinus && styles.disableContainerStyle,
      ]}
    >
      <MCIcon
        name="minus"
        size={normalize(17)}
        color={disabledMinus ? colors.disabled_smoke : colors.error_rose}
      />
    </Pressable>

    <View style={styles.midTextContainer}>
      <MyText isRegular={false} customStyle={styles.orangStyle}>
        {`${noOfPeople} orang`}
      </MyText>
    </View>

    <Pressable onPress={onPressAdd} style={styles.rightContainer}>
      <MCIcon
        name="plus"
        size={normalize(17)}
        color={colors.secondary_orchid}
      />
    </Pressable>
  </View>
);

export default DonationAdder;
