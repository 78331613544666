import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import MyText from '@atoms/MyText';
import { styles } from './style';

const ReferralContainer = ({ referralCode }) => (
  <View style={styles.refferalContainer}>
    <MyText customStyle={styles.referralDescText}>
      Kode Referral GajiGesa
    </MyText>
    <MyText isRegular={false} customStyle={styles.referralCodeStyle}>
      {referralCode}
    </MyText>
  </View>
);
ReferralContainer.propTypes = {
  referralCode: PropTypes.string,
};
ReferralContainer.defaultProps = {
  referralCode: '',
};
export default ReferralContainer;
