import { StyleSheet } from 'react-native';
import { normalize } from '../../../sharedStyle/helper';
import colors from '@gaji-gesa/gg-react-shared/src/colors';

export const styles = StyleSheet.create({
  containerStyle: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  textStyle: {
    color: colors.neutral_darkMist,
    fontSize: normalize(10),
    marginRight: normalize(7),
  },
  imageStyle: {
    height: normalize(30),
    width: normalize(35),
  },
});
