import { StyleSheet } from 'react-native';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { normalize } from '../../../sharedStyle/helper';

export const styles = StyleSheet.create({
  principleRow: {
    flexDirection: 'row',
    paddingLeft: normalize(12),
    justifyContent: 'space-between',
    paddingVertical: normalize(10),
  },
  principleAmtStyle: {
    color: colors.success_cactus,
    marginLeft: normalize(6),
  },
  metaRow: {
    flexDirection: 'row',
    marginTop: normalize(2),
    backgroundColor: colors.neutral_mist,
    justifyContent: 'space-evenly',
    marginHorizontal: normalize(10),
    marginVertical: normalize(8),
    borderRadius: normalize(12),
    paddingVertical: normalize(8),
  },
  metaTitleStyle: { fontSize: normalize(11), marginBottom: normalize(5) },
  metaTagStyle: {
    fontSize: normalize(10.5),
    color: colors.warning_darkSunflower,
  },
  metaInfoStyle: {
    fontSize: normalize(12),
    marginTop: normalize(3),
    width: normalize(87),
  },
  tenureBubble: {
    borderRadius: normalize(38),
    height: normalize(20),
    width: normalize(20),
    backgroundColor: colors.warning_softSunflower,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tenureTxtStyle: {
    fontSize: normalize(12),
    color: colors.blackColor,
  },
  containerAdj: { marginLeft: normalize(18) },
  installAmtTxt: {
    fontSize: normalize(12),
    marginTop: normalize(3),
    width: normalize(88),
  },

  metaContainer: {
    borderRadius: normalize(12),
    backgroundColor: colors.warning_softSunflower,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: normalize(12),
    paddingHorizontal: normalize(8),
  },
  outerContainer: {
    borderWidth: 2,
    borderColor: '#EFEFEF',
    marginHorizontal: normalize(12),
    borderRadius: normalize(12),
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    marginVertical: normalize(10),
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  row: { flexDirection: 'row' },
});
