import { StyleSheet } from 'react-native';
import { normalize } from '../../../sharedStyle/helper';
import colors from '@gaji-gesa/gg-react-shared/src/colors';

export const styles = StyleSheet.create({
  ListHeader: {
    display: 'flex',
    flexDirection: 'column',
    height: normalize(35),
    paddingHorizontal: normalize(14),
    marginVertical: normalize(16),
  },
  Group153: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
  },
  Group761: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  NomorHpLainnya: {
    color: colors.neutral_charcoal,
    fontSize: normalize(14),
  },
  Maks10NomorLainnya: {
    color: colors.neutral_darkMist,
    fontSize: normalize(10),
  },
  TambahNomorHp: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    color: colors.secondary_orchid,
    fontSize: normalize(12),
    textAlign: 'right',
    textDecorationLine: 'underline',
  },
});
