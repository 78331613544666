const colors = {
  primary_carnation: '#F56B57',
  primary_darkCarnation: '#A14335',
  primary_softCarnation: '#FEF0EE',
  secondary_softOrchid: '#EBEFFE',
  secondary_orchid: '#3863F0',
  secondary_darkOrchid: '#223B90',
  success_softCactus: '#E6EFE3',
  success_cactus: '#56893E',
  success_darkCactus: '#2E6930',
  warning_softSunflower: '#FEF0DC',
  warning_sunflower: '#F8B24F',
  warning_darkSunflower: '#A17230',
  error_softRose: '#FEEAE8',
  error_rose: '#F23015',
  error_darkRose: '#911D0D',
  neutral_cotton: 'white',
  neutral_mist: '#F4F4F4',
  neutral_darkMist: '#909090',
  neutral_charcoal: '#454545',
  variants_softClover: '#D5F2EA',
  variants_clover: '#2CBE96',
  variants_darkClover: '#1A725A',
  disabled_smoke: '#DDDDDD',
  shadowColor: 'rgba(0, 0, 0, 0.1)',
  transparent: 'transparent',
  blackColor: '#000000',
  gainsboro: '#DDDDDD',
  grey_tab: '#F2F2F2',
  foundationDarkShade: '#595959',
  darkYellow: '#A66C1A',
};

module.exports = colors;
