import { StyleSheet } from 'react-native';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { normalize } from '../../../sharedStyle/helper';

export const styles = StyleSheet.create({
  profileInfoContainer: { flexDirection: 'row' },
  iconsStyle: { justifyContent: 'center' },
  detailContainer: { marginLeft: normalize(7) },
  nameStyle: { fontSize: normalize(11) },
  phoneContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  eyeIconStyle: {
    fontSize: normalize(18),
    marginLeft: normalize(5),
    color: colors.secondary_orchid,
  },
  phoneStyle: {
    fontSize: normalize(11),
    color: colors.neutral_darkMist,
  },
});
