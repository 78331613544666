import React from 'react';
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  ActivityIndicator,
} from 'react-native';
import { Font } from '@style/Fonts';

const LoadingButton = ({
  loading,
  bordered = false,
  text,
  loadingColor = 'white',
  accessibilityLabel,
  disabled,
  textColor = 'white',
  buttonColor = '#3863F0',
  onPress,
  container,
  textTransform = 'uppercase',
  textStyle,
  disabledStyle,
}) => (
  <View
    style={[
      bordered ? styles.bordered : styles.container,
      { backgroundColor: buttonColor, opacity: disabled ? 0.9 : 1 },
      container,
      disabled && disabledStyle,
      { paddingVertical: 0 },
    ]}
  >
    <Pressable
      accessibilityLabel={accessibilityLabel}
      disabled={disabled || loading}
      onPress={onPress}
      android_ripple={{ color: '#D3D3D3' }}
      style={[
        styles.pressable,
        { paddingVertical: container?.paddingVertical ?? 12 },
      ]}
    >
      {loading ? (
        <ActivityIndicator size="small" color={loadingColor} />
      ) : (
        <Text
          style={{
            ...styles.text,
            color: textColor,
            textTransform,
            ...textStyle,
          }}
        >
          {text}
        </Text>
      )}
    </Pressable>
  </View>
);

const styles = StyleSheet.create({
  pressable: {
    width: '100%',
    paddingVertical: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  bordered: {
    width: '90%',
    backgroundColor: 'white',
    borderColor: 'red',
    borderWidth: 1,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
  },
  text: {
    color: 'white',
    fontFamily: Font.Nunito_Bold,
    textTransform: 'uppercase',
  },
  container: {
    width: '90%',
    backgroundColor: '#3863F0',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    overflow: 'hidden',
  },
});

export default LoadingButton;
