import React from 'react';
import { View, Pressable } from 'react-native';
import PropTypes from 'prop-types';
import MyText from '@atoms/MyText';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { normalize } from '@style/helper';
import { numberWithDots } from '@gaji-gesa/gg-react-shared/src/utils/sharedUtils';
import { SVG_ICONS } from '../../../uiUtils/SvgIcons';

import { styles } from './style';

const LoanItem = ({
  principalAmt,
  receivable,
  tenure,
  installmentAmount,
  onPressItem,
}) => (
  <Pressable style={styles.container} onPress={onPressItem}>
    <View>
      <View style={styles.principleRow}>
        {SVG_ICONS.loan.cash}
        <MyText isRegular={false} customStyle={styles.principleAmtStyle}>
          {`Rp${numberWithDots(principalAmt)}`}
        </MyText>
      </View>

      <View style={styles.metaRow}>
        <View>
          <MyText isRegular customStyle={styles.metaTitleStyle}>
            Jumlah dana diterima
          </MyText>
          <MyText isRegular={false} customStyle={styles.metaInfoStyle}>
            {`Rp${numberWithDots(receivable)}`}
          </MyText>
        </View>
        <View style={styles.containerAdj}>
          <MyText isRegular customStyle={styles.metaTitleStyle}>
            Tenor
          </MyText>

          <View style={styles.tenureBubble}>
            <MyText isRegular={false} customStyle={styles.tenureTxtStyle}>
              {`${tenure}x`}
            </MyText>
          </View>
        </View>
        <View style={styles.containerAdj}>
          <MyText isRegular customStyle={styles.metaTitleStyle}>
            Cicilan per bulan
          </MyText>
          <MyText isRegular={false} customStyle={styles.installAmtTxt}>
            {`Rp${numberWithDots(installmentAmount)}`}
          </MyText>
        </View>
      </View>
    </View>

    <View style={styles.rightArrowContainer}>
      <Icon name="keyboard-arrow-right" size={normalize(22)} />
    </View>
  </Pressable>
);
LoanItem.propTypes = {
  principalAmt: PropTypes.number,
  receivable: PropTypes.number,
  tenure: PropTypes.number,
  installmentAmount: PropTypes.number,
  onPressItem: PropTypes.func,
};

export default LoanItem;
