import { StyleSheet } from 'react-native';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { normalize } from '../../../sharedStyle/helper';

const styles = StyleSheet.create({
  containerStyle: { margin: 0, justifyContent: 'flex-end' },
  innerContainer: {
    backgroundColor: 'white',
    width: '100%',
    paddingVertical: 10,
    borderTopStartRadius: 20,
    borderTopEndRadius: 20,
  },
  rowStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: normalize(11),
    marginTop: normalize(2),
  },
  subRow: { flexDirection: 'row', alignItems: 'center' },
  iconContainer: {
    backgroundColor: colors.secondary_softOrchid,
    borderRadius: normalize(12),
    padding: normalize(2),
    marginLeft: normalize(20),
  },
  crossPressable: {
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    paddingVertical: normalize(5),
    paddingRight: normalize(10),
  },
  witdrawLimitStyle: {
    fontSize: normalize(20),
    marginTop: normalize(16),
    marginLeft: normalize(22),
  },

  limitStyle: { fontSize: normalize(15), marginLeft: normalize(7) },

  modalIconStyle: { alignSelf: 'center', marginRight: normalize(6) },

  tooltipBannerTextStyle: { fontSize: normalize(11.5), marginLeft: 10 },
  tooltipBannerIconContainerStyle: { justifyContent: 'center' },
  tooltipBannerContainerStyle: {
    backgroundColor: colors.neutral_mist,
    marginHorizontal: normalize(15),
    marginTop: normalize(11),
  },
});
export default styles;
