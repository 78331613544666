import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  textName: {
    marginHorizontal: 5,
  },
  container: {
    borderRadius: 20,
    overflow: 'hidden',
    justifyContent: 'center',
    width: '90%',
    alignSelf: 'center',
    marginTop: 10,
    marginBottom: 15,
  },
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
});

export default styles;
