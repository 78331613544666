import React from 'react';
import { View } from 'react-native';
import { styles } from './styles';

export const dot = (showDot) => {
  if (showDot) {
    return <View style={styles.dot} />;
  }
  return null;
};
