import React from 'react';
import { Pressable } from 'react-native';
import { colors } from '@style/colors';
import { normalize } from '../../sharedStyle/helper';
import MyText from '@atoms/MyText';

function getPressableTabStyle(isActive) {
  return {
    borderBottomWidth: isActive ? 3 : 0,
    borderBottomColor: isActive ? colors.orange : colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 15,
    paddingVertical: normalize(11),
  };
}

const Tab = ({ onPress, text, isActive }) => (
  <Pressable
    onPress={onPress}
    style={getPressableTabStyle(isActive)}
    android_ripple={{ color: colors.lightGrey }}
  >
    <MyText
      isRegular={isActive ? false : true}
      customStyle={{ color: isActive ? colors.orange : colors.black }}
    >
      {text}
    </MyText>
  </Pressable>
);

export default React.memo(Tab);
