import { gql } from '@apollo/client';

const GET_ELIGIBILTY_APP_STATUS = gql`
  query getEligiblityAppStatus {
    loan_eligibility(
      where: { loan_products_eligibility: { enabled: { _eq: true } }, eligibility: { _eq: "ELIGIBLE" } }
    ) {
      loanProductCode
    }
    loan_applications(order_by: { createdAt: desc }, limit: 1) {
      applicationStatus
      userNotified
      loanProductCode
      id
    }
  }
`;

const UPDATE_LOAN_STATUS = gql`
  mutation markForLoan($applicationId: Int) {
    update_loan_applications(where: { id: { _eq: $applicationId } }, _set: { userNotified: true }) {
      returning {
        applicationStatus
        loanProductCode
        id
      }
    }
  }
`;

const GET_LOAN_PRODUCTS = gql`
  query getLoanProducts {
    loan_eligibility(
      where: { loan_product_eligibility: { enabled: { _eq: true } }, eligibility: { _eq: "ELIGIBLE" } }
    ) {
      loan_product_eligibility {
        upfrontFee
        tenure
        principal
        loanCode
        installmentValue
        fee
        disbursedAmount
      }
    }
    gg_employee {
      status
    }
  }
`;

const SUBMT_FOR_LOAN = gql`
  mutation submitLoan($loanPayload: loan_applications_insert_input!) {
    insert_loan_applications(objects: [$loanPayload]) {
      returning {
        id
        applicationStatus
      }
    }
  }
`;

const GET_APPLICATION_DETAILS = gql`
  query getApplicationDetails($appld: Int, $loanProductCode: String) {
    loan_applications(where: { id: { _eq: $appld } }) {
      loan_app_installments(order_by: { dueDate: asc }) {
        id
        installmentValue
        paidDate
        status
        dueDate
      }
      loanProductCode
      fee
      applicationStatus
    }
    loan_eligibility(where: { loanProductCode: { _eq: $loanProductCode } }) {
      eligibility
      loan_product_eligibility {
        loanCode
        principal
        tenure
        upfrontFee
        fee
        disbursedAmount
        installmentValue
      }
    }
    gg_employee {
      employeeCode
      employerInfo {
        employerCode
      }
      bank_name {
        bankName
        bank_name {
          bankAccountNo
          beneficiaryName
        }
      }
    }
  }
`;

export { GET_ELIGIBILTY_APP_STATUS, UPDATE_LOAN_STATUS, GET_LOAN_PRODUCTS, SUBMT_FOR_LOAN, GET_APPLICATION_DETAILS };
