import { StyleSheet } from 'react-native';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { normalize } from '../../../sharedStyle/helper';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: normalize(15),
    borderTopWidth: normalize(4),
    borderBottomWidth: normalize(4),
    borderColor: colors.neutral_mist,
    backgroundColor: colors.neutral_cotton,
    marginTop: normalize(-2),
  },
  titleStyle: { fontSize: normalize(12) },
  iconContainerStyle: { flexDirection: 'row' },
  collapseContainerStyle: {
    backgroundColor: colors.neutral_cotton,
    marginBottom: normalize(10),
  },
});

export default styles;
