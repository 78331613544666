import { StyleSheet } from 'react-native';
import { colors } from '@style/colors';
import { normalize } from '../../sharedStyle/helper';

const styles = StyleSheet.create({
  mainContainer: {
    marginBottom: 12,
    flexDirection: 'row',
    backgroundColor: colors.softOrchid,
    padding: 10,
    borderRadius: 5,
  },
  text: {
    marginStart: 10,
    width: '90%',
  },
  textContainer: { flexDirection: 'row', width: '92%', paddingLeft: 5 },
  textStyle: { marginLeft: 3, fontSize: normalize(10.5) },
});

export default styles;
