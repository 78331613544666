import React from 'react';
import { View, Image, Pressable } from 'react-native';
import PropTypes from 'prop-types';
import MyText from '@atoms/MyText';
import { styles } from './style';

const LoanHeader = ({ headerText, onLinkPress }) => (
  <View style={styles.infoOuterContainer}>
    <View style={styles.infoLeftSubContainer}>
      <Image
        source={require('@gaji-gesa/gg-react-shared/src/assets/Svg/loan/art_e-Kasbon.png')}
        resizeMode={'contain'}
        style={styles.logoImageStyle}
      />
    </View>
    <View style={styles.infoRightSubContainer}>
      <MyText isRegular={false} customStyle={styles.infoTextStyle}>
        {headerText}
      </MyText>

      <Pressable onPress={onLinkPress} style={styles.linkStyle}>
        <MyText isRegular={false} customStyle={styles.linkTextStyle}>
          Pelajari Cara Pengajuan di Sini
        </MyText>
      </Pressable>
    </View>
  </View>
);
LoanHeader.propTypes = {
  headerText: PropTypes.string,
  onLinkPress: PropTypes.func,
};
LoanHeader.defaultProps = {
  headerText: '',
  onLinkPress: () => {},
};
export default LoanHeader;
