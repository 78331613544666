import { StyleSheet } from 'react-native';
import { normalize } from '../../../sharedStyle/helper';
import colors from '@gaji-gesa/gg-react-shared/src/colors';

export const styles = StyleSheet.create({
  containerStyle: {
    marginHorizontal: normalize(16),
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: colors.disabled_smoke,
    borderRadius: normalize(20),
    height: 40,
    overflow: 'hidden',
    shadowColor: '#000',
    backgroundColor: colors.neutral_cotton,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },

  leftPressable: {
    width: '12%',
    borderRightWidth: 1,
    borderColor: colors.disabled_smoke,
    justifyContent: 'center',
    alignItems: 'center',
  },

  disableContainerStyle: { backgroundColor: colors.neutral_mist },
  midTextContainer: {
    width: '76%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  orangStyle: { fontSize: normalize(14), color: colors.neutral_charcoal },

  rightContainer: {
    width: '12%',
    borderLeftWidth: 1,
    borderColor: colors.disabled_smoke,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
