import React from 'react';
import MyText from '@atoms/MyText';
import PropTypes from 'prop-types';
import { styles } from './style';

function shouldRenderOTAVersion(otaVersion) {
  if (!otaVersion) return null;
  return <MyText> {`| OTA Version ${otaVersion}`}</MyText>;
}

const AppVersion = ({ version, otaVersion }) => {
  return (
    <MyText isRegular customStyle={styles.appVersionText}>
      {`App Version ${version}`}
      {shouldRenderOTAVersion(otaVersion)}
    </MyText>
  );
};

AppVersion.propTypes = {
  version: PropTypes.string.isRequired,
  otaVersion: PropTypes.string.isRequired,
};
export default AppVersion;
