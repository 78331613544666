export const REFERRAL_CASES = {
  isValidCode: 'isValidCode',
  setReferralLoading: 'setBillReferralLoading',
  setReferralError: 'setReferralError',
  isTransacted: 'isTransacted',
  redeemCode: 'redeemCode',
  appOpenedFromDeeplink: 'appOpenedFromDeeplink',
  setRedeemCodeLoading: 'setRedeemCodeLoading',
  clearReferral: 'clearReferral',
};

const initialState = {
  isValidCode: false,
  loading: false,
  error: false,
  isTransacted: true,
  redeemCode: '',

  appOpenedFromDeeplink: false,
  redeemCodeLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REFERRAL_CASES.isValidCode:
      return {
        ...state,
        isValidCode: action.payload.data,
        loading: action.payload.loading,
      };

    case REFERRAL_CASES.setRedeemCodeLoading:
      return {
        ...state,
        redeemCodeLoading: action.payload,
      };

    case REFERRAL_CASES.setReferralLoading:
      return {
        ...state,
        loading: action.payload,
      };

    case REFERRAL_CASES.setReferralError:
      return {
        ...state,
        error: action.payload,
      };

    case REFERRAL_CASES.isTransacted:
      return {
        ...state,
        isTransacted: action.payload,
      };

    case REFERRAL_CASES.redeemCode:
      return {
        ...state,
        redeemCode: action.payload,
      };

    case REFERRAL_CASES.appOpenedFromDeeplink:
      return {
        ...state,
        appOpenedFromDeeplink: action.payload,
      };

    case REFERRAL_CASES.clearReferral:
      return initialState;

    default:
      return state;
  }
};
