import React from 'react';
import { View, Pressable } from 'react-native';
import MyText from '@atoms/MyText';
import Collapsible from 'react-native-collapsible';
import AntDesign from 'react-native-vector-icons/AntDesign';
import styles from './style';
import PropTypes from 'prop-types';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import TNCitem from '@atoms/Referral/TNCitem';

const CollapsibleTerms = ({
  dataList,
  title,
  collapsedUsage,
  refereeAmt,
  referrerAmt,
  onPressDropDown,
}) => {
  if (dataList && dataList.length > 0) {
    return (
      <>
        <Pressable
          android_ripple={{ color: colors.lightGrey }}
          style={styles.termsHeaderRow}
          onPress={onPressDropDown}
        >
          <MyText isRegular={false} customStyle={styles.termsHeaderText}>
            {title}
          </MyText>
          <AntDesign name={collapsedUsage ? 'down' : 'up'} size={20} />
        </Pressable>
        <Collapsible collapsed={collapsedUsage}>
          <View style={styles.collapsibleContainer}>
            {dataList.map((o, i) => (
              <TNCitem
                key={i}
                item={o}
                index={i}
                refereeAmt={refereeAmt}
                referrerAmt={referrerAmt}
              />
            ))}
          </View>
        </Collapsible>
      </>
    );
  }

  return null;
};

CollapsibleTerms.propTypes = {
  dataList: PropTypes.any,
  title: PropTypes.string,
  collapsedUsage: PropTypes.bool,
  refereeAmt: PropTypes.number,
  referrerAmt: PropTypes.number,
  onPressDropDown: PropTypes.func,
};

export default CollapsibleTerms;
