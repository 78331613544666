import { StyleSheet } from 'react-native';
import { colors } from '@gaji-gesa/gg-react-shared/src/appColors';
import { normalize, normalizeHeight } from '@style/helper';

export const styles = StyleSheet.create({
  itemContainer: {
    width: '22%',
    justifyContent: 'center',
    alignItems: 'center',
    marginEnd: 15,
    marginVertical: 20,
  },
  product: {
    justifyContent: 'center',
    alignItems: 'center',
    height: normalizeHeight(50),
    width: '80%',
    marginBottom: 8,
    backgroundColor: colors.white,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
    borderRadius: normalize(12),
  },
  image: {
    height: normalizeHeight(30),
    width: normalize(30),
    alignSelf: 'center',
  },

  title: {
    flex: 1,
    textAlign: 'center',
  },

  contentStyle: { justifyContent: 'space-between' },

  baruStyle: {
    height: normalize(12),
    zIndex: 10,
    width: normalize(25),
    alignSelf: 'center',
    position: 'absolute',
    marginTop: normalize(-5),
    elevation: 5,
    right: 3,
    top: 5,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.orange,
    borderRadius: normalize(27),
  },
});
