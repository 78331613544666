import { TOOLTIP_CONTENT } from '../../utils/sharedConstants';

export const WITHDRAWAL_CASES = {
  setInitialWithdrawal: 'setInitialWithdrawal',
  setWorkedDays: 'setWorkedDays',
  setWorkingDays: 'setWorkingDays',
  setMaxSalary: 'setMaxSalary',
  setWithdrawAmount: 'setWithdrawAmount',
  setSalaryWorkDays: 'setSalaryWorkDays',
  setReason: 'setReason',
  setTodayBalance: 'setTodayBalance',
  setUpdatedDate: 'setUpdatedDate',
  setLockPeriod: 'setLockPeriod',
  setMinBalance: 'setMinBalance',
  setReasonList: 'setReasonList',
  setEditable: 'setEditable',
  setUsedBalance: 'setUsedBalance',
  setOptions: 'setOptions',
  setReferralCheck: 'setReferralCheck',
  setReferralPrecheckFail: 'setReferralPrecheckFail',
};

const initialState = {
  workedDays: 0,
  workingDays: 0,
  maxSalary: 0,
  withdrawAmount: '0',
  salaryWorkDays: 0,
  reason: {
    selectedReason: '',
    selectedReasonId: 0,
    selectedReasonColor: '',
  },
  todayBalance: 0,
  updatedDate: '',
  lockPeriod: false,
  minBalance: 0,
  reasonList: [],
  firstWorkingDate: null,
  endWorkingDate: null,
  startWithdrawalDate: null,
  numberOfLockPeriod: null,
  withdrawalLockType: '',
  payDateWithdrawal: false,
  options: [],
  isEditable: false,
  usedBalance: null,
  referralPrecheck: { visible: false, message: TOOLTIP_CONTENT['reward'] },
  referralPrecheckFail: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case WITHDRAWAL_CASES.setReferralPrecheckFail:
      return {
        ...state,
        referralPrecheckFail: action.payload,
      };

    case WITHDRAWAL_CASES.setReferralCheck:
      return {
        ...state,
        referralPrecheck: { ...action.payload },
      };

    case WITHDRAWAL_CASES.setOptions:
      return {
        ...state,
        ...action.payload,
      };

    case WITHDRAWAL_CASES.setUsedBalance:
      return {
        ...state,
        usedBalance: action.payload,
      };

    case WITHDRAWAL_CASES.setInitialWithdrawal:
      return {
        ...state,
        ...action.payload,
      };

    case WITHDRAWAL_CASES.setWorkedDays:
      return {
        ...state,
        workedDays: action.payload,
      };

    case WITHDRAWAL_CASES.setWorkingDays:
      return {
        ...state,
        workingDays: action.payload,
      };

    case WITHDRAWAL_CASES.setMaxSalary:
      return {
        ...state,
        maxSalary: action.payload,
      };

    case WITHDRAWAL_CASES.setWithdrawAmount:
      return {
        ...state,
        withdrawAmount: action.payload,
      };

    case WITHDRAWAL_CASES.setSalaryWorkDays:
      return {
        ...state,
        salaryWorkDays: action.payload,
      };
    case WITHDRAWAL_CASES.setReason:
      return {
        ...state,
        reason: {
          ...state.reason,
          [action.payload.key]: action.payload.data,
        },
      };

    case WITHDRAWAL_CASES.setTodayBalance:
      return {
        ...state,
        todayBalance: action.payload,
      };

    case WITHDRAWAL_CASES.setUpdatedDate:
      return {
        ...state,
        updatedDate: action.payload,
      };

    case WITHDRAWAL_CASES.setLockPeriod:
      return {
        ...state,
        lockPeriod: action.payload,
      };

    case WITHDRAWAL_CASES.setMinBalance:
      return {
        ...state,
        minBalance: action.payload,
      };

    case WITHDRAWAL_CASES.setReasonList:
      return {
        ...state,
        reasonList: action.payload,
      };
    case WITHDRAWAL_CASES.setEditable:
      return {
        ...state,
        isEditable: action.payload,
      };
    default:
      return state;
  }
};
