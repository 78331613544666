import colors from '../colors';

export const DRAWER_NAVIGATION_TYPES = {
  navigation: 'navigation',
  link: 'link',
  logout: 'logout',
};

export const COMMON_CONSTANTS = {
  support: 'https://api.whatsapp.com/send?&phone=6281315276948',
  ytLink: 'https://youtube.com/playlist?list=PLK-ft83oaMqaYDwzwJJh0DNx7WpI-qURK',
  playstoreLink: 'https://play.google.com/store/apps/details?id=com.gajitimnative',
  othersImageUrl: 'https://storage.googleapis.com/gajigesa-public/icons/lainnya.png',
  loanIconUrl: 'https://storage.googleapis.com/gajigesa-public/icons/e-kasbon.png',
  bankTransferIconUrl: 'https://storage.googleapis.com/gajigesa-public/icons/bank-transfer.png',
  zakatIconUrl: 'https://storage.googleapis.com/gajigesa-public/icons/ic_zakat.png',
  networkError: 'Network Error',
  productMIlvik:
    'https://api.whatsapp.com/send?phone=6281776645845&text=Halo,%20saya%20adalah%20pengguna%20GajiGesa%20dan%20ingin%20melakukan%20konsultasi%20kesehatan.',
  bankTransferSupport: 'https://bit.ly/verifikasibank_gajigesa',
};

export const VOUCHER_TEXT_CONSTANTS = {
  grab: {
    text1: [
      '1 (satu) buah voucher digital Grab untuk digunakan bertransaksi via aplikasi Grab',
      'Jika kode voucher belum diterima via SMS setelah 3 jam transaksi, pelanggan harap menghubungi Layanan Bantuan GajiGesa',
    ],
    text2: [
      'Pelanggan akan mendapatkan kode voucher melalui SMS setelah pembelian voucher digital berhasil melalui aplikasi GajiGesa.',
      'Kode voucher GrabGifts yang sudah dibeli di GajiGesa tidak dapat dikembalikan atau ditukar. Jika kode voucher GrabGifts sudah expired, kode tidak dapat ditukarkan dengan kode baru.',
      'Kode voucher GrabGifts hanya berlaku untuk 1 (satu) kali perjalanan. Sisa balance dari kode voucher yang tidak terpakai akan hangus dan tidak dapat dikembalikan ke akun OVO Anda.',
      'Layanan Grab yang dapat digunakan untuk kode voucher GrabGifts adalah GrabCar, GrabCar Plus, GrabCar 6-Seater, GrabBike.',
      'Jika kode tidak dapat dipakai, Anda dapat menghubungi Customer Service Grab di nomor telepon 021-80648777 atau e-mail support.id@grab.com.',
      'Tim GajiGesa berhak melakukan pembatalan jika menemukan adanya kecurangan atau penipuan.',
      'GajiGesa berhak untuk mengubah syarat dan ketentuan yang berlaku tanpa pemberitahuan terlebih dulu.',
      'Dengan membeli voucher ini, pengguna dianggap mengerti dan menyetujui semua syarat & ketentuan berlaku',
    ],
    text3: [
      'Masuk ke aplikasi Grab dan pilih Car atau Bike.',
      'Masukkan lokasi penjemputan, lalu klik Promo.',
      'Masukkan kode voucher dan klik Use Now.',
      'Jumlah tarif akan dipotong sesuai dengan voucher yang dipilih.',
    ],
  },
  gocar: {
    text1: [
      '1 (satu) buah voucher digital transaksi GoCar untuk digunakan bertransaksi via aplikasi Gojek',
      'Masa berlaku voucher: 14 (empat belas) hari setelah kode diaktifkan',
      'Jika kode voucher belum diterima via SMS setelah 3 jam transaksi, pelanggan harap menghubungi Layanan Bantuan GajiGesa',
    ],
    text2: [
      'Pelanggan akan mendapatkan kode voucher melalui SMS setelah pembelian voucher digital berhasil melalui aplikasi GajiGesa.',
      'Pembelian kode voucher yang telah berhasil tidak bisa dibatalkan.',
      'Kode voucher hanya berlaku untuk 1 (satu) kali transaksi dengan GoCar.',
      'Jika harga transaksi lebih kecil dari nominal voucher, tidak ada pengembalian kelebihan biaya transaksi GoCar.',
      'Jika harga transaksi lebih besar dari nominal voucher, maka sisa pembayaran dapat dilakukan dengan metode pembayaran tunai atau GoPay.',
      'Penggunaan voucher tidak dapat digabungkan dengan voucher lainnya.',
      'Jika ada kendala Voucher Gojek, pengguna bisa langsung mengakses laman Bantuan, lalu profile menu dan klik Bantuan > pilih Section menu Promo, dan customer bisa mengajukan kode promo yang tidak bisa digunakan. Atau, email langsung ke customerservice@go-jek.com.',
      'Jika pengguna mengalami kendala pada kode voucher, harap tunggu respon balasan dari Tim CS Gojek, karena Gojek akan langsung melakukan pengecekan kendala pada kode voucher tersebut.',
      'Estimasi penyelesaian kendala adalah maks. 2 x 24 jam terhitung sejak pengguna complain melalui customerservice@go-jek.com.',
      'Tim GajiGesa berhak melakukan pembatalan jika menemukan adanya kecurangan atau penipuan.',
      'GajiGesa berhak untuk mengubah syarat dan ketentuan yang berlaku tanpa pemberitahuan terlebih dulu.',
      'Dengan membeli voucher ini, pengguna dianggap mengerti dan menyetujui semua syarat & ketentuan berlaku.',
    ],
    text3: [
      'Masuk ke aplikasi Gojek dan pilih GoCar.',
      'Masukkan lokasi penjemputan, lalu klik Promo. ',
      'Masukkan kode voucher dan klik Apply Code. ',
      'Jumlah tarif akan dipotong sesuai dengan voucher yang dipilih.',
    ],
  },
  gofood: {
    text1: [
      '1 (satu) buah voucher digital transaksi GoFood untuk digunakan bertransaksi via aplikasi Gojek',
      'Masa berlaku voucher: 14 (empat belas) hari setelah kode diaktifkan',
      'Jika kode voucher belum diterima via SMS setelah 3 jam transaksi, pelanggan harap menghubungi Layanan Bantuan GajiGesa',
    ],
    text2: [
      'Pelanggan akan mendapatkan kode voucher melalui SMS setelah pembelian voucher digital berhasil melalui aplikasi GajiGesa.',
      'Pembelian kode voucher yang telah berhasil tidak bisa dibatalkan.',
      'Kode voucher hanya berlaku untuk 1 (satu) kali transaksi dengan GoFood.',
      'Jika harga transaksi lebih kecil dari nominal voucher, tidak ada pengembalian kelebihan biaya transaksi GoFood.',
      'Jika harga transaksi lebih besar dari nominal voucher, maka sisa pembayaran dapat dilakukan dengan metode pembayaran tunai atau GoPay.',
      'Penggunaan voucher tidak dapat digabungkan dengan voucher lainnya.',
      'Jika ada kendala Voucher Gojek, pengguna bisa langsung mengakses laman Bantuan, lalu profile menu dan klik Bantuan > pilih Section menu Promo, dan customer bisa mengajukan kode promo yang tidak bisa digunakan. Atau, email langsung ke customerservice@go-jek.com.',
      'Jika pengguna mengalami kendala pada kode voucher, harap tunggu respon balasan dari Tim CS Gojek, karena Gojek akan langsung melakukan pengecekan kendala pada kode voucher tersebut.',
      'Estimasi penyelesaian kendala adalah maks. 2 x 24 jam terhitung sejak pengguna complain melalui customerservice@go-jek.com.',
      'Tim GajiGesa berhak melakukan pembatalan jika menemukan adanya kecurangan atau penipuan.',
      'GajiGesa berhak untuk mengubah syarat dan ketentuan yang berlaku tanpa pemberitahuan terlebih dulu.',
      'Dengan membeli voucher ini, pengguna dianggap mengerti dan menyetujui semua syarat & ketentuan berlaku.',
    ],
    text3: [
      'Masuk ke aplikasi Gojek dan pilih GoFood.',
      'Masukkan lokasi penjemputan, lalu klik Promo.',
      'Masukkan kode voucher dan klik Apply Code.',
      'Jumlah tarif akan dipotong sesuai dengan voucher yang dipilih.',
    ],
  },
  goride: {
    text1: [
      '1 (satu) buah voucher digital transaksi GoRide untuk digunakan bertransaksi via aplikasi Gojek',
      'Masa berlaku voucher: 14 (empat belas) hari setelah kode diaktifkan',
      'Jika kode voucher belum diterima via SMS setelah 3 jam transaksi, pelanggan harap menghubungi Layanan Bantuan GajiGesa',
    ],
    text2: [
      'Pelanggan akan mendapatkan kode voucher melalui SMS setelah pembelian voucher digital berhasil melalui aplikasi GajiGesa.',
      'Pembelian kode voucher yang telah berhasil tidak bisa dibatalkan.',
      'Kode voucher hanya berlaku untuk 1 (satu) kali transaksi dengan GoRide.',
      'Jika harga transaksi lebih kecil dari nominal voucher, tidak ada pengembalian kelebihan biaya transaksi GoRide.',
      'Jika harga transaksi lebih besar dari nominal voucher, maka sisa pembayaran dapat dilakukan dengan metode pembayaran tunai atau GoPay.',
      'Penggunaan voucher tidak dapat digabungkan dengan voucher lainnya.',
      'Jika ada kendala Voucher Gojek, pengguna bisa langsung mengakses laman Bantuan, lalu profile menu dan klik Bantuan > pilih Section menu Promo, dan customer bisa mengajukan kode promo yang tidak bisa digunakan. Atau, email langsung ke customerservice@go-jek.com.',
      'Jika pengguna mengalami kendala pada kode voucher, harap tunggu respon balasan dari Tim CS Gojek, karena Gojek akan langsung melakukan pengecekan kendala pada kode voucher tersebut.',
      'Estimasi penyelesaian kendala adalah maks. 2 x 24 jam terhitung sejak pengguna complain melalui customerservice@go-jek.com.',
      'Tim GajiGesa berhak melakukan pembatalan jika menemukan adanya kecurangan atau penipuan.',
      'GajiGesa berhak untuk mengubah syarat dan ketentuan yang berlaku tanpa pemberitahuan terlebih dulu.',
      'Dengan membeli voucher ini, pengguna dianggap mengerti dan menyetujui semua syarat & ketentuan berlaku.',
    ],
    text3: [
      'Masuk ke aplikasi Gojek dan pilih GoRide.',
      'Masukkan lokasi penjemputan, lalu klik Promo.',
      'Masukkan kode voucher dan klik Apply Code.',
      'Jumlah tarif akan dipotong sesuai dengan voucher yang dipilih.',
    ],
  },
  kopidaripada: {
    text1: [
      '1 (satu) buah voucher digital transaksi Kopi Dari Pada senilai Rp30.000,- hanya untuk digunakan bertransaksi via aplikasi Hangry',
      'Jika kode voucher belum diterima via SMS setelah 3 jam transaksi, pelanggan harap menghubungi Layanan Bantuan GajiGesa',
    ],
    text2: [
      'Pelanggan akan mendapatkan kode voucher melalui SMS setelah pembelian voucher digital berhasil melalui aplikasi GajiGesa.',
      'Pembelian kode voucher yang telah berhasil tidak bisa dibatalkan.',
      'Promo hanya berlaku dengan pembelian minimal Rp100.000.',
      'Kode hanya dapat digunakan untuk 1 (satu) kali pemakaian untuk 1 (satu) akun yang terdaftar di aplikasi Hangry.',
      'Pembelian hanya berlaku di aplikasi Hangry (delivery order) dan tidak bisa digunakan pada aplikasi pesan makan online lainnya, seperti GrabFood atau GoFood.',
      'Voucher hanya dapat digunakan di DKI Jakarta, Bekasi, dan Tangerang.',
      'Voucher tidak dapat digabungkan dengan promo lainnya.',
      'Voucher hanya berlaku untuk semua produk Kopi Dari Pada.',
      'Pihak Hangry berhak untuk membatalkan transaksi bila pengguna voucher tidak sesuai dengan syarat dan ketentuan yang berlaku.',
      'Syarat dan ketentuan dapat berubah sewaktu-waktu tanpa pemberitahuan terlebih dahulu.',
      'Tim GajiGesa berhak melakukan pembatalan transaksi voucher jika menemukan adanya kecurangan atau penipuan.',
      'GajiGesa berhak untuk mengubah syarat dan ketentuan yang berlaku tanpa pemberitahuan terlebih dulu.',
      'Dengan membeli voucher ini, pengguna dianggap mengerti dan menyetujui semua syarat & ketentuan berlaku.',
    ],
    text3: [
      'Download Hangry Apps di perangkat iOS atau Android kamu.',
      'Lakukan sign up untuk pengguna baru atau log in dengan nomor hp yang terhubung dengan akun Hangry-mu.',
      'Pilih produk Kopi Dari Pada yang kamu inginkan. Jika sudah, klik "Lihat Keranjang" untuk melihat isi keranjangmu.',
      'Klik "Pakai Kupon Diskon" yang terdapat di atas tombol "Bayar" untuk masuk ke halaman Voucher. Kamu akan masuk ke dalam daftar voucher yang kamu miliki.',
      'Masukkan kode voucher yang akan kamu dapatkan setelah membeli voucher ini.',
      'Voucher akan otomatis tertampil setelah kamu memasukkan kode voucher. Klik "Pakai". Jika berhasil, total biaya belanja kamu akan otomatis terpotong senilai nominal voucher yang dipilih.',
      'Klik "Bayar" dan pilih metode pembayaran yang diinginkan (OVO, Gopay, Dana).',
      'Selesai! Selamat menikmati produk Hangry!',
    ],
  },
  kopitiam: {
    text1: [
      '1 (satu) buah voucher Kopitiam untuk makan di tempat.',
      'Masa berlaku voucher: 29 hari setelah pembelian',
      'Jika kode voucher belum diterima via SMS setelah 3 jam transaksi, pelanggan harap menghubungi Layanan Bantuan GajiGesa',
    ],
    text2: [
      'Pelanggan akan mendapatkan kode voucher melalui SMS setelah pembelian voucher digital berhasil melalui aplikasi GajiGesa.',
      'Pembelian kode voucher yang telah berhasil tidak bisa dibatalkan.',
      'Voucher berlaku setiap hari, termasuk hari libur nasional.',
      'Voucher sudah termasuk pajak dan layanan.',
      'Voucher tidak bisa digunakan untuk menu promo dan tidak dapat digabungkan dengan promo lainnya.',
      'E-Voucher ini tidak dapat dipakai di Kopitiam Go!',
      'Maksimum pemakaian 5 (lima) E-Voucher dalam sekali pembelanjaan.',
      'Voucher tidak dapat diuangkan. Jika total transaksi lebih kecil daripada nominal voucher, maka sisa nilai voucher akan hangus.',
      'Syarat dan ketentuan dapat berubah sewaktu-waktu tanpa pemberitahuan terlebih dahulu.',
      'Tim GajiGesa berhak melakukan pembatalan transaksi voucher jika menemukan adanya kecurangan atau penipuan.',
      'GajiGesa berhak untuk mengubah syarat dan ketentuan yang berlaku tanpa pemberitahuan terlebih dulu.',
      'Dengan membeli voucher ini, pengguna dianggap mengerti dan menyetujui semua syarat & ketentuan berlaku.',
    ],
    text3: [
      'Lakukan transaksi di store yang sudah Anda pilih.',
      'Pastikan Anda menukarkan e-voucher saat masih berlaku.',
      'Klik link e-voucher yang dikirimkan melalui app GajiGesa/SMS, lalu tunjukkan ke kasir. Apabila e-voucher yang dikirimkan berupa kode unik dan bukan link, tunjukan kode unik tersebut ke kasir.',
      'Selesai! Anda tidak harus membayar ke kasir, kecuali jika pembelian produk melebihi nilai e-voucher Anda.',
    ],
  },
  kfc: {
    text1: [
      '1 (satu) buah voucher transaksi KFC untuk pembelian langsung di store.',
      'Masa berlaku voucher: 29 hari setelah pembelian',
      'Jika kode voucher belum diterima via SMS setelah 3 jam transaksi, pelanggan harap menghubungi Layanan Bantuan GajiGesa',
    ],
    text2: [
      'Pelanggan akan mendapatkan kode voucher melalui SMS setelah pembelian voucher digital berhasil melalui aplikasi GajiGesa.',
      'Pembelian kode voucher yang telah berhasil tidak bisa dibatalkan.',
      'Voucher berlaku setiap hari, termasuk hari libur nasional.',
      'Voucher sudah termasuk pajak dan layanan.',
      'Voucher tidak bisa digunakan untuk menu promo dan tidak dapat digabungkan dengan promo lainnya.',
      'Voucher tidak dapat diuangkan. Jika total transaksi lebih kecil daripada nominal voucher, maka sisa nilai voucher akan hangus.',
      'Syarat dan ketentuan dapat berubah sewaktu-waktu tanpa pemberitahuan terlebih dahulu.',
      'Tim GajiGesa berhak melakukan pembatalan transaksi voucher jika menemukan adanya kecurangan atau penipuan.',
      'GajiGesa berhak untuk mengubah syarat dan ketentuan yang berlaku tanpa pemberitahuan terlebih dulu.',
      'Dengan membeli voucher ini, pengguna dianggap mengerti dan menyetujui semua syarat & ketentuan berlaku.',
    ],
    text3: [
      'Lakukan transaksi di store yang sudah Anda pilih.',
      'Pastikan Anda menukarkan e-voucher saat masih berlaku.',
      'Klik link e-voucher yang dikirimkan melalui app GajiGesa/SMS, lalu tunjukkan ke kasir. Apabila e-voucher yang dikirimkan berupa kode unik dan bukan link, tunjukan kode unik tersebut ke kasir.',
      'Selesai! Anda tidak harus membayar ke kasir, kecuali jika pembelian produk melebihi nilai e-voucher Anda.',
    ],
  },
  yoshinoya: {
    text1: [
      '1 (satu) buah voucher transaksi Yoshinoya untuk pembelian Layanan Food Delivery via Whatsapp.',
      'Masa berlaku voucher: 29 hari setelah pembelian',
      'Jika kode voucher belum diterima via SMS setelah 3 jam transaksi, pelanggan harap menghubungi Layanan Bantuan GajiGesa',
    ],
    text2: [
      'Pelanggan akan mendapatkan kode voucher melalui SMS setelah pembelian voucher digital berhasil melalui aplikasi GajiGesa.',
      'Pembelian kode voucher yang telah berhasil tidak bisa dibatalkan.',
      'Voucher berlaku setiap hari, termasuk hari libur nasional.',
      'Voucher sudah termasuk pajak dan layanan.',
      'Voucher tidak dapat digabungkan dengan promo lainnya.',
      'Voucher tidak dapat diuangkan.',
      'Jika jumlah total pembelian kurang dari nilai Voucher, maka Customer tidak bisa untuk meminta selisih pembayarannya kepada Yoshinoya.',
      'Jika jumlah total pembelian melebihi nilai voucher, maka Customer membayar selisih pembayaran dengan transfer kepada Yoshinoya.',
      'Syarat dan ketentuan dapat berubah sewaktu-waktu tanpa pemberitahuan terlebih dahulu.',
      'Tim GajiGesa berhak melakukan pembatalan transaksi voucher jika menemukan adanya kecurangan atau penipuan.',
      'GajiGesa berhak untuk mengubah syarat dan ketentuan yang berlaku tanpa pemberitahuan terlebih dulu.',
      'Dengan membeli voucher ini, pengguna dianggap mengerti dan menyetujui semua syarat & ketentuan berlaku.',
    ],
    text3: [
      'Hubungi Delivery Service Yoshinoya menggunakan Whatsapp di nomor +62 858-1150-0566',
      'Pilih menu makanan yang ingin dipesan.',
      'Sebut kode unik e-voucher kepada Petugas Yoshinoya atau kirim screenshoot redeem page voucher.',
      'Petugas Yoshinoya akan menginput Kode Voucher ke dalam sistem terlebih dahulu.',
      'Jika berhasil, maka pesanan tersebut akan dilanjutkan, dan dipastikan untuk diproses.',
      'Jika tidak berhasil, Petugas yoshinoya akan langsung memberitahu Customer mengapa Voucher tersebut tidak dapat di-redeem/ditukarkan.',
    ],
  },
  tawan: {
    text1: [
      '1 (satu) buah voucher diskon Ta Wan senilai untuk digunakan bertransaksi langsung di restoran',
      'Jika kode voucher belum diterima via SMS setelah 3 jam transaksi, pelanggan harap menghubungi Layanan Bantuan GajiGesa',
    ],
    text2: [
      'Pelanggan akan mendapatkan kode voucher melalui SMS setelah pembelian voucher digital berhasil melalui aplikasi GajiGesa.',
      'Pembelian kode voucher yang telah berhasil tidak bisa dibatalkan.',
      'Masa berlaku voucher ini akan diberikan setelah voucher berhasil dibeli.',
      'Voucher berlaku setiap hari, termasuk hari libur nasional dan sudah termasuk pajak dan layanan.',
      'Voucher tidak dapat digabungkan dengan promo lainnya',
      'Jika total transaksi lebih kecil daripada nominal voucher, maka sisa nilai voucher akan hangus.',
      'Pihak Ta Wan berhak untuk membatalkan transaksi bila pengguna voucher tidak sesuai dengan syarat dan ketentuan yang berlaku.',
      'Syarat dan ketentuan dapat berubah sewaktu-waktu tanpa pemberitahuan terlebih dahulu.',
      'Tim GajiGesa berhak melakukan pembatalan transaksi voucher jika menemukan adanya kecurangan atau penipuan.',
      'GajiGesa berhak untuk mengubah syarat dan ketentuan yang berlaku tanpa pemberitahuan terlebih dulu.',
      'Dengan membeli voucher ini, pengguna dianggap mengerti dan menyetujui semua syarat & ketentuan berlaku.',
    ],
    text3: [
      'Lakukan transaksi di store yang sudah Anda pilih.',
      'Pastikan Anda menukarkan e-voucher saat masih berlaku.',
      'Klik link e-voucher yang dikirimkan melalui app GajiGesa/SMS, lalu tunjukkan ke kasir. Apabila e-voucher yang dikirimkan berupa kode unik dan bukan link, tunjukan kode unik tersebut ke kasir.',
      'Selesai! Anda tidak harus membayar ke kasir, kecuali jika pembelian produk melebihi nilai e-voucher Anda.',
    ],
  },
  moonchicken: {
    text1: [
      '1 (satu) buah voucher digital transaksi Moon Chicken senilai hanya untuk digunakan bertransaksi via aplikasi Hangry',
      'Jika kode voucher belum diterima via SMS setelah 3 jam transaksi, pelanggan harap menghubungi Layanan Bantuan GajiGesa',
    ],
    text2: [
      'Pelanggan akan mendapatkan kode voucher melalui SMS setelah pembelian voucher digital berhasil melalui aplikasi GajiGesa.',
      'Pembelian kode voucher yang telah berhasil tidak bisa dibatalkan.',
      'Promo hanya berlaku dengan pembelian minimal Rp150.000.',
      'Kode hanya dapat digunakan untuk 1 (satu) kali pemakaian untuk 1 (satu) akun yang terdaftar di aplikasi Hangry.',
      'Pembelian hanya berlaku di aplikasi Hangry (delivery order) dan tidak bisa digunakan pada aplikasi pesan makan online lainnya, seperti GrabFood atau GoFood.',
      'Voucher hanya dapat digunakan di DKI Jakarta, Bekasi, dan Tangerang.',
      'Voucher tidak dapat digabungkan dengan promo lainnya.',
      'Voucher hanya berlaku untuk semua produk Moon Chicken.',
      'Pihak Hangry berhak untuk membatalkan transaksi bila pengguna voucher tidak sesuai dengan syarat dan ketentuan yang berlaku.',
      'Syarat dan ketentuan dapat berubah sewaktu-waktu tanpa pemberitahuan terlebih dahulu.',
      'Tim GajiGesa berhak melakukan pembatalan transaksi voucher jika menemukan adanya kecurangan atau penipuan.',
      'GajiGesa berhak untuk mengubah syarat dan ketentuan yang berlaku tanpa pemberitahuan terlebih dulu.',
      'Dengan membeli voucher ini, pengguna dianggap mengerti dan menyetujui semua syarat & ketentuan berlaku.',
    ],
    text3: [
      'Download Hangry Apps di perangkat iOS atau Android kamu.',
      'Lakukan sign up untuk pengguna baru atau log in dengan nomor hp yang terhubung dengan akun Hangry-mu.',
      'Pilih produk Moon Chicken yang kamu inginkan. Jika sudah, klik "Lihat Keranjang" untuk melihat isi keranjangmu.',
      'Klik "Pakai Kupon Diskon" yang terdapat di atas tombol "Bayar" untuk masuk ke halaman Voucher. Kamu akan masuk ke dalam daftar voucher yang kamu miliki.',
      'Masukkan kode voucher yang akan kamu dapatkan setelah membeli voucher ini.',
      'Voucher akan otomatis tertampil setelah kamu memasukkan kode voucher. Klik "Pakai". Jika berhasil, total biaya belanja kamu akan otomatis terpotong senilai nominal voucher yang dipilih.',
      'Klik "Bayar" dan pilih metode pembayaran yang diinginkan (OVO, Gopay, Dana).',
      'Selesai! Selamat menikmati produk Hangry!',
    ],
  },
  acharesto: {
    text1: [
      '1 (satu) buah voucher digital transaksi Accha Resto hanya untuk digunakan bertransaksi via aplikasi Hangry',
      'Jika kode voucher belum diterima via SMS setelah 3 jam transaksi, pelanggan harap menghubungi Layanan Bantuan GajiGesa',
    ],
    text2: [
      'Pelanggan akan mendapatkan kode voucher melalui SMS setelah pembelian voucher digital berhasil melalui aplikasi GajiGesa.',
      'Pembelian kode voucher yang telah berhasil tidak bisa dibatalkan.',
      'Voucher hanya dapat digunakan untuk daerah Jabodetabek.',
      'Masa berlaku voucher dapat dilihat di halaman rincian pesanan.',
      'Voucher yang tidak digunakan dalam periode penukaran akan dianggap hangus',
      'Voucher tidak dapat ditukar, dikembalikan, serta digabungkan dengan promosi lainnya',
      'Pengguna bertanggung jawab atas perlindungan dan kerahasiaan kode voucher',
      'Syarat dan ketentuan dapat berubah sewaktu-waktu tanpa pemberitahuan terlebih dahulu.',
      'Tim GajiGesa berhak melakukan pembatalan transaksi voucher jika menemukan adanya kecurangan atau penipuan.',
      'GajiGesa berhak untuk mengubah syarat dan ketentuan yang berlaku tanpa pemberitahuan terlebih dulu.',
      'Dengan membeli voucher ini, pengguna dianggap mengerti dan menyetujui semua syarat & ketentuan berlaku.',
    ],
    text3: [
      'Lakukan transaksi voucher yang kamu mau',
      'Cek kodemu di daftar transaksi',
      'Klaim kode voucher ke nomor CS Accha (+62 812-1997-1882)',
      'Pesan makananmu sekarang!',
    ],
  },
  nasiayam: {
    text1: [
      '1 (satu) buah voucher digital transaksi Nasi Ayam & Kulit Bude Sari hanya untuk digunakan bertransaksi via aplikasi Hangry',
      'Jika kode voucher belum diterima via SMS setelah 3 jam transaksi, pelanggan harap menghubungi Layanan Bantuan GajiGesa',
    ],
    text2: [
      'Pelanggan akan mendapatkan kode voucher melalui SMS setelah pembelian voucher digital berhasil melalui aplikasi GajiGesa.',
      'Pembelian kode voucher yang telah berhasil tidak bisa dibatalkan.',
      'Promo hanya berlaku dengan pembelian minimal Rp100.000.',
      'Kode hanya dapat digunakan untuk 1 (satu) kali pemakaian untuk 1 (satu) akun yang terdaftar di aplikasi Hangry.',
      'Pembelian hanya berlaku di aplikasi Hangry (delivery order) dan tidak bisa digunakan pada aplikasi pesan makan online lainnya, seperti GrabFood atau GoFood.',
      'Voucher hanya dapat digunakan di DKI Jakarta, Bekasi, dan Tangerang.',
      'Voucher tidak dapat digabungkan dengan promo lainnya.',
      'Voucher hanya berlaku untuk semua produk Nasi Ayam & Kulit Bude Sari.',
      'Pihak Hangry berhak untuk membatalkan transaksi bila pengguna voucher tidak sesuai dengan syarat dan ketentuan yang berlaku.',
      'Syarat dan ketentuan dapat berubah sewaktu-waktu tanpa pemberitahuan terlebih dahulu.',
      'Tim GajiGesa berhak melakukan pembatalan transaksi voucher jika menemukan adanya kecurangan atau penipuan.',
      'GajiGesa berhak untuk mengubah syarat dan ketentuan yang berlaku tanpa pemberitahuan terlebih dulu.',
      'Dengan membeli voucher ini, pengguna dianggap mengerti dan menyetujui semua syarat & ketentuan berlaku.',
    ],
    text3: [
      'Download Hangry Apps di perangkat iOS atau Android kamu.',
      'Lakukan sign up untuk pengguna baru atau log in dengan nomor hp yang terhubung dengan akun Hangry-mu. ',
      'Pilih produk Nasi Ayam & Kulit Bude Sari yang kamu inginkan. Jika sudah, klik "Lihat Keranjang" untuk melihat isi keranjangmu.',
      'Klik "Pakai Kupon Diskon" yang terdapat di atas tombol "Bayar" untuk masuk ke halaman Voucher. Kamu akan masuk ke dalam daftar voucher yang kamu miliki.',
      'Masukkan kode voucher yang akan kamu dapatkan setelah membeli voucher ini.',
      'Voucher akan otomatis tertampil setelah kamu memasukkan kode voucher. Klik "Pakai". Jika berhasil, total biaya belanja kamu akan otomatis terpotong senilai nominal voucher yang dipilih.',
      'Klik "Bayar" dan pilih metode pembayaran yang diinginkan (OVO, Gopay, Dana).',
      'Selesai! Selamat menikmati produk Hangry!',
    ],
  },
  fishstreat: {
    text1: [
      '1 (satu) buah voucher transaksi Fish Streat untuk pembelian langsung di tempat.',
      'Masa berlaku voucher: 29 hari setelah pembelian',
      'Jika kode voucher belum diterima via SMS setelah 3 jam transaksi, pelanggan harap menghubungi Layanan Bantuan GajiGesa',
    ],
    text2: [
      'Pelanggan akan mendapatkan kode voucher melalui SMS setelah pembelian voucher digital berhasil melalui aplikasi GajiGesa.',
      'Pembelian kode voucher yang telah berhasil tidak bisa dibatalkan.',
      'Voucher berlaku setiap hari, termasuk hari libur nasional.',
      'Voucher sudah termasuk pajak dan layanan.',
      'Voucher tidak dapat digabungkan dengan promo lainnya.',
      'Voucher tidak dapat diuangkan',
      'Syarat dan ketentuan dapat berubah sewaktu-waktu tanpa pemberitahuan terlebih dahulu.',
      'Tim GajiGesa berhak melakukan pembatalan transaksi voucher jika menemukan adanya kecurangan atau penipuan.',
      'GajiGesa berhak untuk mengubah syarat dan ketentuan yang berlaku tanpa pemberitahuan terlebih dulu.',
      'Dengan membeli voucher ini, pengguna dianggap mengerti dan menyetujui semua syarat & ketentuan berlaku.',
    ],
    text3: [
      'Lakukan transaksi di store yang sudah Anda pilih.',
      'Pastikan Anda menukarkan e-voucher saat masih berlaku.',
      'Klik link e-voucher yang dikirimkan melalui app GajiGesa/SMS, lalu tunjukkan ke kasir. Apabila e-voucher yang dikirimkan berupa kode unik dan bukan link, tunjukan kode unik tersebut ke kasir.',
      'Selesai! Anda tidak harus membayar ke kasir, kecuali jika pembelian produk melebihi nilai e-voucher Anda.',
    ],
  },
  kulina: {
    text1: [
      '1 (satu) buah voucher digital transaksi Kulina untuk digunakan bertransaksi via aplikasi atau website Kulina.id',
      'Jika kode voucher belum diterima via SMS setelah 3 jam transaksi, pelanggan harap menghubungi Layanan Bantuan GajiGesa',
    ],
    text2: [
      'Pelanggan akan mendapatkan kode voucher melalui SMS setelah pembelian voucher digital berhasil melalui aplikasi GajiGesa.',
      'Pembelian kode voucher yang telah berhasil tidak bisa dibatalkan.',
      'Tidak ada minimum pembelian untuk promo ini.',
      'Kode hanya dapat digunakan untuk 1 (satu) kali transaksi. Apabila voucher hanya digunakan sebagian, maka sisa voucher akan hangus.',
      'Pembelian hanya berlaku di aplikasi atau website Kulina.',
      'Voucher tidak dapat digabungkan dengan promo lainnya.',
      'Voucher tidak dapat ditukarkan dengan uang tunai dalam kondisi apa pun.',
      'Pihak Hangry berhak untuk membatalkan transaksi bila pengguna voucher tidak sesuai dengan syarat dan ketentuan yang berlaku.',
      'Syarat dan ketentuan dapat berubah sewaktu-waktu tanpa pemberitahuan terlebih dahulu.',
      'Tim GajiGesa berhak melakukan pembatalan transaksi voucher jika menemukan adanya kecurangan atau penipuan.',
      'GajiGesa berhak untuk mengubah syarat dan ketentuan yang berlaku tanpa pemberitahuan terlebih dulu.',
      'Dengan membeli voucher ini, pengguna dianggap mengerti dan menyetujui semua syarat & ketentuan berlaku.',
      'Info lebih lanjut hubungi Customer Service Kulina melalui e-mail ke sales@kulina.id.',
    ],
    text3: [
      'Buka website kulina.id atau download aplikasi Kulina di playstore/appstore',
      'Tentukan atau pilih alamat pengantaran',
      'Pilih menu (Basic Lunch)',
      'Pilih berapa box dan berapa jumlah hari ingin berlangganan',
      'Pilih tambahkan keranjang',
      'Pilih "Lihat Keranjang"',
      'Pilih Checkout untuk ke pembayaran dan konfirmasi alamat yang tertera',
      'Pilih "Punya Kode Promo"',
      'Masukkan Kode Promo di "punya kode promo?" dan pilih gunakan',
      'Klik Metode pembayaran untuk pembayarannya',
      'Selesai! Selamat menikmati Kulina!',
    ],
  },
  sangyu: {
    text1: [
      '1 (satu) buah voucher digital transaksi Sangyu hanya untuk digunakan bertransaksi via aplikasi Hangry',
      'Jika kode voucher belum diterima via SMS setelah 3 jam transaksi, pelanggan harap menghubungi Layanan Bantuan GajiGesa',
    ],
    text2: [
      'Pelanggan akan mendapatkan kode voucher melalui SMS setelah pembelian voucher digital berhasil melalui aplikasi GajiGesa.',
      'Pembelian kode voucher yang telah berhasil tidak bisa dibatalkan.',
      'Promo hanya berlaku dengan pembelian minimal Rp100.000.',
      'Kode hanya dapat digunakan untuk 1 (satu) kali pemakaian untuk 1 (satu) akun yang terdaftar di aplikasi Hangry.',
      'Pembelian hanya berlaku di aplikasi Hangry (delivery order) dan tidak bisa digunakan pada aplikasi pesan makan online lainnya, seperti GrabFood atau GoFood.',
      'Voucher hanya dapat digunakan di DKI Jakarta, Bekasi, dan Tangerang.',
      'Voucher tidak dapat digabungkan dengan promo lainnya.',
      'Voucher hanya berlaku untuk semua produk Sangyu.',
      'Pihak Hangry berhak untuk membatalkan transaksi bila pengguna voucher tidak sesuai dengan syarat dan ketentuan yang berlaku.',
      'Syarat dan ketentuan dapat berubah sewaktu-waktu tanpa pemberitahuan terlebih dahulu.',
      'Tim GajiGesa berhak melakukan pembatalan transaksi voucher jika menemukan adanya kecurangan atau penipuan.',
      'GajiGesa berhak untuk mengubah syarat dan ketentuan yang berlaku tanpa pemberitahuan terlebih dulu.',
      'Dengan membeli voucher ini, pengguna dianggap mengerti dan menyetujui semua syarat & ketentuan berlaku.',
    ],
    text3: [
      'Download Hangry Apps di perangkat iOS atau Android kamu.',
      'Lakukan sign up untuk pengguna baru atau log in dengan nomor hp yang terhubung dengan akun Hangry-mu.',
      'Pilih produk Sangyu yang kamu inginkan. Jika sudah, klik "Lihat Keranjang" untuk melihat isi keranjangmu.',
      'Klik "Pakai Kupon Diskon" yang terdapat di atas tombol "Bayar" untuk masuk ke halaman Voucher. Kamu akan masuk ke dalam daftar voucher yang kamu miliki.',
      'Masukkan kode voucher yang akan kamu dapatkan setelah membeli voucher ini.',
      'Voucher akan otomatis tertampil setelah kamu memasukkan kode voucher. Klik "Pakai". Jika berhasil, total biaya belanja kamu akan otomatis terpotong senilai nominal voucher yang dipilih.',
      'Klik "Bayar" dan pilih metode pembayaran yang diinginkan (OVO, Gopay, Dana).',
      'Selesai! Selamat menikmati produk Hangry!',
    ],
  },
  carrefour: {
    text1: [
      '1 (satu) buah voucher belanja Carrefour Transmart untuk pembelian langsung di store.',
      'Masa berlaku voucher: 29 hari setelah pembelian',
      'Jika kode voucher belum diterima via SMS setelah 3 jam transaksi, pelanggan harap menghubungi Layanan Bantuan GajiGesa',
    ],
    text2: [
      'Pelanggan akan mendapatkan kode voucher melalui SMS setelah pembelian voucher digital berhasil melalui aplikasi GajiGesa.',
      'Pembelian kode voucher yang telah berhasil tidak bisa dibatalkan.',
      'Voucher berlaku setiap hari, termasuk hari libur nasional.',
      'Voucher sudah termasuk pajak dan layanan.',
      'Voucher tidak bisa digunakan untuk menu promo dan tidak dapat digabungkan dengan promo lainnya.',
      'Voucher tidak dapat diuangkan. Jika total transaksi lebih kecil daripada nominal voucher, maka sisa nilai voucher akan hangus.',
      'Syarat dan ketentuan dapat berubah sewaktu-waktu tanpa pemberitahuan terlebih dahulu.',
      'Tim GajiGesa berhak melakukan pembatalan transaksi voucher jika menemukan adanya kecurangan atau penipuan.',
      'GajiGesa berhak untuk mengubah syarat dan ketentuan yang berlaku tanpa pemberitahuan terlebih dulu.',
      'Dengan membeli voucher ini, pengguna dianggap mengerti dan menyetujui semua syarat & ketentuan berlaku.',
    ],
    text3: [
      'Lakukan transaksi di store yang sudah Anda pilih.',
      'Pastikan Anda menukarkan e-voucher saat masih berlaku.',
      'Klik link e-voucher yang dikirimkan melalui app GajiGesa/SMS, lalu tunjukkan ke kasir. Apabila e-voucher yang dikirimkan berupa kode unik dan bukan link, tunjukan kode unik tersebut ke kasir.',
      'Selesai! Anda tidak harus membayar ke kasir, kecuali jika pembelian produk melebihi nilai e-voucher Anda.',
    ],
  },
  textAlfamart: {
    text1: [
      'Voucher Digital Alfamart adalah Voucher Belanja non fisik yang dapat digunakan untuk transaksi apa saja kecuali payment e-commerce',
      'Apabila dalam kurun waktu 3 jam setelah pelanggan berhasil melakukan pembelian voucher namun pelanggan belum menerima link voucher melalui SMS pada nomor terdaftar di GajiGesa, pelanggan dapat menghubungin Contact Center GajiGesa',
    ],
    text2: [
      'Pelanggan akan mendapatkan redeem link atau kode voucher melalui SMS setelah pembelian voucher digital melalui aplikasi GajiGesa',
      'Redeem link yang diberikan hanya dapat diakses satu kali, sehingga pelanggan perlu memastikan untuk hanya menggunakan redeem code tersebut saat akan melakukan pembayaran menggunakan voucher di merchant tersebut',
      'Disarankan untuk menukarkan voucher maksimal 7 hari setelah pembelian voucher',
      'Masa validasi kode voucher setelah dibuka linknya hanya 15 menit. Pastikan untuk menggunakannya saat akan melakukan pembayaran di merchant',
      'Kode voucher yang sudah ditukarkan tidak dapat digunakan kembali.',
    ],
    text3: [
      'Lakukan transaksi di store yang kamu pilih.',
      'Pastikan koneksi internet stabil ketika menukarkan voucher digital.',
      'Pastikan penukaran dilakukan saat voucher digital masih berlaku (disarankan melakukan penukaran 7 hari setelah pembelian voucher).',
      'Masa validasi kode voucher setelah dibuka linknya hanya 15 menit. Pastikan untuk menggunakannya saat akan melakukan pembayaran di merchant.',
      'Setelah link terbuka, pindai QR code yang diterima pada mesin pembayaran.',
    ],
  },
  textGrab: {
    text1: [
      'Voucher Digital Grab Transport adalah Voucher non fisik yang dapat digunakan untuk melakukan transaksi Grab Car maupun Grab Bike',
      'Apabila dalam kurun waktu 3 jam setelah pelanggan berhasil melakukan pembelian voucher namun pelanggan belum menerima kode voucher melalui SMS pada nomor terdaftar di GajiGesa, pelanggan dapat menghubungin Contact Center GajiGesa',
    ],
    text2: [
      'Pelanggan akan mendapatkan redeem link atau kode voucher melalui SMS setelah pembelian voucher digital melalui aplikasi GajiGesa',
      'Kode voucher hanya dapat digunakan sekali',
      'Disarankan untuk menukarkan kode voucher maksimal 7 hari dari tanggal pembelian voucher',
      'Voucher tidak dapat diuangkan',
      'Voucher tidak dapat digabungkan dengan promo lain',
    ],
    text3: [
      'Masuk ke aplikasi Grab dan pilih Car atau Bike.',
      'Masukkan lokasi penjemputan lalu klik promo.',
      'Masukkan kode voucher dan klik Use Now.',
      'Jumlah tarif akan dipotong sesuai dengan voucher yang dipilih',
    ],
  },
  textIndomaret: {
    text1: [
      'Voucher Digital Indomaret adalah Voucher Belanja non fisik yang dapat digunakan untuk transaksi apa saja kecuali payment e-commerce',
      'Apabila dalam kurun waktu 3 jam setelah pelanggan berhasil melakukan pembelian voucher namun pelanggan belum menerima link voucher melalui SMS pada nomor terdaftar di GajiGesa, pelanggan dapat menghubungin Contact Center GajiGesa',
    ],
    text2: [
      'Pelanggan akan mendapatkan redeem link atau kode voucher melalui SMS setelah pembelian voucher digital melalui aplikasi GajiGesa',
      'Redeem link yang diberikan hanya dapat diakses satu kali, sehingga pelanggan perlu memastikan untuk hanya menggunakan redeem code tersebut saat akan melakukan pembayaran menggunakan voucher di merchant tersebut',
      'Disarankan untuk menukarkan voucher maksimal 7 hari setelah pembelian voucher',
      'Masa validasi kode voucher setelah dibuka linknya hanya 15 menit. Pastikan untuk menggunakannya saat akan melakukan pembayaran di merchant',
      'Kode voucher yang sudah ditukarkan tidak dapat digunakan kembali.',
    ],
    text3: [
      'Lakukan transaksi di store yang kamu pilih.',
      'Pastikan koneksi internet stabil ketika menukarkan voucher digital.',
      'Pastikan penukaran dilakukan saat voucher digital masih berlaku (disarankan melakukan penukaran 7 hari setelah pembelian voucher).',
      'Masa validasi kode voucher setelah dibuka linknya hanya 15 menit. Pastikan untuk menggunakannya saat akan melakukan pembayaran di merchant.',
      'Setelah link terbuka, pindai QR code yang diterima pada mesin pembayaran.',
    ],
  },
  textGrabFood: {
    text1: [
      'Voucher Digital Grab Food adalah Voucher non fisik yang dapat digunakan untuk melakukan transaksi Grab Food',
      'Apabila dalam kurun waktu 3 jam setelah pelanggan berhasil melakukan pembelian voucher namun pelanggan belum menerima kode voucher melalui SMS pada nomor terdaftar di GajiGesa, pelanggan dapat menghubungin Contact Center GajiGesa',
    ],
    text2: [
      'Pelanggan akan mendapatkan redeem link atau kode voucher melalui SMS setelah pembelian voucher digital melalui aplikasi GajiGesa',
      'Kode voucher hanya dapat digunakan sekali',
      'Disarankan untuk menukarkan kode voucher maksimal 7 hari dari tanggal pembelian voucher',
      'Voucher tidak dapat diuangkan',
      'Voucher tidak dapat dikombinasikan dengan promo lain',
    ],
    text3: [
      'Masuk ke aplikasi Grab dan pilih Food.',
      'Masukkan lokasi delivery, restoran dan menu.',
      'Masukkan kode voucher dan klik Use Now.',
      'Jumlah tarif akan dipotong sesuai dengan voucher yang dipilih',
    ],
  },
  textHalodoc: {
    text1: [
      'Voucher Digital Halodoc adalah Voucher non fisik yang dapat digunakan untuk melakukan Rapid Test COVID-19 yang valid digunakan di semua lokasi Rapid Test.',
      'Apabila dalam kurun waktu 3 jam setelah pelanggan berhasil melakukan pembelian voucher namun pelanggan belum menerima kode voucher melalui SMS pada nomor terdaftar di GajiGesa, pelanggan dapat menghubungin Contact Center GajiGesa',
    ],
    text2: [
      'Pelanggan akan mendapatkan redeem link atau kode voucher melalui SMS setelah pembelian voucher digital melalui aplikasi GajiGesa',
      'Kode voucher yang diberikan hanya berlaku untuk satu kali Rapid Test.',
      'Voucher berlaku selama 14 hari setelah pembelian voucher',
      'Voucher berlaku untuk semua lokasi Rapid Test and semua partner pada aplikasi Halodoc',
      'Voucher tidak dapat digabungkan dengan promo lain',
      'Voucher dapat dibeli lebih dari satu kali untuk setiap akun',
    ],
    text3: [
      'Buka aplikasi Halodoc dan pilih menu COVID-19.',
      'Pilih lokasi, tanggal dan waktu Rapid Test',
      'Pilih nama pasien.',
      'Isi nama dan tanggal lahir kemudian upload kartu identitas (KTP, SIM atau Paspor).',
      'Masukkan kode voucher dan pilih metode pembayaran.',
      'Selesaikan proses pembayaran (biaya akan terpotong melalui kode voucher) dan Rapid Test-mu akan terdaftar.',
    ],
  },
  textSayurbox: {
    text1: [
      'Voucher Digital Sayur Box adalah Voucher non fisik yang dapat digunakan untuk berbelanja di outlet Sayurbox',
      'Apabila dalam kurun waktu 3 jam setelah pelanggan berhasil melakukan pembelian voucher namun pelanggan belum menerima kode voucher melalui SMS pada nomor terdaftar di GajiGesa, pelanggan dapat menghubungin Contact Center GajiGesa',
    ],
    text2: [
      'Pelanggan akan mendapatkan redeem link atau kode voucher melalui SMS setelah pembelian voucher digital melalui aplikasi GajiGesa',
      'Redeem link yang diberikan hanya dapat diakses satu kali, sehingga pelanggan perlu memastikan untuk hanya menggunakan redeem code tersebut saat akan melakukan pembayaran menggunakan voucher di merchant tersebut',
      'Disarankan untuk menukarkan voucher maksimal 7 hari setelah pembelian voucher',
      'Masa validasi kode voucher setelah dibuka linknya hanya 15 menit. Pastikan untuk menggunakannya saat akan melakukan pembayaran di merchant',
      'Kode voucher yang sudah ditukarkan tidak dapat digunakan kembali.',
    ],
    text3: [
      'Download Sayurbox App di Play Store (Android) atau App Store (IOS).',
      'Cari buah dan sayur yang kamu inginkan',
      'Klik “Voucher” di halaman Checkout.',
      'Buka link yang dikirimkan Gajigesa di SMS, catat kode vouchernya.',
      'Masukkan kode voucher pada aplikasi Sayurbox dan klik Pakai.',
      'Lanjut ke halaman pembayaran dan selesaikan prosesnya.',
      'Check order status di halaman “My Order”.',
      'Untuk info lengkap buka https://www.sayurbox.com/shop',
    ],
  },
};

export const VOUCHER_BILLER_DATA = [
  {
    name: 'Voucher Alfamart',
    link: 'https://storage.googleapis.com/gajigesa-public/icons/logo-alfamart.svg',
  },
  {
    name: 'Voucher Grab Transport',
    link: 'https://storage.googleapis.com/gajigesa-public/icons/logo-grab.svg',
  },
  {
    name: 'Voucher Grab Food',
    link: 'https://storage.googleapis.com/gajigesa-public/icons/logo-grabfood.svg',
  },
  {
    name: 'Voucher Indomaret',
    link: 'https://storage.googleapis.com/gajigesa-public/icons/logo-indomaret.svg',
  },
  {
    name: 'Voucher Halodoc',
    link: 'https://storage.googleapis.com/gajigesa-public/icons/logo-halodoc.svg',
  },
  {
    name: 'Voucher Sayur Box',
    link: 'https://storage.googleapis.com/gajigesa-public/icons/logo-sayurbox.svg',
  },
];

export const WITHDRAW_REASONS = [
  [
    { reason: 'Sewa Kontrakan', icon: 'home', color: 'blue' },
    { reason: 'Tagihan dan Utilitas', icon: 'money-bill-wave', color: 'blue' },
    { reason: 'Biaya Pendidikan Anak', icon: 'graduation-cap', color: 'green' },
    { reason: 'Biaya Kesehatan', icon: 'briefcase-medical', color: 'green' },
    { reason: 'Pajak', icon: 'money-check-alt', color: 'green' },
    { reason: 'Kebutuhan Sehari-hari', icon: 'store', color: 'blue' },
    { reason: 'Belanja', icon: 'shopping-cart', color: 'red' },
    { reason: 'Kebutuhan Pangan', icon: 'utensils', color: 'blue' },
    { reason: 'Kado & Donasi', icon: 'gift', color: 'red' },
  ],
  [
    { reason: 'Kebutuhan Pribadi', icon: 'smile', color: 'red' },
    { reason: 'Transportasi', icon: 'taxi', color: 'blue' },
    { reason: 'Perbaikan Kendaraan', icon: 'motorcycle', color: 'blue' },
    { reason: 'Renovasi Rumah', icon: 'house-damage', color: 'blue' },
    { reason: 'Liburan', icon: 'suitcase', color: 'red' },
    { reason: 'Modal Usaha', icon: 'search-dollar', color: 'green' },
    { reason: 'Investasi', icon: 'chart-line', color: 'green' },
    { reason: 'Hiburan', icon: 'play-circle', color: 'red' },
    { reason: 'Kebutuhan Lainnya', icon: 'angle-right', color: 'blue' },
  ],
];

export const USER_ROLES = {
  employeeWithGTEmployer: 'gajitim employer',
};

export const PAYSLIP = {
  EMPLOYEE_NAME: 'EMPLOYEE_NAME',
  PHONE_NUMBER: 'PHONE_NUMBER',
  BANK: 'BANK',
  ACCOUNT_NUMBER: 'ACCOUNT_NUMBER',
  SALARY_AND_PAYMENT: 'SALARY_AND_PAYMENT',
  SALARY_PAYMENTS: 'SALARY_PAYMENTS',
  ATTENDANCE_HISTORY: 'ATTENDANCE_HISTORY',
  DATE: 'DATE',
  DESCRIPTION: 'DESCRIPTION',
  TOTAL: 'TOTAL',
  TITLE2: 'ATTENDANCE',
  NOTE: 'NOTE',
  WORKING_HOURS: 'WORKING_HOURS',
  SALARY: 'SALARY',
  ADDITION: 'ADDITION',
  DEDUCTION: 'DEDUCTION',
  BASE_SALARY: 'BASE_SALARY',
  TOTAL_ADDITION: 'TOTAL_ADDITION',
  BONUS: 'BONUS',
  OVERTIME: 'OVERTIME',
  TOTAL_DEDUCTION: 'TOTAL_DEDUCTION',
  NETT_SALARY: 'NETT_SALARY',
  PAID_SALARY: 'PAID_SALARY',
  OUTSTANDING_SALARY: 'OUTSTANDING_SALARY',
  ATTENDANCE: 'ATTENDANCE',
  ABSENT: 'ABSENT',
  PAID_LEAVE: 'PAID_LEAVE',
  LATE: 'LATE',
  DAY_OFF: 'DAY_OFF',
  PRESENT: 'PRESENT',
  PENDING: 'PENDING',
  OUTSTANDING: 'OUTSTANDING',
  PAID: 'PAID',
  LATE_FINE: 'LATE_FINE',
  HOLIDAY: 'HOLIDAY',
  PAYMENT_FROM_GAJIGESA: 'PAYMENT_FROM_GAJIGESA',
  PAYMENT_FROM_GAJITIM: 'PAYMENT_FROM_GAJITIM',
  OVERPAID: 'OVERPAID',
};

export const ATTENDANCE_COLORS = {
  hadir: '#137A08',
  absen: '#C80000',
  libur: '#7B47E9',
  terlambat: '#EC850D',
  lembur: '#AB8141',
  jamKerja: '#137A08',
  jamIstirahat: '#2D76E4',
};

export const ATTENDANCE_ICONS = {
  hadir: 'https://storage.googleapis.com/gajigesa-public/icons/attendance_hadir.svg',
  absen: 'https://storage.googleapis.com/gajigesa-public/icons/attendance_absen.svg',
  libur: 'https://storage.googleapis.com/gajigesa-public/icons/attendance_libur.svg',
  terlambat: 'https://storage.googleapis.com/gajigesa-public/icons/attendance_terlambat.svg',
  lembur: 'https://storage.googleapis.com/gajigesa-public/icons/attendance_lembur.svg',
  jamKerja: 'https://storage.googleapis.com/gajigesa-public/icons/attendance_jam_kerja.svg',
  jamIstirahat: 'https://storage.googleapis.com/gajigesa-public/icons/attendance_jam_istirahat.svg',
  jamIstirahatList: 'https://storage.googleapis.com/gajigesa-public/icons/attendance_jam_istirahat_list.svg',
  jamKerjaButton: 'https://storage.googleapis.com/gajigesa-public/icons/attendance_jam_kerja_button.svg',
  jamIstirahatButton: 'https://storage.googleapis.com/gajigesa-public/icons/attendance_jam_istirahat_button.svg',
  jamKerjaModal: 'https://storage.googleapis.com/gajigesa-public/icons/attendance_jam_kerja_modal.svg',
  pending: 'https://storage.googleapis.com/gajigesa-public/icons/ClockCircle.svg',
  approve: 'https://storage.googleapis.com/gajigesa-public/icons/Check.svg',
  reject: 'https://storage.googleapis.com/gajigesa-public/icons/Reject.svg',
  dayOff: 'https://storage.googleapis.com/gajigesa-public/icons/Day_Off.svg',
};

export const KETENTUAN_LAYANAN = {
  validDate: 'Tanggal Berlaku: 15 Agustus 2020',
  title1: 'Ketentuan layanan dan kebijakan privasi ini ',
  title2: 'merujuk pada dan merupakan syarat dan ketentuan dari PT Easy Management Systems ',
  title3:
    'yang menjelaskan tentang informasi yang Kami kumpulkan dari Anda melalui www.gajigesa.com Situs, aplikasi seluler GajiGesa Aplikasi dan/atau layanan-layanan terkait',
  desc1:
    'Ketentuan Layanan ini juga mengatur bagaimana Kami menggunakan, mengungkapkan, menyimpan dan mengumpulkan informasi Anda tersebut dan langkah-langkah yang Kami ambil untuk melindungi informasi Anda secara wajar.',
  info2:
    'Harap Anda membaca secara cermat dan seksama setiap dari Ketentuan Layanan ini. Dengan mengakses atau menggunakan Layanan GajiGesa maka berarti Anda telah setuju untuk terikat dengan Ketentuan Layanan ini. Tidak ada satu pun ketentuan di dalam Ketentuan Layanan ini yang memaksa Anda untuk mengakses atau menggunakan Layanan GajiGesa. Jika Anda tidak setuju untuk menggunakan Layanan GajiGesa, maka Anda dapat meninggalkan Situs dan Aplikasi Kami.',
  info3:
    'Untuk dapat menggunakan Layanan GajiGesa atau menerima Layanan Kami, Anda menjamin dan memastikan bahwa Anda bukanlah: (a) anak atau pihak yang belum cukup umur untuk mengadakan kontrak yang mengikat secara hukum dengan GajiGesa; (b) orang atau pihak yang dilarang oleh hukum untuk menerima atau menggunakan Layanan GajiGesa; (c) warga negara asing; (d) pekerja atau karyawan dimana pemberi kerja Anda belum atau tidak menandatangani kontrak Kerjasama dengan GajiGesa; atau (e) orang atau pihak yang menurut kebijakan GajiGesa tidak berhak menggunakan Layanan GajiGesa, termasuk namun tidak terbatas orang atau pihak yang melakukan tindak pidana, melanggar hukum atau melanggar kontrak yang berlaku.',
  info4:
    'GajiGesa dapat mengubah Ketentuan Layanan ini dari waktu ke waktu. Setiap dan semua perubahan yang ada pada Ketentuan Layanan ini akan ditampilkan pada Situs GajiGesa. Ketentuan Layanan akan selalu menampilkan tanggal terakhir dilakukan revisi.',
  info5:
    'Kami merekomendasi Anda untuk selalu membaca Ketentuan Layanan ini dari waktu ke waktu. Jika Anda menggunakan Layanan GajiGesa setelah perubahan tersebut ditampilkan maka Anda dianggap telah menerima Ketentuan Layanan yang baru dan setuju untuk terikat dengan perubahan apa pun yang ada pada Ketentuan Layanan. Kami dapat namun tidak berkewajiban memberitahukan Anda untuk setiap informasi atau ketentuan yang berubah secara signifikan melalui Situs dan/atau Aplikasi Kami.',
  info6:
    'Ketentuan Layanan ini berlaku untuk kegiatan-kegiatan pada Layanan yang disediakan oleh Kami, yang mungkin berisikan fitur-fitur atau tautan-tautan ke Situs dan Layanan yang disediakan oleh pihak ketiga, serta produk dan Layanan-Layanan lainnya yang mungkin disediakan untuk Anda melalui website pihak ketiga ("Layanan Pihak Ketiga"). Setiap informasi yang Anda berikan kepada Layanan Pihak Ketiga, tunduk pada kebijakan, jika ada, dari masing-masing operator yang mengatur privasi dan keamanan, bahkan apabila diakses melalui Layanan. Kami tidak memiliki kendali atas sifat, konten, ketersediaan, atau praktik privasi dan keamanan dari Layanan Pihak Ketiga apa pun atau situs atau Layanan pihak ketiga mana pun yang terhubung dari, atau dapat diakses melalui Layanan. Ketentuan Layanan ini tidak mencakup tautan-tautan di dalam Layanan yang menautkannya ke Layanan Pihak Ketiga lainnya. Kami menganjurkan Anda untuk mempelajari Ketentuan Layanan dan keamanan dari pihak ketiga sebelum Anda memberikan informasi pribadi kepada mereka.',
  info8:
    'Kami mengumpulkan dan memproses informasi-informasi pribadi Anda untuk tujuan-tujuan, antara lain, sebagai berikut:',
  info9: 'untuk tujuan pengidentifikasian serta untuk mengelola akun Anda, setiap kali Anda ingin Layanan tersediakan;',
  info10:
    'kustomisasi pengalaman Anda, dengan mengidentifikasikan preferensi Anda sehingga Kami dapat menawarkan pengalaman yang dipersonalisasikan (seperti bahasa), merekomendasikan fungsi-fungsi tertentu dari Layanan, produk, peLayanan, dan/atau minat Kami; yang mungkin akan menarik bagi Anda;',
  info11:
    'pengoperasian, pemeliharaan, peningkatan dan penyediaan semua fitur Layanan, untuk menyediakan Layanan dan informasi yang Anda minta, untuk menanggapi komentar dan pertanyaan-pertanyaan dan dengan cara lain memberikan dukungan kepada para pengguna;',
  info12:
    'analisa tren penggunaan dan preferensi para pengguna Kami, peningkatan Layanan, pengembangan produk-produk baru, Layanan, fitur, fungsionalitas, kinerja, dan partisipasi dalam studi dan pengembangan material pemasaran;',
  info13:
    'menghubungi Anda untuk tujuan-tujuan administratif seperti Layanan pelanggan, atau untuk menyampaikan komunikasi, termasuk update promosi dan acara, yang berkaitan dengan produk dan Layanan yang ditawarkan oleh Kami dan oleh pihak ketiga;',
  info14:
    'menyediakan iklan produk dan/atau Layanan Kami yang dipersonalisasi, serta iklan yang disediakan oleh penyedia Layanan dari pihak ketiga yang relevan; yang Kami yakini mungkin akan menarik bagi Anda dan preferensi; dan/atau',
  info15:
    'membagikan informasi pribadi Anda dengan prosesor-prosesor yang relevan dan pihak ketiga yang seperlunya untuk Kami memberikan Layanan Kami kepada Anda.',
  info16:
    'Berkenaan dengan penjelasan tujuan pada huruf (f) dan (g) di atas, Kami ingin memberitahukan Anda bahwa Kami telah menerapkan pengamanan yang sesuai untuk memastikan keamanan, kerahasiaan, perlindungan informasi pribadi Anda. Silakan melihat Kebijakan Berbagi Data pada Bagian 5 di bawah ini untuk detail lebih lanjut tentang hal ini.',
  info17:
    'Ketika Anda menggunakan Layanan Kami atau Layanan Pihak Ketiga maka Kami dapat mengumpulkan informasi pribadi Anda, baik langsung dari Anda atau dari entitas yang mengoperasikan Layanan Pihak Ketiga. Informasi-informasi pribadi yang Kami kumpulkan meliputi:',
  info18: 'nama depan;',
  info19: 'alamat email;',
  info20: 'alamat pengiriman;',
  info21: 'nomor telepon;',
  info22: 'nomor identifikasi karyawan;',
  info23: 'detail pekerjaan;',
  info24: 'tanggal lahir;',
  info25: 'lokasi geografis; dan/atau',
  info26: 'informasi relevan lainnya terkait dengan Layanan Kami.',
  info27:
    'Selain informasi-informasi pribadi yang disebutkan di atas, mohon dicatat bahwa Kami secara otomatis akan mengumpulkan, menyimpan dan memproses jenis-jenis data tertentu yang dihasilkan oleh interaksi Anda dengan Layanan Kami. Kami secara otomatis akan memproses, antara lain, data-data berikut ini:',
  info28:
    'Alamat Internet Protocol (IP) yang dengannya komputer Anda atau perangkat lainnya terhubung ke internet untuk mengakses Layanan Kami;',
  info29: 'nama pengguna, alamat e-mail, serta kata sandi akun Anda;',
  info30:
    'informasi teknis tentang komputer, perangkat, dan koneksi internet Anda, misalnya, jenis dan versi browser yang Anda gunakan, plug-in, sistem operasi (OS), atau pengaturan-pengaturan terkait lainnya;',
  info31: 'lokasi komputer atau perangkat yang Anda gunakan untuk mengakses Layanan Kami;',
  info32: 'informasi penggunaan lainnya tentang penggunaan Layanan, termasuk riwayat halaman yang Anda lihat.',
  info33:
    'Selain itu, Kami juga dapat menggunakan pengidentifikasi perangkat, cookie, dan teknologi lainnya pada perangkat dan Layanan untuk mengumpulkan informasi-informasi penelusuran, penggunaan, dan informasi teknis lainnya. Silakan merujuk pada ketentuan Cookie Kami pada Bagian 3 di bawah ini.',
  info34:
    'Kami akan mengumpulkan dan menggunakan informasi pribadi yang diuraikan di atas guna memberikan Layanan kepada Anda dengan cara yang Andal dan aman. Sejauh Kami memproses data-data pribadi Anda untuk tujuan lainnya maka Kami meminta persetujuan Anda terlebih dahulu.',
  info35:
    'Biaya administrasi atau penarikan (“Biaya Administrasi”) akan dikenakan pada Anda dalam jumlah yang ditampilkan ketika Anda meminta Upah Berdasarkan Permintaan (On-Demand Wages) dari GajiGesa. Biaya Pemrosesan tersebut akan dibayarkan pada saat pembayaran Upah Berdasarkan Permintaan (On-Demand Wages) Anda.',
  info36:
    'Biaya Administrasi adalah biaya untuk berpartisipasi dalam Layanan GajiGesa dan ini mewakili biaya GajiGesa yang terkait dengan pemrosesan permintaan Anda.',
  info37:
    'Sepanjang tidak ditentukan secara tegas di dalam Layanan GajiGesa, maka tidak ada biaya tambahan dalam menggunakan Layanan GajiGesa.',
  info38: 'Kami dapat menggunakan cookie dan secara otomatis mengumpulkan informasi-informasi untuk:',
  info39:
    'personalisasi Layanan Kami, seperti mengingat informasi-informasi tentang Anda sehingga Anda tidak perlu mengisikannya kembali selama kunjungan Anda atau di waktu berikutnya ketika Anda menggunakan Layanan;',
  info40: 'menyediakan iklan, konten, dan informasi-informasi khusus;',
  info41: 'memonitor dan menganalisa efektivitas Layanan dan kegiatan-kegiatan pemasaran dari pihak ketiga;',
  info42: 'memonitor metrik penggunaan situs agregat seperti jumlah total pengunjung serta halaman yang dilihat; dan',
  info43: 'melacak entri, submisi, dan status Anda dalam promosi atau kegiatan lain apa pun pada Layanan.',
  info44:
    'Dalam menyediakan Layanan kami dan Layanan Pihak Ketiga melalui Layanan kami, kami akan menggunakan jenis cookie berikut ini:',
  info45:
    'Cookie yang diperlukan. Cookie ini memungkinkan fungsionalitas inti dari Layanan, seperti keamanan, manajemen jaringan, dan aksesibilitas.',
  info46:
    'Cookie analitik. Cookie ini memungkinkan Kami untuk meningkatkan Layanan Kami dengan cara mengumpulkan dan melaporkan informasi-informasi tentang bagaimana Anda berinteraksi dengan Layanan Kami serta fungsinya. Cookie ini berinteraksi dengan perangkat atau komputer Anda dengan cara yang tidak secara langsung mengidentifikasi Anda karena hanya akan menghasilkan data-data yang agregat.',
  info47:
    'Cookie pihak ketiga. Cookie jenis ini, dalam konteks ini, termasuk mesin pencari, pemasok layanan analisis, media sosial, dan perusahaan periklanan.',
  info48:
    'Harap Anda untuk meninjau file "Bantuan" browser web Anda guna mempelajari cara tepat untuk mengubah pengaturan cookie Anda. Harap dicatat bahwa jika Anda menghapus, atau memilih untuk tidak menerima cookie dari Layanan Kami maka Anda mungkin tidak akan bisa memanfaatkan fitur-fitur Layanan Kami untuk mendapatkan potensi sepenuhnya. Kami juga dapat menggunakan cookie dari pihak ketiga pada Layanan Kami. Kami tidak memproses atau menanggapi sinyal "jangan lacak" browser web atau transmisi serupa lainnya yang menunjukkan permintaan untuk menonaktifkan pelacakan online dari pengguna yang mengunjungi Layanan Kami atau yang menggunakan Layanan Kami.',
  info49:
    'Ketika Anda menggunakan Layanan Kami maka Kami dapat mengirimkan satu atau lebih cookie - yaitu file teks kecil yang berisikan serangkaian karakter alfanumerik - ke perangkat Anda. Kami dapat menggunakan cookie sesi dan cookie tetap. Cookie sesi akan menghilang setelah Anda menutup browser Anda. Cookie tetap akan tetap ada setelah Anda menutup browser Anda dan dapat digunakan oleh browser Anda pada kunjungan berikutnya ke Layanan Kami.',
  info50:
    'Kami secara otomatis juga dapat merekam informasi-informasi tertentu dari perangkat Anda dengan menggunakan berbagai jenis teknologi, termasuk "clear gifs" atau "web beacons". Informasi yang telah dikumpulkan secara otomatis ini mungkin akan termasuk alamat IP atau alamat perangkat atau ID Anda, browser web dan/atau jenis perangkat, halaman web atau situs yang Anda kunjungi sebelum atau tepat setelah Anda menggunakan Layanan, halaman atau konten lain yang Anda lihat atau jika tidak berinteraksi dengan Layanan Kami, dan tanggal serta waktu yang Anda kunjungi, akses, atau menggunakan Layanan. Kami juga dapat menggunakan teknologi ini untuk mengumpulkan informasi-informasi perihal interaksi Anda dengan pesan email, seperti apakah Anda membuka, mengklik, atau meneruskan sebuah pesan. Informasi ini dikumpulkan dari semua pengguna, dan mungkin akan terhubung dengan informasi lain tentang Anda.',
  info51:
    'Kami dapat melakukan penelitian perihal demografi, minat, dan perilaku pelanggan Kami berdasarkan informasi pribadi serta informasi lainnya yang diberikan kepada Kami, yang dapat dikompilasi dan dianalisis secara menyeluruh dan dibagi dengan afiliasi, agen, dan mitra bisnis Kami. Kami akan memproses seluruh informasi tersebut dengan tidak secara langsung mengidentifikasi Anda karena ini hanya akan menghasilkan keseluruhan data.',
  info52:
    'Selain seperti yang sudah Kami jelaskan dalam Ketentuan Layanan ini, Kami tidak akan mengungkapkan informasi pribadi Anda yang sudah Kami kumpulkan di Layanan kepada pihak ketiga tanpa adanya persetujuan dari Anda. Kami hanya akan membagikan informasi pribadi Anda dengan cara yang dijelaskan di bawah ini kepada penyedia Layanan atau prosesor pihak ketiga yang relevan.',
  info53:
    'Kami secara otomatis juga dapat merekam informasi-informasi tertentu dari perangkat Anda dengan menggunakan berbagai jenis teknologi, termasuk "clear gifs" atau "web beacons". Informasi yang telah dikumpulkan secara otomatis ini mungkin akan termasuk alamat IP atau alamat perangkat atau ID Anda, browser web dan/atau jenis perangkat, halaman web atau situs yang Anda kunjungi sebelum atau tepat setelah Anda menggunakan Layanan, halaman atau konten lain yang Anda lihat atau jika tidak berinteraksi dengan Layanan Kami, dan tanggal serta waktu yang Anda kunjungi, akses, atau menggunakan Layanan. Kami juga dapat menggunakan teknologi ini untuk mengumpulkan informasi-informasi perihal interaksi Anda dengan pesan email, seperti apakah Anda membuka, mengklik, atau meneruskan sebuah pesan. Informasi ini dikumpulkan dari semua pengguna, dan mungkin akan terhubung dengan informasi lain tentang Anda.',
  info54:
    'Kami mengerti dan menghormati arti pentingnya menjaga informasi pribadi Anda agar tetap aman dan rahasia. Yakinlah bahwa Kami telah menerapkan pengamanan-pengamanan yang sesuai (mis. persetujuan pemrosesan dan kebijakan prosedur operasi standar) untuk memastikan bahwa informasi pribadi Anda telah dilindungi secara memadai.',
  info55:
    'Selain seperti yang dijelaskan di bawah ini, Anda akan menerima permintaan persetujuan dari Kami ketika Kami membagikan informasi pribadi Anda dengan pihak ketiga, dimana Anda memiliki pilihan untuk menunjukkan apakah Anda setuju jika Kami ingin melakukannya atau tidak.',
  info56: 'Berikut ini adalah kondisi-kondisi dimana Kami berbagi informasi pribadi Anda:',
  info57:
    'Kami berbagi informasi pribadi Anda dengan pihak ketiga, termasuk lembaga keuangan yang melakukan proses pencairan dana atau host web pihak ketiga, yang memfasilitasi penyampaian Layanan. Pihak ketiga ini dapat memiliki akses ke atau memproses informasi tentang Anda sebagai bagian dari penyediaan Layanan tersebut untuk Kami. Secara umum, Kami akan membatasi informasi yang diberikan kepada penyedia Layanan ini secara wajar sesuai yang diperlukan bagi mereka untuk menjalankan fungsinya, dan Kami meminta mereka agar setuju untuk menjaga kerahasiaan informasi tersebut. Pihak ketiga tersebut harus setuju untuk menggunakan data pribadi Anda hanya untuk tujuan yang digunakan oleh Kami dan mereka harus:',
  info58:
    'mematuhi prinsip-prinsip privasi atau pun mekanisme-mekanisme lainnya yang diizinkan oleh undang-undang yang berlaku untuk mentransfer dan memproses Data Pribadi; atau',
  info59:
    'setuju untuk memberikan perlindungan data pribadi yang memadai yang tidak kalah protektifnya dari yang telah ditetapkan dalam Ketentuan Layanan ini;',
  info60:
    'Cookie pihak ketiga. Cookie jenis ini, dalam konteks ini, termasuk mesin pencari, pemasok layanan analisis, media sosial, dan perusahaan periklanan.',
  info61: 'kepatuhan dengan berbagai kewajiban pelaporan;',
  info62: 'untuk tujuan bisnis atau pemasaran; atau',
  info63:
    'untuk membantu pihak-pihak tersebut dalam memahami minat, kebiasaan, dan pola penggunaan para pengguna Kami untuk program, konten, Layanan, iklan, promosi, dan/atau fungsi tertentu yang tersedia melalui Layanan Kami.',
  info64:
    'Anda memiliki pilihan untuk memberikan putusan mengenai pengumpulan, penggunaan, dan kegiatan berbagi informasi pribadi Anda. Harap diperhatikan bahwa jika Anda memutuskan untuk tidak memberikan Kami informasi pribadi yang Kami minta, maka Anda tidak dapat mengakses semua fitur dari Layanan Kami.',
  info65:
    'Jika Anda ingin mengakses atau mengubah informasi pribadi apa pun yang Anda berikan kepada Kami, Anda dapat melakukannya melalui Layanan Kami atau melalui Layanan Pihak Ketiga, atau Anda dapat menghubungi Kami di info@gajigesa.com. Pada email permintaan Anda, jangan lupa untuk menulis "informasi pribadi" pada subjek atau badan email. Harap dicatat bahwa meskipun setiap perubahan yang Anda buat akan tercerminkan di dalam database aktif Kami dalam periode waktu yang wajar, Kami dapat menyimpan semua informasi yang sudah Anda kirimkan untuk cadangan, pengarsipan, pencegahan penipuan dan penyalahgunaan, analitik, kepatuhan kewajiban hukum, atau dimana Kami sebaliknya cukup percaya bahwa Kami memiliki alasan yang sah untuk melakukannya.',
  info66:
    'Harap diperhatikan bahwa dengan menyetujui Ketentuan Layanan ini maka berarti Anda juga setuju untuk menerima email pemasaran dari Kami, seperti yang telah Kami jelaskan dalam Bagian 2 pada huruf (e) dokumen ini.',
  info67:
    'Kapan pun Anda dapat berhenti berlangganan dengan mengikuti instruksi yang ada di dalam email. Anda juga dapat menghentikan persetujuan yang Anda berikan kepada Kami untuk mengirimkan email pemasaran kepada Anda, dengan mengirimkan permintaan persetujuan Anda kepada Kami melalui email di info@gajigesa.com. Dalam email permintaan persetujuan tersebut, Anda jangan lupa untuk menulis "berhenti berlangganan" pada subjek atau isi email atau korespondensi.Kapan pun Anda dapat berhenti berlangganan dengan mengikuti instruksi yang ada di dalam email. Anda juga dapat menghentikan persetujuan yang Anda berikan kepada Kami untuk mengirimkan email pemasaran kepada Anda, dengan mengirimkan permintaan persetujuan Anda kepada Kami melalui email di info@gajigesa.com. Dalam email permintaan persetujuan tersebut, Anda jangan lupa untuk menulis "berhenti berlangganan" pada subjek atau isi email atau korespondensi.',
  info68:
    'Perlu diketahui bahwa jika Anda ingin menarik persetujuan Anda dalam menerima email pemasaran dari Kami atau memodifikasi sifat atau frekuensi komunikasi promosi yang Anda terima dari Kami, ini mungkin akan memerlukan waktu hingga sepuluh (10) hari kerja bagi Kami untuk memproses permintaan Anda tersebut, dan selama periode menunggu itu Anda mungkin akan terus menerima komunikasi promosi dari Kami. Setelah Anda memilih berhenti menerima pesan pemasaran dari Kami maka Anda akan terus menerima pesan administratif dari Kami mengenai Layanan Kami.',
  info69:
    'Kami menggunakan upaya yang wajar dalam melakukan pengamanan fisik, teknis, dan administratif guna melindungi informasi-informasi pribadi Anda. Informasi-informasi pribadi juga dapat disimpan di perangkat yang Anda gunakan untuk mengakses Layanan.',
  info70:
    'Ketika Anda memberikan informasi pribadi Anda kepada Kami, informasi Anda akan dilindungi secara online dan offline. Dimana pun Kami mengumpulkan informasi pribadi Anda, informasi itu akan dienkripsi dan dikirimkan kepada Kami dengan cara yang aman. Meskipun Kami menggunakan enkripsi untuk melindungi informasi pribadi yang dikirimkan secara online, Kami juga melindungi informasi Anda secara offline. Kami menyimpan informasi pribadi Anda menggunakan teknologi enkripsi yang aman dan di komputer/server yang disimpan dalam lingkungan yang aman.',
  info71:
    'Apabila informasi pribadi diperoleh sebagai akibat dari pelanggaran keamanan atau tindakan ilegal lainnya, maka Kami akan segera memberitahu orang-orang yang mana informasi pribadinya telah dikompromikan sesuai dengan prosedur pemberitahuan yang ditetapkan di dalam Ketentuan Layanan ini atau hukum lainnya yang berlaku.',
  info72:
    'Kami tidak bertanggung jawab atas akibat dari pelanggaran keamanan atau tindakan ilegal tersebut, termasuk namun tidak terbatas pada pengungkapan secara sengaja maupun tidak sengaja atas nama akun, nama sandi atau informasi penting lainnya kepada pihak lain yang bukan merupakan pengguna GajiGesa.',
  info73:
    'Namun demikian kami dapat mengambil tindakan yang wajar untuk mengambil tindakan hukum yang diperlukan atau bekerja sama dengan Anda untuk melaporkan pelanggar keamanan atau tindakan ilegal tersebut kepada pejabat pemerintah yang berwenang.',
  info74:
    'Dalam hal terjadi pelanggaran keamanan, atau pelanggaran Ketentuan Layanan ini oleh Anda, Anda setuju untuk membela, mengganti rugi dan membebaskan GajiGesa dan para pejabat, direktur, komisaris, pemegang saham dan karyawannya, dari dan terhadap semua klaim, gugatan, tuntutan, kerugian, pengeluaran atau tindakan hukum lainnya, termasuk namun tidak terbatas pada biaya pengacara, baik seluruhnya atau sebagian, yang timbul dari dan/atau sehubungan dengan pelanggaran keamanan, atau pelanggaran Ketentuan Layanan oleh Anda tersebut.',
  info75:
    'Kami tidak bertanggung jawab untuk setiap klaim, gugatan, tuntutan, kerugian, pengeluaran atau tindakan hukum lainnya yang timbul atau sehubungan dengan kesalahan atau kelalaian pemberi kerja dalam melakukan perhitungan gaji Anda.',
  info76:
    'Kami hanya akan menyimpan informasi-informasi pribadi Anda yang dikumpulkan untuk jangka waktu terbatas selama Kami membutuhkannya guna memenuhi tujuan yang Kami jelaskan sebelumnya, kecuali diwajibkan oleh hukum.',
  info77:
    'Semua data yang ada di dalam Layanan Kami akan disimpan selama lima (5) tahun, atau periode lain yang lebih lama sebagaimana yang dipersyaratkan oleh hukum dan peraturan-peraturan yang berlaku. Pelanggan dapat meminta penghapusan data pribadi mereka, tetapi informasi transaksi tertentu harus tetap disimpan untuk tujuan hukum. Setelah lima (5) tahun, informasi pribadi pelanggan akan dihapus namun informasi-informasi transaksional non-spesifik tetap akan dipertahankan.',
  info78:
    'Apabila Anda ingin bertanya tentang untuk apa, jika ada, informasi-informasi pribadi Anda yang Kami simpan atau meminta penghapusan informasi pribadi apa pun yang telah Anda kirimkan kepada Kami, silakan menghubungi Kami/mengirimkan email di info@gajigesa.com. Dalam email permintaan Anda, jangan lupa untuk menulis "informasi pribadi" pada subjek atau badan email tersebut.',
  info79:
    'Merupakan kebijakan Kami untuk memberikan pemberitahuan (notifikasi), apakah pemberitahuan tersebut memang diharuskan oleh undang-undang atau untuk tujuan-tujuan pemasaran atau terkait dengan bisnis lainnya, kepada Anda melalui:',
  info80: 'pemberitahuan email,',
  info81: 'pemberitahuan tertulis atau hard copy, atau',
  info82:
    'melalui tampilan yang mencolok dari pemberitahuan tersebut di Layanan Kami, sebagaimana ditentukan oleh Kami atas kebijakan Kami sendiri.',
  info83:
    'Kami memiliki hak untuk menentukan bentuk dan cara dalam memberikan pemberitahuan kepada Anda, sejauh diizinkan oleh hukum dan peraturan-peraturan yang berlaku.',
  info84:
    'Berkenaan dengan pemberitahuan atau notifikasi tersebut, dalam hal terjadi pelanggaran keamanan, maka dengan ini Anda setuju bahwa Kami dapat mengirimkan pemberitahuan kepada Anda secara elektronik. Kami akan mengirimkan pemberitahuan tersebut dengan email yang terdaftar/teregistrasi atau dengan cara elektronik lainnya yang akan memastikan bahwa Anda akan menerima pemberitahuan tersebut.',
  info85:
    'Kami memiliki hak untuk mengubah Ketentuan Layanan kapan saja dan dengan alasan apa pun. Kami akan memperbarui dan memposting Ketentuan Layanan yang telah diubah pada Layanan Kami. Perubahan pada Ketentuan Layanan ini akan efektif ketika diposting pada halaman ini. Ketika Kami mengubah kebijakan secara material maka Kami akan memberitahukan Anda, antara lain, dengan cara memperbarui "Tanggal Berlaku" pada bagian atas dari halaman ini. Kami merekomendasi Anda untuk membaca dengan teliti Ketentuan Layanan ini dari waktu ke waktu',
  info86:
    'Apabila Anda memiliki pertanyaan atau komentar tentang Ketentuan Layanan ini, silakan menghubungi Kami melalui email di info@gajigesa.com dan jangan lupa untuk menulis "Ketentuan Layanan" pada subjek atau badan email tersebut.',
  info87:
    'Ketentuan Layanan ini diatur dan ditafsirkan menurut hukum negara Republik Indonesia. Dalam hal terjadi sengketa atau permasalahan yang timbul sehubungan dengan Ketentuan Layanan ini, Anda dan Kami setuju untuk menyelesaikan sengketa atau permasalahan tersebut secara musyawarah dalam jangka waktu 30 (tiga puluh) hari kalender sejak sengketa atau permasalahan tersebut disampaikan kepada Kami. Dalam hal sengketa atau permasalahan tersebut tidak dapat diselesaikan dalam jangka waktu tersebut, maka Anda dan Kami setuju untuk menyelesaikan sengketa atau permasalahan tersebut secara akhir melalui Badan Arbitrase Nasional Indonesia (BANI) di Wahana Graha Lantai 1 dan 2, Jl. Mampang Prapatan No. 2, Jakarta, Indonesia, sesuai dengan peraturan arbitrase BANI. Majelis arbitrase akan terdiri dari tiga orang arbiter, satu dipilh oleh penggugat, satu dipilih oleh tergugat dan ketua arbiter dipilih oleh para arbiter yang ditunjuk oleh penggugat dan tergugat. Jika para arbiter penggugat dan tergugat gagal untuk menunjuk ketua arbiter dalam waktu 10 (sepuluh) hari kalender, ketua arbiter akan ditunjuk oleh Ketua BANI. Hasil putusan dari majelis arbitrase adalah bersifat final dan mengikat di antara Anda dan Kami.',
};

export const KETENTUAN_LAYANAN_TITLES = [
  '1. Penerimaan Ketentuan',
  '2. Ruang Lingkup Penerapan',
  '3. Informasi yang Kami Kumpulkan dan Bagaimana Kami Menggunakannya',
  '4. Biaya Administrasi',
  '5. Kebijakan Cookie Kami',
  '6. Keseluruhan Data',
  '7. Kebijakan Berbagi Data Kami',
  '8. Pilihan Anda Tentang Informasi Pribadi Anda',
  '9. Material Pemasaran',
  '10. Keamanan Informasi Pribadi Anda',
  '12. Ganti Rugi GajiGesa',
  '13. Penyimpanan Data',
  '14. Pemberitahuan atau Notifikasi',
  '15. Perubahan dan Pembaruan pada Kebijakan Privasi',
  '16. Hukum Yang Mengatur dan Penyelesaian Sengketa',
];

export const KETENTUAN_LAYANAN_LIST_1 = [
  'untuk tujuan pengidentifikasian serta untuk mengelola akun Anda, setiap kali Anda ingin Layanan tersediakan;',
  'kustomisasi pengalaman Anda, dengan mengidentifikasikan preferensi Anda sehingga Kami dapat menawarkan pengalaman yang dipersonalisasikan (seperti bahasa), merekomendasikan fungsi-fungsi tertentu dari Layanan, produk, peLayanan, dan/atau minat Kami; yang mungkin akan menarik bagi Anda;',
  'pengoperasian, pemeliharaan, peningkatan dan penyediaan semua fitur Layanan, untuk menyediakan Layanan dan informasi yang Anda minta, untuk menanggapi komentar dan pertanyaan-pertanyaan dan dengan cara lain memberikan dukungan kepada para pengguna;',
  'analisa tren penggunaan dan preferensi para pengguna Kami, peningkatan Layanan, pengembangan produk-produk baru, Layanan, fitur, fungsionalitas, kinerja, dan partisipasi dalam studi dan pengembangan material pemasaran;',
  'menghubungi Anda untuk tujuan-tujuan administratif seperti Layanan pelanggan, atau untuk menyampaikan komunikasi, termasuk update promosi dan acara, yang berkaitan dengan produk dan Layanan yang ditawarkan oleh Kami dan oleh pihak ketiga;',
  'menyediakan iklan produk dan/atau Layanan Kami yang dipersonalisasi, serta iklan yang disediakan oleh penyedia Layanan dari pihak ketiga yang relevan; yang Kami yakini mungkin akan menarik bagi Anda dan preferensi; dan/atau',
  'membagikan informasi pribadi Anda dengan prosesor-prosesor yang relevan dan pihak ketiga yang seperlunya untuk Kami memberikan Layanan Kami kepada Anda.',
];

export const KETENTUAN_LAYANAN_LIST_2 = [
  'nama depan;',
  'alamat email;',
  'alamat pengiriman;',
  'nomor telepon;',
  'nomor identifikasi karyawan;',
  'detail pekerjaan;',
  'tanggal lahir;',
  'lokasi geografis; dan/atau',
  'informasi relevan lainnya terkait dengan Layanan Kami.',
];

export const KETENTUAN_LAYANAN_LIST_3 = [
  'Alamat Internet Protocol (IP) yang dengannya komputer Anda atau perangkat lainnya terhubung ke internet untuk mengakses Layanan Kami;',
  'nama pengguna, alamat e-mail, serta kata sandi akun Anda;',
  'informasi teknis tentang komputer, perangkat, dan koneksi internet Anda, misalnya, jenis dan versi browser yang Anda gunakan, plug-in, sistem operasi (OS), atau pengaturan-pengaturan terkait lainnya;',
  'lokasi komputer atau perangkat yang Anda gunakan untuk mengakses Layanan Kami;',
  'informasi penggunaan lainnya tentang penggunaan Layanan, termasuk riwayat halaman yang Anda lihat.',
];

export const KETENTUAN_LAYANAN_LIST_4 = [
  'personalisasi Layanan Kami, seperti mengingat informasi-informasi tentang Anda sehingga Anda tidak perlu mengisikannya kembali selama kunjungan Anda atau di waktu berikutnya ketika Anda menggunakan Layanan;',
  'menyediakan iklan, konten, dan informasi-informasi khusus;',
  'memonitor dan menganalisa efektivitas Layanan dan kegiatan-kegiatan pemasaran dari pihak ketiga;',
  'memonitor metrik penggunaan situs agregat seperti jumlah total pengunjung serta halaman yang dilihat; dan',
  'melacak entri, submisi, dan status Anda dalam promosi atau kegiatan lain apa pun pada Layanan.',
];

export const KETENTUAN_LAYANAN_LIST_5 = [
  'Cookie yang diperlukan. Cookie ini memungkinkan fungsionalitas inti dari Layanan, seperti keamanan, manajemen jaringan, dan aksesibilitas.',
  'Cookie analitik. Cookie ini memungkinkan Kami untuk meningkatkan Layanan Kami dengan cara mengumpulkan dan melaporkan informasi-informasi tentang bagaimana Anda berinteraksi dengan Layanan Kami serta fungsinya. Cookie ini berinteraksi dengan perangkat atau komputer Anda dengan cara yang tidak secara langsung mengidentifikasi Anda karena hanya akan menghasilkan data-data yang agregat.',
  'Cookie pihak ketiga. Cookie jenis ini, dalam konteks ini, termasuk mesin pencari, pemasok layanan analisis, media sosial, dan perusahaan periklanan.',
];

export const TRANSACTION_HISTORY = {
  DECREASE: 'DECREASE',
  INCREASE: 'INCREASE',
};

export const SAVINGS_TOUR_TYPES = {
  TYPE1: 'TYPE1',
  TYPE2: 'TYPE2',
  TYPE3: 'TYPE3',
  TYPE4: 'TYPE4',
  TYPE5: 'TYPE5',
  TYPE6: 'TYPE6',
  TYPE7: 'TYPE7',
};

export const TERMS_N_CONDITIONS = [
  `Program ini hanya berlaku untuk sesama karyawan yang bekerja dalam satu kantor/perusahaan.`,
  `Program ini hanya berlaku untuk transaksi menggunakan aplikasi GajiGesa.`,
  `Pemberi kode referral akan mendapatkan insentif berupa GajiGesa poin besar 25.000 per teman yang pertama kali bertransaksi di GajiGesa memakai kode referralmu.`,
  `Kamu bisa mengundang teman sebanyak yang kamu mau! Maks. GajiGesa poin yang bisa kamu kumpulkan adalah 150.000.`,
  `Pengguna kode referral juga akan mendapatkan insentif berupa GajiGesa poin besar 25.000 setelah sukses bertransaksi (tanpa minimum transaksi) pertama kali dengan menggunakan kode referral dari kamu. Transaksi ini termasuk penarikan gaji, pembayaran tagihan, dan pengisian ulang saldo.`,
  `Baca syarat dan ketentuan selengkapnya disini `,
];

export const TOOLTIP_CONTENT = {
  balance: 'Uang Kamu adalah Total Pendapatan - Total Pengeluaran',
  totalAmount: 'Total Pendapatan adalah Gaji Pro Rata + Tunjangan, Bonus, dan Komisi.',
  referral:
    'Masukkan kode referral dari temanmu sekantor dan dapatkan untung GajiGesa Poin setelah transaksi pertamamu',
  reward: 'Kode referral berhasil dipakai',
  rewardFailed: 'Kode referral gagal dipakai. Transaksi ini tidak mendapatkan hadiah poin.',
};

export const TERMS = {
  first: 'Apabila ada, saya bersedia untuk membayar biaya yang muncul atas penggunaan layanan GajiGesa.',
  second:
    'Saya menyetujui biaya layanan GajiGesa akan dikurangi secara langsung dari jumlah dana yang saya ajukan dan saya akan menerima dana di rekening saya sejumlah yang telah dikurangi biaya layanan yang muncul.',
  third:
    'Saya menyetujui gaji yang akan saya terima dari perusahaan pada tanggal gajian akan dipotong sejumlah total transaksi yang saya lakukan melalui layanan GajiGesa.',
};

export const LIST_TYPES = {
  SIMPLE_LIST: 'SIMPLE_LIST',
  COLUMN_LIST: 'COLUMN_LIST',
  MULTICOLUMN_LIST: 'MULTICOLUMN_LIST',
  NO_LIST: 'NO_LIST', // Does not have any of the list mentioned above
  NO_COMPONENT: 'NO_COMPONENT', // Does not render any react component
};

export const CATEGORY = {
  10: {
    listName: LIST_TYPES.COLUMN_LIST, // Name of the list used by a category
    id: 10, // Id of the category
    name: 'Pulsa', //Name of the category
    withBlueBg: true, // Defines if the parent component should have a blue background
    showAccount: true, // Defines if the Account Card which has Name and PhoneNumber info is needed
    withTabs: false, // Defines if the Parent component should have tabs
    hasConfirmButton: false, // Defines if there's a confirm button
    modalNominals: false, // Defined if the nominals are shown in bottomSheet modal except for Confirm Button
    info: ['Nomor Ponsel', 'Product'], // Number of rows and their title, "Product" means it has dynamic name
    detail: ['Harga Voucher'], // Number of rows and thier title in details
  },
  13: {
    listName: LIST_TYPES.MULTICOLUMN_LIST,
    id: 13,
    name: 'Voucher',
    withBlueBg: false,
    showAccount: false,
    withTabs: false,
    hasConfirmButton: true,
    modalNominals: true,
    showTitle: true,
    info: ['Product'],
    detail: [],
  },
  16: {
    listName: LIST_TYPES.SIMPLE_LIST,
    id: 16,
    name: 'Asuransi',
    withBlueBg: false,
    showAccount: false,
    withTabs: false,
    hasConfirmButton: false,
    modalNominals: false,
    hasImage: true,
    info: [],
    detail: [],
  },
  15: {
    listName: LIST_TYPES.MULTICOLUMN_LIST,
    id: 15,
    name: 'eMoney',
    withBlueBg: true,
    showAccount: true,
    withTabs: false,
    hasConfirmButton: true,
    modalNominals: true,
    showTitle: false,
    info: ['Nomor Ponsel', 'Product'],
    detail: [],
  },
  7: {
    listName: LIST_TYPES.SIMPLE_LIST,
    id: 7,
    name: 'Paket Data',
    withBlueBg: true,
    showAccount: true,
    withTabs: false,
    hasConfirmButton: false,
    modalNominals: true,
    hasImage: false,
    info: ['Nomor Ponsel', 'Product'],
    detail: ['Harga Voucher'],
  },
  5: {
    listName: LIST_TYPES.COLUMN_LIST,
    id: 5,
    name: 'Token PLN',
    withBlueBg: true,
    showAccount: false,
    withTabs: true,
    hasConfirmButton: true,
    modalNominals: false,
    info: ['ID Pelanggan', 'Nominal Token'],
    detail: ['Harga Token'],
  },
  6: {
    listName: LIST_TYPES.NO_COMPONENT,
    id: 6,
    name: 'PDAM',
    withBlueBg: true,
    showAccount: false,
    withTabs: true,
    hasConfirmButton: true,
    modalNominals: false,
    info: ['ID Pelanggan', 'Nama PDAM', 'Nama', 'Bulan/Tahun'],
    detail: ['Tagihan PLN', 'Biaya Admin'],
  },
  8: {
    listName: LIST_TYPES.NO_LIST,
    id: 8,
    name: 'Pascabayar',
    withBlueBg: true,
    showAccount: true,
    withTabs: false,
    hasConfirmButton: true,
    modalNominals: false,
    info: ['Nomor Ponsel', 'Tagihan'],
    detail: ['Biaya Admin'],
  },
  17: {
    listName: LIST_TYPES.NO_COMPONENT,
    id: 17,
    name: 'Tagihan PLN',
    withBlueBg: true,
    showAccount: false,
    withTabs: true,
    hasConfirmButton: true,
    modalNominals: false,
    info: ['ID Pelanggan', 'Nama', 'Bulan/Tahun'],
    detail: ['Tagihan PLN', 'Biaya Admin'],
  },
  18: {
    listName: LIST_TYPES.MULTICOLUMN_LIST,
    id: 18,
    name: 'Milvik',
    withBlueBg: true,
    showAccount: true,
    withTabs: false,
    hasConfirmButton: true,
    modalNominals: false,
    showTitle: false,
    info: ['Product'],
    detail: ['Harga Voucher'],
  },
  29: {
    listName: LIST_TYPES.MULTICOLUMN_LIST,
    id: 29,
    name: 'Zakat',
    withBlueBg: true,
    showAccount: true,
    withTabs: false,
    hasConfirmButton: true,
    modalNominals: false,
    showTitle: false,
    info: ['Pembayaran Zakat', 'Donatur'],
    detail: ['Nilai Zakat'],
  },
};

export const POSTPAID_VALIDATION_VALUE = {
  'BP-06': {
    value: 185000,
  },
  'BP-17': {
    value: 300000,
  },
  'BP-08': {
    value: 235000,
  },
};

export const BP_CATEGORY_NAMES = {
  ISI_ULANG: 'Isi Ulang',
  TAGIHAN: 'Tagihan',
  PROMO: 'Promo',
  KEBUTUHAN_SEHARI_HARI: 'Kebutuhan Sehari-hari',
};

export const FEATURE_FLAGS = {
  NEW_BILLPAYMENT: 'NEW_BP        ',
  REFERRAL: 'REFERRAL      ',
  APPTOUR: 'APPTOUR       ',
  DYNAMIC_ACCOUNTS_POINS: 'D_ACC_POINS   ',
  GAMIFICATION: 'GAMIFICATION  ',
  SAVINGS_HOME: 'SAVINGS_HOME  ',
  STORIES: 'STORIES       ',
  BP_HOMEPAGE: 'BP_HOMEPAGE   ',
  BP_POINTS: 'BP_POINTS     ',
  SAV_OFFER: 'SAV_OFFER     ',
  WD_TOUR: 'WD_TOUR       ',
  REFERRAL_TOUR: 'REFERRAL_TOUR ',
  PULSA_TOUR: 'PULSA_TOUR    ',
  POP_PERMISSION: 'POP_PERMISSION',
  LOAN: 'LOAN          ',
  MUL_BANK_ACC: 'MUL_BANK_ACC  ',
  PHONE_ENROLL: 'PHONE_ENROLL  ',
  BANK_SEL_TRNSF: 'BANK_SEL_TRNSF',
  ZAKAT: 'ZAKAT         ',
  EWA_MODAL: 'EWA_MODAL     ',
  SALARY_INFO: 'SALARY_INFO   ',
  SECURITY_PIN: 'SECURITY_PIN  ',
  CHAT_GPT: 'CHAT_GPT      ',
};

export const PWA_REFFERAL_LINK = 'https://gajigesa.com/gajigesa-referral';
export const LOAN_READ_LINK = 'https://gajigesa.com/e-Kasbon';

export const UPDATE_TYPE = {
  OPTIONAL: 'OPTIONAL',
  MANDATORY: 'MANDATORY',
  NO_UPDATE: 'NO_UPDATE',
};

export const BP_TOP_TAB_LABEL = {
  SALARY_ACCOUNT: 'Gaji Prorata',
  POINT: 'Poin',
};

export const STORIES_ROUTE = 'Stories/StoriesData.json';

export const TERMS_AND_CONDITIONS_ROUTE = 'TermsAndCondition/termsandCondition.json';

export const REWARD_INFO_ROUTE = 'RewardInfo/RewardInfo.json';

export const DYNAMIC_ACCOUNTS_TYPE = {
  EWA: 'EWA',
  POINTS: 'POINTS',
  GOLD: 'GOLD',
};

export const ERROR_CODES = {
  'GG-429': 'GG-429',
  'GG-500': 'GG-500',
  'GG-4291': 'GG-4291',
  'GG-4031': 'GG-4031',
  'GG-4032': 'GG-4032',
  'GG-4033': 'GG-4033',
  'GG-404': 'GG-404',
  'GG-409': 'GG-409',
  'GG-4091': 'GG-4091',
};

export const LOGIN_INFO_SHEET_KEYS = {
  'GG-429': 'LIMIT_EXCEED',
  'GG-500': 'OTHER_ERROR',
  'GG-4031': 'SUSPEND_REASON1',
  'GG-4032': 'SUSPEND_REASON2',
  'GG-4033': 'SUSPEND_REASON3',
  'GG-404': 'NOT_REGISTERED',
  'GG-401': 'EXPIRED_OTP',
  'GG-410': 'INVALID_OTP',
  'GG-4291': 'TOO_MANY_REQUEST_OTP',
  IN_PROCESS: 'IN_PROCESS',
  REGISTERED_USER: 'REGISTERED_USER',
  RESEND_OTP: 'RESEND_OTP',
  LOGOUT: 'LOGOUT',
};

export const BOTTONSHEET_KEYS = {
  BANK_DELETE: 'BANK_DELETE',
  MAX_LIMIT: 'MAX_LIMIT',
  DUPLICATE_ENTRY: 'DUPLICATE_ENTRY',
};

export const MESSAGGE_TYPE = {
  SMS: 'SMS',
  WHATSAPP: 'WHATSAPP',
};

export const CHECK_DATA_DROPDOWN_LIST = [
  {
    text: 'ID Karyawan',
    placeHolder: 'Masukkan ID Karyawan',
    isSelected: false,
    identityType: 'employee-id',
  },
  {
    text: 'KTP (Kartu Tanda Penduduk)',
    placeHolder: 'Masukkan Nomor KTP Anda',
    isSelected: false,
    identityType: 'national-id',
  },
];

export const MISSION_STATUS = {
  COMPLETED: 'COMPLETED',
  ON_GOING: 'IN_PROGRESS',
};

export const MISSION_GOAL_TYPE = {
  TOTAL_COUNT: 'TOTAL_COUNT',
  TOTAL_AMOUNT: 'TOTAL_AMOUNT',
};

export const TIER_STATUS_TYPE = {
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN_PROGRESS',
  DISABLED: 'DISABLED',
};

export const WELCOME_DATA = [
  {
    srNo: 0,
    cardColor: colors.secondary_orchid,
    textContext: 'Tarik gajimu lebih awal kapan dan di mana pun. \nTanpa bunga dan, pastinya, bukan pinjaman',
    circleGradient: ['rgba(44, 190, 150, 0.9)', 'rgba(44, 190, 150, 0.2)', 'rgba(44, 190, 150 , 0)'],
  },
  {
    srNo: 1,
    cardColor: colors.primary_carnation,
    textContext: 'Bayar tagihanmu lebih mudah dan \ntop-up saldo pulsa atau e-wallet lebih praktis',
    circleGradient: ['rgba(248, 178, 79, 0.9)', 'rgba(248, 178, 79, 0.2)', 'rgba(248, 178, 79, 0)'],
  },
  {
    srNo: 2,
    cardColor: colors.variants_clover,
    textContext: 'Bikin transaksi kebutuhan harianmu \nmakin hemat dengan tukar hadiah poin!',
    circleGradient: ['rgba(49, 91, 231, 0.5)', 'rgba(49, 91, 231, 0.2)', 'rgba(49, 91, 231, 0)'],
  },
];

export const MILVIK_CONST = [
  {
    title: 'Apa itu Milvik?',
    data: [
      {
        info: [
          'Milvik adalah telekonsultasi kesehatan terjangkau, tanpa batas selama 24 jam untuk keluarga. Dengan membeli voucher seharga Rp15.000-, para pengguna bisa langsung berkonsultasi dengan dokter secara online melalui WhatsApp.',
        ],
        subTitle: '',
        bulleted: false,
      },
    ],
  },

  {
    title: 'Cara Reedem Voucher',
    data: [
      {
        subTitle: 'Cara pertama:',
        info: [
          'Jika pembelian voucher sudah berhasil. Pilih Lihat Voucher Saya',
          'Salin Kode Voucher Telekonsultasi. Lalu, pilih WhatsApp Chat',
          'Pengguna akan dialihkan ke laman WhatsApp Milvik, lalu pilih Konsultasi Dokter (Pengguna Voucher Milvik Dokter)',
        ],
        bulleted: true,
      },
      {
        subTitle: 'Cara kedua:',
        info: [
          'Buka aplikasi GajiGesa > Fitur Pembayaran',
          'Pilih Telekonsultasi di bagian Kesejahteraan',
          'Pada halaman Voucher Saya, salin Kode Voucher',
          'Pilih Konsultasi',
          'Pengguna akan dialihkan ke laman WhatsApp Milvik, lalu pilih Konsultasi Dokter (Pengguna Voucher Milvik Dokter)',
        ],
        bulleted: true,
      },
    ],
  },
  {
    title: 'Yang Kamu Dapatkan',
    data: [
      {
        subTitle: '',
        info: ['1x Telekonsultasi chat 24/7 dengan dokter umum.'],
        bulleted: false,
      },
    ],
  },
  {
    title: 'Syarat dan Ketentuan',
    data: [
      {
        subTitle: '',
        info: [
          'Transaksi pembelian voucher konsultasi dokter online dengan Milvik hanya bisa dilakukan melalui aplikasi GajiGesa.',
          'Harga voucher adalah senilai Rp15.000. Pembelian bisa dilakukan dengan gaji prorata pada EWA atau GajiGesa Poin.',
          'Sebelum melakukan konsultasi, voucher harus ditukar terlebih dahulu via layanan WhatsApp Milvik.',
          'Konsultasi dokter online akan dilakukan via WhatsApp.',
          'Voucher hanya bisa ditukar apabila kode masih valid dan belum pernah digunakan.',
          'Voucher konsultasi dokter online dengan Milvik hanya berlaku selama 3 bulan setelah pembelian.',
          'Penggunaan voucher Milvik harus mengikuti syarat dan ketentuan dalam aplikasi GajiGesa.',
          'Voucher yang sudah kamu beli tak bisa dikembalikan.',
          'Komentar, pertanyaan, serta keluhan mengenai konsultasi dokter online dengan Milvik dan/atau Syarat dan Ketentuan ini, ditujukan ke Layanan Customer Support GajiGesa.',
          'GajiGesa berhak mengubah syarat dan ketentuan yang berlaku tanpa pemberitahuan terlebih dulu.',
          'Dengan membeli voucher Milvik, kamu dianggap telah setuju dan membaca semua syarat dan ketentuan berlaku.',
        ],
        bulleted: true,
      },
    ],
  },
];

export const VOUCHER_INV_STATUS = {
  AVAILABLE: 'AVAILABLE',
  PURCHASED: 'PURCHASED',
  REDEEMED: 'REDEEMED',
  DELETED: 'DELETED',
  EXPIRED: 'EXPIRED',
};

export const VOUCHER_INV_ASSETS = {
  [VOUCHER_INV_STATUS.AVAILABLE]: {
    voucherColor: colors.secondary_orchid,
    ctaText: 'Salin',
    leftCircleStyle: { borderColor: colors.secondary_orchid },
  },
  [VOUCHER_INV_STATUS.PURCHASED]: {
    voucherColor: colors.secondary_orchid,
    ctaText: 'Salin',
    leftCircleStyle: { borderColor: colors.secondary_orchid },
  },
  [VOUCHER_INV_STATUS.REDEEMED]: {
    voucherColor: colors.neutral_darkMist,
    ctaText: 'Terpakai',
    leftCircleStyle: { borderColor: colors.neutral_darkMist },
  },
  [VOUCHER_INV_STATUS.DELETED]: {
    voucherColor: colors.secondary_orchid,
    ctaText: 'Salin',
    leftCircleStyle: { borderColor: colors.secondary_orchid },
  },
  [VOUCHER_INV_STATUS.EXPIRED]: {
    voucherColor: colors.secondary_orchid,
    ctaText: 'Salin',
    leftCircleStyle: { borderColor: colors.secondary_orchid },
  },
};

export const OTHER_ACCOUNT_NAMES = {
  SAVINGS: 'SAVINGS',
};

export const DYNAMIC_ACCOUNT_NAMES = {
  SALARY_ACCOUNT: 'SALARY_ACCOUNT', // This is not a dynamic account in our system but is a core account
  SDACCOUNT: 'SALARY_DISBURSEMENT',
  POINTS: 'POINTS',
  WORK_ALLOWANCE: 'UANG_KANTOR',
  UANG_KAS: 'UANG_KAS',
};

export const TRANSACTION_LIST_DYNAMIC_ACCOUNTS = [
  DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT,
  DYNAMIC_ACCOUNT_NAMES.POINTS,
  DYNAMIC_ACCOUNT_NAMES.SDACCOUNT,
  DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE,
  DYNAMIC_ACCOUNT_NAMES.UANG_KAS,
];
export const HOMEPAGE_DYNAMIC_ACCOUNTS = [
  DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT,
  DYNAMIC_ACCOUNT_NAMES.SDACCOUNT,
  DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE,
  DYNAMIC_ACCOUNT_NAMES.UANG_KAS,
];

export const BP_DYNAMIC_ACCOUNTS = [
  DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT,
  DYNAMIC_ACCOUNT_NAMES.POINTS,
  DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE,
  DYNAMIC_ACCOUNT_NAMES.UANG_KAS,
];

export const TRANSACTION_SCREEN_ACCOUNT_NAME = {
  'Salary Disbursement': 'Pencairan Gaji',
  POINTS: 'Poin',
  GajiGesa: 'GajiGesa',
  'Uang Kantor': 'UANG_KANTOR',
};

export const TRANSACTION_TYPE = {
  EXPENSE: 'EXPENSE',
  INCOME: 'INCOME',
};

export const TRANSACTION_DETAILS_KEYS = {
  transRequestedAmount: 'Jumlah Penarikan',
  transFee: 'Biaya Admin',
  transReceivedAmount: 'Jumlah Yang Diterima',
  transAmount: 'Nominal',
  transDate: 'Tanggal',
  moneyAccount: 'Kantong Dana',
  displayName: 'Transaksi',
  transStatus: 'transStatus',
  referenceNumber: 'No. Referensi',
  otherMobileNumber: 'Nomor HP',
  otherOwnerName: 'Nama',
  bankName: 'Nama Bank Penerima',
  bankAccountNumber: 'Nomor Rekening Penerima',
  beneficiaryName: 'Nama Pemilik Rekening',
  donatorCount: 'Jumlah Donatur',
  donatorName: 'Donator',
  email: 'Email',
  totalPembayaran: 'Total Pembayaran',
};

/* 
[Account Name]:{
  [reasonId/default(if not avail)]:{
    [detailsKey (key which is recieved from BE on transactionList api)]:{
      name:'Name of the key you want to show on the UI',
      priority:'To decide which details comes first',
      isAmount:'Decides if the key is an amount',
      isDate:'Decides if the key is a Date'
    }
  }
}
*/

const ggTransactionConfig = {
  default: {
    displayName: {
      name: TRANSACTION_DETAILS_KEYS.displayName,
      priority: 1,
    },
    bankName: {
      name: TRANSACTION_DETAILS_KEYS.bankName,
      priority: 2,
    },
    bankAccountNumber: {
      name: TRANSACTION_DETAILS_KEYS.bankAccountNumber,
      priority: 3,
    },
    beneficiaryName: {
      name: TRANSACTION_DETAILS_KEYS.beneficiaryName,
      priority: 4,
    },
    transRequestedAmount: {
      name: TRANSACTION_DETAILS_KEYS.transRequestedAmount,
      priority: 5,
      isAmount: true,
    },
    transFee: {
      name: TRANSACTION_DETAILS_KEYS.transFee,
      priority: 6,
      isAmount: true,
    },
    transReceivedAmount: {
      name: TRANSACTION_DETAILS_KEYS.transReceivedAmount,
      priority: 7,
      isAmount: true,
    },
    transDate: {
      name: TRANSACTION_DETAILS_KEYS.transDate,
      priority: 10,
      isDate: true,
    },
    otherMobileNumber: {
      name: TRANSACTION_DETAILS_KEYS.otherMobileNumber,
      priority: 8,
      isAmount: false,
    },
    otherOwnerName: {
      name: TRANSACTION_DETAILS_KEYS.otherOwnerName,
      priority: 9,
      isAmount: false,
    },
    moneyAccount: {
      name: TRANSACTION_DETAILS_KEYS.moneyAccount,
      priority: 11,
    },
  },
  34: {
    // Comission
    transAmount: {
      name: TRANSACTION_DETAILS_KEYS.transAmount,
      priority: 1,
      isAmount: true,
    },
    transDate: {
      name: TRANSACTION_DETAILS_KEYS.transDate,
      priority: 2,
      isDate: true,
    },
    moneyAccount: {
      name: TRANSACTION_DETAILS_KEYS.moneyAccount,
      priority: 3,
    },
  },
  19: {
    displayName: {
      name: TRANSACTION_DETAILS_KEYS.displayName,
      priority: 1,
    },
    donatorCount: {
      name: TRANSACTION_DETAILS_KEYS.donatorCount,
      priority: 2,
      isDonatorCount: true,
    },

    donatorName: {
      name: TRANSACTION_DETAILS_KEYS.donatorName,
      priority: 3,
    },
    email: {
      name: TRANSACTION_DETAILS_KEYS.email,
      priority: 4,
    },

    transAmount: {
      name: TRANSACTION_DETAILS_KEYS.totalPembayaran,
      priority: 5,
      isAmount: true,
    },

    transDate: {
      name: TRANSACTION_DETAILS_KEYS.transDate,
      priority: 6,
      isDate: true,
    },

    moneyAccount: {
      name: TRANSACTION_DETAILS_KEYS.moneyAccount,
      priority: 7,
    },
  },
};

export const TRANSACTION_DETAIL_CONFIG = {
  SALARY_DISBURSEMENT: {
    default: {
      transRequestedAmount: {
        name: TRANSACTION_DETAILS_KEYS.transRequestedAmount,
        priority: 1,
        isAmount: true,
      },
      transFee: {
        name: TRANSACTION_DETAILS_KEYS.transFee,
        priority: 2,
        isAmount: true,
      },
      transReceivedAmount: {
        name: TRANSACTION_DETAILS_KEYS.transReceivedAmount,
        priority: 3,
        isAmount: true,
      },
      transDate: {
        name: TRANSACTION_DETAILS_KEYS.transDate,
        priority: 4,
        isDate: true,
      },
      moneyAccount: {
        name: TRANSACTION_DETAILS_KEYS.moneyAccount,
        priority: 4,
      },
    },
    39: {
      transReceivedAmount: {
        name: TRANSACTION_DETAILS_KEYS.transReceivedAmount,
        priority: 1,
        isAmount: true,
      },
      transDate: {
        name: TRANSACTION_DETAILS_KEYS.transDate,
        priority: 2,
        isDate: true,
      },
      moneyAccount: {
        name: TRANSACTION_DETAILS_KEYS.moneyAccount,
        priority: 3,
      },
    },
    38: {
      transRequestedAmount: {
        name: 'Jumlah penyesuaian',
        priority: 1,
        isAmount: true,
      },
      transDate: {
        name: TRANSACTION_DETAILS_KEYS.transDate,
        priority: 2,
        isDate: true,
      },
      moneyAccount: {
        name: TRANSACTION_DETAILS_KEYS.moneyAccount,
        priority: 3,
      },
    },
  },
  POINTS: {
    default: {
      displayName: {
        name: TRANSACTION_DETAILS_KEYS.displayName,
        priority: 1,
      },
      transRequestedAmount: {
        name: TRANSACTION_DETAILS_KEYS.transRequestedAmount,
        priority: 2,
        isAmount: true,
      },
      transFee: {
        name: TRANSACTION_DETAILS_KEYS.transFee,
        priority: 3,
        isAmount: true,
      },
      transReceivedAmount: {
        name: TRANSACTION_DETAILS_KEYS.transReceivedAmount,
        priority: 4,
        isAmount: true,
      },
      transDate: {
        name: TRANSACTION_DETAILS_KEYS.transDate,
        priority: 5,
        isDate: true,
      },
      moneyAccount: {
        name: TRANSACTION_DETAILS_KEYS.moneyAccount,
        priority: 6,
      },
    },
  },
  GajiGesa: ggTransactionConfig,
  UANG_KAS: ggTransactionConfig,
  UANG_KANTOR: {
    default: {
      transRequestedAmount: {
        name: TRANSACTION_DETAILS_KEYS.transRequestedAmount,
        priority: 1,
        isAmount: true,
      },
      transFee: {
        name: TRANSACTION_DETAILS_KEYS.transFee,
        priority: 2,
        isAmount: true,
      },
      transReceivedAmount: {
        name: TRANSACTION_DETAILS_KEYS.transReceivedAmount,
        priority: 3,
        isAmount: true,
      },
      transDate: {
        name: TRANSACTION_DETAILS_KEYS.transDate,
        priority: 4,
        isDate: true,
      },
      moneyAccount: {
        name: TRANSACTION_DETAILS_KEYS.moneyAccount,
        priority: 4,
      },
    },
    40: {
      referenceNumber: {
        name: TRANSACTION_DETAILS_KEYS.referenceNumber,
        priority: 1,
      },
      transReceivedAmount: {
        name: TRANSACTION_DETAILS_KEYS.transReceivedAmount,
        priority: 2,
        isAmount: true,
      },
      transDate: {
        name: TRANSACTION_DETAILS_KEYS.transDate,
        priority: 3,
        isDate: true,
      },
      moneyAccount: {
        name: TRANSACTION_DETAILS_KEYS.moneyAccount,
        priority: 4,
      },
    },
    41: {
      transReceivedAmount: {
        name: 'Jumlah penyesuaian',
        priority: 1,
        isAmount: true,
      },
      transDate: {
        name: TRANSACTION_DETAILS_KEYS.transDate,
        priority: 2,
        isDate: true,
      },
      moneyAccount: {
        name: TRANSACTION_DETAILS_KEYS.moneyAccount,
        priority: 3,
      },
    },
  },
};

export const APP_TOUR_INTERACTION_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  STARTED: 'STARTED',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
};

export const PRODUCT_CODE_MILVIK = 'BP-18';

export const APP_TOUR_FLOWS = {
  TRANSACTION: 'TRANSACTION',
};

export const LOAN_META_INFO = [
  'Pilih jumlah dana yang kamu butuhkan',
  'Tunggu proses pengecekan oleh tempat kerjamu',
  'Setelah pengajuan disetujui, cek uang masuk di rekening terdaftar (utama) di GajiGesa',
  'Pada hari gajian, ada pemotongan gaji untuk cicilan e-Kasbon sesuai dengan tenor',
  'Ajukan lagi e-Kasbon jika cicilanmu sudah selesai',
  'Baca syarat dan ketentuan selengkapnya di sini',
];

export const LOAN_ATTENTION_TEXT = [
  'Tim HR tempat kerjamu akan mengecek pengajuanmu terlebih dahulu',
  'Dana ini akan ditransfer ke rekening bank terdaftar (utama) di GajiGesa',
  'Proses verifikasi dan transfer membutuhkan waktu maks. 1 x 24 jam kerja',
  'Pembayaran cicilan akan langsung memotong gajimu',
];

export const LOAN_APPLICATION_STATUS = {
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  DISBURSED: 'DISBURSED',
  FULLY_PAID: 'FULLY_PAID',
};

export const LOAN_TERMS = [
  'Saya menyetujui bahwa persetujuan atas layanan Kasbon adalah kewenangan penuh perusahaan tempat saya bekerja.',
  'Saya bersedia untuk membayar biaya layanan dan cicilan Kasbon yang muncul atas penggunaan layanan ini.',
  'Saya menyetujui biaya layanan akan dikurangi secara langsung dari jumlah dana yang saya ajukan dan saya akan menerima dana di rekening saya sejumlah yang telah dikurangi biaya layanan yang muncul.',
  'Saya menyetujui gaji yang akan saya terima dari perusahaan pada setiap tanggal gajian akan dipotong sejumlah total transaksi yang saya lakukan melalui layanan ini.',
  'Saya akan bertanggung jawab untuk penyelesaian kewajiban layanan Kasbon ini (apabila masih ada), terlepas dari status hubungan kerja saya dengan perusahaan.',
];

export const PHONE_ENROLLMENT_STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const PHONE_ENROLL_LINK = 'https://bit.ly/verifikasihp_gajigesa';

export const PHONE_ENROLL_INFO_TEXT =
  '1. Permudah transaksi beli pulsa, paket data, dan top-up e-wallet untuk orang lain dengan mendaftarkan nomornya\n2. Nomor ini akan diverifikasi manual oleh tim GajiGesa';

export const BP_NAMES = {
  'bp-10': 'pulsa',
  'bp-15': 'emoney',
  'bp-08': 'pascabayar',
};

export const SELECTED_NOMINATION_ZAKAT = {
  categoryId: 'BP-29',
  categoryPrefix: 'BP-29',
  code: 'ZAKAT50',
};
