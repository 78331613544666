import React from 'react';
import { Pressable, View } from 'react-native';
import { styles } from './style';
import MyText from '@atoms/MyText';
import Icon from 'react-native-vector-icons/MaterialIcons';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import PropTypes from 'prop-types';

const INFO_APP_STATUS = {
  APPROVED: {
    backgroundColor: colors.success_softCactus,
    textDesc:
      'e-Kasbon disetujui! Dana berhasil ditransfer ke rekening rekening terdaftar (utama) di GajiGesa',
    iconName: 'check-circle-outline',
    iconColor: colors.success_darkCactus,
    showLink: true,
  },
  DISBURSED: {
    backgroundColor: colors.success_softCactus,
    textDesc:
      'e-Kasbon disetujui! Dana berhasil ditransfer ke rekening rekening terdaftar (utama) di GajiGesa',
    iconName: 'check-circle-outline',
    iconColor: colors.success_darkCactus,
    showLink: true,
  },
  PENDING_APPROVAL: {
    backgroundColor: colors.warning_softSunflower,
    textDesc:
      'Pengajuanmu sedang dalam proses verifikasi. Kami akan memberitahumu setelah disetujui',
    iconName: 'query-builder',
    iconColor: colors.warning_darkSunflower,
  },
  default: {
    backgroundColor: colors.secondary_softOrchid,
    textDesc:
      'Jika disetujui dana akan ditransfer ke rekening bank terdaftar (utama) di GajiGesa',
    iconName: 'info-outline',
    iconColor: colors.secondary_orchid,
  },
};

const InfoBar = ({ appStatus, show = true, onPressLink }) => {
  if (!show) return null;

  function shouldShowLink(showLink) {
    if (showLink) {
      return (
        <Pressable onPress={onPressLink}>
          <MyText isRegular={false} customStyle={styles.linkTextStyle}>
            {'Riwayat e-Kasbon'}
          </MyText>
        </Pressable>
      );
    }
    return null;
  }

  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor:
            INFO_APP_STATUS[appStatus ?? 'default'].backgroundColor,
        },
      ]}
    >
      <Icon
        style={[
          styles.iconContainer,
          { color: INFO_APP_STATUS[appStatus ?? 'default'].iconColor },
        ]}
        name={INFO_APP_STATUS[appStatus ?? 'default'].iconName}
      />
      <View style={styles.appStatusStyle}>
        <MyText isRegular={false} customStyle={styles.textStyle}>
          {INFO_APP_STATUS[appStatus ?? 'default'].textDesc}
          {shouldShowLink(INFO_APP_STATUS[appStatus ?? 'default'].showLink)}
        </MyText>
      </View>
    </View>
  );
};

InfoBar.propTypes = {
  appStatus: PropTypes.string,
  show: PropTypes.bool,
  onPressLink: PropTypes.func,
};
export default InfoBar;
