export const BILLPAYMENT_REDUCER_CASES = {
  updateProduct: 'updateProduct',
  updateBiller: 'updateBiller',
  resetProduct: 'resetProduct',
  setRegisteredAccounts: 'setRegisteredAccounts',
  setRegisteredAccountsWater: 'setRegisteredAccountsWater',
  setRegisteredAccountsPrepaid: 'setRegisteredAccountsPrepaid',
};

const initialState = {
  selectedProduct: {},
  selectedBiller: null,
  registeredAccounts: [],
  registeredAccountsWater: [],
  reigsteredAccountsPrepaid: [],
};

export default (state = initialState, action) => {
  switch (action?.type) {
    case BILLPAYMENT_REDUCER_CASES.updateProduct:
      return { ...state, selectedProduct: action.payload };

    case BILLPAYMENT_REDUCER_CASES.updateBiller:
      return { ...state, selectedBiller: action.payload };

    case BILLPAYMENT_REDUCER_CASES.resetProduct:
      return initialState;

    case BILLPAYMENT_REDUCER_CASES.setRegisteredAccounts:
      return { ...state, registeredAccounts: action.payload };

    case BILLPAYMENT_REDUCER_CASES.setRegisteredAccountsWater:
      return { ...state, registeredAccountsWater: action.payload };

    case BILLPAYMENT_REDUCER_CASES.setRegisteredAccountsPrepaid:
      return { ...state, registeredAccountPrepaid: action.payload };

    default:
      return state;
  }
};
