import React from 'react';
import { View, Pressable } from 'react-native';
import MyText from '@atoms/MyText';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import PropTypes from 'prop-types';
import { SVG_ICONS } from '../../uiUtils/SvgIcons';
import { numberWithDots } from '@gaji-gesa/gg-react-shared/src/utils/sharedUtils';
import Arrow from 'react-native-vector-icons/MaterialIcons';
import { normalize } from '../../sharedStyle/helper';
import styles from './style';

const PoinCard = ({ coinBalance, onPoinPressHandler }) => (
  <View style={styles.poinCointainer}>
    <View style={styles.coinInfo}>
      <MyText isRegular={false} customStyle={styles.titleStyle} h5>
        Poin Anda
      </MyText>
      <View style={styles.rightContainer}>
        {SVG_ICONS.coin}
        <MyText h5 isRegular={false} customStyle={styles.coinTextStyle}>
          {numberWithDots(coinBalance)}
        </MyText>
      </View>
    </View>
    <Pressable
      testID="poin-press"
      android_ripple={styles.rippleColor}
      onPress={onPoinPressHandler}
      style={styles.poinCta}
    >
      <MyText isRegular={false} customStyle={styles.ctaTextStyle}>
        Tukar Poin
      </MyText>
      <Arrow
        name="arrow-forward"
        size={normalize(14)}
        color={colors.neutral_mist}
      />
    </Pressable>
  </View>
);

PoinCard.propTypes = {
  coinBalance: PropTypes.number.isRequired,
  onPoinPressHandler: PropTypes.func.isRequired,
};

export default React.memo(PoinCard);
