export const LOAN_REDUCER_CASES = {
  setLoan: 'setLoan',
  setStatusLoan: 'setStatusLoan',
  setAmountReceived: 'setAmountReceived',
  setLoanCalculate: 'setLoanCalculate',
  setAdminFee: 'setAdminFee',
  resetLoan: 'resetLoan',
};

const initialState = {
  loan: {},
  statusLoan: '',
  amountReceived: 0,
  loanCalculate: {},
  adminFee: 0,
};

export default (state = initialState, action) => {
  switch (action?.type) {
    case LOAN_REDUCER_CASES.setLoan:
      return { ...state, loan: action.payload };
    case LOAN_REDUCER_CASES.setStatusLoan:
      return { ...state, statusLoan: action.payload };
    case LOAN_REDUCER_CASES.setAmountReceived:
      return { ...state, amountReceived: action.payload };
    case LOAN_REDUCER_CASES.setLoanCalculate:
      return { ...state, loanCalculate: action.payload };
    case LOAN_REDUCER_CASES.setAdminFee:
      return { ...state, adminFee: action.payload };
    case LOAN_REDUCER_CASES.resetLoan:
      return initialState;

    default:
      return state;
  }
};
