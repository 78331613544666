export const ATTENDANCE_REDUCER_CASES = {
  setPaymentCycles: 'setPaymentCycles',
  setSummaryData: 'setSummaryData',
  setAttendanceData: 'setAttendanceData',
  setCurrentButton: 'setCurrentButton',
  setModalDate: 'setModalDate',
  setAttendanceSubmission: 'setAttendanceSubmission',
  setTodayAttendance: 'setTodayAttendance',
  setSalaryType: 'setSalaryType',
  setAttendanceSubmissionToday: 'setAttendanceSubmissionToday',
  setSkipBreak: 'setSkipBreak',
};

const initialState = {
  paymentCycles: [],
  summaryData: {},
  attendanceData: [],
  currentButton: 'Mulai Kerja',
  attendanceModalDate: '',
  attendanceSubmission: {},
  todayAttendance: {},
  salaryType: '',
  attendanceSubmissionToday: {},
  skipBreak: '',
};

export default (state = initialState, action) => {
  switch (action?.type) {
    case ATTENDANCE_REDUCER_CASES.setAttendanceSubmissionToday:
      return {
        ...state,
        attendanceSubmissionToday: action.payload,
      };
    case ATTENDANCE_REDUCER_CASES.setSalaryType:
      return {
        ...state,
        salaryType: action.payload,
      };
    case ATTENDANCE_REDUCER_CASES.setPaymentCycles:
      return {
        ...state,
        paymentCycles: action.payload,
      };

    case ATTENDANCE_REDUCER_CASES.setSummaryData:
      return {
        ...state,
        summaryData: action.payload,
      };

    case ATTENDANCE_REDUCER_CASES.setAttendanceData:
      return {
        ...state,
        attendanceData: action.payload,
      };

    case ATTENDANCE_REDUCER_CASES.setCurrentButton:
      return {
        ...state,
        currentButton: action.payload,
      };

    case ATTENDANCE_REDUCER_CASES.setModalDate:
      return {
        ...state,
        attendanceModalDate: action.payload,
      };

    case ATTENDANCE_REDUCER_CASES.setAttendanceSubmission:
      return {
        ...state,
        attendanceSubmission: action.payload,
      };

    case ATTENDANCE_REDUCER_CASES.setTodayAttendance:
      return {
        ...state,
        todayAttendance: action.payload,
      };

    case ATTENDANCE_REDUCER_CASES.setSkipBreak:
      return {
        ...state,
        skipBreak: action.payload,
      };

    default:
      return state;
  }
};
