import { StyleSheet } from 'react-native';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { normalize } from '@style/helper';

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginHorizontal: normalize(14),
    paddingVertical: normalize(10),
    paddingHorizontal: normalize(10),
    backgroundColor: colors.neutral_mist,
    borderRadius: normalize(7),
    marginTop: normalize(10),
    alignItems: 'center',
  },
  iconContainer: { fontSize: normalize(20), color: colors.neutral_charcoal },
  textStyle: { fontSize: normalize(11), marginLeft: normalize(10) },
});
