import React from 'react';
import { View } from 'react-native';
import MyText from '@atoms/MyText';
import { SVG_ICONS } from '../../../uiUtils/SvgIcons';
import { styles } from './style.js';

const RewardBox = () => (
  <View style={styles.infoOuterContainer}>
    <MyText customStyle={styles.infoTextStyle}>
      Kumpulkan hadiah sampai dengan
    </MyText>
    <View style={styles.rewardInfo}>
      <View style={styles.coinAdj}>{SVG_ICONS.coin4}</View>
      <MyText isRegular={false} customStyle={styles.rewardStyle}>
        2.500.000 Poin
      </MyText>
    </View>
  </View>
);

export default RewardBox;
