import { StyleSheet } from 'react-native';
import { normalize } from '../../../sharedStyle/helper';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
export const styles = StyleSheet.create({
  Group991: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: normalize(52),
    paddingLeft: normalize(15),
    paddingRight: 12,
    paddingTop: normalize(15),
    borderRadius: 12,
  },
  Frame13537: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  Keyboard_arrow_right: {
    width: 18,
    height: 18,
  },
  RekeningNumber: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },

  Name_provider: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
  },

  Line2: {
    width: '100%',
    height: 1,
    borderWidth: 1,
    borderColor: colors.neutral_mist,
  },
  badgeStyle: {
    borderRadius: 12,
    backgroundColor: colors.primary_softCarnation,
    paddingHorizontal: normalize(9),
    paddingVertical: normalize(7),
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
  },
  badeSuccessStyle: {
    paddingRight: normalize(30),
    backgroundColor: colors.variants_softClover,
  },
  badgeTextStyle: {
    fontSize: normalize(10),
    color: colors.error_rose,
  },
  badgeSuccessText: {
    fontSize: normalize(10),
    color: colors.variants_darkClover,
  },
  badgeIconStyle: { position: 'absolute', right: 0, bottom: 0 },

  pendingStyle: {
    backgroundColor: colors.warning_softSunflower,
    color: colors.warning_darkSunflower,
  },
  rejectedStyle: {
    backgroundColor: colors.error_softRose,
    color: colors.error_darkRose,
  },

  badgeCtaContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row',
  },

  rightArrowStyle: { fontSize: normalize(17), marginRight: normalize(6) },
  deleteIconStyle: {
    fontSize: normalize(16),
    marginLeft: normalize(10),
    marginRight: normalize(6),
  },
  showTickStyle: {
    fontSize: normalize(17),
    color: colors.primary_carnation,
    marginRight: normalize(4),
  },
  phoneNumberStyle: {
    color: colors.neutral_charcoal,
    fontSize: normalize(12),
  },
  ownerStyle: {
    color: colors.neutral_darkMist,
    fontSize: normalize(12),
  },
});
