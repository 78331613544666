import { USER_PHONE_CASES } from '../reducer/UserPhoneReducer';

export const setUserSelectedPhone = (data) => ({
  type: USER_PHONE_CASES.setSelectedPhone,
  data,
});

export const clearUserSelectedPhone = () => ({
  type: USER_PHONE_CASES.clearSelectedPhone,
});
