import React from 'react';
import { View } from 'react-native';
import { styles } from './style';
import MyText from '@atoms/MyText';
import PropTypes from 'prop-types';

const Baru = ({ showBaru, customStyles }) => {
  if (!showBaru) return null;
  return (
    <View style={[styles.container, customStyles]}>
      <MyText customStyle={styles.textStyle}>Baru</MyText>
    </View>
  );
};

Baru.propTypes = {
  showBaru: PropTypes.bool,
  customStyles: PropTypes.object,
};

export default Baru;
