import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { StyleSheet } from 'react-native';
import { normalize } from '../../../sharedStyle/helper';

export const styles = StyleSheet.create({
  container: { paddingHorizontal: normalize(15), marginTop: normalize(5) },
  title: { fontSize: normalize(14) },
  descriptText: {
    color: colors.neutral_darkMist,
    fontSize: normalize(12),
    marginTop: normalize(3),
  },

  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: normalize(5),
    paddingRight: normalize(12),
  },
  keyStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: normalize(8),
    paddingRight: normalize(12),
  },
  valueStyle: { fontSize: normalize(12) },
  dividerStyle: { marginTop: normalize(10), height: normalize(2.5) },
});
