import React from 'react';
import { Pressable, View } from 'react-native';
import PropTypes from 'prop-types';
import MyText from '@atoms/MyText';
import styles from './style';
import { bankSelectionComponent } from './BankItemSelectUtils';
import { colors } from '@gaji-gesa/gg-react-shared/src/appColors';
const BankItemSelect = ({ item, isSelected, onPress }) => {
  return (
    <Pressable
      disabled={item.status === 'PENDING'}
      onPress={onPress}
      android_ripple={{ color: colors.greyShade }}
      style={styles.mainContainer}
    >
      <View
        style={[
          styles.textContainer,
          { opacity: item.status === 'PENDING' ? 0.3 : 1 },
        ]}
      >
        <MyText isRegular={false} customStyle={styles.bankName}>
          {item.bankName}
        </MyText>
        <MyText>{item.accountNumber}</MyText>
        <View style={styles.rowContainer}>
          <MyText h2 customStyle={styles.greyText} isRegular={false}>
            {item.nickName}
            {item.beneficiaryName !== '' && item.nickName !== '' ? ' • ' : ''}
          </MyText>
          <MyText h2 customStyle={styles.greyText}>
            {item.beneficiaryName}
          </MyText>
        </View>
      </View>
      {bankSelectionComponent(item.status, isSelected)}
    </Pressable>
  );
};

BankItemSelect.propTypes = {
  item: PropTypes.shape({
    status: PropTypes.string.isRequired,
    bankName: PropTypes.string.isRequired,
    accountNumber: PropTypes.string.isRequired,
    beneficiaryName: PropTypes.string.isRequired,
    nickName: PropTypes.string.isRequired,
  }),
  isSelected: PropTypes.bool.isRequired,
  onPress: PropTypes.func.isRequired,
};
BankItemSelect.defaultProps = {};
export default BankItemSelect;
