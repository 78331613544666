import { MISSION_STATUS, TIER_STATUS_TYPE } from './sharedConstants';

const getTiersAndMissions = (data) => {
  const tiers = {};
  const missions = {};
  data.employee_mission.forEach((e) => {
    tiers[e.employeeTier.id] = {
      ...e.employeeTier,
    };
    const arr = missions[e.mission.mission_category.id]?.data ?? [];
    arr.push(e);
    missions[e.mission.mission_category.id] = {
      data: arr,
      tierInfo: e.employeeTier,
    };
  });

  const updatedTier = Object.values(tiers).sort((a, b) => a.tier.displayOrder - b.tier.displayOrder);
  // COMING SOON addition
  updatedTier.push({
    tierId: 100,
    status: 'DISABLED',
    tier: {
      displayOrder: 10,
    },
  });
  return { tiers: updatedTier, missions };
};

const getMissionListWithoutTierInfo = (data) =>
  data.map((e) => {
    delete e.employeeTier;
    return {
      ...e,
    };
  });

const sortSubMissionsByDisplayOrder = (sortedSubMission) => {
  sortedSubMission.sort((a, b) => a.mission.displayOrder - b.mission.displayOrder);
};

const checkIfAllMissionCompleted = (sortedSubMission, missions, key) =>
  sortedSubMission.filter((e) => e.status === MISSION_STATUS.COMPLETED).length === missions[key].data.length;

const sortMissionCategory = (newMission) => {
  newMission.sort((a, b) => Number(a) - Number(b));
};

const getNewMissionSectionList = (missions, newMission) => {
  Object.keys(missions).forEach((key) => {
    const sortedSubMission = getMissionListWithoutTierInfo(missions[key].data);
    sortSubMissionsByDisplayOrder(sortedSubMission);

    let missionCategoryDetail = {
      categoryName: '',
      createdAt: '',
      displayOrder: null,
      id: null,
      isAllMissionCompleted: false,
    };

    if (sortedSubMission.length > 0) {
      const isAllMissionCompleted = checkIfAllMissionCompleted(sortedSubMission, missions, key);

      missionCategoryDetail = {
        ...missions[key].data[0].mission.mission_category,
        isAllMissionCompleted,
      };
    }

    newMission.push({
      title: {
        ...missionCategoryDetail,
        tierInfo: missions[key].tierInfo,
      },
      data: sortedSubMission,
    });
  });
};

const getCurrentTierMission = (missions, tiers, activeTierIdx = -1) => {
  if (tiers.length === 0 || (activeTierIdx > -1 && tiers[activeTierIdx].status === TIER_STATUS_TYPE.DISABLED))
    return { noOfCompleted: 0, tierMissions: [], sectionListFeed: [], currentTier: [] };

  const sectionListFeed = [];
  let tierId = 0;
  let currentTier = [];
  if (activeTierIdx === -1) {
    currentTier = tiers.filter(
      (t) => t.status === TIER_STATUS_TYPE.IN_PROGRESS || t.status === TIER_STATUS_TYPE.COMPLETED,
    );
    if (currentTier.length > 0) {
      tierId = currentTier[currentTier.length - 1].tierId;
    }
  } else {
    tierId = tiers[activeTierIdx].tierId;
  }
  const accumalator = (tm, cm) => {
    if (tierId === cm.title.tierInfo.tierId) {
      tm.push(...cm.data);
      sectionListFeed.push(cm);
    }
    return tm;
  };
  const tierMissions = missions.reduce(accumalator, []);
  const noOfCompleted = tierMissions.filter((t) => t.status === TIER_STATUS_TYPE.COMPLETED).length;

  return { noOfCompleted, tierMissions, sectionListFeed, currentTier };
};

const MISSION_SOURCE = {
  stories: 'stories',
};

const MISSION_DESCRIPTION = {
  tier1: {
    storyMission: 'Tonton app story min. 1 kali',
    withdrawMission: 'Tarik gaji akumulasi min. Rp1.700.000',
    withdrawCountMission: 'Tarik gaji sebanyak min. 3 kal',
    bpTransaction: 'Bayar tagihan & top-up saldo sebanyak min. 1 kali',
    visitReferral: 'Kunjungi halaman referral 1 kali',
    shareReferral: 'Salin & bagikan kode referral Anda 1 kali',
    inviteFriend: 'Undang 1 temanmu untuk transaksi pertama kali',
  },
  ramadan: {
    withdrawal: 'Tarik gaji hingga Rp500.000',
    withdrawaltimes: 'Tarik gaji sebanyak min. 3 kali',
    bp: 'Bayar tagihan atau top-up saldo min. 1 kali',
    quiz: 'Ikuti kuis seru spesial Semarak Ramadan GajiGesa',
    yt: 'Tonton video informasi finansial di Youtube GajiGesa',
    referralPage: 'Kunjungi halaman referral 1 kali',
    referralShare: 'Salin & bagikan kode referral Anda 1 kali',
  },
};

const handleNavigation = (navigation, missionDescription, linking) => {
  switch (missionDescription) {
    case MISSION_DESCRIPTION.tier1.storyMission:
      navigation.navigate('HomeScene', {
        screen: 'Home',
        params: { screen: 'Dashboard', params: { missionSource: MISSION_SOURCE.stories } },
      });
      break;

    case MISSION_DESCRIPTION.tier1.withdrawMission:
      navigation.navigate('Home');
      break;

    case MISSION_DESCRIPTION.tier1.withdrawCountMission:
      navigation.navigate('Home');
      break;

    case MISSION_DESCRIPTION.tier1.bpTransaction:
      navigation.navigate('Pembayaran');
      break;

    case MISSION_DESCRIPTION.tier1.visitReferral:
      navigation.navigate('TransactionList', { screen: 'Referral', params: { source: 'mission' } });
      break;

    case MISSION_DESCRIPTION.tier1.shareReferral:
      navigation.navigate('TransactionList', { screen: 'Referral', params: { source: 'mission' } });
      break;

    case MISSION_DESCRIPTION.tier1.inviteFriend:
      navigation.navigate('TransactionList', { screen: 'Referral', params: { source: 'mission' } });
      break;

    case MISSION_DESCRIPTION.ramadan.withdrawaltimes:
    case MISSION_DESCRIPTION.ramadan.withdrawal:
      navigation.navigate('HomeScene', {
        screen: 'Home',
        params: { screen: 'Dashboard', params: { missionSource: 'mission' } },
      });
      break;

    case MISSION_DESCRIPTION.ramadan.bp:
      navigation.navigate('Pembayaran', { screen: 'Billpayment', params: { missionSource: 'mission' } });
      break;

    case MISSION_DESCRIPTION.ramadan.quiz:
      linking.openURL('https://gajigesa.com/semarak-ramadan-indonesia/');
      break;

    case MISSION_DESCRIPTION.ramadan.yt:
      linking.openURL('https://www.youtube.com/@gajigesa_id/');
      break;

    case MISSION_DESCRIPTION.ramadan.referralPage:
      navigation.navigate('TransactionList', { screen: 'Referral', params: { source: 'mission' } });
      break;

    case MISSION_DESCRIPTION.ramadan.referralShare:
      navigation.navigate('TransactionList', { screen: 'Referral', params: { source: 'mission' } });
      break;

    default:
      break;
  }
};

export {
  getTiersAndMissions,
  getMissionListWithoutTierInfo,
  sortSubMissionsByDisplayOrder,
  checkIfAllMissionCompleted,
  sortMissionCategory,
  getNewMissionSectionList,
  getCurrentTierMission,
  handleNavigation,
  MISSION_DESCRIPTION,
  MISSION_SOURCE,
};
