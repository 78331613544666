import { StyleSheet } from 'react-native';
import { colors } from '@style/colors';

export const styles = StyleSheet.create({
  eyeIcon: { marginStart: 10 },
  hasMoreBankButton: {
    borderRadius: 10,
    backgroundColor: colors.white,
    borderWidth: 0.5,
    borderColor: colors.philippineGray,
    alignSelf: 'center',
    width: '92%',
  },
  pressableStyle: {
    width: '100%',
  },
  bankDetailsContainer: {
    marginLeft: '10%',
    width: '80%',
  },
  buttonStyle: { fontSize: 12, color: colors.black },
  imageButton: {
    justifyContent: 'center',
    borderRadius: 30,
    backgroundColor: colors.white,
    borderWidth: 0.5,
    borderColor: colors.philippineGray,
    alignSelf: 'center',
  },
  divider: {
    marginVertical: 10,
    height: 2,
  },
  bankIcon: { alignSelf: 'flex-start', marginTop: 8 },
  greyText: {
    color: colors.philippineGray,
  },
  mainContainer: {
    margin: 10,
    borderRadius: 15,
    borderWidth: 1,
    borderColor: colors.gainsboro,
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  detailsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  bankStyle: { paddingRight: 15 },
});
