import { StyleSheet } from 'react-native';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { normalize } from '../../../sharedStyle/helper';

const styles = StyleSheet.create({
  timeStampText: { fontSize: normalize(12) },
  containerStyle: {
    marginHorizontal: normalize(15),
    borderRadius: normalize(12),
    backgroundColor: colors.neutral_cotton,
    elevation: 3,
    shadowColor: colors.blackColor,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    flexDirection: 'row',
    paddingVertical: normalize(15),
    paddingHorizontal: normalize(23),
    marginTop: normalize(12),
    justifyContent: 'space-between',
  },
  rowStyle: { flexDirection: 'row', alignItems: 'center' },
  circleStyle: {
    width: 12,
    height: 12,
    borderRadius: 12 / 2,
  },
  displayText: { marginLeft: normalize(11), fontSize: normalize(12) },
});
export default styles;
