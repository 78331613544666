import { StyleSheet } from 'react-native';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { normalize } from '../../../sharedStyle/helper';

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginHorizontal: normalize(14),
    paddingVertical: normalize(10),
    paddingHorizontal: normalize(10),
    backgroundColor: colors.secondary_softOrchid,
    borderRadius: normalize(7),
    marginTop: normalize(10),
    alignItems: 'center',
  },
  iconContainer: { fontSize: normalize(20), color: colors.secondary_orchid },
  textStyle: { fontSize: normalize(11), marginLeft: normalize(10) },
  linkTextStyle: {
    textDecorationLine: 'underline',
    color: colors.secondary_orchid,
    marginTop: normalize(4),
  },
  appStatusStyle: { width: '85%' },
});
