import React from 'react';
import { View, Pressable } from 'react-native';
import PropTypes from 'prop-types';
import { styles } from './styles';
import Bank from 'react-native-vector-icons/FontAwesome';
import MyText from '@atoms/MyText';
import Arrow from 'react-native-vector-icons/MaterialIcons';
import { colors } from '@style/colors';
import { dot } from './HasMoreBankUtils';

const HasMoreBank = ({ onPress, showDot }) => {
  return (
    <View style={styles.mainContainer}>
      <Pressable
        onPress={onPress}
        style={styles.pressable}
        android_ripple={{ color: colors.greyShade }}
      >
        <View style={styles.bankIconContainer}>
          <Bank
            name="bank"
            size={15}
            color={colors.black}
            style={styles.bankIcon}
          />
          <MyText h2 isRegular={false}>
            Lihat Rekening Bank Terdaftar
          </MyText>
          {dot(showDot)}
        </View>
        <Arrow name={'keyboard-arrow-right'} color={colors.black} size={25} />
      </Pressable>
    </View>
  );
};

HasMoreBank.propTypes = {
  onPress: PropTypes.func.isRequired,
  showDot: PropTypes.bool.isRequired,
};
HasMoreBank.defaultProps = {};
export default HasMoreBank;
