import React from 'react';
import { View } from 'react-native';
import MyText from '@atoms/MyText';
import { numberWithDots } from '@gaji-gesa/gg-react-shared/src/utils/sharedUtils';
import { SVG_ICONS } from '../../../uiUtils/SvgIcons';
import { styles } from './style';
import PropTypes from 'prop-types';

export const MetaTag = ({ showTag }) => {
  if (showTag)
    return (
      <View style={styles.metaContainer}>
        <MyText isRegular={false} customStyle={styles.metaTagStyle}>
          Menunggu Persetujuan
        </MyText>
      </View>
    );

  return null;
};

const LoanDetailCard = ({
  principalAmt,
  recievable,
  tenure,
  installAmt,
  showTag,
}) => {
  return (
    <View style={styles.outerContainer}>
      <View style={styles.principleRow}>
        <View style={styles.row}>
          {SVG_ICONS.loan.cash}
          <MyText isRegular={false} customStyle={styles.principleAmtStyle}>
            {`Rp${numberWithDots(principalAmt)}`}
          </MyText>
        </View>
        <MetaTag showTag={showTag} />
      </View>

      <View style={styles.metaRow}>
        <View>
          <MyText isRegular customStyle={styles.metaTitleStyle}>
            Jumlah dana diterima
          </MyText>
          <MyText
            numberOfLines={2}
            isRegular={false}
            customStyle={styles.metaInfoStyle}
          >
            {`Rp${numberWithDots(recievable)}`}
          </MyText>
        </View>
        <View style={styles.containerAdj}>
          <MyText isRegular customStyle={styles.metaTitleStyle}>
            Cicilan
          </MyText>

          <MyText isRegular={false} customStyle={styles.tenureTxtStyle}>
            {`${tenure}x`}
          </MyText>
        </View>
        <View style={styles.containerAdj}>
          <MyText isRegular customStyle={styles.metaTitleStyle}>
            Cicilan per bulan
          </MyText>
          <MyText
            numberOfLines={2}
            isRegular={false}
            customStyle={styles.installAmtTxt}
          >
            {`Rp${numberWithDots(installAmt)}`}
          </MyText>
        </View>
      </View>
    </View>
  );
};

LoanDetailCard.propTypes = {
  principalAmt: PropTypes.number,
  recievable: PropTypes.number,
  tenure: PropTypes.number,
  installAmt: PropTypes.number,
  showTag: PropTypes.bool,
};

LoanDetailCard.defaultProps = {
  principalAmt: 0,
  recievable: 0,
  tenure: '0',
  installAmt: 0,
};

export default LoanDetailCard;
