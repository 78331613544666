import { AUTH_REDUCER_CASES } from '../reducer/Authentication';

export const setTokens = (data) => ({
  type: AUTH_REDUCER_CASES.setAllTokens,
  payload: data,
});

export const setUserName = (data) => ({
  type: AUTH_REDUCER_CASES.setUsername,
  payload: data,
});

export const setPhoneNumber = (data) => ({
  type: AUTH_REDUCER_CASES.setPhoneNumber,
  payload: data,
});

export const setRoleId = (data) => ({
  type: AUTH_REDUCER_CASES.setRoleId,
  payload: data,
});

export const setClickCount = (data) => ({
  type: AUTH_REDUCER_CASES.setClickCount,
  payload: data,
});

export const setShowAlert = (data) => ({
  type: AUTH_REDUCER_CASES.setShowAlert,
  payload: data,
});

export const setTokenOtp = (data) => ({
  type: AUTH_REDUCER_CASES.setOtpToken,
  payload: data,
});

export const setInitalAuthData = (data) => ({
  type: AUTH_REDUCER_CASES.setInitalAuthData,
  payload: data,
});

export const setAccountNumber = (data) => ({
  type: AUTH_REDUCER_CASES.setAccountNumber,
  payload: data,
});

export const setEmployeeId = (data) => ({
  type: AUTH_REDUCER_CASES.setEmployeeId,
  payload: data,
});

export const setEmployerId = (data) => ({
  type: AUTH_REDUCER_CASES.setEmployerId,
  payload: data,
});

export const setUserRoles = (data) => ({
  type: AUTH_REDUCER_CASES.setUserRoles,
  payload: data,
});

export const setIsGajitimEmployee = (data) => ({
  type: AUTH_REDUCER_CASES.setIsGajitimEmployee,
  payload: data,
});

export const setIsGajiTim = (data) => ({
  type: AUTH_REDUCER_CASES.setIsGajiTim,
  payload: data,
});

export const setIsGajiGesa = (data) => ({
  type: AUTH_REDUCER_CASES.setIsGajiGesa,
  payload: data,
});

export const autoLogout = (callBack) => ({
  type: AUTH_REDUCER_CASES.setAutoLogout,
  payload: callBack,
});

export const setbURL = (data) => ({
  type: AUTH_REDUCER_CASES.setbURL,
  payload: data,
});

export const setApiKey = (data) => ({
  type: AUTH_REDUCER_CASES.setApiKey,
  payload: data,
});

export const setUserId = (data) => ({
  type: AUTH_REDUCER_CASES.setUserId,
  payload: data,
});
