import React from 'react';
import MyText from '@atoms/MyText';
import styles from './style';
export const getErrorComponent = (error) => {
  if (error.visible) {
    return (
      <MyText h2 customStyle={styles.errorText}>
        {error.message}
      </MyText>
    );
  }
  return null;
};
