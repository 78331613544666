import { useLazyQuery } from '@apollo/client';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setFeatureFlag,
  setFeatureFlagError,
  setFeatureFlagLoading,
} from '@gaji-gesa/gg-react-shared/src/redux/action/FeatureFlags';
import { FEATURE_FLAGS } from '@gaji-gesa/gg-react-shared/src/gqlQuery/featureFlag';
import { isFeatureEnabled } from '@gaji-gesa/gg-react-shared/src/utils/sharedUtils';

const useFeatureFlag = ({
  featureName,
  onCompleted,
  onCompleteWithDynamicFeature,
  onErrorCallBack,
}) => {
  const { featureFlags, error, loading } = useSelector(
    (state) => state.FeatureFlag
  );
  const { employerId } = useSelector((state) => state.Authentication);
  const dispatch = useDispatch();
  const [getFeatureFlags, {}] = useLazyQuery(FEATURE_FLAGS, {
    fetchPolicy: 'no-cache',
    variables: { employerId },
    onCompleted: (data) => {
      dispatch(setFeatureFlag(data?.app_feature ?? [], false));
      dispatch(setFeatureFlagError(false));
      AsyncStorage.setItem('@featureFlag', JSON.stringify(data.app_feature));
      dispatch(setFeatureFlagLoading(false));
      if (onCompleted) {
        onCompleted(isFeatureEnabled(data?.app_feature ?? [], featureName));
      }
      if (onCompleteWithDynamicFeature) {
        onCompleteWithDynamicFeature(data?.app_feature ?? []);
      }
    },
    onError: () => {
      dispatch(setFeatureFlagLoading(false));
      dispatch(setFeatureFlagError(true));
      if (onErrorCallBack) onErrorCallBack();
    },
  });

  useEffect(() => {
    if (error && !loading) {
      dispatch(setFeatureFlagLoading(true));
      getFeatureFlags();
    }
  }, [dispatch, error, getFeatureFlags, loading]);

  const isFeatureAvailale = useMemo(() => {
    return isFeatureEnabled(featureFlags, featureName);
  }, [featureFlags, featureName]);

  if (featureName) {
    return [isFeatureAvailale, getFeatureFlags];
  }

  return [getFeatureFlags];
};

export default useFeatureFlag;
