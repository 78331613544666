import React from 'react';
import { View } from 'react-native';
import MyText from '@atoms/MyText';
import { styles } from './style';

const DescriptionBanner = ({ icon, literal }) => (
  <View style={styles.containerStyle}>
    <View style={styles.iconContainer}>{icon}</View>
    <View style={styles.textContainer}>
      <MyText customStyle={styles.textStyle}>{literal}</MyText>
    </View>
  </View>
);

export default DescriptionBanner;
