import { colors } from '@gaji-gesa/gg-react-shared/src/appColors';
import { Font } from '../../sharedStyle/Fonts';
import { normalizeHeight } from '../../sharedStyle/helper';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  sliderContainer: { width: '100%' },
  subContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  cardContainer: {
    width: '90%',
    marginBottom: 15,
  },
  button: {
    paddingVertical: 10,
    width: '100%',
    justifyContent: 'center',
    borderRadius: 30,
  },
  disabledButton: {
    opacity: 0.5,
  },
  textAmount: { marginTop: 5 },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  input: {
    marginTop: 5,
    marginLeft: 5,
    flex: 1,
    fontFamily: Font.Nunito_Bold,
    fontSize: 16,
    color: colors.black,
    paddingVertical: 0,
  },
  sliderTrack: {
    height: 8,
    borderRadius: 50,
    backgroundColor: colors.softCarnation,
  },
  sliderThumb: {
    width: normalizeHeight(20),
    height: normalizeHeight(19),
    borderRadius: 50,
    backgroundColor: colors.orange,
  },
  sliderStyle: {
    width: '100%',
    color: colors.softCarnation,
  },
  saldoTersedia: {
    color: colors.grey,
  },
  tersediaContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 5,
  },
});

export default styles;
