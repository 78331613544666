import { StyleSheet } from 'react-native';
import { normalize } from '../../../sharedStyle/helper';

export const styles = StyleSheet.create({
  container: {
    paddingVertical: normalize(8),
    paddingLeft: normalize(12),
    marginTop: normalize(7),
  },
});
