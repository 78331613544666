export const APP_TOUR_TYPE = {
  homepage: 'homepage',
  referral: 'referral',
  tokenpln: 'tokenpln',
  tagihanpln: 'tagihanpln',
  pdam: 'pdam',
  pascabayar: 'pascabayar',
  pulsa: 'pulsa',
  packetdata: 'paketdata',
  referral_code_apply: 'referral_code_apply',
  savings: 'savings',
  profilePage: 'profile_page_app_tour',
  withdrawal: 'withdrawal',
  newBankRegistration: 'newBankRegistration',
  phoneEnrollTxn: 'phoneEnrollTxn',
};

export const BP_APP_TOUR = {
  tokenpln: 'tokenpln',
  tagihanpln: 'tagihanpln',
  pdam: 'pdam',
  pascabayar: 'pascabayar',
  pulsa: 'pulsa',
  packetdata: 'paketdata',
};

export const APPTOURMETA = {
  [APP_TOUR_TYPE.withdrawal]: {
    1: { description: 'Ini adalah saldo GajiGesa yang bisa kamu pakai untuk bertransaksi', tourStep: 1 },
    2: {
      description:
        'Untuk tarik gaji kamu bisa menggunakan slider atau kamu bisa ketik langsung jumlah uang yang ingin kamu ambil',
      tourStep: 2,
    },
    3: {
      description: 'Kamu akan memilih alasan tarik gajimu terlebih dahulu sebelum proses penarikan gaji',
      tourStep: 3,
      flowEndStep: true,
    },
  },
  [APP_TOUR_TYPE.referral]: {
    1: { description: 'Ajak teman sekantormu pakai Gajigesa dan dapatkan untung berlipat ganda', tourStep: 1 },
    2: {
      description: 'Kamu juga bisa klik di sini untuk pelajari lebih lanjut untuk dapatkan untung berlipat ganda',
      tourStep: 2,
    },
    3: {
      description: 'Klik di sini untuk bagikan kode referral ke teman sekantormu yang belum pernah pakai GajiGesa',
      tourStep: 3,
    },
    4: {
      description:
        'Salin kode referralmu dan berikan ke teman sekantormu supaya ia bisa dapat untung sama besarnya denganmu!',
      tourStep: 4,
      flowEndStep: true,
    },
  },

  [APP_TOUR_TYPE.homepage]: {
    1: {
      description: 'Klik di sini untuk tarik setengah atau seluruh gaji prorata yang telah kamu hasilkan',
      tourStep: 1,
    },
    2: { description: 'Klik di sini untuk lihat dan tukar GajiGesa poin yang telah kamu kumpulkan', tourStep: 2 },
    3: { description: 'Klik di sini untuk lihat jumlah penghematanmu selama bertransaksi di GajiGesa', tourStep: 3 },
    4: {
      description: 'Pilih jumlah gaji yang akan kamu tarik. Kamu bisa ketik langsung atau geser tombol oranye',
      tourStep: 4,
    },
    5: { description: 'Di sini kamu bisa langsung pilih transaksi lain yang sering kamu lakukan', tourStep: 5 },
    6: {
      description:
        'Cari tahu promosi terkini di GajiGesa. Makin sering kamu gunakan GajiGesa, makin banyak poin yang bisa kamu kumpulkan.',
      tourStep: 6,
    },
    7: {
      description: 'Jangan sampai ketinggal informasi terbaru di GajiGesa dengan melihat video di sini',
      tourStep: 7,
    },
    8: {
      description: 'Klik di sini jika kamu memutuskan untuk tarik gaji saat menjelajahi fitur lain GajiGesa',
      tourStep: 8,
    },
    9: {
      description: 'Di sini kamu bisa lihat informasi perhitungan gaji yang telah kamu peroleh',
      tourStep: 9,
      flowEndStep: true,
    },
  },
  [APP_TOUR_TYPE.tokenpln]: {
    1: { description: 'Ini transaksi pertamamu? Klik Tab "Input Baru"', tourStep: 1 },
    2: { description: 'Masukkan nomor ID Pelangganmu di sini', tourStep: 2 },
    3: { description: 'Pernah transaksi? Pilih ID Pelanggan terdaftar yang ingin kamu isi ulang di sini', tourStep: 3 },
    4: { description: 'Klik ini untuk menyelesaikan pembayaran token PLN-mu' }, // not being used
    5: {
      description:
        'Pilih jumlah nominal isi ulang token PLN. Klik Konfirmasi untuk selesaikan pembayaran dan dapatkan nomor token PLN',
      tourStep: 4,
      flowEndStep: true,
    },
    6: { description: 'Klik ini untuk menyelesaikan pembayaran tagihan PLN-mu', tourStep: 5, flowEndStep: true },
  },
  [APP_TOUR_TYPE.tagihanpln]: {
    1: { description: 'Ini transaksi pertamamu? Klik Tab "Input Baru"', tourStep: 1 },
    2: { description: 'Masukkan nomor ID Pelangganmu di sini', tourStep: 2 },
    3: { description: 'Pernah transaksi? Pilih tagihan ID Pelanggan terdaftar yang ingin kamu bayar', tourStep: 3 },
    4: { description: 'Klik ini untuk menyelesaikan pembayaran tagihan PLN-mu' }, // not being used
    5: { description: 'Klik ini untuk menyelesaikan pembayaran tagihan PLN-mu', tourStep: 4, flowEndStep: true },
  },
  [APP_TOUR_TYPE.pdam]: {
    1: { description: 'Ini transaksi pertamamu? Klik Tab "Input Baru"', tourStep: 1 },
    2: { description: 'Masukkan nomor ID Pelangganmu dan pilih wilayah rumahmu disini', tourStep: 2 },
    3: { description: 'Pernah transaksi? Pilih tagihan ID Pelanggan terdaftar yang ingin kamu bayar', tourStep: 3 },
    4: { description: 'Pernah transaksi? Pilih tagihan ID Pelanggan terdaftar yang ingin kamu bayar' }, // not being used
    5: { description: 'Klik ini untuk menyelesaikan pembayaran tagihan PDAM-mu', tourStep: 4, flowEndStep: true },
  },
  [APP_TOUR_TYPE.pulsa]: {
    1: {
      description: 'Berikut adalah nomor HP yang bisa kamu isi ulang pulsanya. Nomor ini tidak bisa diganti, ya.',
      tourStep: 1,
    },
    2: { description: 'Pilih jumlah pulsa yang ingin kamu isi ulang', tourStep: 2, flowEndStep: true },
  },
  [APP_TOUR_TYPE.pascabayar]: {
    1: {
      description: 'Berikut adalah nomor HP yang bisa kamu bayarkan tagihannya. Nomor ini tidak bisa diganti, ya.',
      tourStep: 1,
    },
    2: {
      description: 'Klik “Konfirmasi” untuk menyelesaikan pembayaran tagihan pascabayarmu',
      tourStep: 2,
      flowEndStep: true,
    },
  },
  [APP_TOUR_TYPE.packetdata]: {
    1: {
      description: 'Berikut adalah nomor HP yang bisa kamu isi ulang paket datanya. Nomor ini tidak bisa diganti, ya',
      tourStep: 1,
    },
    2: { description: 'Klik ini untuk lihat detail paket data yang ingin kamu isi ulang', tourStep: 2 },
    3: {
      description: 'Pilih paket data yang ingin kamu isi ulang, lalu konfirmasi detail pembayarannya',
      tourStep: 3,
      flowEndStep: true,
    },
  },
  [APP_TOUR_TYPE.profilePage]: {
    1: {
      title: 'Informasi Pribadi',
      description: 'Kini kamu bisa cek informasi GajiGesa-mu, termasuk poin',
      tourStep: 1,
    },
    2: {
      title: 'Detail Profil',
      description: 'Cek dan selesaikan misi yang masih tersisa, lalu tingkatkan level profilmu',
      tourStep: 2,
    },
    3: {
      title: 'Detail Profil',
      description: 'Ajak teman kerjamu untuk pakai GajiGesa dan raih banyak poin!',
      tourStep: 3,
      flowEndStep: true,
    },
  },
  [APP_TOUR_TYPE.newBankRegistration]: {
    1: {
      title: 'Tambah Rekening Bank',
      description: 'Kini kamu bisa tambah lebih dari satu rekening bank di menu Profil',
      tourStep: 1,
    },
    2: {
      title: 'Daftar Nomor HP',
      description:
        'Kini kamu bisa tambah nomor HP orang terdekatmu untuk permudah top-up pulsa, paket data, atau saldo e-wallet.',
      tourStep: 2,
      flowEndStep: true,
    },
  },
  [APP_TOUR_TYPE.phoneEnrollTxn]: {
    1: {
      title: 'Ganti Nomor',
      description: 'Kini kamu bisa pilih nomor siapa yang mau kamu belikan pulsa, paket data, atau e-wallet',
      tourStep: 1,
      flowEndStep: true,
    },
  },
};
