import { gql } from "@apollo/client";

const INSERT_PERMISSION = gql`mutation insertPermission($locationAllowed : String, $permissionPopUp: String, $phonebookAllowed:String, $employeeId : Int) {
    permissionResponse:insert_permission_response(objects: {locationAllowed: $locationAllowed, permissionPopUp: $permissionPopUp, phonebookAllowed: $phonebookAllowed, employeeId : $employeeId}) {
        affected_rows
      }
  }
  `
const CHECK_PERMISSIONS = gql`query checkPermissions {
    permission_response(order_by: {createdAt: desc}) {
      locationAllowed
      phonebookAllowed
      permissionPopUp
    }
  }`
export { INSERT_PERMISSION, CHECK_PERMISSIONS }