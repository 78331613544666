import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import PropTypes from 'prop-types';
import { colors } from '@style/colors';

const Divider = ({ header, horizontal, customStyle, showDivider }) => {
  if (showDivider) {
    return (
      <>
        {header && <Text style={styles.heading}>{header}</Text>}
        <View
          style={[
            horizontal ? styles.dividerHorizontal : styles.dividerVertical,
            customStyle,
          ]}
        />
      </>
    );
  }
  return null;
};

Divider.propTypes = {
  header: PropTypes.string,
  horizontal: PropTypes.bool,
  customStyle: PropTypes.object,
  showDivider: PropTypes.bool,
};

Divider.defaultProps = {
  header: null,
  horizontal: false,
  customStyle: {},
  showDivider: true,
};

const styles = StyleSheet.create({
  dividerVertical: {
    width: 1,
    opacity: 0.2,
    height: '100%',
    backgroundColor: colors.grey,
  },
  dividerHorizontal: {
    width: '100%',
    height: 0.8,
    opacity: 0.2,
    backgroundColor: colors.grey,
  },
  heading: {
    fontSize: 16,
    color: colors.black,
    marginTop: 20,
    marginStart: 25,
    marginBottom: 10,
  },
});

export default Divider;
