export const SALARY_REDUCER_CASES = {
  getPayslip: 'getPayslip',
  setSalaryCycle: 'setSalaryCycle',
  setSalaryCycleDate: 'setSalaryCycleDate',
  setCurrentSalaryCycle: 'setCurrentSalaryCycle',
  setBasicSalary: 'setBasicSalary',
  getPayslipUrl: 'getPayslipUrl',
  setCurrentCycle: 'setCurrentCycle',
  payslipDownload: 'payslipDownload',
};

const initialState = {
  employeePayslipUrl: '',
  currentSalaryCycle: '',
  basicSalary: 0,
  salaryCycleDate: [],
  salaryCycle: [],
  employeePayslip: {},
  currentCycle: [],
  payslipDownload: { loading: false, isDownloaded: false },
};

export default (state = initialState, action) => {
  switch (action?.type) {
    case SALARY_REDUCER_CASES.setCurrentCycle:
      return {
        ...state,
        currentCycle: action.payload,
      };

    case SALARY_REDUCER_CASES.payslipDownload:
      return {
        ...state,
        payslipDownload: {
          loading: action.payload.loading,
          isDownloaded: action.payload.isDownloaded,
        },
      };

    case SALARY_REDUCER_CASES.getPayslip:
      return { ...state, employeePayslip: action.payload };
    case SALARY_REDUCER_CASES.setBasicSalary:
      return { ...state, basicSalary: action.payload };
    case SALARY_REDUCER_CASES.setCurrentSalaryCycle:
      return { ...state, currentSalaryCycle: action.payload };
    case SALARY_REDUCER_CASES.setSalaryCycle:
      return { ...state, salaryCycle: action.payload };
    case SALARY_REDUCER_CASES.setSalaryCycleDate:
      return { ...state, salaryCycleDate: action.payload };
    case SALARY_REDUCER_CASES.getPayslipUrl:
      return { ...state, employeePayslipUrl: action.payload };

    default:
      return state;
  }
};
