export const BILLPAYMENT_CASES = {
  setBillPaymentList: 'setBillPaymentList',
  setBillPaymentLoading: 'setBillPaymentLoading',
  setBillPaymentError: 'setBillPaymentError',
  setpointsBillPayment: 'setpointsBillPayment',
  setWorkAllowanceBillPayment: 'setWorkAllowanceBillPayment',
};

const initialState = {
  billpaymentList: [],
  loading: true,
  error: false,
  pointsBillPaymentList: [],
  workAllowanceBillPaymentList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BILLPAYMENT_CASES.setBillPaymentList:
      return {
        ...state,
        billpaymentList: action.payload.list,
        loading: action.payload.loading,
      };

    case BILLPAYMENT_CASES.setBillPaymentLoading:
      return {
        ...state,
        loading: action.data,
      };

    case BILLPAYMENT_CASES.setBillPaymentError:
      return {
        ...state,
        error: action.data,
      };

    case BILLPAYMENT_CASES.setBillPaymentLoading:
      return {
        ...state,
        loading: action.payload,
      };

    case BILLPAYMENT_CASES.setpointsBillPayment:
      return {
        ...state,
        pointsBillPaymentList: action.payload,
      };

    case BILLPAYMENT_CASES.setWorkAllowanceBillPayment:
      return {
        ...state,
        workAllowanceBillPaymentList: action.payload,
      };

    case BILLPAYMENT_CASES.setBillPaymentError:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};
