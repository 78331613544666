import React from 'react';
import { View } from 'react-native';
import { styles } from './style';
import MyText from '@atoms/MyText';
import Icon from 'react-native-vector-icons/MaterialIcons';
import PropTypes from 'prop-types';

const AlertBar = ({ text, containerStyle, show }) => {
  if (show)
    return (
      <View style={[styles.container, containerStyle]}>
        <Icon style={styles.iconContainer} name="info-outline" />
        <MyText isRegular={false} customStyle={styles.textStyle}>
          {text}
        </MyText>
      </View>
    );

  return null;
};

AlertBar.propTypes = {
  text: PropTypes.string.isRequired,
  containerStyle: PropTypes.object,
  show: PropTypes.bool,
};

AlertBar.defaulProps = {
  show: true,
};

export default AlertBar;
