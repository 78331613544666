import React from 'react';
import { ActivityIndicator, Text, TouchableOpacity } from 'react-native';
import { colors } from '@style/colors';
import { buttonColor } from '@style/Layouts';

const ButtonColor = ({ accessibilityLabel, onPress, title, buttonStyle, textStyle, disabled, loading, isRounded }) => (
  <TouchableOpacity
    accessibilityLabel={accessibilityLabel}
    disabled={disabled || loading}
    onPress={onPress}
    style={[buttonColor.button, buttonStyle, isRounded ? { borderRadius: 20 } : { borderRadius: 3 }]}
    activeOpacity={0.6}
  >
    {loading ? (
      <ActivityIndicator color={colors.white} size="small" />
    ) : (
      <Text style={{ ...buttonColor.title, ...textStyle }}>{title}</Text>
    )}
  </TouchableOpacity>
);

export default ButtonColor;
