import React from 'react';
import { StyleSheet, Text } from 'react-native';
import { colors } from '@style/colors';
import { Font } from '@style/Fonts';

const MyText = ({ children, isRegular = true, customStyle, h1, h2, h3, h4, h5, onPress, numberOfLines, props }) => {
  const returnFontsize = () => {
    if (h1) return 10;
    if (h2) return 12;
    if (h3) return 14;
    if (h4) return 16;
    if (h5) return 18;
  };
  return (
    <Text
      {...props}
      numberOfLines={numberOfLines}
      ellipsizeMode="tail"
      onPress={onPress}
      style={[!isRegular ? styles.bold : styles.regular, { fontSize: returnFontsize() }, customStyle]}
    >
      {children}
    </Text>
  );
};

const styles = StyleSheet.create({
  bold: {
    fontFamily: Font.Nunito_Bold,
    color: colors.black,
  },
  regular: {
    fontFamily: Font.Nunito_Regular,
    color: colors.black,
  },
});

export default MyText;
