import React from 'react';
import { View } from 'react-native';
import MyText from '@atoms/MyText';
import styles from './style';

const TitleText = ({ title }) => (
  <View style={styles.titleTextContainer}>
    <MyText isRegular={false} customStyle={styles.textStyle}>
      {title}
    </MyText>
  </View>
);
export default TitleText;
