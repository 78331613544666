export const TRANSACTION_REDUCER_CASES = {
  verifyWithdrawal: 'verifyWithdrawal',
  verifyBillPayment: 'verifyBillPayment',
  setReason: 'setReason',
  resetTransaction: 'resetTransaction',
  resetInquiry: 'resetInquiry',
  setInquiry: 'setInquiry',
  setUserAccountNumber: 'setUserAccountNumber',
  setTransactionCategoryId: 'setTransactionCategoryId',
  setTransactionSource: 'setTransactionSource',
};

const initialState = {
  requestAmount: 0,
  bankName: '',
  accountNumber: '',
  userAccountNumber: '',
  feeAmount: 0,
  actualAmount: 0,
  transactionId: '',
  inquiry: {},
  selectedReason: '',
  selectedReasonId: 0,
  selectedReasonColor: '',
  productName: '',
  description: '',
  beneficiaryName: '',
  transCategoryId: 'wd-01',
  transSource: 'home_page',
};

export default (state = initialState, action) => {
  switch (action?.type) {
    case TRANSACTION_REDUCER_CASES.setTransactionCategoryId:
      return {
        ...state,
        transCategoryId: action.payload,
      };

    case TRANSACTION_REDUCER_CASES.setTransactionSource:
      return {
        ...state,
        transSource: action.payload,
      };
    case TRANSACTION_REDUCER_CASES.verifyWithdrawal:
      return {
        ...state,
        requestAmount: action.payload.requestAmount,
        bankName: action.payload.bank.bankName,
        accountNumber: action.payload.bankAccountNo,
        feeAmount: action.payload.fee,
        actualAmount: action.payload.subtotal,
        transactionId: action.payload.id,
        beneficiaryName: action.payload.beneficiaryName,
      };

    case TRANSACTION_REDUCER_CASES.verifyBillPayment:
      return {
        ...state,
        inquiry: action.payload,
        transactionId: action.payload.id,
        userAccountNumber: action.payload.accountNumber,
        productName: action.payload.productName,
      };

    case TRANSACTION_REDUCER_CASES.setReason:
      return {
        ...state,
        selectedReason: action.payload.reason,
        selectedReasonId: action.payload.reasonId,
        selectedReasonColor: action.payload.reasonColor,
        description: action.payload.description,
      };

    case TRANSACTION_REDUCER_CASES.setInquiry:
      return {
        ...state,
        inquiry: action.payload,
      };

    case TRANSACTION_REDUCER_CASES.setUserAccountNumber:
      return {
        ...state,
        userAccountNumber: action.payload,
      };

    case TRANSACTION_REDUCER_CASES.resetTransaction:
      return initialState;

    case TRANSACTION_REDUCER_CASES.resetInquiry:
      return {
        ...state,
        inquiry: {},
      };

    default:
      return state;
  }
};
