import { REFERRAL_CASES } from "../reducer/Referral";

export const setIsValidReferral = (data, loading = false) => ({
    type: REFERRAL_CASES.isValidCode,
    payload: {
        data,
        loading:loading
    }
})

export const setReferralError = (data) => ({
    type: REFERRAL_CASES.setReferralError,
    payload: data
})

export const setReferralLoading = (data) => ({
    type: REFERRAL_CASES.setReferralLoading,
    payload: data
})

export const setIsTransacted = (data) => ({
    type: REFERRAL_CASES.isTransacted,
    payload: data
})

export const setRedeemCode = (data) => ({
    type: REFERRAL_CASES.redeemCode,
    payload: data
})

export const setRedeemCodeLoading = (data) => ({
    type: REFERRAL_CASES.setRedeemCodeLoading,
    payload: data
})

export const setAppOpenedFromDeepLink = (data) => ({
    type: REFERRAL_CASES.appOpenedFromDeeplink,
    payload: data
})

export const clearReferral = () => ({
    type: REFERRAL_CASES.clearReferral,
})