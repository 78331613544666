export const FEATURE_FLAGS_CASES = {
  setFeatureFlags: 'setFeatureFlags',
  setFeatureFlagLoading: 'setFeatureFlagLoading',
  setFeatureFLagError: 'setFeatureFLagError',
};

const initialState = {
  featureFlags: [],
  loading: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FEATURE_FLAGS_CASES.setFeatureFlags:
      return {
        ...state,
        featureFlags: action.payload.data,
        loading: action.payload.loading,
      };

    case FEATURE_FLAGS_CASES.setFeatureFLagError:
      return {
        ...state,
        error: action.payload,
      };

    case FEATURE_FLAGS_CASES.setFeatureFlagLoading:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
