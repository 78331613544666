import { StyleSheet } from 'react-native';
import { colors } from '@style/colors';
import { normalize } from '@style/helper';

const styles = StyleSheet.create({
  innerContainer: {
    backgroundColor: colors.white,
    width: '100%',
    borderTopEndRadius: 15,
    borderTopStartRadius: 15,
    paddingHorizontal: normalize(5),
    paddingBottom: 15,
  },
  modal: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: normalize(10),
  },
  titleText: { paddingLeft: normalize(9), marginBottom: normalize(20) },
  dividerStyle: { borderWidth: 0.4, borderColor: colors.gainsboro },
});

export default styles;
