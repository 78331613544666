import React from 'react';
import { View, Pressable } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import MCI from 'react-native-vector-icons/MaterialCommunityIcons';
import { PHONE_ENROLLMENT_STATUS } from '@gaji-gesa/gg-react-shared/src/utils/sharedConstants';
import MyText from '@atoms/MyText';
import PropTypes from 'prop-types';
import { styles } from './stlyle';

export const PHONE_ENROLLMENT_META = {
  [PHONE_ENROLLMENT_STATUS.PENDING]: {
    text: 'Dalam Verifikasi',
    style: styles.pendingStyle,
  },
  [PHONE_ENROLLMENT_STATUS.REJECTED]: {
    text: 'Ditolak',
    style: styles.rejectedStyle,
  },
};

export function shouldRenderBadge(status) {
  if (
    status === PHONE_ENROLLMENT_STATUS.PENDING ||
    status === PHONE_ENROLLMENT_STATUS.REJECTED
  ) {
    return (
      <View style={[styles.badgeStyle, PHONE_ENROLLMENT_META[status].style]}>
        <MyText
          isRegular={false}
          customStyle={[
            styles.badgeTextStyle,
            PHONE_ENROLLMENT_META[status].style,
          ]}
        >
          {PHONE_ENROLLMENT_META[status].text}
        </MyText>
      </View>
    );
  }

  return null;
}

export function shouldRenderCTA(
  isTransactional,
  status,
  onPressDeleteHandler,
  showTick
) {
  if (status === PHONE_ENROLLMENT_STATUS.PENDING) return null;

  if (isTransactional) {
    if (showTick) return <MCI name="check" style={styles.showTickStyle} />;
    return <Icon name="keyboard-arrow-right" style={styles.rightArrowStyle} />;
  }

  if (status !== PHONE_ENROLLMENT_STATUS.PENDING)
    return (
      <Pressable onPress={onPressDeleteHandler}>
        <MCI name="delete" style={styles.deleteIconStyle} />
      </Pressable>
    );

  return null;
}

export function getOpacity(status) {
  if (status === PHONE_ENROLLMENT_STATUS.PENDING) return { opacity: 0.4 };
}

const ListItem = ({
  phoneNumber,
  ownerName,
  onPresHandler,
  onDeletePress,
  disabled,
  status,
  isTransact,
  showTick,
}) => {
  return (
    <Pressable
      disabled={disabled}
      onPress={onPresHandler}
      style={styles.Group991}
    >
      <View style={styles.Frame13537}>
        <View style={getOpacity(status)}>
          <View style={styles.RekeningNumber}>
            <MyText isRegular customStyle={styles.phoneNumberStyle}>
              {phoneNumber}
            </MyText>
          </View>
          <View style={styles.Name_provider}>
            <MyText isRegular={false} customStyle={styles.ownerStyle}>
              {ownerName}
            </MyText>
          </View>
        </View>

        <View style={styles.badgeCtaContainer}>
          {shouldRenderBadge(status)}
          {shouldRenderCTA(isTransact, status, onDeletePress, showTick)}
        </View>
      </View>
      <View style={styles.Line2} />
    </Pressable>
  );
};

ListItem.propTypes = {
  phoneNumber: PropTypes.string,
  ownerName: PropTypes.string,
  onPresHandler: PropTypes.func,
  onDeletePress: PropTypes.func,
  disabled: PropTypes.bool,
  status: PropTypes.string,
  isTransact: PropTypes.bool,
};

export default ListItem;
