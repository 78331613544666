export const COMMON_REDUCER_CASES = {
  respSuccess: 'respSuccess',
  respError: 'respError',
  respReset: 'respReset',
  setShowTerms: 'setShowTerms',
  setAcceptedTerms: 'setAcceptedTerms',
  setLoanTerms: 'setLoanTerms',
  setConfirmed: 'setConfirmed',
  updateShowModal: 'updateShowModal',
  setPageFailed: 'setPageFailed',
  resetModalType: 'resetModalType',
  setShowModal: 'setShowModal',
  setModalType: 'setModalType',
  setCanContinue: 'setCanContinue',
  setDisabledSubmit: 'setDisabledSubmit',
  setDisabledDate: 'setDisabledDate',
  setDisabledToday: 'setDisabledToday',
  setGlobalLoading: 'setGlobalLoading',
  setToast: 'setToast',
  setParentPage: 'setParentPage',
  setTour: 'setTour',
  setStories: 'setStories',
  setSource: 'setSource',
  setActiveSavingDetailsTab: 'setActiveSavingDetailsTab',
  setAppTourParams: 'setAppTourParams',
  resetAppTourParams: 'resetAppTourParams',
  setSeenProfile: 'setSeenProfile',
  setSeenLoan: 'setSeenLoan',
  setSeenZakat: 'setSeenZakat',
  setLatestPWA: 'setLatestPWA',
  setShowPinRegister: 'setShowPinRegister',
  setVerifiedPin: 'setVerifiedPin',
  warn: 'warn',
};

const initialState = {
  respMessage: {
    type: '',
    message: '',
    toastDuration: 2000,
    positionValue: 15,
    positionType: 'top',
  },
  showTerms: false,
  acceptedTerms: false,
  confirmed: false,
  showModal: false,
  modalType: '',
  pageFailed: '',
  canContinue: false,
  disabledSubmit: false,
  disabledDate: false,
  disabledToday: false,
  toast: {
    visible: false,
    message: '',
    duration: 0,
    gravity: '',
  },
  parentPage: '',
  tour: '',
  stories: [],
  showStories: false,
  activeSavingDetailTab: 'summary',
  eventSource: 'home_page',
  deepLink: '',
  appTourParams: {
    type: null,
    step: 1,
    isLast: false,
    isPrevious: null,
  },
  seenProfile: false,
  seenLoan: false,
  seenZakat: false,
  securityPin: {
    showPinRegister: false,
    invalidAttempts: 0,
    isActive: false,
    verifiedPin: false,
  },
};

export default (state = initialState, action) => {
  switch (action?.type) {
    case COMMON_REDUCER_CASES.setTour:
      return {
        ...state,
        tour: action.payload,
      };

    case COMMON_REDUCER_CASES.setStories:
      return {
        ...state,
        stories: action.payload.stories,
        showStories: action.payload.showStories,
      };

    case COMMON_REDUCER_CASES.respSuccess:
      return {
        ...state,
        respMessage: { ...state.respMessage, ...action.payload, type: 'success' },
      };

    case COMMON_REDUCER_CASES.respError:
      return {
        ...state,
        respMessage: { ...state.respMessage, ...action.payload, type: 'error' },
      };

    case COMMON_REDUCER_CASES.warn:
      return {
        ...state,
        respMessage: { ...state.respMessage, ...action.payload, type: 'warn' },
      };

    case COMMON_REDUCER_CASES.respReset:
      return { ...state, respMessage: initialState.respMessage };

    case COMMON_REDUCER_CASES.setShowTerms:
      return { ...state, showTerms: action.payload };

    case COMMON_REDUCER_CASES.setAcceptedTerms:
      return { ...state, acceptedTerms: !state.acceptedTerms };

    case COMMON_REDUCER_CASES.setLoanTerms:
      return { ...state, acceptedTerms: action.payload };

    case COMMON_REDUCER_CASES.setConfirmed:
      return { ...state, confirmed: action.payload };

    case COMMON_REDUCER_CASES.updateShowModal:
      return {
        ...state,
        showModal: !state.showModal,
        modalType: action.payload,
      };

    case COMMON_REDUCER_CASES.setPageFailed:
      return { ...state, pageFailed: action.payload };

    case COMMON_REDUCER_CASES.resetModalType:
      return { ...state, modalType: '' };

    case COMMON_REDUCER_CASES.setShowModal:
      return { ...state, showModal: action.payload };

    case COMMON_REDUCER_CASES.setModalType:
      return { ...state, modalType: action.payload };

    case COMMON_REDUCER_CASES.setCanContinue:
      return { ...state, canContinue: action.payload };

    case COMMON_REDUCER_CASES.setDisabledSubmit:
      return { ...state, disabledSubmit: action.payload };

    case COMMON_REDUCER_CASES.setDisabledDate:
      return { ...state, disabledDate: action.payload };

    case COMMON_REDUCER_CASES.setDisabledToday:
      return { ...state, disabledToday: action.payload };

    case COMMON_REDUCER_CASES.setGlobalLoading:
      return { ...state, globalLoading: action.payload };

    case COMMON_REDUCER_CASES.setToast:
      return { ...state, toast: action.payload };

    case COMMON_REDUCER_CASES.setParentPage:
      return { ...state, parentPage: action.payload };

    case COMMON_REDUCER_CASES.setSource:
      return { ...state, eventSource: action.payload };

    case COMMON_REDUCER_CASES.setActiveSavingDetailsTab:
      return { ...state, activeSavingDetailTab: action.payload };

    case COMMON_REDUCER_CASES.setAppTourParams:
      return { ...state, appTourParams: { ...state.appTourParams, ...action.payload } };

    case COMMON_REDUCER_CASES.resetAppTourParams:
      return { ...state, appTourParams: initialState.appTourParams };

    case COMMON_REDUCER_CASES.setSeenProfile:
      return { ...state, seenProfile: action.payload };

    case COMMON_REDUCER_CASES.setSeenLoan:
      return { ...state, seenLoan: action.payload };

    case COMMON_REDUCER_CASES.setSeenZakat:
      return { ...state, seenZakat: action.payload };

    case COMMON_REDUCER_CASES.setLatestPWA:
      return { ...state, latestPWA: action.payload };

    case COMMON_REDUCER_CASES.setShowPinRegister:
      return { ...state, securityPin: action.payload };

    case COMMON_REDUCER_CASES.setVerifiedPin:
      return { ...state, securityPin: { ...state.securityPin, verifiedPin: action.payload } };

    default:
      return state;
  }
};
