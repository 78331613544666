import { gql } from '@apollo/client';

const WITHDRAWAL_REQUEST = gql`
  query withdrawalRequest($employeeId: Int) {
    gg_employee(where: { id: { _eq: $employeeId } }) {
      bankId
      bankAccountNo
      beneficiaryName
      bank_name {
        bankName
        bankCode
      }
      salaryProfiles {
        fee
        feeType
      }
    }
  }
`;

export { WITHDRAWAL_REQUEST };
