import React, { useRef, useEffect } from 'react';
import { UIManager, findNodeHandle } from 'react-native';
import { normalize } from '@style/helper';
import CustomTooltip from './CustomTooltip';

const ToolTip = ({ children, description, onClose, placement = 'UP', isVisible, positionValue = 10 }) => {
  const [layoutTooltip, setLayoutTooltip] = React.useState({ x: 0, y: 0 });
  const cRef = useRef(null);

  const measure = () => {
    if (cRef.current) {
      UIManager.measure(findNodeHandle(cRef.current), (x, y, width, height, pageX, pageY) => {
        setLayoutTooltip({ x: pageX + width / 2, y: pageY });
      });
    }
  };

  useEffect(() => {
    if (isVisible) measure();
  }, [isVisible]);

  return (
    <>
      {React.cloneElement(children, {
        ...children.props,
        ref: cRef,
        onLayout: measure,
      })}
      {layoutTooltip.x > 0 && (
        <CustomTooltip
          description={description}
          isVisible={isVisible}
          tooltipPosValue={{ x: layoutTooltip.x, y: layoutTooltip.y + normalize(positionValue) }}
          onClose={onClose}
          toolTipType={placement}
        />
      )}
    </>
  );
};

export default ToolTip;
