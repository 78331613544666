export const TRANSACTION_DETAILS_CASES = {
  setBalances: 'setBalances',
  setTransactionList: 'setTransactionList',
  setAccuredSalary: 'setAccuredSalary',
  setFilter: 'setFilter',
  setReasonsTransaction: 'setReasonsTransaction',
  setEmployeePaymentCycle: 'setEmployeePaymentCycle',
  changeExpense: 'changeExpense',
  changeIncome: 'changeIncome',
  changeTransactionList: 'changeTransactionList',
  setAllExpense: 'setAllExpense',
  setAllIncome: 'setAllIncome',
};

const initialState = {
  balance: 0,
  totalIncome: 0,
  totalExpense: 0,
  accuredSalary: 0,
  transactionList: [],
  filter: {},
  reasons: {
    expense: [],
    income: [],
  },
  paymentCycles: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TRANSACTION_DETAILS_CASES.setAllIncome:
      const allIncome = state.reasons.income.slice();
      return {
        ...state,
        reasons: {
          ...state.reasons,
          income: allIncome.map((e) => ({ ...e, isChecked: action.payload })),
        },
      };

    case TRANSACTION_DETAILS_CASES.setAllExpense:
      const allExpense = state.reasons.expense.slice();
      return {
        ...state,
        reasons: {
          ...state.reasons,
          expense: allExpense.map((e) => ({ ...e, isChecked: action.payload })),
        },
      };

    case TRANSACTION_DETAILS_CASES.changeTransactionList:
      const newTransaction = state.transactionList.slice();
      const findTransaction = newTransaction.findIndex((e) => e.transHistoryId === action.payload.transHistoryId);
      if (findTransaction !== -1) {
        newTransaction[findTransaction] = {
          ...newTransaction[findTransaction],
          transactionReasonId: action.payload.transactionReasonId,
          description: action.payload.description,
          transReason: action.payload.transReason,
        };
      }

      return {
        ...state,
        transactionList: newTransaction,
      };

    case TRANSACTION_DETAILS_CASES.changeIncome:
      const newIncome = state.reasons.income.slice();
      const findIndexIncome = newIncome.findIndex((e) => e.id === action.payload);
      if (findIndexIncome !== -1) {
        newIncome[findIndexIncome] = {
          ...newIncome[findIndexIncome],
          isChecked: !newIncome[findIndexIncome].isChecked,
        };
      }

      return {
        ...state,
        reasons: {
          ...state.reasons,
          income: newIncome,
        },
      };

    case TRANSACTION_DETAILS_CASES.changeExpense:
      const newExpense = state.reasons.expense.slice();
      const findIndex = newExpense.findIndex((e) => e.id === action.payload);
      if (findIndex !== -1) {
        newExpense[findIndex] = {
          ...newExpense[findIndex],
          isChecked: !newExpense[findIndex].isChecked,
        };
      }

      return {
        ...state,
        reasons: {
          ...state.reasons,
          expense: newExpense,
        },
      };

    case TRANSACTION_DETAILS_CASES.setAccuredSalary:
      return {
        ...state,
        accuredSalary: action.payload,
      };

    case TRANSACTION_DETAILS_CASES.setEmployeePaymentCycle:
      return {
        ...state,
        paymentCycles: action.payload,
      };

    case TRANSACTION_DETAILS_CASES.setBalances:
      return {
        ...state,
        balance: action.payload.balance,
        totalIncome: action.payload.totalIncome,
        totalExpense: action.payload.totalExpense,
      };

    case TRANSACTION_DETAILS_CASES.setAccuredSalary:
      return {
        ...state,
        accuredSalary: action.payload,
      };

    case TRANSACTION_DETAILS_CASES.setTransactionList:
      return {
        ...state,
        transactionList: action.payload,
      };

    case TRANSACTION_DETAILS_CASES.setFilter:
      return {
        ...state,
        filter: action.payload,
      };

    case TRANSACTION_DETAILS_CASES.setReasonsTransaction:
      return {
        ...state,
        reasons: {
          ...state.reasons,
          [action.payload.childKey]: action.payload.data,
        },
      };
    default:
      return state;
  }
};
