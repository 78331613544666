import { StyleSheet } from 'react-native';
import { normalize, normalizeHeight } from '../../../sharedStyle/helper';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: colors.neutral_mist,
    alignItems: 'center',
  },
  textStyle: {
    textAlign: 'center',
    marginVertical: normalize(20),
    fontSize: normalize(16),
  },
  loadinButtonStyle: { fontSize: normalize(12), textTransform: 'capitalize' },
  buttonContainerStyle: {
    paddingVertical: normalizeHeight(10),
    elevation: 0,
    backgroundColor: colors.primary_carnation,
    color: colors.black,
    width: '85%',
    marginHorizontal: normalize(15),
    borderRadius: normalize(30),
  },
  imageContainer: { height: normalize(120), width: normalize(120) },
});
