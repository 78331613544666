import React, { useCallback, useMemo } from 'react';
import ExpoMatIcon from '@expo/vector-icons/MaterialIcons';
import RNMatIcon from 'react-native-vector-icons/MaterialIcons';
import SimpleIcon from 'react-native-vector-icons/SimpleLineIcons';
import ExpoSimpleIcon from '@expo/vector-icons/SimpleLineIcons';
import { colors } from '@style/colors';
import { normalize, normalizeHeight } from '@style/helper';
import Toast from 'react-native-easy-toast';
import { StyleSheet, View, Text, Platform } from 'react-native';
import { Font } from '@style/Fonts';

const MIcon = Platform.OS === 'web' ? ExpoMatIcon : RNMatIcon;
const SIcon = Platform.OS === 'web' ? ExpoSimpleIcon : SimpleIcon;

const CustomToast = React.forwardRef(
  ({ type, positionType, positionValue }, ref) => {
    const toastBgColor = useMemo(() => {
      if (type === 'error') {
        return colors.toastError;
      }
      if (type === 'success') {
        return colors.toastSuccess;
      }

      return colors.warning;
    }, [type]);

    return (
      <Toast
        ref={ref}
        fadeOutDuration={2000}
        position={positionType}
        positionValue={normalize(positionValue)}
        //	toastIcon ={<MIcon name= {type == 'error' ?"highlight-off" : "check-circle-outline"} size={normalize(27)} color={colors.white} />}
        style={[
          styles.content,
          {
            backgroundColor: toastBgColor,
          },
        ]}
      />
    );
  }
);

export default React.memo(CustomToast);

export const ToastView = ({ message, type }) => {
  const returnToastIcon = useCallback(() => {
    if (type === 'error') {
      return (
        <MIcon
          name={'highlight-off'}
          size={normalize(21)}
          color={colors.white}
        />
      );
    }
    if (type === 'success') {
      return (
        <MIcon
          name={'check-circle-outline'}
          size={normalize(21)}
          color={colors.white}
        />
      );
    }
    return <SIcon name={'clock'} size={normalize(21)} color={colors.black} />;
  }, [type]);

  const toastTextColor = useMemo(() => {
    if (type === 'error' || type === 'success') {
      return colors.white;
    }

    return colors.black;
  }, [type]);

  return (
    <>
      <View style={styles.iconView}>{returnToastIcon()}</View>
      <View style={styles.textView}>
        <Text style={[styles.text, { color: toastTextColor }]}>{message}</Text>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  content: {
    //  justifyContent: "center",
    backgroundColor: colors.toastSuccess,
    borderRadius: 8,
    flexDirection: 'row',
    marginHorizontal: '4%',
    width: '90%',
    paddingVertical: normalizeHeight(8),
    paddingHorizontal: normalize(5),
  },
  text: {
    color: colors.white,
    fontSize: normalize(12.5),
    fontFamily: Font.Nunito_Bold,
  },
  iconView: {
    paddingLeft: normalize(10),
    paddingRight: normalize(4),
    justifyContent: 'center',
    alignItems: 'center',
  },
  textView: {
    maxWidth: '90%',
    paddingLeft: normalize(10),
    paddingRight: normalize(7),
    justifyContent: 'center',
  },
});
