import React from 'react';
import { View } from 'react-native';
import MyText from '@atoms/MyText';
import styles from './style.js';

const Item = ({ icon, title }) => (
  <View style={styles.itemContainer}>
    <View>{icon}</View>
    <MyText isRegular customStyle={styles.itemStyle}>
      {title}
    </MyText>
  </View>
);

export default Item;
