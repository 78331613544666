import { StyleSheet } from 'react-native';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { normalize } from '../../../sharedStyle/helper';

const styles = StyleSheet.create({
  titleText: { fontSize: normalize(14) },
  limitRowStyle: {
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  absoluteContainer: {
    marginHorizontal: normalize(15),
    borderRadius: normalize(12),
    backgroundColor: colors.neutral_cotton,
    elevation: 5,
    shadowColor: colors.blackColor,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  topCotainerRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: normalize(12),
  },
  iconStyle: { alignSelf: 'center', marginRight: normalize(6) },
  ewaBalance: {
    alignSelf: 'center',
    fontSize: normalize(23),
    color: colors.success_cactus,
    marginVertical: normalize(7),
  },

  collapsibleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: normalize(15),
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: colors.neutral_mist,
    marginTop: normalize(11),
  },
  collapsibleSubValueRow: { flexDirection: 'row', alignItems: 'center' },
  totalExpsenseRowContainer: {
    flexDirection: 'row',
    paddingVertical: normalize(15),
    paddingHorizontal: normalize(15),
    paddingRight: normalize(23),
    justifyContent: 'space-between',
  },
  totalExpenseValue: { marginRight: normalize(11), fontSize: normalize(12) },

  limitTextStyle: { marginRight: normalize(4), fontSize: normalize(12) },
  limitValueStyle: { fontSize: normalize(11.5), marginRight: normalize(4) },
  accuredTitleText: { fontSize: normalize(12) },
  accuredValueText: { marginRight: normalize(10), fontSize: normalize(12) },
  totalExpenseText: { fontSize: normalize(12) },
  rowAdj: { marginTop: normalize(-13) },
});
export default styles;
