import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import MyText from '@atoms/MyText';
import { SVG_ICONS } from '../../../uiUtils/SvgIcons';
import { numberWithDots } from '@gaji-gesa/gg-react-shared/src/utils/sharedUtils';
import { styles } from './style';

const BenifitBox = ({ poinReward, title, description, containerStyle }) => (
  <View style={[styles.container, containerStyle]}>
    <View style={styles.poinContainer}>
      <View>{SVG_ICONS.coin4}</View>
      <MyText
        isRegular={false}
        customStyle={styles.benifitPoinTextStyle}
      >{`${numberWithDots(poinReward)} Poin`}</MyText>
    </View>
    <MyText isRegular={false} customStyle={styles.benifitTextStyle}>
      {title}
    </MyText>
    <MyText customStyle={styles.benifitDescStyle}>{description}</MyText>
  </View>
);

BenifitBox.propTypes = {
  poinReward: PropTypes.number,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  containerStyle: PropTypes.object,
};
BenifitBox.defaultProps = {
  poinReward: 0,
  containerStyle: {},
};
export default BenifitBox;
