import React from 'react';
import { View } from 'react-native';
import MyText from '@atoms/MyText';
import styles from './style';

const Row = ({ title, value, containerStyle }) => (
  <View style={[styles.innerRowStyle, containerStyle]}>
    <MyText customStyle={styles.titleTextStyle} isRegular={false}>
      {title}
    </MyText>
    <MyText isRegular customStyle={styles.innterRowValue}>
      {value}
    </MyText>
  </View>
);

export default Row;
