import React from 'react';
import { View, Linking } from 'react-native';
import PropTypes from 'prop-types';
import MyText from '@atoms/MyText';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { LOAN_READ_LINK } from '@gaji-gesa/gg-react-shared/src/utils/sharedConstants';
import { styles } from './style';

const HYPERLINK_TEXT = 'Baca syarat dan ketentuan selengkapnya di sini';

const LoanMetaItem = ({ item, index }) => {
  return (
    <View style={styles.termsRow}>
      <View style={styles.termsIconContainer}>
        <View style={styles.circlePlaceholder}>
          <MyText customStyle={styles.numericText}>{index + 1}</MyText>
        </View>
      </View>
      <View style={styles.termsTextContainer}>
        <MyText isRegular customStyle={styles.termsTextStyle}>
          {item}
          {item === HYPERLINK_TEXT && (
            <MyText
              customStyle={{ color: colors.secondary_orchid }}
              onPress={() => Linking.openURL(LOAN_READ_LINK)}
            >
              {' '}
              {LOAN_READ_LINK}
            </MyText>
          )}
        </MyText>
      </View>
    </View>
  );
};

LoanMetaItem.propTypes = {
  item: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
};

export default LoanMetaItem;
