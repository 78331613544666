import { colors } from '../appColors';
import moment from 'moment';
import haversine from 'haversine-distance';
import {
  ATTENDANCE_COLORS,
  ATTENDANCE_ICONS,
  BP_CATEGORY_NAMES,
  CATEGORY,
  DYNAMIC_ACCOUNT_NAMES,
  PAYSLIP,
  POSTPAID_VALIDATION_VALUE,
  VOUCHER_INV_STATUS,
  VOUCHER_TEXT_CONSTANTS,
  COMMON_CONSTANTS,
  BOTTONSHEET_KEYS,
  PHONE_ENROLLMENT_STATUS,
} from './sharedConstants';
import { BP_APP_TOUR } from './sharedAppTourConstants';
import { createNewBankAccount, deleteBankAccount } from '../redux/action/UserBanks';
import { respWarn } from '../redux/action/Common';
import { SHARED_AMPLITUDE_CONSTANTS } from './amplitudeConstants';

export const numberWithDots = (x) => x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

export const formatPhoneNumber = (phoneNumber, countryCode = '62') => {
  if (phoneNumber === '') return '';
  const firstTwoChar = phoneNumber.substring(0, 2);
  const firstChar = phoneNumber.substring(0, 1);
  if (firstTwoChar === '62') {
    return `${countryCode}${phoneNumber.substring(2, phoneNumber.length)}`;
  }
  if (firstChar === '0') {
    return `${countryCode}${phoneNumber.substring(1, phoneNumber.length)}`;
  }
  return `${countryCode}${phoneNumber}`;
};

export const formatUnderScoreLowerCase = (keyWord) => {
  if (!keyWord || keyWord.length == 0) {
    return keyWord;
  }
  return keyWord?.toLowerCase()?.replace(/ /g, '_') ?? '';
};

export const removeNonNumeric = (num) => num?.toString().replace(/[^0-9]/g, '');

export function sharedSortedPointsBp(e) {
  switch (e.prefix) {
    case 'BP-05':
      return {
        ...e,
        priority: 3,
      };

    case 'BP-06':
      return {
        ...e,
        priority: 8,
      };

    case 'BP-07':
      return {
        ...e,
        priority: 5,
      };

    case 'BP-08':
      return {
        ...e,
        priority: 7,
      };

    case 'BP-10':
      return {
        ...e,
        priority: 2,
      };

    case 'BP-13':
      return {
        ...e,
        priority: 1,
      };

    case 'BP-17':
      return {
        ...e,
        priority: 6,
      };

    default:
      return {
        ...e,
        priority: 10,
      };
  }
}

export const formatShorBPList = (list, pointBalance) => {
  let concatenatedBpList = [];
  for (let index = 0; index < list.length; index++) {
    concatenatedBpList.push(...list[index].data[0].list);
  }

  let sortedConcatenatedList = [];

  sortedConcatenatedList = concatenatedBpList
    .filter(
      (e) =>
        !(pointBalance < POSTPAID_VALIDATION_VALUE[e.prefix]?.value || e.prefix === 'PR-01' || e.prefix === 'BP-15'),
    )
    .map((e) => sharedSortedPointsBp(e));

  sortedConcatenatedList.sort((a, b) => {
    return a.priority - b.priority;
  });
  if (sortedConcatenatedList.length === 3) {
    let others = {
      id: '100000',
      name: 'Lainnya',
      imageUrl: COMMON_CONSTANTS.othersImageUrl,
      priority: 4,
    };
    sortedConcatenatedList.push(others);

    return sortedConcatenatedList;
  } else if (sortedConcatenatedList.length > 3) {
    let others = {
      id: '100000',
      name: 'Lainnya',
      imageUrl: COMMON_CONSTANTS.othersImageUrl,
      priority: 4,
    };
    const newConcatenatedList = sortedConcatenatedList.slice(0, 3);
    newConcatenatedList.sort((a, b) => {
      return a.priority - b.priority;
    });
    newConcatenatedList.push(others);
    return newConcatenatedList;
  }

  return sortedConcatenatedList;
};

export const getSdTransactionReasons = (reasons) => reasons.filter((e) => e.reason === 'Pencairan Gaji');
export const getWorkAllowanceReasons = (reasons) => reasons.filter((e) => e.reason === 'Biaya Lainnya');

export const getDynamicAcountListByModule = (localAccounts, staticAccounts) => {
  const array = [];
  localAccounts.forEach((e) => {
    if (staticAccounts.find((x) => x === e.constantAccountName)) {
      array.push({
        ...e,
        text: e.accountName,
      });
    }
  });
  return array;
};

export const checkIfHasSdAccount = (dynamicAccounts, dataAccounts) => {
  const findSdAccount = dynamicAccounts.findIndex((e) => e.accountTypeName === DYNAMIC_ACCOUNT_NAMES.SDACCOUNT);
  if (findSdAccount === -1) {
    const findSdAccountInLocalAccount = dataAccounts.findIndex(
      (e) => e.constantAccountName === DYNAMIC_ACCOUNT_NAMES.SDACCOUNT,
    );
    if (findSdAccountInLocalAccount !== -1) {
      dataAccounts.splice(findSdAccountInLocalAccount, 1);
    }
    return false;
  }
  return true;
};

const removeUnavailableDynamicAccounts = (availableDynamicAccounts, dataAccounts) => {
  const newDataAccounts = [];
  dataAccounts.forEach((e) => {
    const isAccountAvailable = availableDynamicAccounts.includes(e.constantAccountName);
    if (isAccountAvailable) {
      newDataAccounts.push(e);
    }
    if (!e.isRealDynamicAccount && !isAccountAvailable) {
      newDataAccounts.push(e);
    }
  });
  return newDataAccounts;
};

export const syncWithDynamicAcounts = (dynamicAccounts, dataAccounts) => {
  const availableDynamicAccounts = [];
  let newDataAccounts = dataAccounts.slice();
  dynamicAccounts.forEach((e) => {
    availableDynamicAccounts.push(e.accountTypeName);
    const findLocalAccount = dataAccounts.findIndex((x) => x.constantAccountName === e.accountTypeName);
    if (findLocalAccount !== -1) {
      newDataAccounts[findLocalAccount] = {
        ...newDataAccounts[findLocalAccount],
        id: e.id,
        amount: `Rp${numberWithDots(e.availableBalance)}`,
        accountName:
          e.accountTypeName === DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE
            ? e.accountName
            : newDataAccounts[findLocalAccount].accountName, // Work allowance can have dynamic names
      };
    }
  });

  newDataAccounts = removeUnavailableDynamicAccounts(availableDynamicAccounts, newDataAccounts);
  return newDataAccounts;
};

export const findAccountByName = (dataAccounts, accountTypeName) =>
  dataAccounts.find((x) => x.constantAccountName === accountTypeName);

export const findDynamicAccount = (dynamicAccounts, accountTypeName) =>
  dynamicAccounts.find((e) => e.accountTypeName === accountTypeName);

export const sortVouchersByPurchase = (vouchersList) => {
  const purchasedList = [];
  const redeemList = [];
  const expiredList = [];
  vouchersList.forEach((v) => {
    if (v.status === VOUCHER_INV_STATUS.PURCHASED) purchasedList.push(v);
    if (v.status === VOUCHER_INV_STATUS.REDEEMED) redeemList.push(v);
    if (v.status === VOUCHER_INV_STATUS.EXPIRED) expiredList.push(v);
  });

  return purchasedList.concat(redeemList, expiredList);
};

export const sortTransactionDetailsByPriority = (arr) =>
  arr.sort((a, b) => new Date(a.priority) - new Date(b.priority));

export const getChangedField = (oldReason, newReason, oldDesc, newDesc) => {
  if (oldReason != newReason && oldDesc != newDesc) return 'description,reason';
  if (oldReason != newReason) return 'reason';
  if (oldDesc != newDesc) return 'description';
};

export const formatBillPaymentCategories = (list, key) => {
  if (key === BP_CATEGORY_NAMES.ISI_ULANG) {
    const prepaidList = [];
    for (let index = 0; index < list.length; index++) {
      // Doing this because BE sends a lot of BP categories which are not meant to be shown on FE, therefore we are just taking those which are available in our BP config on FE
      if (!!CATEGORY[list[index].id]?.id) {
        prepaidList.push(list[index]);
      }
    }
    return prepaidList;
  } else {
    return list.map((e) => {
      if (key === 'Kebutuhan Sehari-hari') {
        // BE giving Voucher product name as "Voucher Digital Prepaid", need to show just "Voucher"
        return {
          ...e,
          name: 'Voucher',
        };
      }
      if (e.active) return e;
    });
  }
};

export const formatBillPayment = (billpaymentDataFromServer) => {
  const billpaymentList = [];
  for (const key in billpaymentDataFromServer ?? {}) {
    const section = {
      title: key,
      data: [
        {
          title: key,
          list: formatBillPaymentCategories(billpaymentDataFromServer[key], key),
        },
      ],
    };

    if (section.data[0].list.length > 0) billpaymentList.push(section);
  }
  return billpaymentList;
};

export const removePointsDisabledBp = (billpaymentList, pointBalance) => {
  if (billpaymentList.length === 0) return [];
  const newbillpaymentList = JSON.parse(JSON.stringify(billpaymentList));
  const findPostpaid = newbillpaymentList.findIndex((e) => e.title === BP_CATEGORY_NAMES.TAGIHAN);
  const postpaidList = [];
  if (findPostpaid !== -1) {
    newbillpaymentList[findPostpaid]?.data[0]?.list.forEach((e) => {
      if (pointBalance >= POSTPAID_VALIDATION_VALUE[e.prefix]?.value) {
        postpaidList.push(e);
      }
    });
    newbillpaymentList[findPostpaid].data[0] = {
      ...newbillpaymentList[findPostpaid].data[0],
      list: postpaidList,
    };
  }
  if (postpaidList.length === 0) {
    newbillpaymentList.splice(findPostpaid, 1);
  }
  const findPrepaid = newbillpaymentList.findIndex((e) => e.title === BP_CATEGORY_NAMES.ISI_ULANG);
  if (findPrepaid !== -1) {
    const findEmoney = newbillpaymentList[findPrepaid]?.data[0]?.list.findIndex((e) => e.name === 'eMoney');
    if (findEmoney !== -1) {
      newbillpaymentList[findPrepaid]?.data[0]?.list.splice(findEmoney, 1);
    }
  }

  const findPromo = newbillpaymentList.findIndex((e) => e.title === 'Kesejahteraan');
  if (findPromo !== -1) {
    const findAsuransi = newbillpaymentList[findPromo]?.data[0]?.list.findIndex((e) => e.name === 'Asuransi');
    if (findAsuransi !== -1) {
      newbillpaymentList[findPromo]?.data[0]?.list.splice(findAsuransi, 1);
    }
  }

  return newbillpaymentList;
};

export const returnBpCategoriesByAccount = (accountName, billpaymentList = [], pointBalance = 0) => {
  switch (accountName) {
    case DYNAMIC_ACCOUNT_NAMES.POINTS:
      return removePointsDisabledBp(billpaymentList, pointBalance);

    case DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE:
      return billpaymentList;

    default:
      return billpaymentList;
  }
};

export const sliderInputChange = (availableBalance, minBalance, x) => {
  let val;
  if (x == 0 || x == 'NaN') {
    val = minBalance;
  } else if (x < minBalance) {
    val = minBalance;
  } else {
    if (x < 1000) {
      val = Math.floor(x * 1000);
    } else if (x > availableBalance) {
      val = Math.floor(availableBalance);
    } else {
      val = Math.floor(x);
    }
  }
  return val;
};

// Slider interval when user slides the slider
export const getSliderInterval = (availableBalance) => (availableBalance < 100000 ? 10000 : 50000);

// Maximum Value of the slider
export const sliderMaximumValue = (availableBalance, minBalance) => {
  if (availableBalance === 0) return 0;
  return availableBalance < minBalance ? 0 : availableBalance;
};

// Minimum Value of the slider
export const sliderMinValue = (availableBalance, minBalance) => {
  if (availableBalance === 0) return 0;
  return availableBalance < minBalance ? 0 : minBalance;
};

export const textMinimumValue = (maximumValue, minimumValue) =>
  maximumValue < minimumValue ? 0 : numberWithDots(minimumValue);
export const textMaximumValue = (maximumValue, minimumValue) =>
  maximumValue < minimumValue ? 0 : numberWithDots(maximumValue);

export const returnInitialSortedStories = (oldStories) =>
  oldStories.map((e) => ({
    ...e,
    stories: e.stories.map((e) => ({
      ...e,
      hasViewed: true,
    })),
    viewed: true,
  }));

export const checkIfAllStoriesViewed = (oldStories, viewedStories) => oldStories.length === viewedStories.length;

export const refreshStories = (stories) => {
  if (stories) {
    let viewedStories = [];
    let notViewedStories = [];
    stories.forEach((e) => {
      let singleStory = e.stories.slice();
      const viewedStoryLength = singleStory.filter((e) => e?.hasViewed).length;

      if (viewedStoryLength === singleStory.length) {
        e = {
          ...e,
          viewed: true,
        };
        viewedStories.push(e);
      } else {
        e = {
          ...e,
          viewed: false,
        };
        notViewedStories.push(e);
      }
    });
    return notViewedStories.concat(viewedStories);
  }
  return [];
};

export const checkIfLastSubStory = (currentStoryIndex, data, findStory) =>
  currentStoryIndex === data[findStory].stories.length - 1;

export const checkIfViewingStoryValid = (data, currentViewableItem, findStory) =>
  data[currentViewableItem]?.id === data[findStory]?.id;

export const setVoucherText = (biller) => {
  switch (biller) {
    case 'Voucher Alfamart':
      return VOUCHER_TEXT_CONSTANTS.textAlfamart;

    case 'Voucher Grab Transport':
      return VOUCHER_TEXT_CONSTANTS.textGrab;

    case 'Voucher Indomaret':
      return VOUCHER_TEXT_CONSTANTS.textIndomaret;

    case 'Voucher Grab Food':
      return VOUCHER_TEXT_CONSTANTS.textGrabFood;

    case 'Voucher Halodoc':
      return VOUCHER_TEXT_CONSTANTS.textHalodoc;

    case 'Voucher Sayur Box':
      return VOUCHER_TEXT_CONSTANTS.textSayurbox;

    case 'Grab Voucher':
      return VOUCHER_TEXT_CONSTANTS.grab;

    case 'Go Car':
      return VOUCHER_TEXT_CONSTANTS.gocar;

    case 'Go Food':
      return VOUCHER_TEXT_CONSTANTS.gofood;

    case 'Go Ride':
      return VOUCHER_TEXT_CONSTANTS.goride;

    case 'Kopi Dari Pada':
      return VOUCHER_TEXT_CONSTANTS.kopidaripada;

    case 'Kopitiam':
      return VOUCHER_TEXT_CONSTANTS.kopitiam;

    case 'KFC':
      return VOUCHER_TEXT_CONSTANTS.kfc;

    case 'Yoshinoya':
      return VOUCHER_TEXT_CONSTANTS.yoshinoya;

    case 'Voucher Ta Wan':
      return VOUCHER_TEXT_CONSTANTS.tawan;

    case 'Moon Chicken':
      return VOUCHER_TEXT_CONSTANTS.moonchicken;

    case 'Accha Resto':
      return VOUCHER_TEXT_CONSTANTS.acharesto;

    case 'Nasi Ayam Kulit Bude Sari':
      return VOUCHER_TEXT_CONSTANTS.nasiayam;

    case 'Fish Streat':
      return VOUCHER_TEXT_CONSTANTS.fishstreat;

    case 'Kulina':
      return VOUCHER_TEXT_CONSTANTS.kulina;

    case 'San Gyu':
      return VOUCHER_TEXT_CONSTANTS.sangyu;

    case 'Voucher Carrefour Transmart':
      return VOUCHER_TEXT_CONSTANTS.carrefour;

    default:
      return [];
  }
};

export const numberAmount = (val) => {
  let num = val;

  const addCommas = (num) => num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  return `${val < -1 ? '-' : ''}${addCommas(removeNonNumeric(num))}`;
};

export const convertDotsToNum = (x) => {
  if (x == '0') {
    return parseInt(x);
  } else {
    return parseInt(x?.replace(/[.]/g, ''));
  }
};

export const setBillPaymentReason = (biller) => {
  switch (biller) {
    case 'Voucher Alfamart':
      return { reason: 'Belanja Bulanan/Sembako', id: 6 };

    case 'Voucher Indomaret':
      return { reason: 'Belanja Bulanan/Sembako', id: 6 };

    case 'Voucher Sayur Box':
      return { reason: 'Kebutuhan Sehari-hari', id: 6 };

    case 'Voucher Grab Transport':
      return { reason: 'Transportasi', id: 11 };

    case 'Voucher Grab Food':
      return { reason: 'Makanan & Minuman', id: 8 };

    case 'Voucher Halodoc':
      return { reason: 'Biaya Kesehatan', id: 4 };

    case 'Grab Voucher':
      return { reason: 'Makanan & Minuman', id: 8 };

    case 'Go Car':
      return { reason: 'Transportasi', id: 11 };

    case 'Go Food':
      return { reason: 'Makanan & Minuman', id: 8 };

    case 'Go Ride':
      return { reason: 'Transportasi', id: 11 };

    case 'Kopi Dari Pada':
      return { reason: 'Makanan & Minuman', id: 8 };

    case 'Kopitiam':
      return { reason: 'Makanan & Minuman', id: 8 };

    case 'KFC':
      return { reason: 'Makanan & Minuman', id: 8 };

    case 'Yoshinoya':
      return { reason: 'Makanan & Minuman', id: 8 };

    case 'Voucher Ta wan':
      return { reason: 'Makanan & Minuman', id: 8 };

    case 'Moon Chicken':
      return { reason: 'Makanan & Minuman', id: 8 };

    case 'Accha Resto':
      return { reason: 'Makanan & Minuman', id: 8 };

    case 'Nasi Ayam Kulit Bude Sari':
      return { reason: 'Makanan & Minuman', id: 8 };

    case 'Fish Streat':
      return { reason: 'Makanan & Minuman', id: 8 };

    case 'Kulina':
      return { reason: 'Makanan & Minuman', id: 8 };

    case 'San Gyu':
      return { reason: 'Makanan & Minuman', id: 8 };

    case 'Carrefour Transmart':
      return { reason: 'Belanja Bulanan/Sembako', id: 6 };

    case 'Milvik':
      return { reason: 'Biaya Kesehatan', id: 4 };

    case 'Zakat':
      return { reason: 'Biaya Kesehatan', id: 19 };

    case 'OVO':
    case 'ShopeePay':
    case 'GO-PAY':
    case 'Dana':
    case 'LinkAja':
      return { reason: 'Lainnya', id: 18 };
    default:
      return { reason: 'Tagihan & Utilitas', id: 18 };
  }
};

export const validate = (type, input) => {
  let errorMsg = '';
  if (type == 'phone') {
    if (input == '') {
      errorMsg = 'Masukkan nomor telpon anda';
    } else if (input.charAt(0) != 1 && input.charAt(2) == 0) {
      errorMsg = 'Masukkan nomor telpon anda tanpa angka 0 didepan';
    } else if (input.charAt(0) != 1 && input.length < 8) {
      errorMsg = 'Nomor HP minimal harus 8 digit';
    }
  } else {
    if (input == 0) {
      errorMsg = 'Masukkan Kode OTP anda';
    }
  }
  return errorMsg;
};

export const flattenObject = (obj) =>
  Object.keys(obj).reduce((acc, k) => {
    if (typeof obj[k] === 'object' && obj[k] !== null && Object.keys(obj[k]).length > 0)
      Object.assign(acc, flattenObject(obj[k], k));
    else acc[k] = obj[k];
    return acc;
  }, {});

export const returnPayslip = (state) => {
  switch (state) {
    case PAYSLIP.EMPLOYEE_NAME:
      return 'Nama Karyawan';

    case PAYSLIP.PHONE_NUMBER:
      return 'No. Ponsel';

    case PAYSLIP.BANK:
      return 'Bank Karyawan';

    case PAYSLIP.ACCOUNT_NUMBER:
      return 'Nomor Rekening';

    case PAYSLIP.SALARY_AND_PAYMENT:
      return 'Pembayaran dan Gaji';

    case PAYSLIP.SALARY_PAYMENTS:
      return 'Pembayaran';

    case PAYSLIP.ATTENDANCE_HISTORY:
      return 'Riwayat Kehadiran';

    case PAYSLIP.DATE:
      return 'Tanggal';

    case PAYSLIP.DESCRIPTION:
      return 'Deskripsi';

    case PAYSLIP.TOTAL:
      return 'Jumlah';

    case PAYSLIP.TITLE2:
      return 'Kehadiran';

    case PAYSLIP.NOTE:
      return 'Catatan';

    case PAYSLIP.WORKING_HOURS:
      return 'Jam Kerja';

    case PAYSLIP.SALARY:
      return 'Gaji';

    case PAYSLIP.ADDITION:
      return 'Tunjangan';

    case PAYSLIP.DEDUCTION:
      return 'Potongan';

    case PAYSLIP.BASE_SALARY:
      return 'Gaji Pokok';

    case PAYSLIP.BONUS:
      return 'Bonus';

    case PAYSLIP.NETT_SALARY:
      return 'Gaji Bersih';

    case PAYSLIP.OVERTIME:
      return 'Lembur';

    case PAYSLIP.TOTAL_ADDITION:
      return 'Total Tunjangan';

    case PAYSLIP.TOTAL_DEDUCTION:
      return 'Total Potongan';

    case PAYSLIP.PAID_SALARY:
      return 'Gaji yang sudah dibayar';

    case PAYSLIP.OUTSTANDING_SALARY:
      return 'Gaji yang belum dibayar';

    case PAYSLIP.ATTENDANCE:
      return 'Hadir';

    case PAYSLIP.ABSENT:
      return 'Absen';

    case PAYSLIP.PAID_LEAVE:
      return 'Libur';

    case PAYSLIP.LATE:
      return 'Terlambat';

    case PAYSLIP.PRESENT:
      return 'Hadir';

    case PAYSLIP.PENDING:
      return 'Belum dibayar';

    case PAYSLIP.OUTSTANDING:
      return 'Outstanding';

    case PAYSLIP.PAID:
      return 'Sudah dibayar';

    case PAYSLIP.LATE_FINE:
      return 'Denda terlambat';

    case PAYSLIP.HOLIDAY:
      return 'Libur';

    case PAYSLIP.PAYMENT_FROM_GAJIGESA:
      return 'Melalui Gajigesa';

    case PAYSLIP.PAYMENT_FROM_GAJITIM:
      return 'Melalui Perusahaan';

    case PAYSLIP.DAY_OFF:
      return 'Hari Off';

    case PAYSLIP.OVERPAID:
      return 'Overpaid';

    default:
      return state;
  }
};

export const timeHelper = (time) => {
  if (time === null) return '';

  let hour = time.split(':')[0];
  let min = time.split(':')[1];

  if (hour.charAt(0) == '0') {
    hour = hour.substring(1, 2);
  }

  return `${hour}jam${min}min`;
};

export const getAttendanceText = (label) => {
  switch (label) {
    case 'PRESENT':
      return 'Hadir';

    case 'ABSENT':
      return 'Absen';

    case 'HOLIDAY':
      return 'Libur';

    case 'LATE':
      return 'Terlambat';

    case 'OVERTIME':
      return 'Lembur';

    case 'WORK_HOURS':
      return 'Jam Kerja';

    case 'BREAK_HOURS':
      return 'Jam Istirahat';
  }
};

export const getIndoDay = (date) => {
  switch (date) {
    case 'Mon':
      return 'Sen';
    case 'Tue':
      return 'Sel';
    case 'Wed':
      return 'Rab';
    case 'Thu':
      return 'Kam';
    case 'Fri':
      return 'Jum';
    case 'Sat':
      return 'Sab';
    case 'Sun':
      return 'Min';

    default:
      return date;
  }
};

export const getIndoMonth = (month) => {
  switch (month) {
    case 'Oct':
      return 'Okt';
    case 'May':
      return 'Mei';
    case 'Dec':
      return 'Des';
    case 'Aug':
      return 'Agus';
    default:
      return month;
  }
};

export const getIndoMonthLong = (month) => {
  switch (month) {
    case 'October':
      return 'Oktober';
    case 'May':
      return 'Mei';
    case 'December':
      return 'Desember';
    case 'August':
      return 'Agustus';
    case 'January':
      return 'Januari';
    case 'February':
      return 'Februari';
    case 'March':
      return 'Maret';
    case 'April':
      return 'April';
    case 'June':
      return 'Juni';
    case 'July':
      return 'Juli';
    case 'September':
      return 'September';
    case 'November':
      return 'Nopember';
    default:
      return month;
  }
};

export const dateFormer = (date) => {
  const day = getIndoDay(date.format('ddd'));
  const dayDate = date.format('DD');
  const month = getIndoMonth(date.format('MMM'));
  const year = date.format('YYYY');

  return `${day}, ${dayDate} ${month} ${year}`;
};

export const listDateFormer = (date) => {
  const dayDate = date?.format('DD');
  const month = getIndoMonth(date?.format('MMM'));

  return `${dayDate} ${month}`;
};

export const getAttendanceColor = (label) => {
  switch (label) {
    case 'PRESENT':
      return ATTENDANCE_COLORS.hadir;

    case 'ABSENT':
      return ATTENDANCE_COLORS.absen;

    case 'HOLIDAY':
      return ATTENDANCE_COLORS.libur;

    case 'LATE':
      return ATTENDANCE_COLORS.terlambat;

    case 'OVERTIME':
      return ATTENDANCE_COLORS.lembur;

    case 'WORK_HOURS':
      return ATTENDANCE_COLORS.jamKerja;

    case 'BREAK_HOURS':
      return ATTENDANCE_COLORS.jamIstirahat;
  }
};

export const getAttendanceIcon = (label) => {
  switch (label) {
    case 'PRESENT':
      return ATTENDANCE_ICONS.hadir;
    case 'ABSENT':
      return ATTENDANCE_ICONS.absen;
    case 'HOLIDAY':
      return ATTENDANCE_ICONS.libur;
    case 'LATE':
      return ATTENDANCE_ICONS.terlambat;
    case 'OVERTIME':
      return ATTENDANCE_ICONS.lembur;
    case 'WORK_HOURS':
      return ATTENDANCE_ICONS.jamKerja;
    case 'BREAK_HOURS':
      return ATTENDANCE_ICONS.jamIstirahat;
  }
};

export const getMulaiSelesai = (button) => {
  if (button.includes('Mulai')) {
    return 'mulai';
  }
  if (button.includes('Selesai')) {
    return 'selesai';
  }
};

export const getAttendanceStatus = (status) => {
  if (status == 'APPROVED') {
    return {
      text: 'Disetujui',
      icon: ATTENDANCE_ICONS.approve,
      color: '#2CBE96',
    };
  }
  if (status == 'REJECTED') {
    return { text: 'Ditolak', icon: ATTENDANCE_ICONS.reject, color: '#EB5757' };
  }
  if (status == 'PENDING') {
    return {
      text: 'Pending',
      icon: ATTENDANCE_ICONS.pending,
      color: '#929292',
    };
  }
  return { text: '', icon: '', color: '#fff' };
};

export const camelToSnake = (str) => {
  str = str.charAt(0).toLowerCase() + str.slice(1);
  str = str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
  return str;
};

export const spaceToSnake = (str) => str.replace(/[ -]/g, '_');

export const setRange = (
  firstWorkingDate,
  endWorkingDate,
  startWithdrawalDate,
  withdrawalLockType,
  numberOfLockPeriod,
  payDateWithdrawal,
) => {
  let startFullDate = '';
  let endFullDate = '';

  // HIDE OR SHOW VARIABLES
  let startMonth = '';
  let endMonth = '';
  let startYear = '';
  let endYear = '';

  // START DATE
  if (startWithdrawalDate != null) {
    startFullDate = moment(firstWorkingDate).set('date', startWithdrawalDate).format('DD-MMMM-YYYY');

    if (moment(startFullDate).isBefore(moment(firstWorkingDate).format('DD-MMMM-YYYY'))) {
      startFullDate = moment(startFullDate).add(1, 'month').format('DD-MMMM-YYYY');
    } else if (moment(startFullDate).isSame(moment(firstWorkingDate))) {
      // do nothing
    }
  } else {
    startFullDate = moment(firstWorkingDate).format('DD-MMMM-YYYY');
  }

  // END DATE
  if (withdrawalLockType == 'FIXED') {
    if (numberOfLockPeriod != 0) {
      endFullDate = moment(endWorkingDate).set('date', numberOfLockPeriod);

      if (moment(endFullDate).isAfter(moment(endWorkingDate))) {
        endFullDate = moment(endFullDate).subtract(1, 'month').format('DD-MMMM-YYYY');
      }
      endFullDate = moment(endFullDate).subtract(1, 'days').format('DD-MMMM-YYYY');
    } else {
      endFullDate = moment(endWorkingDate)
        .subtract(numberOfLockPeriod + 1, 'days')
        .format('DD-MMMM-YYYY');
    }
  } else {
    endFullDate = moment(endWorkingDate)
      .subtract(numberOfLockPeriod + 1, 'days')
      .format('DD-MMMM-YYYY');

    if (moment(endFullDate).isAfter(moment(endWorkingDate))) {
      endFullDate = moment(endFullDate).subtract(1, 'month').format('DD-MMMM-YYYY');
    }
  }

  // SAME MONTH
  if (moment(startFullDate).get('month') != moment(endFullDate).get('month')) {
    startMonth = getIndoMonthLong(moment(startFullDate).format('MMMM'));
    endMonth = getIndoMonthLong(moment(endFullDate).format('MMMM'));
  } else {
    endMonth = getIndoMonthLong(moment(endFullDate).format('MMMM'));
  }

  // SAME YEAR
  if (moment(startFullDate).get('year') != moment(endFullDate).get('year')) {
    startYear = moment(startFullDate).format('YYYY');
    endYear = moment(endFullDate).format('YYYY');
  } else {
    endYear = moment(endFullDate).format('YYYY');
  }

  let fullWithdrawalDate =
    startWithdrawalDate != null
      ? moment(endFullDate).set('date', startWithdrawalDate).format('YYYY-MM-DD')
      : moment(startFullDate).format('YYYY-MM-DD');

  // SAME DATE
  if (moment(startFullDate).isSame(moment(endFullDate)) && !moment(endFullDate).isSame(moment(fullWithdrawalDate))) {
    if (payDateWithdrawal) {
      return `Transaksi bisa dilakukan pada tanggal gajian.`;
    } else {
      return `Tidak ada periode untuk melakukan transaksi.`;
    }
  }

  // EDGE CASES
  if (
    (moment(startFullDate).get('month') > moment(endFullDate).get('month') &&
      (moment(startFullDate).get('year') > moment(endFullDate).get('year') ||
        moment(startFullDate).get('year') == moment(endFullDate).get('year'))) ||
    moment(startFullDate).get('year') > moment(endFullDate).get('year')
  ) {
    if (payDateWithdrawal) {
      return `Transaksi bisa dilakukan pada tanggal gajian.`;
    } else {
      return `Tidak ada periode untuk melakukan transaksi.`;
    }
  }

  if (
    moment(startFullDate).get('month') == moment(endFullDate).get('month') &&
    moment(startFullDate).get('year') == moment(endFullDate).get('year') &&
    moment(startFullDate).get('date') > moment(endFullDate).get('date')
  ) {
    if (payDateWithdrawal) {
      return `Transaksi bisa dilakukan pada tanggal gajian.`;
    } else {
      return `Tidak ada periode untuk melakukan transaksi.`;
    }
  }

  // EMPTY EDGE CASE
  if (
    !firstWorkingDate &&
    !endWorkingDate &&
    !startWithdrawalDate &&
    !withdrawalLockType &&
    !numberOfLockPeriod &&
    !payDateWithdrawal
  ) {
    return `Tidak ada periode untuk melakukan transaksi.`;
  }

  // CONSTRUCTING STRINGS
  let startDate = moment(startFullDate).get('date');
  let endDate = moment(endFullDate).get('date');

  let fullStartDate = !moment(startFullDate).isSame(moment(endFullDate))
    ? `${startDate}${startMonth != '' ? ` ${startMonth}` : ''}${startYear != '' ? ` ${startYear}` : ''} - `
    : '';
  let fullEndDate = `${endDate}${endMonth != '' ? ` ${endMonth}` : ''}${endYear != '' ? ` ${endYear}` : ''}`;

  return `Transaksi bisa dilakukan pada ${fullStartDate}${fullEndDate} ${
    payDateWithdrawal ? ` dan pada tanggal gajian` : ''
  }`;
};

/*********************************************************************************  ATTENDANCE CHAIN OPERATOR HELPERS ***************************************************************************************/

export const GET_SELF_ATTENDANCE_CONDITIONS = (attendance, typeSalary, skipbreak) => {
  return {
    // SPECIAL CASES
    isDayOff: attendance?.is_day_off,
    skippedBreak: skipbreak ?? '',
    isNotNull: attendance?.submission != null,
    isNull: attendance?.submission == null,

    // SALARYTYPE CHECK
    isHourly: typeSalary == 'HOURLY',
    isDaily: typeSalary == 'DAILY',
    isMonthly: typeSalary == 'MONTHLY',

    // HAS EMPLOYER MARKED?
    employerMarked: attendance?.last_update_mode == 'EMPLOYER',
    employeeSubmitted: attendance?.last_update_mode == 'SUBMISSION',

    // SUBMISSION
    submittedStartBreak: attendance?.submission?.start_break ?? null,
    submittedEndBreak: attendance?.submission?.end_break ?? null,
    submittedStartWork: attendance?.submission?.submitted_start_work ?? null,
    submittedEndWork: attendance?.submission?.submitted_end_work ?? null,

    // FINAL SUBMISSIONS
    startWork: attendance?.start_work ?? null,
    startBreak: attendance?.start_break ?? null,
    endBreak: attendance?.end_break ?? null,
    endWork: attendance?.end_work ?? null,

    // ATTENDANCE STATUS
    isHoliday: attendance?.present == 'ABSENT' && attendance?.paid == true,
    isAbsent: attendance?.present == 'ABSENT' && attendance?.paid == false,
    isPresent: attendance?.present == 'PRESENT' && attendance?.paid == true,

    // SUBMISSION STATUS
    endWorkStatus: attendance?.submission?.end_work_status ?? null,
    startWorkStatus: attendance?.submission?.start_work_status ?? null,
  };
};

export const textModalAttendance = (attendance, typeSalary, skipbreak) => {
  const att = GET_SELF_ATTENDANCE_CONDITIONS(attendance, typeSalary, skipbreak);

  let startWork =
    !att.startWork &&
    !att.startBreak &&
    !att.endBreak &&
    !att.endWork &&
    att.isNull &&
    !att.submittedStartWork &&
    !att.submittedStartBreak &&
    !att.submittedEndBreak &&
    !att.submittedEndBreak;
  let startBreak =
    att.isHourly &&
    ((att.startWork && !att.startBreak) ||
      (att.isNotNull && att.submittedStartWork && !att.startBreak && !att.submittedStartBreak)) &&
    !att.skippedBreak;
  let endBreak =
    att.isHourly &&
    ((att.startBreak && !att.endBreak && !att.skippedBreak) ||
      (att.isNotNull && att.submittedStartWork && att.submittedStartBreak && !att.submittedEndBreak)) &&
    !att.skippedBreak;
  let endWork =
    att.isHourly &&
    ((att.employerMarked && !att.startBreak && !att.endBreak) ||
      (att.startBreak && att.endBreak) ||
      (att.isNotNull && att.submittedStartWork && att.submittedStartBreak && att.submittedEndBreak));

  if (startWork) {
    return 'Mulai Kerja';
  } else if (startBreak) {
    return 'Mulai Istirahat';
  } else if (endBreak) {
    return 'Selesai Istirahat';
  } else if (endWork) {
    return 'Selesai Kerja';
  } else {
    return 'Selesai Kerja';
  }
};

export const textStatusAttendance = (attendance, typeSalary) => {
  const att = GET_SELF_ATTENDANCE_CONDITIONS(attendance, typeSalary);
  let condition = (!att.employerMarked && !att.isHoliday) || (!att.employerMarked && !att.isAbsent);
  return !!condition;
};

export const renderButtonAttendance = (attendance, typeSalary) => {
  const att = GET_SELF_ATTENDANCE_CONDITIONS(attendance, typeSalary);
  let first =
    att.isDaily &&
    ((att.employerMarked && att.isPresent) ||
      (att.employerMarked && att.isHoliday) ||
      (att.employerMarked && att.isAbsent));
  let second =
    att.isMonthly &&
    ((att.employerMarked && att.isAbsent) ||
      (att.employerMarked && att.isHoliday) ||
      (att.employerMarked && att.isPresent));
  let third =
    att.isMonthly &&
    ((att.employerMarked && att.isAbsent) ||
      (att.employerMarked && att.isHoliday) ||
      (att.employerMarked && att.isPresent));

  return !!(first || second || third);
};

export const renderTextButton = (attendance, typeSalary) => {
  const att = GET_SELF_ATTENDANCE_CONDITIONS(attendance, typeSalary);
  let libur = (att.isDaily || att.isHourly || att.isMonthly) && att.employerMarked && att.isHoliday;
  let present = (att.isDaily || att.isMonthly) && att.employerMarked && att.isPresent;
  let absent = (att.isDaily || att.isMonthly) && att.employerMarked && att.isAbsent;

  if (libur) {
    return 'Libur';
  } else if (present) {
    return 'Hadir';
  } else if (absent) {
    return 'Absent';
  }
};

export const renderIconButton = (attendance, typeSalary) => {
  const att = GET_SELF_ATTENDANCE_CONDITIONS(attendance, typeSalary);
  let libur = (att.isDaily || att.isHourly || att.isMonthly) && att.employerMarked && att.isHoliday;
  let present = (att.isDaily || att.isMonthly) && att.employerMarked && att.isPresent;
  let absent = (att.isDaily || att.isMonthly) && att.employerMarked && att.isAbsent;

  if (libur) {
    return ATTENDANCE_ICONS.libur;
  } else if (present) {
    return ATTENDANCE_ICONS.hadir;
  } else if (absent) {
    return ATTENDANCE_ICONS.absen;
  }
};

export const renderButtonHourly = (attendance, typeSalary) => {
  const att = GET_SELF_ATTENDANCE_CONDITIONS(attendance, typeSalary);
  let x =
    typeSalary == 'HOURLY' &&
    (!att.startBreak || !att.endBreak) &&
    (att.startWork || att.endWork) &&
    att.isPresent &&
    att.employerMarked;

  return !!x;
};

export const renderButton = (attendance, typeSalary, skipbreak) => {
  const x = GET_SELF_ATTENDANCE_CONDITIONS(attendance, typeSalary, skipbreak);

  // EACH CONDITION AS IS
  let first = !!(x.skippedBreak && x.isHourly && x.submittedStartBreak);
  let second = !!(x.isHourly && x.employerMarked && x.submittedEndWork);
  let third = !!(
    x.isHourly &&
    x.employerMarked &&
    x.startWork &&
    x.startBreak &&
    x.endBreak &&
    !x.endWork &&
    x.submittedEndWork
  );
  let fourth = !!(x.isHourly && x.employerMarked && x.startWork && x.startBreak && x.endBreak && x.endWork);
  let fifth = !!(x.isHourly && x.employeeSubmitted && x.startWork && x.endWork);
  let sixth = !!(x.isHourly && x.startWork && x.startBreak && x.endBreak && x.endWork);
  let seventh = !!(
    x.isDaily &&
    ((x.employerMarked && x.isHoliday) || (x.employerMarked && x.isPresent) || (x.employerMarked && x.isAbsent))
  );
  let eighth = !!(
    x.isMonthly &&
    ((x.employerMarked && x.isAbsent) || (x.employerMarked && x.isHoliday) || (x.employerMarked && x.isAbsent))
  );
  let ninth = !attendance;
  let tenth = x.isNotNull && !!(x.submittedStartWork && x.submittedEndWork);

  return !!(first || second || third || fourth || fifth || sixth || seventh || eighth || ninth || tenth);
};

export const renderButtonText = (attendance, typeSalary, skipbreak) => {
  const x = GET_SELF_ATTENDANCE_CONDITIONS(attendance, typeSalary, skipbreak);
  // CONDITIONS AS IS
  let first = !!(
    x.isHourly &&
    ((x.isNotNull && x.submittedStartWork && !x.submittedStartBreak && !x.startBreak) ||
      (x.startWork && !x.startBreak && !x.endBreak && x.endWork) ||
      (x.startWork && !x.startBreak && !x.endBreak && !x.endWork)) &&
    !x.skippedBreak
  );

  let second = !!(
    x.isHourly &&
    ((x.isNotNull && x.submittedStartWork && x.submittedStartBreak && !x.submittedEndBreak && !x.submittedEndWork) ||
      (x.startWork && x.startBreak && !x.endBreak && !x.endWork) ||
      (x.startWork && x.startBreak && !x.endBreak)) &&
    !x.skippedBreak
  );

  let third = !!(
    x.isHourly &&
    ((x.isNotNull && x.submittedStartWork && x.submittedStartBreak && x.submittedEndBreak) ||
      (x.startWork && x.startBreak && x.endBreak) ||
      (x.startWork && !x.startBreak && !x.endBreak))
  );

  let fourth = !x.isHourly && !!(x.isNotNull && x.submittedStartWork);

  if (first) {
    return 'Mulai Istirahat';
  } else if (second) {
    return 'Selesai Istirahat';
  } else if (third) {
    return 'Selesai Kerja';
  } else if (fourth) {
    return 'Selesai Kerja';
  } else {
    if (x.submittedStartWork === null) {
      return 'Mulai Kerja';
    } else {
      return 'Selesai Kerja';
    }
  }
};

export const renderButtonIcon = (attendance, typeSalary, skipbreak) => {
  const x = GET_SELF_ATTENDANCE_CONDITIONS(attendance, typeSalary, skipbreak);

  let first = !!(
    x.isHourly &&
    ((x.isNotNull && x.submittedStartWork && !x.startBreak && !x.submittedStartBreak) ||
      (x.startWork && !x.startBreak && !x.endBreak) ||
      (x.startWork && x.startBreak && !x.endBreak) ||
      (x.startWork && !x.startBreak && !x.endBreak && !x.endWork)) &&
    !x.skippedBreak
  );

  let second = !!(
    x.isHourly &&
    x.isNotNull &&
    x.submittedStartWork &&
    !x.submittedEndBreak &&
    !x.submittedEndWork &&
    !x.skippedBreak
  );

  if (first || second) {
    return ATTENDANCE_ICONS.jamIstirahatButton;
  } else {
    return ATTENDANCE_ICONS.jamKerjaButton;
  }
};

export const renderButtonColor = (attendance, typeSalary, skipbreak) => {
  const x = GET_SELF_ATTENDANCE_CONDITIONS(attendance, typeSalary, skipbreak);

  let first = !!(
    (x.isHourly && ((x.startBreak && !x.endBreak) || (x.startWork && x.startBreak && x.endBreak && !x.endWork))) ||
    x.skippedBreak
  );

  let second = !!(
    (x.isHourly &&
      x.isNotNull &&
      ((x.submittedStartWork && x.submittedStartBreak && x.submittedEndBreak && x.submittedEndWork) ||
        (!x.submittedStartWork && !x.submittedStartBreak && !x.submittedEndBreak && !x.submittedEndWork) ||
        (x.submittedStartWork && x.submittedStartBreak && !x.submittedEndBreak && !x.submittedEndWork) ||
        (x.submittedStartWork && x.submittedStartBreak && x.submittedEndBreak && !x.submittedEndWork) ||
        (!x.submittedStartWork && !x.submittedStartBreak && !x.submittedEndBreak && !x.submittedEndWork))) ||
    x.skippedBreak
  );

  let third = !!(!x.isHourly && x.isNotNull && x.submittedStartWork && !x.submittedEndWork);

  if (first || second || third) {
    return '#f56b57';
  } else {
    return '#2CBE96';
  }
};

export const renderSkipBreak = (attendance, typeSalary, skipbreak) => {
  let x = GET_SELF_ATTENDANCE_CONDITIONS(attendance, typeSalary, skipbreak);

  let presentOrAbsent = x.isPresent || x.isAbsent;
  let first = !!(
    (x.startWork && !x.startBreak && !x.endBreak && !x.endWork) ||
    (x.startWork && x.endWork && !x.startBreak && !x.endBreak) ||
    (x.submittedStartWork && !x.submittedStartBreak && !x.startBreak && !x.submittedEndBreak && !x.submittedEndWork)
  );

  return !!(attendance && x.isHourly && presentOrAbsent && first && !x.skippedBreak);
};

export const renderAfterSubmit = (attendance, typeSalary, skipbreak) => {
  const x = GET_SELF_ATTENDANCE_CONDITIONS(attendance, typeSalary, skipbreak);

  let first = x.isHourly && (x.isHoliday || x.isPresent || (x.employerMarked && x.isAbsent));
  let second = x.isDaily && (x.isHoliday || x.isPresent || (x.employerMarked && x.isAbsent));
  let third = x.isMonthly && (x.isAbsent || x.isHoliday || (x.employerMarked && x.isAbsent));
  let fourth = attendance && x.isNotNull;
  return !!(first || second || third || fourth);
};

function shouldCheckEndWorkIfNotPending(x) {
  return x.endWorkStatus != 'PENDING' ? x.endWork : x.submittedEndWork;
}

function shouldCheckStartWorkIfNotPending(x) {
  return x.startWorkStatus != 'PENDING' ? x.startWork : x.submittedStartWork;
}

function shouldGetFourthValue(x) {
  return x.endWork || !x.endWork || (x.submittedStartWork && x.submittedEndWork);
}

function shouldGetThirdValue(x) {
  return (x.startWork && !x.endWork && !x.submittedEndWork) || (x.submittedStartWork && !x.submittedEndWork);
}

function shouldGetSecondValue(x) {
  return (
    x.isHourly &&
    ((x.startBreak && x.endBreak && !x.endWork && !x.submittedEndWork) ||
      (x.startBreak && x.submittedEndBreak && !x.submittedEndWork))
  );
}

function shouldGetFirstValue(x) {
  return (
    x.isHourly &&
    ((x.startWork && x.startBreak && !x.endBreak && !x.endWork) ||
      (x.startBreak && !x.submittedEndBreak && !x.submittedEndWork))
  );
}

function getFinishWorkValue(x) {
  return x.endWork ? x.endWork : shouldCheckEndWorkIfNotPending(x);
}

function getStartWorkValue(x) {
  return x.startWork ? x.startWork : shouldCheckStartWorkIfNotPending(x);
}

function getDoneRestValue(x) {
  return x.endBreak ? x.endBreak : x.submittedEndBreak;
}

function getStartRestValue(x) {
  return x.startBreak ? x.startBreak : x.submittedStartBreak;
}

export const renderAfterSubmitText = (attendance, typeSalary, skipbreak) => {
  const x = GET_SELF_ATTENDANCE_CONDITIONS(attendance, typeSalary, skipbreak);

  let first = shouldGetFirstValue(x);

  let second = shouldGetSecondValue(x);

  let third = shouldGetThirdValue(x);

  let fourth = shouldGetFourthValue(x);

  if (first) {
    return `Mulai Istirahat ${getStartRestValue(x)}`;
  }
  if (second) {
    return `Selesai Istirahat ${getDoneRestValue(x)}`;
  }
  if (third) {
    return `Mulai Kerja ${getStartWorkValue(x)}`;
  }
  if (fourth) {
    return `Selesai Kerja ${getFinishWorkValue(x)}`;
  }
  return null;
};

function checkOtherFinishedWork(x) {
  return x.isNotNull && x.submittedStartWork ? 'selesai kerja' : 'mulai kerja';
}

function checkOtherFinishedRest(x) {
  return (x.isHourly && x.employerMarked && !x.startBreak && !x.endBreak) ||
    (x.startBreak && x.endBreak) ||
    (x.isHourly && x.isNotNull && x.submittedStartWork && x.startBreak && x.endBreak)
    ? 'selesai kerja'
    : checkOtherFinishedWork(x);
}

function checkOtherStartRestCondition(x) {
  return (x.isHourly && x.startBreak && !x.endBreak && !x.skippedBreak) ||
    (x.isHourly && x.isNotNull && x.submittedStartWork && x.startBreak && !x.endBreak && !x.skippedBreak)
    ? 'selesai istirahat'
    : checkOtherFinishedRest(x);
}

export const renderConfirmText = (attendance, typeSalary, skipbreak) => {
  let x = GET_SELF_ATTENDANCE_CONDITIONS(attendance, typeSalary, skipbreak);

  return `Beri tahu perusahaan kalau kamu ${
    (x.isHourly && x.startWork && !x.startBreak && !x.skippedBreak) ||
    (x.isHourly && x.isNotNull && x.submittedStartWork && !x.startBreak && !x.startBreak && !x.skippedBreak)
      ? 'mulai istirahat'
      : checkOtherStartRestCondition(x)
  } pukul ${moment().format('HH:mm')}?`;
};

export const checkDistanceBetweenCoordinates = (firstLat, firstLng, secondLat, secondLng, distance = 200) => {
  const a = { lat: firstLat, lng: firstLng };
  const b = { lat: secondLat, lng: secondLng };

  const checkDistance = haversine(a, b); // gives distance in meters

  return {
    isEnoughDistance: checkDistance <= distance,
    actualDistance: checkDistance,
  };
};

export const getStatusAndColor = (status) => {
  switch (status) {
    case 'PENDING':
      return {
        bgColor: '#FFEDD3',
        color: '#F8B24F',
        text: 'Menunggu',
      };

    case 'COMPLETED':
    case 'CREDITED':
    case 'DEBITED':
      return {
        color: '#56893E',
        bgColor: '#8ED36D',
        text: 'Sukses',
      };

    case 'FAILED':
      return {
        bgColor: '#FAB1A6',
        color: '#F23015',
        text: 'Gagal',
      };
    case 'REJECTED':
      return {
        bgColor: '#FAB1A6',
        color: '#F23015',
        text: 'Ditolak',
      };

    default:
      return {
        color: colors.white,
        text: '',
      };
  }
};

export const roundOff = (val) => {
  const newVal = Math.ceil(val);
  const _val = newVal.toString();
  const len = _val.length;
  const divider = Math.pow(10, len - 2);

  return Math.floor(newVal / divider) * divider;
};

export const getCurrentTimeStamp = () => moment().format('DD/MM/YYYY HH:MM:SS');

export const calculatePercentage = (current, total) => {
  if (total == 0) return 0;
  return Math.round((current / total) * 100);
};

export const setImage = (item, url) => {
  switch (item) {
    case 'Voucher Alfamart':
      return 'https://storage.googleapis.com/gajigesa-public/icons/alfamart-logo.png';
    case 'Voucher Grab Transport':
      return 'https://storage.googleapis.com/gajigesa-public/icons/grab-logo.png';
    case 'Voucher Grab Food':
      return 'https://storage.googleapis.com/gajigesa-public/icons/grabfood-logo.png';
    case 'Voucher Indomaret':
      return 'https://storage.googleapis.com/gajigesa-public/icons/indomaret-logo.png';
    case 'Voucher Halodoc':
      return 'https://storage.googleapis.com/gajigesa-public/icons/halodoc-logo.png';
    case 'Voucher Sayur Box':
      return 'https://storage.googleapis.com/gajigesa-public/icons/sayurbox-logo.png';
    default:
      return url;
  }
};

export const sortedPointsBp = (e) => sharedSortedPointsBp(e);

export const utmToData = (url) =>
  JSON.parse(
    `{${url
      .split('/')
      .reverse()[0]
      .split('?')
      .reverse()[0]
      .split('&')
      .map((item) => `"${item.split('=')[0]}":"${item.split('=')[1]}"`)}}`,
  );

const singleFeatureEnabled = (featureList, featureCode) => {
  const findIndex = featureList.findIndex((e) => e.code === featureCode);
  if (findIndex !== -1) {
    return {
      feature: featureList[findIndex],
      show: featureList[findIndex].show,
    };
  }
  return {
    feature: null,
    show: false,
  };
};

const multipleFeatureEnabled = (featureList, featureCodes) => {
  const resultObj = {};
  featureCodes.forEach((o) => {
    resultObj[o] = singleFeatureEnabled(featureList, o);
  });
  return resultObj;
};

export const isFeatureEnabled = (featureList, featureCode) => {
  if (Array.isArray(featureCode)) return multipleFeatureEnabled(featureList, featureCode);
  return singleFeatureEnabled(featureList, featureCode);
};

export const getReferralEncryption = (code) =>
  code
    .split('')
    .map((item) => item.charCodeAt(0))
    .join('');

//Checks if the screen exists in system

export const getAvailableBalance = (
  salaryWithCappedAmount,
  availableBalance = 0,
  workingDays,
  workedDays,
  maxWithdrawalAmount = 0,
  usedBalance,
) => {
  maxWithdrawalAmount = !(maxWithdrawalAmount > 0) ? 0 : maxWithdrawalAmount;

  if (maxWithdrawalAmount !== 0) {
    const balance = Math.min(availableBalance, maxWithdrawalAmount - usedBalance);
    if (balance < 0) {
      return 0;
    } else {
      return balance;
    }
  } else {
    const balance = availableBalance;
    if (balance < 0) {
      return 0;
    } else {
      return balance;
    }
  }
};

export const getAccruedSalary = (salaryWithCappedAmount, workingDays, workedDays, maxWithdrawalAmount) => {
  // TODO
  /*
  balance = min(availableBalance, maxWithdrawal-usedBalance)
  if(balance<0){
    balance = 0
  }
  */

  const todaySalaryFloor = Math.floor((salaryWithCappedAmount / workingDays) * workedDays);
  if (maxWithdrawalAmount > 0) {
    if (todaySalaryFloor < maxWithdrawalAmount) return todaySalaryFloor;
    if (todaySalaryFloor >= maxWithdrawalAmount) return maxWithdrawalAmount;
    return 0;
  }
  if (todaySalaryFloor < salaryWithCappedAmount) return todaySalaryFloor;
  if (todaySalaryFloor >= salaryWithCappedAmount) return salaryWithCappedAmount;
  return 0;
};

export const accruedSalaryTransactionHistory = (
  accruedSalary,
  currentCycleIndex,
  salaryWithCappedAmount,
  workedDays,
  workingDays,
  maxWithdrawalAmount,
) => {
  if (isNaN(Math.round(accruedSalary))) {
    return accruedSalary;
  }
  if (currentCycleIndex === 0) {
    return getAccruedSalary(salaryWithCappedAmount, workingDays, workedDays, maxWithdrawalAmount);
  }
  return accruedSalary;
};

export const getExpirDateText = (expiryDate) => {
  const localeTime = moment.utc(expiryDate).subtract(1, 'm');
  const date = localeTime.format('DD');
  const indoMonth = getIndoMonthLong(localeTime.format('MMMM'));
  const year = localeTime.format('YYYY');
  const dateTime = localeTime.format('HH:mm');
  return `${date} ${indoMonth} ${year} ${dateTime}`;
};

export const checkExpiryDateTime = (expiryDate) => {
  const expiredLocale = moment.utc(expiryDate).local();
  const currentLocale = moment.utc().local();
  return expiredLocale.isSameOrBefore(currentLocale);
};

export const getExpiredTime = (expiryDate) => {
  if (!expiryDate) return '';
  const localeTime = moment.utc(expiryDate).local();
  return localeTime.format('DD/MM/YYYY');
};

export const subtractFromWorkAllowance = (dataAccounts, amountTobeSubtracted) => {
  const newDataAccounts = dataAccounts.slice();
  const findUangKantor = newDataAccounts.findIndex(
    (e) => e.constantAccountName === DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE,
  );
  if (findUangKantor !== -1) {
    let newAmount = removeNonNumeric(newDataAccounts[findUangKantor].amount);
    newAmount = parseInt(newAmount);
    newAmount -= amountTobeSubtracted;
    newDataAccounts[findUangKantor] = {
      ...newDataAccounts[findUangKantor],
      amount: `Rp${numberWithDots(newAmount)}`,
    };
  }
  return newDataAccounts;
};

const codeDecryptor = (encryptedCode) => {
  let output = '';
  let i = 0;
  while (i < encryptedCode.length) {
    output += String.fromCharCode(encryptedCode[i] + encryptedCode[i + 1]);
    i += 2;
  }

  return output;
};

export const refferalDencryption = (code) => {
  if (typeof code === 'string' && !/^\d+$/.test(code)) {
    return code;
  }
  return codeDecryptor(code);
};

//Checks if the screen exists in system
export const validateIfScreenExists = (item) => {
  //TODO: addmore screen names present
  const eligibleScreens = ['Dashboard', 'Pembayaran', 'Referral', 'Gamification', 'SalaryTransfer'];
  return item?.redirectionScreen && eligibleScreens.includes(item.redirectionScreen);
};

export function calculateSubTotal(requestedAmount, fee, feeType) {
  let subTotal = 0;
  if (feeType.toUpperCase() === 'FIXED') {
    subTotal = requestedAmount - fee;
  } else if (feeType.toUpperCase() === 'PERCENTAGE') {
    subTotal = requestedAmount - fee * 0.1;
  }
  return subTotal;
}
export const showBpAppTour = (tourType) => {
  let showTour = false;
  for (const key in BP_APP_TOUR) {
    if (tourType === BP_APP_TOUR[key]) showTour = true;
  }
  return showTour;
};

export const masker = (phoneNumber) => {
  let output = '';
  for (let i in phoneNumber) {
    if (phoneNumber.length - i <= 4) {
      output += phoneNumber[i];
    } else {
      output += '*';
    }
  }
  return output;
};

export function mapDataSameAsEWA(daTLResponse) {
  return daTLResponse.map((item) => {
    const { transHistoryId, notes, positionType, description } = item.account_transaction_history[0] || {
      transHistoryId: '',
      notes: '',
      positionType: '',
      description: '',
    };
    const {
      transactionId,
      transactionReasonId,
      transactionCategoryId,
      transStatus,
      transAmount,
      transRequestedAmount,
      transReceivedAmount,
      transFee,
      transDate,
      gg_transaction_reason,
      gg_transaction_category,
      voucher_list,
      aj_ayopop,
    } = item || {
      transactionId: '',
      transactionReasonId: '',
      transactionCategoryId: '',
      transStatus: '',
      transAmount: 0,
      transRequestedAmount: 0,
      transReceivedAmount: 0,
      transFee: 0,
      transDate: '',
      gg_transaction_reason: null,
      voucher_list: null,
      aj_ayopop: null,
    };
    const { transactionType, transReason } = gg_transaction_reason || {
      transactionType: '',
      transReason: '',
      tag: '',
    };
    const { displayName } = gg_transaction_category;
    const { account_type } = item.employee_account || { account_type: '' };
    const { moneyAccount } = account_type || { moneyAccount: '' };
    const { voucherCode } = voucher_list || { voucherCode: null };
    const { token, redeemUrl } = aj_ayopop || { token: null, redeemUrl: null };
    return {
      transHistoryId,
      transactionId: transactionId,
      transReason: transReason,
      transactionReasonId,
      transactionCategoryId,
      transStatus,
      transAmount,
      transRequestedAmount,
      transReceivedAmount,
      transFee,
      notes,
      moneyAccount,
      transDate,
      transactionType,
      position: positionType,
      description: description,
      displayName,
      coinValue: transAmount,
      token,
      redeemUrl,
      voucherCode,
    };
  });
}

export const returnSelectedItem = (nominalList, id) => {
  if (id === 15) {
    const findItem = nominalList.findIndex((e) => e.isSelected);
    if (findItem !== -1) {
      const findSubItem = nominalList[findItem].data.findIndex((e) => e.isSelected);
      if (findItem !== -1) {
        return nominalList[findItem].data[findSubItem];
      }
    }
    return null;
  }

  const findItem = nominalList.findIndex((e) => e.isSelected);
  if (findItem !== -1) {
    return nominalList[findItem];
  }
  return null;
};

export function getListOfDynamicAccounts(dynamicAccounts) {
  return {
    length: dynamicAccounts.length,
    pointsAccount: dynamicAccounts.find((e) => e.accountTypeName === DYNAMIC_ACCOUNT_NAMES.POINTS),
    workAllowance: dynamicAccounts.find((e) => e.accountTypeName === DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE),
    salaryAccount: dynamicAccounts.find((e) => e.accountTypeName === DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT),
    uangKas: dynamicAccounts.find((e) => e.accountTypeName === DYNAMIC_ACCOUNT_NAMES.UANG_KAS),
    sdAccount: dynamicAccounts.find((e) => e.accountTypeName === DYNAMIC_ACCOUNT_NAMES.SDACCOUNT),
  };
}

export function accountByRuleProductScreen(
  dataAccounts,
  lockPeriod,
  newAvailableBalance,
  item,
  dynamicAccountDetails,
  currentSelectedAccountName,
) {
  return (productFoundUnderWorkAllowance = false, accountList) => {
    const salaryAccount = dataAccounts.find((e) => e.constantAccountName === DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT);
    const searchingAccountList = accountList ?? dataAccounts;

    /*
    Priority:
    EWA -> 1
    POINTS -> 2
    WORK ALLOWANCE -> 3
    */
    const getFallBackAccountByPriority = () => {
      if (!lockPeriod && newAvailableBalance > 0) return salaryAccount;

      const pointsAccount = dataAccounts.find((e) => e.constantAccountName === DYNAMIC_ACCOUNT_NAMES.POINTS);
      if (pointsAccount && CATEGORY[item.id].id !== 15 && dynamicAccountDetails.pointsAccount?.availableBalance > 0)
        return pointsAccount;

      const workAllowanceAccount = dataAccounts.find(
        (e) => e.constantAccountName === DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE,
      );
      if (workAllowanceAccount && dynamicAccountDetails.workAllowance.availableBalance > 0) return workAllowanceAccount;

      const uangKasAccount = dataAccounts.find((e) => e.constantAccountName === DYNAMIC_ACCOUNT_NAMES.UANG_KAS);

      if (uangKasAccount && dynamicAccountDetails.uangKas.availableBalance > 0) {
        return uangKasAccount;
      }

      return salaryAccount;
    };

    const isFromWorkAllowance =
      currentSelectedAccountName === DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE &&
      dynamicAccountDetails.workAllowance?.availableBalance > 0;

    const isUangKasAllowance =
      currentSelectedAccountName === DYNAMIC_ACCOUNT_NAMES.UANG_KAS &&
      dynamicAccountDetails.uangKas?.availableBalance > 0;

    const isFromPoins =
      currentSelectedAccountName === DYNAMIC_ACCOUNT_NAMES.POINTS &&
      dynamicAccountDetails.pointsAccount?.availableBalance > 0;

    if (
      lockPeriod &&
      dynamicAccountDetails.pointsAccount?.availableBalance === 0 &&
      dynamicAccountDetails.workAllowance?.availableBalance === 0 &&
      !isFromWorkAllowance
    )
      return [];

    if (isFromWorkAllowance && productFoundUnderWorkAllowance) {
      return (
        searchingAccountList.find((e) => e.constantAccountName === DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE) ??
        getFallBackAccountByPriority()
      );
    }

    if (isUangKasAllowance) {
      return (
        searchingAccountList.find((e) => e.constantAccountName === DYNAMIC_ACCOUNT_NAMES.UANG_KAS) ??
        getFallBackAccountByPriority()
      );
    }

    if (isFromPoins && CATEGORY[item.id].id !== 15) {
      return (
        searchingAccountList.find((e) => e.constantAccountName === DYNAMIC_ACCOUNT_NAMES.POINTS) ??
        getFallBackAccountByPriority()
      );
    }

    return getFallBackAccountByPriority();
  };
}

export function accountByRuleVoucherScreen(
  dataAccounts,
  lockPeriod,
  newAvailableBalance,
  dynamicAccountDetails,
  currentSelectedAccountName,
) {
  return (productFoundUnderWorkAllowance = false, accountList) => {
    const salaryAccount = dataAccounts.find((e) => e.constantAccountName === DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT);
    const searchingAccountList = accountList ?? dataAccounts;
    const getFallBackAccountByPriority = () => {
      if (!lockPeriod && newAvailableBalance > 0) return salaryAccount;

      const pointsAccount = dataAccounts.find((e) => e.constantAccountName === DYNAMIC_ACCOUNT_NAMES.POINTS);
      if (pointsAccount && dynamicAccountDetails.pointsAccount?.availableBalance > 0) return pointsAccount;

      const workAllowanceAccount = dataAccounts.find(
        (e) => e.constantAccountName === DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE,
      );

      const uangKasAccount = dataAccounts.find((e) => e.constantAccountName === DYNAMIC_ACCOUNT_NAMES.UANG_KAS);

      if (uangKasAccount && dynamicAccountDetails.uangKas.availableBalance > 0) {
        return uangKasAccount;
      }

      if (workAllowanceAccount && dynamicAccountDetails.workAllowance.availableBalance > 0) return workAllowanceAccount;

      return salaryAccount;
    };

    const isFromWorkAllowance =
      currentSelectedAccountName === DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE &&
      dynamicAccountDetails.workAllowance?.availableBalance > 0;
    const isFromPoins =
      currentSelectedAccountName === DYNAMIC_ACCOUNT_NAMES.POINTS &&
      dynamicAccountDetails.pointsAccount?.availableBalance > 0;

    const isUangKasAllowance =
      currentSelectedAccountName === DYNAMIC_ACCOUNT_NAMES.UANG_KAS &&
      dynamicAccountDetails.uangKas?.availableBalance > 0;

    if (isUangKasAllowance) {
      return (
        searchingAccountList.find((e) => e.constantAccountName === DYNAMIC_ACCOUNT_NAMES.UANG_KAS) ??
        getFallBackAccountByPriority()
      );
    }

    if (
      lockPeriod &&
      dynamicAccountDetails.pointsAccount?.availableBalance === 0 &&
      dynamicAccountDetails.workAllowance?.availableBalance === 0 &&
      !isFromWorkAllowance
    )
      return [];

    if (isFromWorkAllowance && productFoundUnderWorkAllowance) {
      return (
        searchingAccountList.find((e) => e.constantAccountName === DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE) ??
        getFallBackAccountByPriority()
      );
    }

    if (isFromPoins) {
      return (
        searchingAccountList.find((e) => e.constantAccountName === DYNAMIC_ACCOUNT_NAMES.POINTS) ??
        getFallBackAccountByPriority()
      );
    }

    return getFallBackAccountByPriority();
  };
}

export function setPointsValues(data, dispatch, coinList, setOffset, offset, setCoinList) {
  const pointsModified = data.points_txn.map((txnItem) => {
    const { gg_transaction_reason } = txnItem || { gg_transaction_reason: '' };
    if (gg_transaction_reason.transactionType === 'EXPENSE') {
      return {
        ...txnItem,
        value: txnItem.value * -1,
      };
    }
    return txnItem;
  });
  dispatch(setCoinList(coinList.concat(pointsModified)));
  setOffset(offset + data.points_txn.length);
}

export function getOffSetForGraphql(isRefresh, customPage, offSet) {
  return isRefresh || customPage ? -1 : offSet;
}

export function getCurrentActiveMission(
  constantAccountName,
  billpaymentList,
  pointsBillPaymentList,
  workAllowanceBillPaymentList,
  showLoan,
  loanCategoryInfo,
  showTransfer,
  transferCategoryInfo,
  showZakat,
  zakatCategoryinfo,
) {
  switch (constantAccountName) {
    case DYNAMIC_ACCOUNT_NAMES.POINTS:
      return pointsBillPaymentList;

    case DYNAMIC_ACCOUNT_NAMES.WORK_ALLOWANCE:
      return workAllowanceBillPaymentList;

    case DYNAMIC_ACCOUNT_NAMES.SALARY_ACCOUNT:
      let udpatedBillPyaments = [...billpaymentList];
      if (showLoan) {
        udpatedBillPyaments.push(loanCategoryInfo);
      } else if (showTransfer) {
        udpatedBillPyaments.push(transferCategoryInfo);
      }
      if (showZakat) {
        udpatedBillPyaments.splice(2, 0, zakatCategoryinfo);
      }
      return udpatedBillPyaments;

    default:
      return billpaymentList;
  }
}

export function checkForLoan(showFeature, data) {
  if (!showFeature || !data) return false;
  if (data.loan_eligibility.length == 0) {
    if (data.loan_applications.length > 0) {
      if (data.loan_applications[0].userNotified === false) return true;
      if (data.loan_applications[0].userNotified === true) return false;
    }
    return false;
  }
  return true;
}

export function needForRefetchAppStatus(data) {
  if (data.loan_applications.length == 0) return false;
  if (data.loan_applications[0].userNotified === true) {
    if (
      data.loan_applications[0].applicationStatus === 'REJECT' ||
      data.loan_applications[0].applicationStatus === 'FULLY_PAID'
    )
      return false;
  }
  return true;
}
export const getUserBanksFromQuery = (data) => {
  let defaultBank = null;
  let userBanks = [];
  let commonUserReadStatus = true;
  if (data.gg_employee.length > 0) {
    defaultBank = {
      bankAccountNo: data.gg_employee[0].bankAccountNo,
      bankName: data.gg_employee[0].bank_name.bankName,
      beneficiaryName: data.gg_employee[0].beneficiaryName,
    };

    data.user_enrolled_bank.forEach((e) => {
      if (!e.isUserRead) commonUserReadStatus = false;

      let newBank = {
        accountNumber: e.accountNumber,
        beneficiaryName: e.beneficiaryName,
        bankName: e.gg_bank.bankName,
        id: e.id,
        isUserRead: e.isUserRead,
        nickName: e.nickname,
        status: e.status,
      };
      userBanks.push(newBank);
    });
  }
  return {
    defaultBank,
    userBanks,
    commonUserReadStatus,
  };
};

export function createBankAccountCompleteCallBack(
  userBanks,
  accountNumber,
  beneficiaryName,
  selectedBank,
  nickName,
  dispatch,
  setSubmitLoading,
  navigation,
) {
  return (data) => {
    if (data.insert_user_enrolled_bank.returning.length > 0) {
      const newUserBank = userBanks.slice();
      newUserBank.push({
        accountNumber,
        beneficiaryName,
        bankName: selectedBank.bankName,
        id: data.insert_user_enrolled_bank.returning[0].id,
        isUserRead: true,
        nickName,
        status: 'PENDING',
      });
      dispatch(createNewBankAccount(newUserBank));
    }

    setSubmitLoading(false);
    dispatch(respWarn('Proses verifikasi mulai diproses setelah kamu klik Verifikasi Sekarang'));
    if (navigation) {
      navigation();
    }
  };
}

export function saveButtonCallBack(selectedBank, accountNumber, beneficiaryName) {
  return () => {
    return !!(selectedBank !== null && accountNumber.length > 0 && beneficiaryName.length > 0);
  };
}

export function setSelectedBankCallBack(setSelectedBank, route) {
  return () => {
    setSelectedBank(route.params?.selectedBank ?? null);
  };
}

export function onPressSubmitCallBack(
  logEvent,
  setSubmitLoading,
  createBank,
  accountNumber,
  selectedBank,
  beneficiaryName,
  nickName,
  employeeId,
) {
  return () => {
    logEvent(SHARED_AMPLITUDE_CONSTANTS.bankRegistration.add_bank_account_saved);
    setSubmitLoading(true);
    createBank({
      variables: { accountNumber, bankId: parseInt(selectedBank.id), beneficiaryName, nickName, employeeId },
    });
  };
}

export function onClickSecondaryBottomSheet(setInfoModal, infoModal, deleteUserBank, currentSelectedBankId) {
  setInfoModal({ ...infoModal, visible: false });
  if (infoModal.errorCode === BOTTONSHEET_KEYS.BANK_DELETE) {
    deleteUserBank({ variables: { accountId: currentSelectedBankId } });
  }
}

export function onCompleteDeleteBank(userBanks, currentSelectedBankId, dispatch) {
  const newUserBank = userBanks.slice();
  const findCurrentSelectedBank = newUserBank.findIndex((e) => e.id === currentSelectedBankId);
  if (findCurrentSelectedBank !== -1) {
    newUserBank.splice(findCurrentSelectedBank, 1);
    dispatch(deleteBankAccount(newUserBank));
  }
}

export function resetNavigationStack(CommonActions, navigation) {
  navigation.dispatch((state) => {
    const routes = state.routes.filter((r) => r.name !== 'LoanListing');
    return CommonActions.reset({
      ...state,
      routes,
      index: routes.length - 1,
    });
  });
}

export function getUpdatedCategories(
  categories,
  loanData,
  isLoan,
  transferData,
  isTransfer,
  shouldShowZakat,
  zakatData,
) {
  if (shouldShowZakat) {
    categories.splice(0, 1);
    categories.unshift(zakatData);
  }

  if (isTransfer) {
    categories.splice(1, 1);
    categories.unshift(transferData);
  }

  if (isLoan) {
    categories.splice(2, 1);
    categories.unshift(loanData);
  }

  return categories;
}

export function checkToNavigateDetails(data, LOAN_APPLICATION_STATUS, navigation, source) {
  if (data.loan_applications.length == 0)
    return navigation.navigate('ProductStack', {
      screen: 'LoanListing',
      params: {
        source,
      },
    });

  if (
    (data.loan_applications[0].applicationStatus === LOAN_APPLICATION_STATUS.APPROVED ||
      data.loan_applications[0].applicationStatus === LOAN_APPLICATION_STATUS.PENDING_APPROVAL ||
      data.loan_applications[0].applicationStatus === LOAN_APPLICATION_STATUS.DISBURSED) &&
    data.loan_applications[0].userNotified
  ) {
    return navigation.navigate('ProductStack', {
      screen: 'LoanDetails',
      params: {
        loanProductCode: data.loan_applications[0].loanProductCode,
        appId: data.loan_applications[0].id,
      },
    });
  }
  if (
    (data.loan_applications[0].applicationStatus === LOAN_APPLICATION_STATUS.REJECTED ||
      data.loan_applications[0].applicationStatus === LOAN_APPLICATION_STATUS.FULLY_PAID) &&
    data.loan_applications[0].userNotified
  ) {
    return navigation.navigate('ProductStack', {
      screen: 'LoanListing',
      params: {
        source,
      },
    });
  }

  return true;
}

export function onShowModalHandler(
  data,
  LOAN_STATUS_TYPES,
  setInfoModalSheet,
  LOAN_APPLICATION_STATUS,
  navigation,
  source,
) {
  if (checkToNavigateDetails(data, LOAN_APPLICATION_STATUS, navigation, source) === true) {
    setInfoModalSheet((prevState) => ({
      ...prevState,
      visible: true,
      data: LOAN_STATUS_TYPES[data.loan_applications[0].applicationStatus],
      type: 'loan',
    }));
  }
}

export function onUpdateHandler(data, LOAN_APPLICATION_STATUS, navigation, source) {
  if (
    data.applicationStatus === LOAN_APPLICATION_STATUS.APPROVED ||
    data.applicationStatus === LOAN_APPLICATION_STATUS.PENDING_APPROVAL ||
    data.applicationStatus === LOAN_APPLICATION_STATUS.DISBURSED
  ) {
    navigation.navigate('ProductStack', {
      screen: 'LoanDetails',
      params: {
        loanProductCode: data.loanProductCode,
        appId: data.id,
        source,
      },
    });
    return;
  }
  navigation.navigate('ProductStack', {
    screen: 'LoanListing',
    params: {
      source,
    },
  });
}

export function filterRejected(payload, isTransactional) {
  const approvedList = [];
  const pendingList = [];
  const rejectedList = [];
  payload.forEach((e) => {
    if (e.status === PHONE_ENROLLMENT_STATUS.APPROVED) approvedList.push(e);
    if (e.status === PHONE_ENROLLMENT_STATUS.PENDING) pendingList.push(e);
    if (!isTransactional) if (e.status === PHONE_ENROLLMENT_STATUS.REJECTED) rejectedList.push(e);
  });
  return approvedList.concat(pendingList, rejectedList);
}

export function onClickSecondaryPhonNoBottomSheet(
  setInfoModal,
  infoModal,
  deletePhoneNumber,
  currentSelectedBankId,
  BOTTONSHEET_PHONE_ENROLLMENT,
) {
  setInfoModal({ ...infoModal, visible: false });
  if (infoModal.errorCode === BOTTONSHEET_PHONE_ENROLLMENT.PHONE_DELETE) {
    deletePhoneNumber({ variables: { eId: currentSelectedBankId } });
  }
}

export function getShowValue(isTransact, paramsVal, mainVal) {
  if (isTransact && paramsVal) return paramsVal;
  return mainVal;
}

export function addPhnoCompleteCallBack(dispatch, navigation) {
  return (data) => {
    if (data?.insert_employee_enrolled_phone?.affected_rows === 1) {
      dispatch(respWarn('Proses verifikasi mulai diproses setelah kamu klik Verifikasi Sekarang'));
      navigation.reset({
        index: 0,
        routes: [{ name: 'PhoneEnrollmentList', params: { source: 'add_phone_number' } }],
      });
    }
  };
}

export function onErrPhoneNoCallbak(
  BOTTONSHEET_PHONE_ENROLLMENT,
  BOTTONSHEET_PHONE_CONTENT,
  returnBottomSheetContent,
  setInfoModal,
  logEvent,
) {
  return (err) => {
    if (err.message === 'PHONE_ALREADY_EXISTS') {
      setInfoModal((prevState) => ({
        ...prevState,
        data: returnBottomSheetContent(
          BOTTONSHEET_PHONE_ENROLLMENT.DUPLICATE_ENTRY,
          BOTTONSHEET_PHONE_CONTENT[BOTTONSHEET_PHONE_ENROLLMENT.DUPLICATE_ENTRY],
        ),
        errorCode: BOTTONSHEET_PHONE_ENROLLMENT.DUPLICATE_ENTRY,
        visible: true,
      }));
      logEvent('number_duplicated');
    }

    if (err.message === 'REGISTERED_PHONE_LIMIT') {
      setInfoModal((prevState) => ({
        ...prevState,
        data: returnBottomSheetContent(
          BOTTONSHEET_PHONE_ENROLLMENT.MAX_LIMIT,
          BOTTONSHEET_PHONE_CONTENT[BOTTONSHEET_PHONE_ENROLLMENT.MAX_LIMIT],
        ),
        errorCode: BOTTONSHEET_PHONE_ENROLLMENT.MAX_LIMIT,
        visible: true,
      }));

      logEvent('max_reached');
    }
  };
}
export const validateNominal = (minBalance, errorInput, setErrorInput, newAvailableBalance, setNominal, value) => {
  setNominal(removeNonNumeric(value));
  const newValue = parseInt(removeNonNumeric(value));
  if (newValue < minBalance) {
    !errorInput.visible && setErrorInput({ visible: true, message: `Min. transfer Rp${numberWithDots(minBalance)}` });
  } else if (newValue > newAvailableBalance) {
    !errorInput.visible && setErrorInput({ visible: true, message: 'Saldo tidak cukup' });
  } else if (errorInput.visible) {
    setErrorInput({ visible: false, message: '' });
  }
};

export const excludeReasonsDisplay = (item) => {
  const exclusionResons = [38, 41, 45, 47, 43];
  return exclusionResons.includes(item.id);
};

export function getMaxWithdrawalAmount(maxWithdrawalAmount, salaryWithCappedAmount) {
  return numberWithDots(maxWithdrawalAmount >= 0 ? maxWithdrawalAmount : salaryWithCappedAmount);
}

export function getClosePeriodDate(endWorkingDate, withdrawalLockType, numberOfLockPeriod) {
  let endFullDate = '';

  // HIDE OR SHOW VARIABLES

  let endMonth = '';

  let endYear = '';

  if (!moment(endWorkingDate).isValid()) return '';

  // END DATE
  if (withdrawalLockType === 'FIXED') {
    if (numberOfLockPeriod != 0) {
      endFullDate = moment(endWorkingDate).set('date', numberOfLockPeriod);

      if (moment(endFullDate).isAfter(moment(endWorkingDate))) {
        endFullDate = moment(endFullDate).subtract(1, 'month').format('DD-MMMM-YYYY');
      }
      endFullDate = moment(endFullDate).subtract(1, 'days').format('DD-MMMM-YYYY');
    } else {
      endFullDate = moment(endWorkingDate)
        .subtract(numberOfLockPeriod + 1, 'days')
        .format('DD-MMMM-YYYY');
    }
  } else {
    endFullDate = moment(endWorkingDate)
      .subtract(numberOfLockPeriod + 1, 'days')
      .format('DD-MMMM-YYYY');

    if (moment(endFullDate).isAfter(moment(endWorkingDate))) {
      endFullDate = moment(endFullDate).subtract(1, 'month').format('DD-MMMM-YYYY');
    }
  }

  // SAME MONTH
  endMonth = getIndoMonthLong(moment(endFullDate).format('MMMM'));
  // SAME YEAR
  endYear = moment(endFullDate).format('YYYY');

  // CONSTRUCTING STRINGS

  const endDate = moment(endFullDate).get('date');

  let returnDate = endDate;

  if (endMonth != '') returnDate = returnDate + ` ${endMonth}`;
  if (endYear != '') returnDate = returnDate + ` ${endYear}`;

  return returnDate;
}

export function getDateRange(
  firstWorkingDate,
  endWorkingDate,
  startWithdrawalDate,
  withdrawalLockType,
  numberOfLockPeriod,
) {
  let startFullDate = '';
  let endFullDate = '';

  // START DATE
  if (startWithdrawalDate != null) {
    startFullDate = moment(firstWorkingDate).set('date', startWithdrawalDate).format('DD/MM/YYYY');

    if (moment(startFullDate).isBefore(moment(firstWorkingDate).format('DD/MM/YYYY'))) {
      startFullDate = moment(startFullDate).add(1, 'month').format('DD/MM/YYYY');
    } else if (moment(startFullDate).isSame(moment(firstWorkingDate))) {
      // do nothing
    }
  } else {
    startFullDate = moment(firstWorkingDate).format('DD/MM/YYYY');
  }

  // END DATE
  if (withdrawalLockType == 'FIXED') {
    if (numberOfLockPeriod != 0) {
      endFullDate = moment(endWorkingDate).set('date', numberOfLockPeriod);

      if (moment(endFullDate).isAfter(moment(endWorkingDate))) {
        endFullDate = moment(endFullDate).subtract(1, 'month').format('DD/MM/YYYY');
      }
      endFullDate = moment(endFullDate).subtract(1, 'days').format('DD/MM/YYYY');
    } else {
      endFullDate = moment(endWorkingDate)
        .subtract(numberOfLockPeriod + 1, 'days')
        .format('DD/MM/YYYY');
    }
  } else {
    endFullDate = moment(endWorkingDate)
      .subtract(numberOfLockPeriod + 1, 'days')
      .format('DD/MM/YYYY');

    if (moment(endFullDate).isAfter(moment(endWorkingDate))) {
      endFullDate = moment(endFullDate).subtract(1, 'month').format('DD/MM/YYYY');
    }
  }

  return `${startFullDate} - ${endFullDate}`;
}

export function validatePin(code) {
  if (code.length !== 6) return false;
  let counter = 0;
  let sequenceCounter = 1;
  const uniqueObj = {};
  let setAscDesc = -1;

  for (let i = 0; i < code.length; i += 1) {
    // all same pin validation
    if (!uniqueObj[code[i]]) {
      uniqueObj[code[i]] = true;
      counter = 1;
    } else if (uniqueObj[code[i]]) counter += 1;

    if (counter === code.length) return false;

    // sequence check
    if (i + 1 < code.length) {
      if (i === 0) {
        if (code[i + 1] > code[i]) setAscDesc = 1;
        else setAscDesc = -1;
      }

      if (setAscDesc === 1) {
        if (code[i + 1] - code[i] === 1) sequenceCounter += 1;
      } else if (setAscDesc === -1) {
        if (code[i] - code[i + 1] === 1) sequenceCounter += 1;
      } else sequenceCounter = 1;
    }

    if (sequenceCounter === code.length) return false;
  }

  return true;
}
