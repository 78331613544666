import { StyleSheet } from 'react-native';
import { colors } from '@style/colors';

export const styles = StyleSheet.create({
  dot: {
    height: 8,
    width: 8,
    borderRadius: 50,
    backgroundColor: colors.brightred,
    marginStart: 10,
  },
  bankIconContainer: { flexDirection: 'row', alignItems: 'center' },
  bankIcon: { marginEnd: 10 },
  pressable: {
    paddingVertical: '5%',
    width: '100%',
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingEnd: '5%',
  },
  mainContainer: {
    overflow: 'hidden',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    alignSelf: 'center',
    marginVertical: 10,
    backgroundColor: colors.white,
    borderRadius: 10,
    borderWidth: 0.5,
    borderColor: colors.philippineGray,
    width: '92%',
  },
});
