import { StyleSheet } from 'react-native';
import colors from '@gaji-gesa/gg-react-shared/src/colors';

export const styles = StyleSheet.create({
  infoOuterContainer: {
    borderRadius: 8,
    marginTop: 16,
    paddingVertical: 18,
    marginHorizontal: 16,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
    backgroundColor: 'white',
  },

  infoTextStyle: { fontSize: 14, textAlign: 'center' },

  rewardInfo: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
  },
  coinAdj: { marginRight: 5 },
  rewardStyle: { fontSize: 20, color: colors.warning_darkSunflower },
});
