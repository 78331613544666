import React from 'react';
import { View } from 'react-native';
import MyText from '@atoms/MyText';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import styles from './style';

const StatusBox = ({ isActive, displayText, timeStamp }) => {
  function shouldRenderClosePeriod(show) {
    if (show)
      return (
        <MyText isRegular={false} customStyle={styles.timeStampText}>
          {timeStamp}
        </MyText>
      );

    return null;
  }

  return (
    <View style={styles.containerStyle}>
      <View style={styles.rowStyle}>
        <View
          style={[
            styles.circleStyle,
            {
              backgroundColor: isActive
                ? colors.success_cactus
                : colors.error_rose,
            },
          ]}
        />
        <MyText customStyle={styles.displayText}>{displayText}</MyText>
      </View>

      {shouldRenderClosePeriod(isActive)}
    </View>
  );
};

export default StatusBox;
