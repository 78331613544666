import React from 'react';
import PropTypes from 'prop-types';
import { Platform, View } from 'react-native';
import ExpoFeather from '@expo/vector-icons/MaterialIcons';
import RNFeather from 'react-native-vector-icons/MaterialIcons';
import { colors } from '@style/colors';
import MyText from '@atoms/MyText';
import styles from './style';

const Info = Platform.OS === 'web' ? ExpoFeather : RNFeather;

const InfoTextComponent = ({
  infoText,
  iconColor,
  containerStyle,
  customTextStyle,
  iconContainer,
  size = 20,
  iconName = 'info-outline',
  hide,
  textBucket,
}) => {
  if (hide) return null;

  function getText(txtArr) {
    return (
      <View>
        {txtArr.map((t, i) => (
          <View style={styles.textContainer}>
            <MyText isRegular={false} customStyle={styles.textStyle}>
              {`${i + 1}`}.
            </MyText>
            <MyText isRegular={false} customStyle={styles.textStyle}>
              {t}
            </MyText>
          </View>
        ))}
      </View>
    );
  }

  function shouldShowText(txt, txtArr) {
    if (txtArr?.length > 0) return getText(txtArr);

    return (
      <MyText
        isRegular={false}
        customStyle={{ ...styles.text, ...customTextStyle }}
      >
        {txt}
      </MyText>
    );
  }

  return (
    <View style={[styles.mainContainer, containerStyle]}>
      <View style={iconContainer}>
        <Info
          name={iconName}
          color={iconColor ? iconColor : colors.blue}
          size={size}
        />
      </View>

      {shouldShowText(infoText, textBucket)}
    </View>
  );
};

InfoTextComponent.propTypes = {
  infoText: PropTypes.string.isRequired,
  iconColor: PropTypes.string,
  containerStyle: PropTypes.object,
};

export default InfoTextComponent;
