import { StyleSheet } from 'react-native';
import { normalize } from '../../../sharedStyle/helper';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
export const styles = StyleSheet.create({
  rowStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: normalize(5),
    paddingVertical: normalize(4),
  },
  titleStyle: { fontSize: normalize(14) },
  outerContainer: {
    paddingHorizontal: normalize(15),
    marginTop: normalize(8),
    paddingVertical: normalize(12),
  },
  innerContainer: {
    backgroundColor: colors.neutral_mist,
    marginTop: normalize(8),
    paddingVertical: normalize(12),
    paddingHorizontal: normalize(12),
    borderRadius: normalize(12),
  },
  mainTitleStyle: { fontSize: normalize(14), marginLeft: normalize(5) },
});
