import { combineReducers } from 'redux';
import Authentication, { AUTH_REDUCER_CASES } from './reducer/Authentication';
import Common from './reducer/Common';
import BillPayments from './reducer/BillPayments';
import Loan from './reducer/Loan';
import Transactions from './reducer/Transactions';
import WIthdrawal from './reducer/WIthdrawal';
import Salary from './reducer/Salary';
import Attendance from './reducer/Attendance';
import TransactionDetails from './reducer/TransactionDetails';
import Coins from './reducer/Coins';
import Modals from './reducer/Modals';
import NewBillPayment from './reducer/NewBillPayment';
import FeatureFlags from './reducer/FeatureFlags';
import Referral from './reducer/Referral';
import DynamicAccounts from './reducer/DynamicAccounts';
import Missions from './reducer/Missions';
import AppTour from './reducer/AppTour';
import UserBank from './reducer/UserBank';
import UserPhoneReducer from './reducer/UserPhoneReducer';

export const combineReducer = combineReducers({
  Authentication: Authentication,
  Common: Common,
  BillPayments: BillPayments,
  Loan: Loan,
  Transactions: Transactions,
  Withdrawal: WIthdrawal,
  Salary: Salary,
  Attendance: Attendance,
  TransactionDetails: TransactionDetails,
  Coins: Coins,
  Modals: Modals,
  NewBillPayment: NewBillPayment,
  FeatureFlag: FeatureFlags,
  Referral: Referral,
  DynamicAccounts: DynamicAccounts,
  Missions: Missions,
  AppTour: AppTour,
  UserBank: UserBank,
  UserPhone: UserPhoneReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === AUTH_REDUCER_CASES.setAutoLogout) {
    return combineReducer(undefined, action);
  }
  return combineReducer(state, action);
};
