import { gql } from '@apollo/client';

const GET_PHONE_NUMBERS = gql`
  query getPhoneNumbers {
    employee_enrolled_phone(order_by: { createdAt: asc }) {
      mobileNumber
      ownerName
      status
      id
    }
  }
`;

const ADD_PHONE_NUMBER = gql`
  mutation addPhoneNumber($userPayload: employee_enrolled_phone_insert_input!) {
    insert_employee_enrolled_phone(objects: [$userPayload]) {
      affected_rows
    }
  }
`;

const DELETE_PHONE_NUMBER = gql`
  mutation deletePhoneNumber($eId: Int!) {
    delete_employee_enrolled_phone_by_pk(id: $eId) {
      id
    }
  }
`;

export { GET_PHONE_NUMBERS, ADD_PHONE_NUMBER, DELETE_PHONE_NUMBER };
