import { StyleSheet } from 'react-native';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { normalize } from '../../../sharedStyle/helper';

export const styles = StyleSheet.create({
  container: {
    borderRadius: normalize(11),
    marginHorizontal: normalize(14),
    marginTop: normalize(8),
    backgroundColor: colors.variants_clover,
    paddingVertical: normalize(17),
    overflow: 'hidden',
  },
  textStyle: {
    color: 'white',
    fontSize: normalize(12),
    marginLeft: normalize(15),
  },
  assetContainer: {
    position: 'absolute',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    zIndex: -1,
  },
  scrapesStyle: { bottom: normalize(13) },
  confettiStyle: { top: normalize(5.5) },
});
