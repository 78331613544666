import { StyleSheet } from 'react-native';
import { normalize } from '../../../sharedStyle/helper';
import colors from '@gaji-gesa/gg-react-shared/src/colors';

export const styles = StyleSheet.create({
  container: {
    height: normalize(12),
    zIndex: 10,
    width: normalize(25),
    alignSelf: 'center',
    position: 'absolute',
    marginTop: normalize(-5),
    right: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.error_rose,
    borderRadius: normalize(27),
  },
  textStyle: {
    fontSize: normalize(8),
    color: colors.neutral_cotton,
  },
});
