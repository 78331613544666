import React from 'react';
import { View } from 'react-native';
import MyText from '@atoms/MyText';
import { numberWithDots } from '@gaji-gesa/gg-react-shared/src/utils/sharedUtils';
import moment from 'moment';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { styles } from './style';
import PropTypes from 'prop-types';

const getBgColor = (count, idx, total) => {
  if (count === 0 && idx === 0) {
    return { bg: styles.ongoingHighlighter, txt: styles.txtStyle };
  }
  if (count === total) {
    return { bg: styles.paidHighter, txt: styles.txtStyle };
  }
  if (count === idx) {
    return { bg: styles.ongoingHighlighter, txt: styles.txtStyle };
  }
  if (count > idx) {
    return { bg: styles.paidHighter, txt: styles.txtStyle };
  }
};

const InstallMentRow = ({ item, index, total, installMentsData }) => {
  const paidCount = installMentsData.filter((e) => e.status === 'PAID').length;

  let displayMeta = {
    icon: 'check-circle',
    color: colors.success_darkCactus,
    text: 'Selesai',
  };

  if (item.status !== 'PAID') {
    displayMeta = {
      icon: 'calendar-blank',
      color: colors.neutral_darkMist,
      text: moment(item.dueDate, 'YYYY-MM-DD').format('DD MMM YYYY'),
    };
  }

  return (
    <View
      style={[styles.installmentRow, getBgColor(paidCount, index, total)?.bg]}
    >
      <View>
        <MyText
          customStyle={[
            styles.srStyles,
            getBgColor(paidCount, index, total)?.txt,
          ]}
          isRegular={false}
        >
          {index + 1}
          <MyText isRegular={false} customStyle={styles.totalTextColor}>
            {`/${total}`}{' '}
          </MyText>
        </MyText>
        <MyText
          isRegular={false}
          customStyle={[
            styles.installTextSyle,
            getBgColor(paidCount, index, total)?.txt,
          ]}
        >
          {`Rp${numberWithDots(item.installmentValue)}`}
        </MyText>
      </View>

      <View style={styles.secondPart}>
        <>
          <Icon name={displayMeta.icon} color={displayMeta.color} size={20} />
          <MyText isRegular={false} customStyle={styles.dueTextStyle}>
            {displayMeta.text}
          </MyText>
        </>
      </View>
    </View>
  );
};

const LoanInstallMents = ({ show, installMentsData }) => {
  if (!show || installMentsData.length === 0) return null;

  return (
    <>
      <View style={styles.outerContainer}>
        <MyText isRegular={false} customStyle={styles.titleStyle}>
          Detail Cicilan
        </MyText>

        <MyText isRegular customStyle={styles.descStyle}>
          Pemotongan gaji akan dilakukan untuk cicilan e-Kasbon setiap siklus
          gajian, sesuai dengan tenor
        </MyText>
      </View>

      <View style={styles.titleContainer}>
        <View style={styles.titleRow}>
          <MyText isRegular={false}>Cicilan Ke</MyText>
          <MyText isRegular={false}>Tanggal dan Status</MyText>
        </View>

        {installMentsData.map((o, i) => (
          <InstallMentRow
            item={o}
            index={i}
            total={installMentsData.length}
            installMentsData={installMentsData}
          />
        ))}
      </View>
    </>
  );
};

LoanInstallMents.propTypes = {
  show: PropTypes.bool,
  installMentsData: PropTypes.array,
};

export default LoanInstallMents;
