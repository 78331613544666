import { COINS_CASES } from '../reducer/Coins';

export const setCoinBalance = (data) => ({
  type: COINS_CASES.setCoins,
  payload: data,
});

export const setCoinList = (data) => ({
  type: COINS_CASES.setCoinList,
  payload: data,
});

export const setUseCoin = (data) => ({
  type: COINS_CASES.setUseCoin,
  payload: data,
});
