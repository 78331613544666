import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { StyleSheet } from 'react-native';
import { normalize } from '../../../sharedStyle/helper';

export const styles = StyleSheet.create({
  outerContainer: {
    marginBottom: normalize(10),
    marginTop: normalize(10),
    paddingHorizontal: normalize(15),
  },
  titleStyle: { fontSize: normalize(14) },
  descStyle: { color: colors.neutral_darkMist, fontSize: normalize(12) },
  titleContainer: {
    marginHorizontal: normalize(15),
    borderWidth: 1,
    borderColor: colors.gainsboro,
    overflow: 'hidden',
    borderRadius: normalize(12),
  },
  titleRow: {
    flexDirection: 'row',
    backgroundColor: colors.neutral_mist,
    paddingHorizontal: normalize(30),
    justifyContent: 'space-between',
    paddingVertical: normalize(12),
  },
  titleText: {},
  ongoingHighlighter: {
    backgroundColor: colors.warning_softSunflower,
    borderLeftWidth: normalize(3),
    borderColor: colors.warning_sunflower,
    borderRightWidth: 0,
    borderRightColor: 'transparent',
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
  },
  paidHighter: {
    backgroundColor: colors.success_softCactus,
    borderLeftWidth: normalize(3),
    borderColor: colors.success_darkCactus,
    borderRightWidth: 0,
    borderRightColor: 'transparent',
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
  },

  installmentRow: {
    flexDirection: 'row',
    borderColor: colors.neutral_mist,
    paddingLeft: normalize(30),
    paddingVertical: normalize(6),
    justifyContent: 'space-between',
    borderWidth: 1.2,
  },
  secondPart: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: normalize(123),
  },
  dueTextStyle: {
    fontSize: normalize(12),
    color: colors.neutral_darkMist,
    marginLeft: normalize(5),
  },
  totalTextColor: { color: colors.neutral_darkMist },
  installTextSyle: { fontSize: normalize(12), color: colors.neutral_darkMist },
  txtStyle: {
    color: colors.neutral_charcoal,
  },
  srStyles: { marginVertical: normalize(4), color: colors.neutral_darkMist },
});
