import React from 'react';
import { StyleSheet, TextInput, View } from 'react-native';
import { colors } from '@style/colors';
import { Font } from '@style/Fonts';
import MyText from '@atoms/MyText';

const InputComponent = ({
  title,
  placeholder,
  value,
  onChangeText,
  multiline = false,
  disabled = false,
  hasColorBox = false,
  color,
  hasTag,
  tagName,
  tagColor,
  tagTextColor,
  onFocus,
  titleStyle,
}) => (
  <View style={{ marginVertical: 8 }}>
    <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
      <MyText customStyle={{ marginStart: 10, color: colors.greyShade, ...titleStyle }}>{title}</MyText>
      {hasTag && (
        <View
          style={{
            paddingHorizontal: 8,
            paddingVertical: 2,
            borderRadius: 20,
            marginStart: 5,
            overflow: 'hidden',
            backgroundColor: tagColor,
          }}
        >
          <MyText h1 customStyle={{ color: tagTextColor }}>
            {tagName}
          </MyText>
        </View>
      )}
    </View>

    {hasColorBox ? (
      <View style={[styles.input, { paddingVertical: 13 }]}>
        <View style={{ height: 20, width: 20, borderRadius: 3, backgroundColor: color }} />
      </View>
    ) : (
      <TextInput
        value={value}
        onFocus={onFocus}
        editable={!disabled}
        style={[
          multiline
            ? { ...styles.input, minHeight: 100, textAlignVertical: 'top' }
            : disabled
            ? { ...styles.input, borderColor: colors.greyShade, color: colors.greyShade }
            : styles.input,
        ]}
        multiline={multiline}
        placeholder={placeholder}
        onChangeText={onChangeText}
      />
    )}
  </View>
);

const styles = StyleSheet.create({
  input: {
    paddingHorizontal: 10,
    borderRadius: 5,
    borderWidth: 0.8,
    borderColor: colors.grey,
    marginTop: 5,
    fontFamily: Font.Nunito_Regular,
  },
});

export default InputComponent;
