import React from 'react';
import { View, Image } from 'react-native';
import LoadingButton from '@atoms/LoadingButton';
import MyText from '@atoms/MyText';
import { styles } from './style';

const ReferralConfigButtonError = ({ onButtonPress }) => (
  <View style={styles.container}>
    <Image
      source={require('@gaji-gesa/gg-react-shared/src/assets/Svg/referral/art_postmail.png')}
      style={styles.imageContainer}
    />
    <MyText customStyle={styles.textStyle}>
      {'Maaf, fitur ini belum tersedia untuk\n tempat kerjamu'}
    </MyText>

    <LoadingButton
      textStyle={styles.loadinButtonStyle}
      text={'Kembali Lagi Nanti!'}
      onPress={onButtonPress}
      container={styles.buttonContainerStyle}
    />
  </View>
);

export default ReferralConfigButtonError;
