import React from 'react';
import { View } from 'react-native';
import MyText from '@atoms/MyText';
import { styles } from './style';

const ZakatPoster = () => (
  <View style={styles.containerStyle}>
    <MyText isRegular customStyle={styles.textOne}>
      Niat Mengeluarkan Zakat
    </MyText>

    <MyText isRegular={false} customStyle={styles.textTwo}>
      نَنويت ان اجرج زكاة الفطر عن نفسي فرضا لله تعالا
    </MyText>

    <MyText isRegular={false} customStyle={styles.textThree}>
      “Nawaitu an ukhrija zakaatal fitri ‘an nafsii fadhan lillahi ta’aala”
    </MyText>

    <MyText customStyle={styles.textFour}>
      {`Saya niat mengeluarkan zakat fitrah dari diriku sendiri fardu\n karena Allah Ta’ala`}
    </MyText>
  </View>
);
export default ZakatPoster;
