import MyText from '@atoms/MyText';
import { colors } from '@style/colors';
import React from 'react';
import ExpoEntypo from '@expo/vector-icons/Entypo';
import RNEntypo from 'react-native-vector-icons/Entypo';
import { View, Pressable, Platform } from 'react-native';
import PropTypes from 'prop-types';
import styles from './styles';

const Down = Platform.OS === 'web' ? ExpoEntypo : RNEntypo;

const AccountSelector = ({ onPress, selectedAccount }) => (
  <View style={[styles.container, { backgroundColor: selectedAccount?.color }]}>
    <Pressable
      onPress={onPress}
      style={styles.button}
      android_ripple={{ color: colors.greyShade }}
    >
      <View style={styles.iconContainer}>
        {selectedAccount?.icon}
        <MyText
          h2
          isRegular={false}
          customStyle={{ ...styles.textName, color: colors.black }}
        >
          {selectedAccount?.accountName}
        </MyText>
      </View>
      <Down name="chevron-down" size={18} color={colors.black} />
    </Pressable>
  </View>
);

AccountSelector.propTypes = {
  onPress: PropTypes.func.isRequired,
  selectedAccount: PropTypes.shape({
    color: PropTypes.string.isRequired,
    disabledIcon: PropTypes.element,
    icon: PropTypes.element.isRequired,
    accountName: PropTypes.string.isRequired,
  }).isRequired,
};

AccountSelector.defaultProps = {};

export default AccountSelector;
