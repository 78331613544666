import { StyleSheet } from 'react-native';
import { normalize } from '../../../sharedStyle/helper';
import colors from '@gaji-gesa/gg-react-shared/src/colors';

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: normalize(20),
    marginVertical: normalize(10),
    backgroundColor: colors.neutral_cotton,
  },
  itemStyle: {
    marginLeft: normalize(15),
    marginRight: normalize(40),
    fontSize: normalize(11),
  },
});
export default styles;
