import React from 'react';
import { View } from 'react-native';
import MyText from '@atoms/MyText';
import { numberWithDots } from '@gaji-gesa/gg-react-shared/src/utils/sharedUtils';
import Divider from '@atoms/Divider';
import { styles } from './style';
import PropTypes from 'prop-types';

const LoanMetaDetail = ({ upFrontFee, show }) => {
  if (!show) return null;

  return (
    <View style={styles.container}>
      <MyText isRegular={false} customStyle={styles.title}>
        Rincian
      </MyText>

      <MyText isRegular customStyle={styles.descriptText}>
        Biaya layanan akan langsung memotong jumlah danamu yang telah disetujui
      </MyText>

      <View style={styles.rowContainer}>
        <MyText isRegular customStyle={styles.keyStyle}>
          Biaya layanan
        </MyText>

        <MyText isRegular={false} customStyle={styles.keyStyle}>
          {`Rp${numberWithDots(upFrontFee)}`}
        </MyText>
      </View>

      <Divider horizontal customStyle={styles.dividerStyle} />
    </View>
  );
};

LoanMetaDetail.propTypes = {
  upFrontFee: PropTypes.number,
  show: PropTypes.bool,
};

export default LoanMetaDetail;
