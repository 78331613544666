import { StyleSheet } from 'react-native';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { normalize } from '../../../sharedStyle/helper';

export const styles = StyleSheet.create({
  container: {
    borderRadius: normalize(16),
    minWidth: '26%',
    backgroundColor: colors.warning_sunflower,
    overflow: 'hidden',
    flexDirection: 'row',
    height: normalize(25),
    alignItems: 'center',
    elevation: 8,
  },
  iconContainer: {
    borderRadius: normalize(16),
    backgroundColor: colors.darkYellow,
    height: normalize(31),
    width: normalize(31),
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: normalize(10),
  },
  textStyle: {
    fontSize: normalize(11),
    paddingLeft: normalize(3),
    color: colors.neutral_cotton,
  },
});
