import { StyleSheet } from 'react-native';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { normalize } from '../../../sharedStyle/helper';

export const styles = StyleSheet.create({
  appVersionText: {
    marginTop: normalize(11),
    fontSize: normalize(10),
    color: colors.neutral_charcoal,
    alignSelf: 'center',
  },
});
