export const colors = {
  bgGreen: '#F2FEFB',
  blue: '#3863F0',
  orange: '#F56B57',
  teal: '#1688B5',
  green: '#2CBE96',
  orange: '#F56B57',
  pink: '#FDE8E4',
  lightblue: '#EDF7FC',
  red: '#C80000',
  brightred: '#FF0000',
  grey: '#979797',
  black: '#454545',
  slateGray: '#757f8c',
  backgroundGrey: '#F2F5F8',
  textGreen: '#137A08',
  textRed: '#C80000',
  textPurple: '#7B47E9',
  textYellow: '#EC850D',
  textBrown: '#AB8141',
  textGrey: '#979797',
  white: '#fff',
  skyblueBorder: '#91D5FF',
  skyblueBg: '#E6F7FF',
  skyBllueDark: '#1890FF',
  switchGreen: '#64B157',
  disabledSwitch: '#E0E0E0',
  lightGrey: '#D3D3D3',
  greyButton: '#E5E5E5',
  selfAttBorder: 'rgba(24, 144, 255, 0.5)',
  transparent: 'transparent',
  lighPink: '#E3496E',
  yellow: '#FEECB6',
  greyBackground: '#F2F3F2',
  lightGreen: '#F9E8E8',
  lightRed: '#E9F6E2',
  slightLightGrey: '#D1D1D1',
  darkGreen: '#56893E',
  greyShade: '#888888',
  oldSilver: '#828282',
  greyBg2: '#F2F2F2',
  azuireishWhite: '#DFF5EF',
  antiFlashWhite: '#F2F2F2',
  textGrey2: '#828282',
  toastError: '#F23015',
  toastSuccess: '#56893E',
  blackTooltip: '#333333',
  whiteTooltip: '#F2F2F2',
  //================== new Theme Colors =============>
  primaryColor: '#F56B57',
  secondaryColor: '#3863F0',
  tertiaryColor: '#2CBE96',
  warning: '#F8B24F',
  philippineGray: '#909090',
  gainsboro: '#DDDDDD',
  softOrchid: '#EBEFFE',
  roseRed: '#F23015',
  darkPrimary: '#A14335',
  poinGold: '#F4A32F',
  softClover: '#D5F2EA',
  clover: '#2CBE96',
  darkClover: '#1A725A',
  sunset: '#FF4D00',
  lightRose: '#f56c57',
  softCarnation: '#FEF0EE',
  mist: '#F4F4F4',
  dimGrey: '#D9D9D9',
  carnationRed: '#F56B57',
  softSunflower: '#FEF0DC',
  aliceBlue: '#ECF0FF',
  greyOutline: '#DEDEDE',
  darkSunFlower: '#A17230',
  softRose: '#FEEAE8',
};
