import Constants from 'expo-constants';

const config = Constants.expoConfig.extra;

export default {
  API_URL: config.API_URL,
  API_GAJITIM: config.API_GAJITIM,
  NEW_GG_URL: config.NEW_GG_URL,
  API_LOAN_URL: config.API_LOAN_URL,
  ENV_SENTRY: config.ENV_SENTRY,
  GRAPHQL_URL: config.GRAPHQL_URL,
  MEDIA_STORAGE: config.MEDIA_STORAGE,
  WHATSAPP_URL: config.WHATSAPP_URL,
  AMPLITUDE_API_KEY: config.AMPLITUDE_API_KEY,
  DYNAMICLINK_API_KEY: config.DYNAMICLINK_API_KEY,
};
