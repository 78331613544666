import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { StyleSheet } from 'react-native';
import { normalize } from '../../../sharedStyle/helper';

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderColor: colors.disabled_smoke,
    paddingBottom: normalize(8),
    paddingHorizontal: normalize(15),
  },
  principleRow: { flexDirection: 'row', marginTop: normalize(12) },
  principleAmtStyle: { color: colors.success_cactus, marginLeft: normalize(4) },
  metaRow: { flexDirection: 'row', marginTop: normalize(4) },
  metaTitleStyle: { fontSize: normalize(11.5) },
  metaInfoStyle: {
    fontSize: normalize(12),
    marginTop: normalize(3),
    width: normalize(87),
  },
  tenureBubble: {
    borderRadius: normalize(38),
    height: normalize(20),
    width: normalize(20),
    backgroundColor: colors.warning_softSunflower,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tenureTxtStyle: {
    fontSize: normalize(12),
    color: colors.warning_darkSunflower,
  },
  containerAdj: { marginLeft: normalize(18) },
  installAmtTxt: {
    fontSize: normalize(12),
    marginTop: normalize(3),
    width: normalize(88),
  },
  rightArrowContainer: { alignItems: 'center', justifyContent: 'center' },
});
