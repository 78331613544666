import { MODAL_REDUCER_CASES } from '../reducer/Modals';

export const showEWAModal = (source) => ({
  type: MODAL_REDUCER_CASES.SET_SHOW_EWA_MODAL,
  payload: {
    source,
    show: true,
  },
});

export const hideEWAModal = () => ({
  type: MODAL_REDUCER_CASES.SET_SHOW_EWA_MODAL,
  payload: {
    source: null,
    show: false,
  },
});

export const showReasonsModal = () => ({
  type: MODAL_REDUCER_CASES.SET_SHOW_REASONS_MODAL,
  payload: true,
});

export const hideReasonsModal = () => ({
  type: MODAL_REDUCER_CASES.SET_SHOW_REASONS_MODAL,
  payload: false,
});
