import { StyleSheet } from 'react-native';
import { normalize } from '../../../sharedStyle/helper';
import colors from '@gaji-gesa/gg-react-shared/src/colors';

export const styles = StyleSheet.create({
  container: {
    marginHorizontal: normalize(8),
    borderRadius: normalize(12),
    backgroundColor: colors.warning_softSunflower,
    paddingVertical: normalize(10),
    alignItems: 'center',
    flex: 1,
  },
  poinContainer: { flexDirection: 'row', alignItems: 'center' },
  benifitPoinTextStyle: {
    fontSize: normalize(15),
    marginLeft: normalize(5),
    color: colors.warning_darkSunflower,
  },
  benifitTextStyle: {
    fontSize: normalize(12),
    textAlign: 'center',
    marginTop: normalize(5),
  },
  benifitDescStyle: {
    fontSize: normalize(10),
    textAlign: 'center',
    marginTop: normalize(5),
  },
});
