import React, { useCallback } from 'react';
import {
  FlatList,
  Platform,
  Pressable,
  TouchableOpacity,
  View,
} from 'react-native';
import { colors } from '@style/colors';
import MyModal from '@atoms/MyModal';
import MyText from '@atoms/MyText';
import ExpoAnt from '@expo/vector-icons/AntDesign';
import RNAnt from 'react-native-vector-icons/AntDesign';
import { styles } from './style';

const Close = Platform.OS === 'web' ? ExpoAnt : RNAnt;

const NominalListSheet = ({ list, onPress, visible, onPressModal }) => {
  const renderItem = useCallback(
    ({ item }) => (
      <Pressable
        onPress={() => {
          onPress(item);
        }}
        android_ripple={{ color: colors.lightGrey }}
        style={{ padding: 15 }}
      >
        <MyText h4>{item.name}</MyText>
      </Pressable>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list]
  );

  const keyExtractor = (item) => item.id.toString();

  return (
    <MyModal
      isVisible={visible}
      onPress={onPressModal}
      containerStyle={styles.modal}
    >
      <View style={styles.container}>
        <TouchableOpacity
          onPress={onPressModal}
          style={{ alignSelf: 'flex-end', padding: 15 }}
        >
          <Close name="close" size={20} color={colors.grey} />
        </TouchableOpacity>
        <MyText
          h5
          isRegular={false}
          customStyle={{
            color: colors.blue,
            paddingHorizontal: 15,
            marginBottom: 10,
          }}
        >
          Pilih Produk:
        </MyText>
        <FlatList
          data={list}
          keyExtractor={keyExtractor}
          maxToRenderPerBatch={15}
          initialNumToRender={15}
          renderItem={renderItem}
        />
      </View>
    </MyModal>
  );
};

export default React.memo(NominalListSheet);
