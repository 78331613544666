import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import PropTypes from 'prop-types';
import MyText from '@atoms/MyText';
import styles from './style';
import { colors } from '@gaji-gesa/gg-react-shared/src/appColors';
import Arrow from 'react-native-vector-icons/MaterialIcons';
import { getBankDetailsUi } from './BankTransferInfo';
const BankTransferInfo = ({
  title,
  placeholder,
  bankName,
  accountNumber,
  beneficiaryName,
  onPress,
}) => {
  return (
    <View style={styles.mainContainer}>
      <MyText h2>{title}</MyText>
      <TouchableOpacity
        onPress={onPress}
        activeOpacity={0.5}
        style={styles.contentContainer}
      >
        {getBankDetailsUi(
          placeholder,
          bankName,
          accountNumber,
          beneficiaryName
        )}
        <Arrow name={'keyboard-arrow-right'} color={colors.black} size={25} />
      </TouchableOpacity>
    </View>
  );
};

BankTransferInfo.propTypes = {
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  bankName: PropTypes.string,
  accountNumber: PropTypes.string,
  beneficiaryName: PropTypes.string,
  onPress: PropTypes.func.isRequired,
};
BankTransferInfo.defaultProps = {};
export default BankTransferInfo;
