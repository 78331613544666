import { gql } from '@apollo/client';

const DAILY_BALANCE_PAYMENTCYCLE = gql`
  query getDailyBalance($paymentCycleId: bpchar) {
    gg_daily_balance(limit: 1, where: { paymentCycleId: { _eq: $paymentCycleId } }, order_by: { createdAt: desc }) {
      availableBalance
      totalWorkingDay
      circleDays
      usedBalance
      salaryWithCappedAmount
      maxWithdrawalAmount
      isLockPeriod
    }
  }
`;

const DAILY_BALANCE_PAYMENTCYCLE_HOMEPAGE = gql`
  query getDynamicAccounts($employeeId: Int) {
    employee_account(where: { status: { _eq: "ENABLED" }, employeeId: { _eq: $employeeId } }) {
      accountName
      availableBalance
      createdAt
      id
      status
      updatedAt
      account_type {
        accountTypeName
      }
    }
  }
`;

const GET_TRANSACTION_REFERENCE_NUMBER = gql`
  query referenceNumber($transactionHistoryId: uuid) {
    transaction_history_reference(where: { transactionHistoryId: { _eq: $transactionHistoryId } }) {
      referenceNumber
    }
  }
`;

const TRANSACTION_SUMMARY_DA = gql`
  query TransactionSummary($accountNo: Int, $paymentCycleId: uuid) {
    balance: employee_account(where: { id: { _eq: $accountNo } }) {
      availableBalance
    }
    totalExpenses: account_transaction_history_aggregate(
      where: {
        accountNumber: { _eq: $accountNo }
        paymentCycleId: { _eq: $paymentCycleId }
        positionType: { _eq: "DECREASE" }
        transaction_details: { transStatus: { _nin: ["REJECTED", "FAILED"] } }
      }
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
    totalIncome: account_transaction_history_aggregate(
      where: {
        accountNumber: { _eq: $accountNo }
        paymentCycleId: { _eq: $paymentCycleId }
        positionType: { _eq: "INCREASE" }
        transaction_details: { transStatus: { _nin: ["REJECTED", "FAILED"] } }
      }
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`;

const TRANSACTION_LIST_DA = gql`
  query ListTransactionHistory($paymentCycleId: uuid, $accountNum: Int, $limit: Int, $offset: Int) {
    account_transaction(
      where: { accountNumber: { _eq: $accountNum }, paymentCycleId: { _eq: $paymentCycleId } }
      limit: $limit
      offset: $offset
      order_by: { createdAt: desc }
    ) {
      transactionId: id
      transReason
      transactionReasonId: transReasonId
      transactionCategoryId: transactionCategoryId
      transStatus
      transAmount
      transRequestedAmount: transAmount
      transReceivedAmount: transTotalAmount
      transFee: transAmountFee
      transDate: createdAt
      employeeId
      employerId
      createdAt
      accountNumber
      paymentCycleId
      sharingFee
      updatedAt
      updatedBy
      userId
      employee_account {
        account_type {
          moneyAccount: accountTypeName
        }
      }
      account_transaction_history {
        transHistoryId: id
        positionType
        description
        notes
      }
      gg_transaction_reason {
        transactionType
        transReason: reason
        tag
      }
      voucher_list {
        voucherCode: voucherCode
      }
      aj_ayopop {
        token
        redeemUrl
      }
      gg_transaction_category {
        displayName: displayname
      }
    }
  }
`;

export {
  DAILY_BALANCE_PAYMENTCYCLE,
  DAILY_BALANCE_PAYMENTCYCLE_HOMEPAGE,
  GET_TRANSACTION_REFERENCE_NUMBER,
  TRANSACTION_LIST_DA,
  TRANSACTION_SUMMARY_DA,
};
