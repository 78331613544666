import { StyleSheet } from 'react-native';
import { colors } from '@gaji-gesa/gg-react-shared/src/appColors';

const styles = StyleSheet.create({
  mainContainer: {
    marginVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textContainer: {
    flex: 1,
  },
  greyText: {
    color: colors.philippineGray,
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  bankName: {
    flex: 1,
    maxWidth: '90%',
  },
});

export default styles;
