import { gql } from '@apollo/client';
const MISSION_DATA_QUERY = gql`
  query mission($employeeId: Int) {
    employee_mission(where: { employeeId: { _eq: $employeeId }, employeeTier: { tier: { enabled: { _eq: true } } } }) {
      mission {
        createdAt
        description
        displayOrder
        goal
        goalType
        id
        missionCategoryId
        mission_category {
          categoryName
          createdAt
          displayOrder
          id
        }
        tierId
        updatedAt
      }
      createdAt
      employeeId
      expiryDate
      goalProgress
      id
      missionId
      status
      updatedAt
      employeeTier {
        updatedAt
        tierId
        status
        id
        expiryDate
        employeeId
        createdAt
        tier {
          createdAt
          displayOrder
          id
          tierName
          tierReward
          updatedAt
          applicationConfigs
        }
      }
    }
  }
`;

export { MISSION_DATA_QUERY };
