import { StyleSheet } from 'react-native';
import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { normalize } from '../../../sharedStyle/helper';

const styles = StyleSheet.create({
  titleTextContainer: {
    paddingHorizontal: normalize(15),
    marginTop: normalize(15),
    backgroundColor: colors.neutral_cotton,
  },
  textStyle: {
    fontSize: normalize(14),
  },
});
export default styles;
