import React from 'react';
import { View, Pressable } from 'react-native';
import { normalize } from '../../../sharedStyle/helper';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import MIcon from 'react-native-vector-icons/MaterialIcons';
import MyText from '@atoms/MyText';
import Collapsible from 'react-native-collapsible';
import Row from '../Row';
import styles from './style';

const AbsoluteCard = ({
  isCollapsed,
  handleColllapsePress,
  ewaBalance,
  withdrawalLimit,
  totalAccuredSalary,
  workingDays,
  daySalary,
  totalExpense,
  handleLimitPress,
}) => (
  <View style={styles.absoluteContainer}>
    <View style={styles.topCotainerRow}>
      <MCIcon
        name="wallet"
        size={normalize(15)}
        color="black"
        style={styles.iconStyle}
      />
      <MyText customStyle={styles.titleText} isRegular={false}>
        Akun Gaji
      </MyText>
    </View>

    <MyText isRegular={false} customStyle={styles.ewaBalance}>
      {ewaBalance}
    </MyText>

    <View style={styles.limitRowStyle}>
      <MyText customStyle={styles.limitTextStyle}>
        Limit GajiGesa Periode Ini
      </MyText>
      <Pressable
        onPress={handleLimitPress}
        style={styles.collapsibleSubValueRow}
      >
        <MyText isRegular={false} customStyle={styles.limitValueStyle}>
          {withdrawalLimit}
        </MyText>
        <MIcon name="keyboard-arrow-right" size={normalize(15)} color="black" />
      </Pressable>
    </View>

    <Pressable
      style={styles.collapsibleContainer}
      onPress={handleColllapsePress}
    >
      <MyText isRegular={false} customStyle={styles.accuredTitleText}>
        Total Gaji Hingga Hari Ini
      </MyText>

      <View style={styles.collapsibleSubValueRow}>
        <MyText isRegular customStyle={styles.accuredValueText}>
          {totalAccuredSalary}
        </MyText>
        <MIcon
          name={isCollapsed ? 'keyboard-arrow-down' : 'keyboard-arrow-up'}
          size={20}
        />
      </View>
    </Pressable>
    <Collapsible collapsed={isCollapsed}>
      <Row title="Total Hari Kerja" value={`${workingDays} Hari`} />
      <Row
        title="x Gaji Per Hari"
        value={daySalary}
        containerStyle={styles.rowAdj}
      />
    </Collapsible>

    <View style={styles.totalExpsenseRowContainer}>
      <MyText isRegular={false} customStyle={styles.totalExpenseText}>
        Total Pengeluaran
      </MyText>
      <MyText isRegular customStyle={styles.totalExpenseValue}>
        {totalExpense}
      </MyText>
    </View>
  </View>
);

export default AbsoluteCard;
