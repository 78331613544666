import { StyleSheet } from 'react-native';
import { colors } from '@gaji-gesa/gg-react-shared/src/appColors';
import { Font } from '@style/Fonts';

const styles = StyleSheet.create({
  contentContainer: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.philippineGray,
    marginTop: 10,
    paddingVertical: 10,
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mainContainer: {
    marginTop: 10,
    marginBottom: 15,
  },
  bankNameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  textInput: {
    flex: 1,
    paddingVertical: 0,
    fontFamily: Font.Nunito_Regular,
  },
  errorText: {
    marginTop: 2,
    color: colors.roseRed,
    marginStart: 3,
  },
});

export default styles;
