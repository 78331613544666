import colors from '@gaji-gesa/gg-react-shared/src/colors';
import { StyleSheet } from 'react-native';
import { normalize } from '../../sharedStyle/helper';

const styles = StyleSheet.create({
  poinCointainer: {
    backgroundColor: colors.neutral_mist,
    marginHorizontal: normalize(12),
    marginVertical: normalize(9),
    borderRadius: normalize(9),
    paddingVertical: normalize(13.5),
    elevation: 10,
  },
  coinInfo: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: normalize(23),
  },
  titleStyle: { marginLeft: normalize(2), fontSize: normalize(13) },
  rightContainer: { flexDirection: 'row', alignItems: 'center' },
  coinTextStyle: { marginLeft: normalize(9) },
  poinCta: {
    backgroundColor: colors.primary_carnation,
    justifyContent: 'center',
    flexDirection: 'row',
    borderRadius: normalize(19),
    alignItems: 'center',
    paddingVertical: normalize(5.5),
    marginHorizontal: normalize(15),
    marginTop: normalize(12),
  },
  rippleColor: { color: colors.primary_darkCarnation },
  ctaTextStyle: {
    color: colors.neutral_mist,
    textAlign: 'center',
    alignSelf: 'center',
    fontSize: normalize(12),
    marginRight: normalize(5),
  },
});
export default styles;
