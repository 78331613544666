export const AUTH_REDUCER_CASES = {
  setAllTokens: 'setAllTokens',
  setUsername: 'setUsername',
  setPhoneNumber: 'setPhoneNumber',
  setRoleId: 'setRoleId',
  setClickCount: 'setClickCount',
  setShowAlert: 'setShowAlert',
  setOtpToken: 'setOtpToken',
  setInitalAuthData: 'setInitalAuthData',
  setAccountNumber: 'setAccountNumber',
  setEmployeeId: 'setEmployeeId',
  setEmployerId: 'setEmployerId',
  setUserRoles: 'setUserRoles',
  setIsGajitimEmployee: 'setIsGajitimEmployee',
  setAutoLogout: 'setAutoLogout',
  setIsGajiTim: 'setIsGajiTim',
  setIsGajiGesa: 'setIsGajiGesa',
  setbURL: 'setbURL',
  setApiKey: 'setApiKey',
  setUserId: 'setUserId',
};

const initialState = {
  accessToken: '',
  otpToken: '',
  refreshToken: '',
  userName: 'user',
  phoneNumber: '',
  isLoggedOut: false,
  roleId: 1,
  accountNumber: '',
  employeeId: 0,
  employerId: 0,
  userRoles: [],
  isGajitimEmployee: false,
  isGajiTim: '',
  isGajiGesa: '',
  bURL: '',
  apiKey: '',
  userId: '',
};

export default (state = initialState, action) => {
  switch (action?.type) {
    case AUTH_REDUCER_CASES.setAccountNumber:
      return {
        ...state,
        accountNumber: action.payload,
      };

    case AUTH_REDUCER_CASES.setInitalAuthData:
      return {
        ...state,
        ...action.payload,
      };

    case AUTH_REDUCER_CASES.setOtpToken:
      return {
        ...state,
        otpToken: action.payload,
      };

    case AUTH_REDUCER_CASES.setAllTokens:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };

    case AUTH_REDUCER_CASES.setUsername:
      return {
        ...state,
        userName: action.payload,
      };

    case AUTH_REDUCER_CASES.setPhoneNumber:
      return {
        ...state,
        phoneNumber: action.payload,
      };

    case AUTH_REDUCER_CASES.setRoleId:
      return {
        ...state,
        // roleId: action.payload.roleId
        roleId: action.payload,
      };

    case AUTH_REDUCER_CASES.setClickCount:
      return {
        ...state,
        clickCount: action.payload.clickCount,
      };

    case AUTH_REDUCER_CASES.setShowAlert:
      return {
        ...state,
        showAlert: action.payload.showAlert,
      };

    case AUTH_REDUCER_CASES.setEmployeeId:
      return {
        ...state,
        employeeId: action.payload,
      };

    case AUTH_REDUCER_CASES.setEmployerId:
      return {
        ...state,
        employerId: action.payload,
      };

    case AUTH_REDUCER_CASES.setUserRoles:
      return {
        ...state,
        userRoles: action.payload,
      };

    case AUTH_REDUCER_CASES.setIsGajitimEmployee:
      return {
        ...state,
        isGajitimEmployee: action.payload,
      };

    case AUTH_REDUCER_CASES.setIsGajiTim:
      return {
        ...state,
        isGajiTim: action.payload,
      };
    case AUTH_REDUCER_CASES.setIsGajiGesa:
      return {
        ...state,
        isGajiGesa: action.payload,
      };
    case AUTH_REDUCER_CASES.setbURL:
      return {
        ...state,
        bURL: action.payload,
      };
    case AUTH_REDUCER_CASES.setApiKey:
      return {
        ...state,
        apiKey: action.payload,
      };
    case AUTH_REDUCER_CASES.setUserId:
      return {
        ...state,
        userId: action.payload,
      };

    case AUTH_REDUCER_CASES.setAutoLogout:
      if (action.payload) {
        action.payload();
      }
      return initialState;

    default:
      return state;
  }
};
