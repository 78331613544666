import { StyleSheet } from 'react-native';
import { normalize } from '../../../sharedStyle/helper';
import colors from '@gaji-gesa/gg-react-shared/src/colors';

export const styles = StyleSheet.create({
  containerStyle: {
    flexDirection: 'row',
    marginHorizontal: normalize(15),
    borderWidth: 1,
    borderColor: colors.disabled_smoke,
    borderRadius: normalize(11),
  },

  salaryAccount: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: normalize(19),
    paddingVertical: normalize(17),
  },
  textContainer: { paddingLeft: normalize(14), justifyContent: 'center' },
  labelText: { fontSize: normalize(12.5), color: colors.secondary_orchid },
  amountStyle: { fontSize: normalize(14), marginTop: normalize(2) },
});
