import LoadingButton from '@atoms/LoadingButton';
import PropTypes from 'prop-types';
import MyText from '@atoms/MyText';
import { colors } from '@gaji-gesa/gg-react-shared/src/appColors';
import { normalize } from '../../sharedStyle/helper';
import React, { useEffect, useMemo, useRef } from 'react';
import { Platform, TextInput, View } from 'react-native';
import AccountSelector from '@atoms/AccountSelector';
import {
  convertDotsToNum,
  numberWithDots,
  textMaximumValue,
  textMinimumValue,
} from '@gaji-gesa/gg-react-shared/src/utils/sharedUtils';
import { Slider } from '@miblanchard/react-native-slider';
import RNEditIcon from 'react-native-vector-icons/MaterialIcons';
import ExpoEditIcon from '@expo/vector-icons/MaterialIcons';
import styles from './styles';

const EditIcon = Platform.OS === 'web' ? ExpoEditIcon : RNEditIcon;

const DAWithdrawalCard = ({
  onPressAccountSelector,
  onPress,
  amount,
  selectedAccount,
  loading,
  isModal,
  maximumValue,
  minimumValue,
  onValueChange,
  onSlidingComplete,
  step,
  isEditable,
  onPressEdit,
}) => {
  const inputRef = useRef();

  const shiftedAmount = useMemo(() => {
    if (amount) {
      const newAmount = amount.slice();
      return newAmount.shift();
    }
    return 0;
  }, [amount]);
  const confirmButtonDisabled =
    shiftedAmount <= 0 || shiftedAmount > maximumValue;
  const onChangeText = (text) => {
    onValueChange([convertDotsToNum(text)]);
  };

  useEffect(() => {
    if (isEditable) {
      inputRef.current.focus();
    }
  }, [isEditable]);

  const showAccountSelector = () => {
    if (!isModal)
      return (
        <AccountSelector
          selectedAccount={selectedAccount}
          onPress={onPressAccountSelector}
        />
      );
    return null;
  };

  return (
    <View style={styles.cardContainer}>
      <View style={styles.subContainer}>
        <MyText
          isRegular={false}
          customStyle={{ color: colors.philippineGray }}
        >
          Nominal Penarikan
        </MyText>

        {showAccountSelector()}
      </View>
      <View style={styles.inputContainer}>
        <MyText isRegular={false} h4 customStyle={styles.textAmount}>
          Rp
        </MyText>
        <TextInput
          ref={inputRef}
          onChangeText={onChangeText}
          editable={isEditable}
          style={styles.input}
          keyboardType="number-pad"
          returnKeyType="done"
          value={numberWithDots(shiftedAmount)}
        />
        <EditIcon
          onPress={onPressEdit}
          size={20}
          color={colors.black}
          name="edit"
        />
      </View>

      <View accessibilityLabel="withdrawAmount" style={styles.sliderContainer}>
        <Slider
          style={styles.sliderStyle}
          trackStyle={styles.sliderTrack}
          thumbStyle={styles.sliderThumb}
          minimumTrackTintColor={colors.orange}
          maximumValue={maximumValue}
          minimumValue={minimumValue}
          onValueChange={onValueChange}
          onSlidingComplete={onSlidingComplete}
          step={step}
          value={
            shiftedAmount > maximumValue
              ? maximumValue
              : shiftedAmount || minimumValue
          }
        />
      </View>
      {/* Saldo Tersedia */}
      <View style={styles.tersediaContainer}>
        <MyText h1 style={styles.saldoTersedia}>
          Rp{textMinimumValue(maximumValue, minimumValue)}
        </MyText>
        <MyText h1 style={styles.saldoTersedia}>
          Rp{textMaximumValue(maximumValue, minimumValue)}
        </MyText>
      </View>
      <LoadingButton
        container={styles.button}
        textStyle={{
          fontSize: normalize(16),
        }}
        disabledStyle={styles.disabledButton}
        disabled={confirmButtonDisabled}
        loading={loading}
        onPress={onPress}
        buttonColor={colors.orange}
        text="Tarik Dana Sekarang"
        textColor={colors.white}
        textTransform="none"
      />
    </View>
  );
};

DAWithdrawalCard.propTypes = {
  onPressAccountSelector: PropTypes.func,
  onPress: PropTypes.func.isRequired,
  amount: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedAccount: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool.isRequired,
  isModal: PropTypes.bool,
  maximumValue: PropTypes.number.isRequired,
  minimumValue: PropTypes.number.isRequired,
  onValueChange: PropTypes.func.isRequired,
  onSlidingComplete: PropTypes.func,
  step: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  onPressEdit: PropTypes.func.isRequired,
};

DAWithdrawalCard.defaultProps = {
  isModal: false,
  onPressAccountSelector: null,
  onSlidingComplete: null,
};

export default React.memo(DAWithdrawalCard);
