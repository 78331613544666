import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import MyText from '@atoms/MyText';
import { styles } from './style';

const ProfileInfo = ({ userName, phoneNumber, profileAvatar }) => {
  return (
    <View style={styles.profileInfoContainer}>
      <View style={styles.iconsStyle}>{profileAvatar}</View>
      <View style={styles.detailContainer}>
        <MyText isRegular={false} customStyle={styles.nameStyle}>
          Hai, {userName}
        </MyText>

        <View style={styles.phoneContainer}>
          <MyText customStyle={styles.phoneStyle}>{phoneNumber}</MyText>
        </View>
      </View>
    </View>
  );
};

ProfileInfo.propTypes = {
  userName: PropTypes.string,
  phoneNumber: PropTypes.string,
  profileAvatar: PropTypes.string.isRequired,
};
ProfileInfo.defaultProps = {
  userName: '',
  phoneNumber: '',
};
export default ProfileInfo;
