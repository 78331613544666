import { BILLPAYMENT_CASES } from '../reducer/NewBillPayment';

export const setBillPaymentList = (data, loading = false) => ({
  type: BILLPAYMENT_CASES.setBillPaymentList,
  payload: {
    list: data,
    loading: loading,
  },
});

export const setBillPaymentError = (data) => ({
  type: BILLPAYMENT_CASES.setBillPaymentError,
  payload: data,
});

export const setBillPaymentLoading = (data) => ({
  type: BILLPAYMENT_CASES.setBillPaymentLoading,
  payload: data,
});

export const setpointsBillPayment = (data) => ({
  type: BILLPAYMENT_CASES.setpointsBillPayment,
  payload: data,
});

export const setWorkAllowanceBillPayment = (data) => ({
  type: BILLPAYMENT_CASES.setWorkAllowanceBillPayment,
  payload: data,
});
