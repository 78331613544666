import { Platform, StyleSheet } from 'react-native';
import { colors } from '@gaji-gesa/gg-react-shared/src/appColors';
import { normalize } from '../../../sharedStyle/helper';

const styles = StyleSheet.create({
  contentContainer: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.philippineGray,
    marginTop: 10,
    paddingVertical: 10,
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mainContainer: {
    marginVertical: 10,
  },
  bankNameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  bankName: {
    maxWidth: Platform.select({ android: '55%', web: '60%' }),
    height: normalize(20),
  },
  accountNumber: {
    maxWidth: Platform.select({ android: '70%', web: '70%' }),
    height: normalize(20),
  },
  bankContainer: {
    flex: 1,
    maxWidth: Platform.select({ android: '70%', web: '90%' }),
  },
});

export default styles;
