import React from 'react';
import { Text, View, StyleSheet, Pressable, Platform } from 'react-native';
import ExpoIonIcon from '@expo/vector-icons/Ionicons';
import RNIonIcon from 'react-native-vector-icons/Ionicons';
import { SvgUri } from 'react-native-svg';

const Icon = Platform.OS === 'web' ? ExpoIonIcon : RNIonIcon;

/* Style Import */
import { normalize, normalizeHeight } from '@style/helper';

const Header = ({ handleClose, title, icon, titleStyle, container }) => (
  <Pressable
    onPress={() => handleClose()}
    style={[styles.container, container]}
    accessibilityLabel="closeButton"
  >
    <View style={styles.title}>
      {title && (
        <>
          {icon && <SvgUri uri={icon} style={styles.titleIcon} />}
          <Text style={[styles.titleText, titleStyle]}>{title}</Text>
        </>
      )}
    </View>

    <Text style={styles.closeText}>
      <Icon name="close" style={styles.close} />
    </Text>
  </Pressable>
);

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    height: normalizeHeight(50),
    paddingHorizontal: 20,
    paddingTop: 15,
    marginBottom: -10,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
    height: normalizeHeight(25),
  },
  titleIcon: { marginTop: 4, marginRight: 15 },
  titleText: {
    fontFamily: 'Nunito-Bold',
    fontSize: normalize(14),
    marginTop: 5,
  },
  closeText: { textAlign: 'right', height: normalizeHeight(30) },
  close: { fontSize: normalize(25), color: '#d1d1d1' },
});

export default Header;
