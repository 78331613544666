import React from 'react';
import PropTypes from 'prop-types';
import { FlatList, Pressable, TouchableOpacity, View } from 'react-native';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { colors } from '@style/colors';
import MyText from '@atoms/MyText';
import styles from './style';
import MyModal from '@atoms/MyModal';

function shouldShowCheckMark(item, selectedAccount) {
  if (
    item?.isSelected ||
    (selectedAccount &&
      selectedAccount !== null &&
      selectedAccount?.constantAccountName === item.constantAccountName)
  ) {
    return <AntDesign name="check" size={20} color={colors.orange} />;
  }
  return false;
}

const DropDownModal = ({
  onClose,
  visible,
  data,
  onPressItem,
  hasIcon,
  selectedAccount,
}) => {
  const renderItem = ({ item }) => (
    <Pressable
      android_ripple={{ color: colors.lightGrey }}
      onPress={() => onPressItem(item)}
      style={styles.item}
    >
      {hasIcon && item.icon}
      <MyText customStyle={{ flex: 1, marginStart: hasIcon ? 10 : 0 }}>
        {selectedAccount ? item.accountName : item.text}
      </MyText>
      {shouldShowCheckMark(item, selectedAccount)}
    </Pressable>
  );

  const keyExtractor = (item) => item.text;

  return (
    <MyModal
      onPress={onClose}
      containerStyle={styles.modal}
      isVisible={visible}
    >
      <View style={styles.innerContainer}>
        <TouchableOpacity
          onPress={onClose}
          style={{ alignSelf: 'flex-end', padding: 15 }}
        >
          <AntDesign name="close" size={20} color={colors.black} />
        </TouchableOpacity>
        <MyText isRegular={false} customStyle={styles.titleText}>
          Pilih Data
        </MyText>
        <FlatList
          data={data}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          ItemSeparatorComponent={() => <View style={styles.dividerStyle} />}
        />
      </View>
    </MyModal>
  );
};

DropDownModal.defaultProps = {
  hasIcon: false,
  selectedAccount: undefined,
};

DropDownModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  onPressItem: PropTypes.func.isRequired,
  hasIcon: PropTypes.bool,
  selectedAccount: PropTypes.shape({
    constantAccountName: PropTypes.string,
  }),
};

export default DropDownModal;
