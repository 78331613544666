import React from 'react';
import { View, Image } from 'react-native';
import MyText from '@atoms/MyText';
import { styles } from './style';

const PoweredBy = ({ title, imagePath }) => (
  <View style={styles.containerStyle}>
    <MyText customStyle={styles.textStyle}>{title}</MyText>

    <Image
      style={styles.imageStyle}
      resizeMode={'contain'}
      source={imagePath}
    />
  </View>
);

export default PoweredBy;
